import { GraphQLClient } from 'graphql-request';
import { domain } from '../utils/variables';

const endpoint = `${domain?.strapi}/graphql`;

const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    authorization: '',
  },
});

const makeGraphQuery = async (query, params) => {
  const data = await graphQLClient.request(query, params);
  // console.log(JSON.stringify(data, undefined, 2));
  return data;
};

export default makeGraphQuery;
