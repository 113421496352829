import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container } from '@mui/material';
import RecommendationList from '../../list/recommendationList';
import RecommendationsEngine from '../recommendationsEngine';
import { useFeatureToggle } from '../../../hooks';
import Referral from '../referral';
// import Feedback from '../feedback';
// import QuickLinks from '../quickLinks';
// import ContactUs from '../contactUs';

const MarketingAds = ({ surveySlug }) => {
  const doFeatureToggle = useFeatureToggle();
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const isReferCustomerActive = doFeatureToggle('referACustomer');
  return (
    <Grid container direction="row" spacing={2}>
      {/* isAuthenticated && (
        <Grid item xs={12}>
          <RecommendationsEngine />
        </Grid>
      ) */}
      {/* {isAuthenticated && (
          <Grid item xs={12}>
            <RecommendationList />
          </Grid>
        )} */}
      {/* <Grid item xs={12}>
        <ContactUs />
      </Grid> */}
      {/* <Grid item xs={12}>
        <QuickLinks />
      </Grid> */}
      {isReferCustomerActive && (
        <Grid item xs={12}>
          <Referral />
        </Grid>
      )}

      {/* <Grid item xs={12}>
        <Feedback eventSlug={surveySlug} authRequired />
      </Grid> */}
    </Grid>
  );
};

export default MarketingAds;
