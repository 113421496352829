export const GET_INVESTMENTS_API_REQUEST = 'GET_INVESTMENTS_API_REQUEST';
export const GET_INVESTMENTS_API_SUCCESS = 'GET_INVESTMENTS_API_SUCCESS';
export const GET_INVESTMENTS_API_ERROR = 'GET_INVESTMENTS_API_ERROR';

export const GET_INVESTMENT_CALCULATIONS_API_REQUEST =
  'GET_INVESTMENT_CALCULATIONS_API_REQUEST';
export const GET_INVESTMENT_CALCULATIONS_API_SUCCESS =
  'GET_INVESTMENT_CALCULATIONS_API_SUCCESS';
export const GET_INVESTMENT_CALCULATIONS_API_ERROR =
  'GET_INVESTMENT_CALCULATIONS_API_ERROR';

export const ADD_INVESTMENT_ACCOUNT_SUCCESS = 'ADD_INVESTMENT_ACCOUNT_SUCCESS';

export const CHANGE_BALANCE_DATE_SUCCESS = 'CHANGE_BALANCE_DATE_SUCCESS';
