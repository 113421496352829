import axios from 'axios';
import {
  apiCredentials,
  domain,
  customerTimeOut,
  agentTimeOut,
  agentType,
} from '../utils/variables';
import { getUserIds } from '../hooks/useAuth';

// const token = JSON.parse(sessionStorage.getItem('data'));
// const token = user.data.id; /*take only token and save in token variable*/
// headers: { Authorization: `Bearer ${mytoken}` },
axios.defaults.timeout = customerTimeOut;
const routerInstance = axios.create({
  baseURL: domain.router,
  timeout: customerTimeOut,
});

const strapiInstance = axios.create({
  baseURL: domain.strapi,
  timeout: customerTimeOut,
});

const crmInstance = axios.create({
  baseURL: domain.support,
  timeout: customerTimeOut,
  withCredentials: false,
});

export const getHeaders = ({
  apiKeys,
  state,
  hasLoginCredentials = false,
  upload = false,
  params,
  initialHeaders,
}) => {
  const userIds = getUserIds(state);
  const headers = { ...initialHeaders };

  headers['Access-Control-Allow-Headers'] = '*';
  headers['Access-Control-Allow-Origin'] = '*';
  headers['Content-Type'] = upload ? params.type : 'application/json;charset=UTF-8';
  headers['Content-Disposition'] = upload ? `attachment; filename=${params.name}` : null;
  headers.Accept = 'application/json';
  headers['Access-Control-Allow-Methods'] = '*';
  headers['Cache-Control'] = 'no-cache';
  headers['Access-Control-Allow-Credentials'] = hasLoginCredentials;
  if (apiKeys?.subscriptionKey)
    headers['Ocp-Apim-Subscription-Key'] = apiKeys?.subscriptionKey;
  if (apiKeys?.apiKey) headers.apikey = apiKeys?.apiKey;
  if (apiKeys?.apiSecret) headers.apisecret = apiKeys?.apiSecret;
  if (userIds?.accessToken) {
    headers.accesstoken = userIds?.accessToken;

    // headers.Authorization = `Bearer ${userIds?.accessToken}`;
  }

  if (userIds?.authId) headers.authid = userIds?.authId;
  if (userIds?.customerId) headers.customerid = userIds?.customerId;
  if (userIds?.agentId) headers.agentid = userIds?.agentId;
  headers.isagent = userIds?.isAgent;
  if (userIds?.usertype) headers.usertype = userIds?.usertype;
  if (state?.auth?.selectedClient?.id) headers.id = state?.auth?.selectedClient?.id;
  if (userIds?.usersubtype) headers.usersubtype = userIds?.usersubtype;
  if (state?.locations?.yourLocation?.alpha3)
    headers.territory = state?.locations?.yourLocation?.alpha3;
  headers.mobile = state?.temp?.isMobile || false;

  return headers;
};

export const routerGetCall = (state, _url, params) => {
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  const timeOut = isAgent ? agentTimeOut : customerTimeOut;
  const abort = axios.CancelToken.source();
  const timeout = setTimeout(() => abort.cancel(`Timeout of ${timeOut}ms.`), timeOut);

  let baseURL;
  let url = _url;
  const urlArray = url?.split('/');
  const serviceType = urlArray && urlArray.length > 1 ? urlArray[1] : url;
  if (
    domain.subdomains[serviceType] &&
    domain.subdomains[serviceType] !== domain.router
  ) {
    baseURL = domain.subdomains[serviceType]; // 'https://a09e-190-213-94-12.ngrok.io';
    urlArray.splice(1, 1);
    url = urlArray.join('/'); // '/viewPortfolio/insurance';
  }
  return routerInstance({
    method: 'get',
    url,
    params,
    timeout: timeOut,
    baseURL,
    cancelToken: abort.token,
    headers: getHeaders({ apiKeys: apiCredentials, state }),
    paramsSerializer: _serialParams => {
      const serialParams = Object.fromEntries(
        Object.entries(_serialParams).filter(([_, v]) => v != null),
      );
      let result = '';
      Object.keys(serialParams).forEach(key => {
        result += `${key}=${encodeURIComponent(serialParams[key])}&`;
      });
      return result.substr(0, result.length - 1);
    },
  })
    .then(response => {
      // // console.log(JSON.stringify(response.data));
      // console.error(JSON.stringify(response, undefined, 2));
      clearTimeout(timeout);
      if (typeof response.data === 'object') {
        const finalData = {
          ...response.data,
          headers: response.headers,
        };
        return finalData;
      }
      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data && typeof error.response.data === 'object') {
        const finalData = {
          ...error.response.data,
          headers: error.response.headers,
        };

        throw finalData;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const routerPostCall = (
  state,
  _url,
  params,
  hasLoginCredentials,
  loginCredentials,
  initialHeaders,
) => {
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  const timeOut = isAgent ? agentTimeOut : customerTimeOut;
  const abort = axios.CancelToken.source();
  const timeout = setTimeout(() => abort.cancel(`Timeout of ${timeOut}ms.`), timeOut);

  let baseURL;
  let url = _url;
  const urlArray = url?.split('/');
  const serviceType = urlArray && urlArray.length > 1 ? urlArray[1] : url;
  if (
    domain.subdomains[serviceType] &&
    domain.subdomains[serviceType] !== domain.router
  ) {
    baseURL = domain.subdomains[serviceType]; // 'https://a09e-190-213-94-12.ngrok.io';
    urlArray.splice(1, 1);
    url = urlArray.join('/'); // '/viewPortfolio/insurance';
  }

  return routerInstance({
    method: 'post',
    url,
    baseURL,
    timeout: timeOut,
    cancelToken: abort.token,
    data: params, // JSON.stringify(params),
    headers: getHeaders({
      apiKeys: apiCredentials,
      state,
      hasLoginCredentials,
      initialHeaders,
    }),

    auth: hasLoginCredentials
      ? {
          username: loginCredentials.username,
          password: loginCredentials.password,
        }
      : undefined,
  })
    .then(response => {
      // console.log('response:', response);
      // console.log('response.data:', response.data);
      // console.error(JSON.stringify(response, undefined, 2));
      clearTimeout(timeout);
      if (typeof response.data === 'object') {
        const finalData = {
          ...response.data,
          headers: response.headers,
        };
        return finalData;
      }
      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data && typeof error.response.data === 'object') {
        const finalData = {
          ...error.response.data,
          headers: error.response.headers,
        };

        throw finalData;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const routerPatchCall = (
  state,
  _url,
  params,
  hasLoginCredentials,
  loginCredentials,
  initialHeaders,
) => {
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  const timeOut = isAgent ? agentTimeOut : customerTimeOut;
  const abort = axios.CancelToken.source();
  const timeout = setTimeout(() => abort.cancel(`Timeout of ${timeOut}ms.`), timeOut);

  let baseURL;
  let url = _url;
  const urlArray = url?.split('/');
  const serviceType = urlArray && urlArray.length > 1 ? urlArray[1] : url;
  if (
    domain.subdomains[serviceType] &&
    domain.subdomains[serviceType] !== domain.router
  ) {
    baseURL = domain.subdomains[serviceType]; // 'https://a09e-190-213-94-12.ngrok.io';
    urlArray.splice(1, 1);
    url = urlArray.join('/'); // '/viewPortfolio/insurance';
  }

  return routerInstance({
    method: 'patch',
    url,
    baseURL,
    timeout: timeOut,
    cancelToken: abort.token,
    data: params, // JSON.stringify(params),
    headers: getHeaders({
      apiKeys: apiCredentials,
      state,
      hasLoginCredentials,
      initialHeaders,
    }),

    auth: hasLoginCredentials
      ? {
          username: loginCredentials.username,
          password: loginCredentials.password,
        }
      : undefined,
  })
    .then(response => {
      // console.log('response:', response);
      // console.log('response.data:', response.data);
      // console.error(JSON.stringify(response, undefined, 2));
      clearTimeout(timeout);
      if (typeof response.data === 'object') {
        const finalData = {
          ...response.data,
          headers: response.headers,
        };
        return finalData;
      }
      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data && typeof error.response.data === 'object') {
        const finalData = {
          ...error.response.data,
          headers: error.response.headers,
        };

        throw finalData;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const routerDeleteCall = (state, _url, params) => {
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  const timeOut = isAgent ? agentTimeOut : customerTimeOut;
  const abort = axios.CancelToken.source();
  const timeout = setTimeout(() => abort.cancel(`Timeout of ${timeOut}ms.`), timeOut);

  let baseURL;
  let url = _url;
  const urlArray = url?.split('/');
  const serviceType = urlArray && urlArray.length > 1 ? urlArray[1] : url;
  if (
    domain.subdomains[serviceType] &&
    domain.subdomains[serviceType] !== domain.router
  ) {
    baseURL = domain.subdomains[serviceType]; // 'https://a09e-190-213-94-12.ngrok.io';
    urlArray.splice(1, 1);
    url = urlArray.join('/'); // '/viewPortfolio/insurance';
  }

  return routerInstance({
    method: 'delete',
    url,
    baseURL,
    params,
    timeout: timeOut,
    cancelToken: abort.token,
    headers: getHeaders({ apiKeys: apiCredentials, state }),
    // paramsSerializer: serialParams => {
    //  let result = '';
    //  Object.keys(serialParams).forEach(key => {
    //    result += `${key}=${encodeURIComponent(serialParams[key])}&`;
    //  });
    //  return result.substr(0, result.length - 1);
    // },
  })
    .then(response => {
      // // console.log(JSON.stringify(response.data));
      // console.error(JSON.stringify(response, undefined, 2));
      clearTimeout(timeout);
      if (typeof response.data === 'object') {
        const finalData = {
          ...response.data,
          headers: response.headers,
        };
        return finalData;
      }
      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data && typeof error.response.data === 'object') {
        const finalData = {
          ...error.response.data,
          headers: error.response.headers,
        };

        throw finalData;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const routerUploadCall = (state, url, params) => {
  // // console.log('Params', params);
  return routerInstance({
    method: 'post',
    url,
    data: params.image, // JSON.stringify(params),
    headers: getHeaders({ apiKeys: apiCredentials, state, upload: true, params }),
  })
    .then(response => {
      // // console.log(JSON.stringify(response.data));
      // console.error(JSON.stringify(response, undefined, 2));

      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data) {
        throw error.response.data;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const strapiGetCall = (state, url, params) => {
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  const timeOut = isAgent ? agentTimeOut : customerTimeOut;
  const abort = axios.CancelToken.source();
  const timeout = setTimeout(() => abort.cancel(`Timeout of ${timeOut}ms.`), timeOut);

  return strapiInstance({
    method: 'get',
    url,
    params,
    timeout: timeOut,
    cancelToken: abort.token,
  })
    .then(response => {
      // // console.log(JSON.stringify(response.data));
      clearTimeout(timeout);
      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data) {
        throw error.response.data;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const strapiPostCall = (state, url, body) => {
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  const timeOut = isAgent ? agentTimeOut : customerTimeOut;
  const abort = axios.CancelToken.source();
  const timeout = setTimeout(() => abort.cancel(`Timeout of ${timeOut}ms.`), timeOut);

  return strapiInstance({
    method: 'post',
    url,
    data: body, // JSON.stringify(params),
    timeout: timeOut,
    cancelToken: abort.token,
  })
    .then(response => {
      // // console.log(JSON.stringify(response.data));
      clearTimeout(timeout);

      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data) {
        throw error.response.data;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const crmPostCall = (state, url, body) => {
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  const timeOut = isAgent ? agentTimeOut : customerTimeOut;
  const abort = axios.CancelToken.source();
  const timeout = setTimeout(() => abort.cancel(`Timeout of ${timeOut}ms.`), timeOut);

  return crmInstance({
    method: 'post',
    url,
    data: body,
    timeout: timeOut,
    headers: getHeaders({ apiKeys: apiCredentials, state }),
  })
    .then(response => {
      clearTimeout(timeout);
      return response.data;
    })
    .catch(_error => {
      // console.error(JSON.stringify(_error.response, undefined, 2));

      const error = { ..._error };
      if (error?.response?.data) {
        throw error.response.data;
      } else if (error?.response) {
        throw error.response;
      } else {
        throw error;
      }
    });
};

export const getUploadHeaders = (api, state, params) => {
  const userIds = getUserIds(state);
  const headers = {};
  if (api?.subscriptionKey) headers['Ocp-Apim-Subscription-Key'] = api?.subscriptionKey;
  // if (api?.apiKey) headers.apikey = api?.apiKey;
  // if (api?.apiSecret) headers.apisecret = api?.apiSecret;
  if (userIds?.accessToken) {
    headers.accesstoken = userIds?.accessToken;
    // headers.Authorization = `Bearer ${userIds?.accessToken}`;
  }

  if (userIds?.authId) headers.authid = userIds?.authId;
  if (userIds?.customerId) headers.customerid = userIds?.customerId;
  if (userIds?.agentId) headers.agentid = userIds?.agentId;
  headers.isAgent = userIds?.isAgent;
  if (userIds?.usertype) headers.usertype = userIds?.usertype;
  if (state?.auth?.selectedClient?.id) headers.id = state?.auth?.selectedClient?.id;
  if (userIds?.usersubtype) headers.usersubtype = userIds?.usersubtype;
  if (state?.locations?.yourLocation?.alpha3)
    headers.territory = state?.locations?.yourLocation?.alpha3;

  return headers;
};

export default {
  routerGetCall,
  routerPostCall,
  routerDeleteCall,
  strapiGetCall,
  strapiPostCall,
  routerUploadCall,
  crmPostCall,
  routerPatchCall,
};
