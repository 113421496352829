import React from 'react';
import {
  Grid,
  Button,
  // Grid,
  Typography,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useIosInstallPrompt from '../../../hooks/useIosInstallPrompt';
import useWebInstallPrompt from '../../../hooks/useWebInstallPrompt';
import { IosShare } from '../../icons';
import { darkModeLogo, lightModeLogo } from '../../../utils/variables/images';
import { themeTypes } from '../../../themes';
import { DetailsModal } from '../../modal';
import styles from './style';

const useStyles = makeStyles(styles);
const InstallPWA = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  // console.log('iosInstallPrompt: ', iosInstallPrompt);
  const [
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
    userShouldBePromptedToInstallWeb,
  ] = useWebInstallPrompt();
  // console.log('userShouldBePromptedToInstallWeb: ', userShouldBePromptedToInstallWeb);
  // console.log('webInstallPrompt: ', webInstallPrompt);
  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  const isiOS13 = /OS 13/.test(window.navigator.userAgent);

  const title = 'Install our App';
  const onClose = iosInstallPrompt ? handleIOSInstallDeclined : handleWebInstallDeclined;
  const content = (
    <>
      <MuiDialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <img
              alt="companyLogo"
              className={classes.image}
              src={theme.palette.mode === themeTypes.dark ? darkModeLogo : lightModeLogo}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.p1} color="primary">
              Discover a World designed just for you, as you move from the old to the new
              Guardian Group online experience.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.p2} color="text.primary">
              We've been hard at work building our online services and have been
              envisioning ths moment for some time. You are being redirected to our new
              user friendly, self-service platform, with secure access to your entire
              insurance portfolio, all in one place.
            </Typography>
          </Grid>
        </Grid>
      </MuiDialogContent>
      {iosInstallPrompt && (
        <>
          <Typography align="center" variant="h6" sx={{ color: 'text.primary' }}>
            Tap
            <IosShare modern={isiOS13} className={classes.font} height={60} width={60} />
            icon, then click &quot;Add to Home Screen&quot;
          </Typography>
        </>
      )}
      {webInstallPrompt && (
        <MuiDialogActions>
          <Button onClick={handleWebInstallAccepted} className={classes.primaryButton}>
            Click to install and enjoy this new experience.
          </Button>
        </MuiDialogActions>
      )}
    </>
  );

  return (
    <>
      {(iosInstallPrompt || (webInstallPrompt && userShouldBePromptedToInstallWeb)) && (
        <DetailsModal
          fullWidth
          onClose={onClose}
          title={title}
          content={content}
          open={
            iosInstallPrompt || (webInstallPrompt && userShouldBePromptedToInstallWeb)
          }
        />
      )}
    </>
  );
};
export default InstallPWA;
