import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductsList from '../../list/productsList';
import routes from '../../../utils/variables/routes';
import { useFeatureToggle } from '../../../hooks';
import { addWizard } from '../../../actions/wizards';
//
const getQuickActions = ({ doFeatureToggle, dispatch, isAuthenticated }) => {
  const handleNewAppClick = () => {
    dispatch(addWizard.emptyInitialWizardDataAction());
  };

  const actions = [
    {
      title: 'Get a Quote',
      subtitle: 'or Advice',
      customIcon: 'playlist_add',
      background: 'secondary.main',
      iconBackground: 'background.transparent',
      variant: 'filled',
      type: 'action',
      href: routes.wizards.quote,
      active: doFeatureToggle('quickActions.getAQuote'),
      onClick: handleNewAppClick,
    },
    {
      title: 'View',
      subtitle: 'my Portfolio',
      customIcon: 'photo_camera_front',
      background: 'primary.main',
      iconBackground: 'background.transparent',
      variant: 'filled',
      type: 'action',
      href: routes.portfolio.portfolioSummary,
      active: doFeatureToggle('quickActions.myPortfolio'),
    },
    {
      title: 'Make',
      subtitle: 'A Payment',
      customIcon: 'payment',
      background: 'primary.main',
      iconBackground: 'background.transparent',
      variant: 'filled',
      type: 'action',
      href: routes.portfolio.portfolioSummary,
      active: doFeatureToggle('quickActions.makeAPayment'),
    },
    {
      title: 'Submit',
      subtitle: 'A Claim',
      customIcon: 'edit_note',
      background: 'tertiary.main',
      iconBackground: 'background.transparent',
      variant: 'filled',
      type: 'action',
      href: routes.wizards.claim,
      active: doFeatureToggle('quickActions.submitAClaim'),
      onClick: handleNewAppClick,
    },
    {
      title: 'Create',
      subtitle: 'New Account',
      customIcon: 'exit_to_app',
      background: 'tertiary.main',
      iconBackground: 'background.transparent',
      variant: 'filled',
      type: 'action',
      href: routes.auth.signUpBio,
      active: doFeatureToggle('quickActions.signUp'),
      hide: 'auth',
    },
  ];
  return actions.filter(x => {
    let active = x?.active;
    if (x?.hide && x?.hide === 'auth' && active) {
      active = !isAuthenticated;
    }
    return active;
  });
};

function QuickActionBar() {
  const doFeatureToggle = useFeatureToggle();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);

  return (
    <ProductsList
      products={getQuickActions({ doFeatureToggle, dispatch, isAuthenticated })}
      isAction
      mobileColumnWidth={4}
    />
  );
}

export default QuickActionBar;
