import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext, ConsentContext } from '../context';
import { activateCookies, parseBool } from '../utils/functions';
import { acceptAllConsentValue, acceptCustomConsentValue } from '../utils/variables';

const useCustomEvent = () => {
  const siteWide = useSelector(state => state?.locations?.siteWide);
  const cookieFeatureDisabled = siteWide?.disableCookieConsent;
  const campaignStored = useSelector(state => state?.misc?.campaign);
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  const customerId = useSelector(state => state?.auth?.user?.customer?.id);
  const usertype = useSelector(state => state?.auth?.usertype);
  const { consent, setConsent } = useContext(ConsentContext);
  const { themeName: theme } = useContext(ThemeContext);

  return ({ action, label, value, nonInteraction }) => {
    const cookieValues = {
      event: {
        category: campaignStored?.campaign,
        action,
        label,
        value,
        nonInteraction,
      },
    };
    const dimensions = {
      alpha2,
      theme,
      customerId,
      usertype,
    };

    const consentValue = consent?.consent; // Cookies.getCookie(consentKey);
    const keys = consent?.customConsent || [];
    const cookies = consent?.cookies || [];
    const cookiePolicyVersion = consent?.version;
    const hasCookiePolicy = !!consentValue;

    if (
      consentValue === acceptAllConsentValue ||
      consentValue === acceptCustomConsentValue ||
      cookieFeatureDisabled
    ) {
      const result = activateCookies(
        cookies,
        consentValue,
        keys,
        cookieValues,
        null,
        dimensions,
        hasCookiePolicy,
      );
    }
  };
};

export default useCustomEvent;
