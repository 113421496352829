import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Container,
  useMediaQuery,
  Alert,
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { useClient, useSmall } from '../../../hooks';
import WizardSummaryCard from '../../cards/wizardSummaryCard';
import DetailsModal from '../detailsModal';
import { ErrorAlert } from '../../common';
import {
  splitCamelCaseToString,
  toTitleCase,
  uuidv4,
  compareAsc,
} from '../../../utils/functions';
import { agentType } from '../../../utils/variables';
import { setModalContent } from '../../../actions/misc';
import { checkComponent } from '../../wizards/functions';
import ClientDetails from '../../../containersV2/clients/details';
import WizardCardModals from '../../../utils/variables/wizardCardModals.json';
import styles from './style';

const filter = require('lodash.filter');
const uniqBy = require('lodash.uniqby');
const flatMap = require('lodash.flatmap');

const useStyles = makeStyles(styles);

const RequestModal = () => {
  // // console.log('encryptKey11: ', encryptKey);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmall = useSmall();
  const storeState = useSelector(state => state);
  const storeModal = useSelector(state => state.misc.modalContent);
  const lobCategories = useSelector(state => state.locations.lobCategories);
  const yourLocation = useSelector(state => state?.locations?.yourLocation);
  const alpha2 = yourLocation?.alpha2;
  const loadMoreRows = useClient({ infiniteScroll: isSmall });
  const clients = useSelector(state => state.clients.allRows);
  const isAgent = useSelector(
    state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
  );
  const wizardCardActions = useSelector(
    state => state?.locations?.yourLocation?.wizardCardActions,
  );
  const countryName = yourLocation?.name;
  const territoryId = yourLocation?.id;

  const modalLob = storeModal?.lob;
  const modalType = storeModal?.type;
  const data = storeModal?.data;
  const clientKey = storeModal?.clientKey;
  const backSlugs = storeModal?.backSlugs;
  const modalActionType = storeModal?.actionType || 'wizard';

  const modalSlug = storeModal?.modalSlug;

  const filteredWizardModals = filter(WizardCardModals, {
    slug: modalSlug,
    territories: [{ alpha2 }],
    lobCategories: [{ lob: modalLob }],
  });
  // console.log('filteredWizardModals: ', filteredWizardModals);

  const selectedWizardModal =
    filteredWizardModals && filteredWizardModals?.length > 0 && filteredWizardModals[0];
  // console.log('selectedWizardModal: ', selectedWizardModal);
  const modalTitle = selectedWizardModal?.title;
  const displayContent = selectedWizardModal?.content;
  const displayProseMirrorContent = selectedWizardModal?.proseMirrorContent;
  const modalRequestAction = selectedWizardModal?.requestActionType;

  useEffect(() => {
    const actionIndex = 0;
    // // console.log('actionIndex: ', actionIndex);
    if (clientKey && clients.length === 0 && isAgent) {
      loadMoreRows({ actionIndex });
    }
  }, [clientKey]);
  const client = clients.find(x => x.clientNumber?.toString() === clientKey?.toString());
  // console.log('clients: ', clients);
  // console.log('client: ', client);

  const infoTitle = `No ${toTitleCase(
    splitCamelCaseToString(modalType),
  )} Forms Available.`;
  const errorTitle = `Error retrieving ${toTitleCase(
    splitCamelCaseToString(modalType),
  )} Forms.`;
  const infoMessage = `Sorry, we don't have any ${toTitleCase(
    splitCamelCaseToString(modalType),
  )} Forms available.`;
  const errorMessage = `Error retrieving our ${toTitleCase(
    splitCamelCaseToString(modalType),
  )} Forms. Please try again.`;

  const getWizardSummaryContent = requestActions => {
    // console.log('summaryWizards: ', summaryWizards);
    const filteredSummaryWizards = requestActions.filter(
      y => y?.territories.some(territory => territory?.id === territoryId) && !y?.hidden,
    );
    // console.log('filteredSummaryWizards: ', filteredSummaryWizards);
    return (
      <Container>
        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={1}>
          {filteredSummaryWizards &&
            filteredSummaryWizards.length > 0 &&
            filteredSummaryWizards.map(_wizard => {
              const wizard = { ..._wizard };
              if (wizard.hidden) return null;

              return (
                <WizardSummaryCard
                  action={wizard}
                  key={wizard.id}
                  lob={modalLob}
                  wizardType={modalType}
                  actionType="modalWizard"
                  data={data}
                  desktopCardWidth={4}
                  hideIcon
                />
              );
            })}
          {!filteredSummaryWizards ||
            (filteredSummaryWizards.length === 0 && (
              <ErrorAlert
                isError={false}
                isSuccess
                isPending={false}
                infoTitle={infoTitle}
                errorTitle={errorTitle}
                infoMessage={infoMessage}
                errorMessage={errorMessage}
              />
            ))}
        </Grid>
      </Container>
    );
  };

  let modalContent = null;
  if (modalLob && modalType !== 'requestActions') {
    let requestActions = [];

    if (modalSlug && selectedWizardModal) {
      const filterCardActions = filter(wizardCardActions, {
        type: modalType,
        territories: [{ alpha2 }],
        lob: { lob: modalLob },
        requestActionType: modalRequestAction,
      });

      requestActions = filterCardActions?.filter(x => {
        if (x.actionType === 'wizard' || !x.actionType) {
          return (
            x?.c360Wizards?.filter(y =>
              y?.territories.some(territory => territory?.alpha2 === alpha2),
            ).length > 0 && lobCategories?.some(lob => lob?.lob === x?.lob?.lob)
          );
        }
        return true;
      });
    } else {
      const filterParams = {
        type: modalType,
        territories: [{ alpha2 }],
        lob: { lob: modalLob },
      };

      const filterCardActions = filter(wizardCardActions, {
        ...filterParams,
      });

      requestActions = filterCardActions?.filter(x => {
        if (x.actionType === 'wizard' || !x.actionType) {
          return (
            x?.c360Wizards?.filter(y =>
              y?.territories.some(territory => territory?.alpha2 === alpha2),
            ).length > 0 && lobCategories?.some(lob => lob?.lob === x?.lob?.lob)
          );
        }
        return true;
      });
      requestActions = requestActions && requestActions[0]?.c360Wizards;
    }

    if (requestActions && requestActions?.length > 0) {
      modalContent = {
        title: modalTitle || `Choose a ${toTitleCase(modalLob)} Service Request`,
        displayContent,
        displayProseMirrorContent,
        content: getWizardSummaryContent(requestActions),
        lob: modalLob,
        data,
      };
    }
  }

  const getRequestContent = requestActions => {
    const filteredActions = [];
    let displayActions = requestActions.map(_action => {
      const action = {
        ..._action,
        startIcon: 'none',
        isConditionalComponent: _action?.isConditional,
      };
      if (!action || action.hidden) return null;
      const props = {
        key: action.id,
        lob: modalLob,
        action,
        actionType: 'action',
        wizardType: 'requestActions',
        data,
      };

      const { show } = checkComponent(action || {}, storeState);
      if (show) {
        filteredActions.push(action);
      }
      return (
        <WizardSummaryCard {...props} desktopCardWidth={4} key={uuidv4()} hideIcon />
      );
    });
    displayActions = displayActions.filter(val => {
      return val !== null && <val />;
    });

    const uniqueTags = uniqBy(flatMap(filteredActions, 'tags'), 'slug');
    let displayTags = uniqueTags.map(_action => {
      const action = { ..._action, startIcon: 'none' };
      if (!action || action.hidden) return null;

      return (
        <ListItem>
          <ListItemIcon>
            <CircleIcon size="small" />
          </ListItemIcon>
          <ListItemText primary={action?.title} secondary={action?.description} />
        </ListItem>
      );
    });
    displayTags = displayTags.filter(val => {
      return val !== null && <val />;
    });
    return (
      <Container>
        <Grid container justifyContent="flex-start" alignItems="stretch" spacing={1}>
          {displayActions && displayActions.length > 0 && displayActions}

          {displayTags && displayTags.length > 0 && (
            <Grid item xs={12}>
              <List sx={{ width: '100%' }}>{displayTags}</List>
            </Grid>
          )}
        </Grid>
      </Container>
    );
  };

  if (modalLob && modalType === 'requestActions') {
    // console.log('modalType: ', modalType);
    // console.log('modalLob: ', modalLob);

    let requestActions = [];

    if (modalSlug && selectedWizardModal) {
      const filterCardActions = filter(wizardCardActions, {
        type: modalType,
        territories: [{ alpha2 }],
        lob: { lob: modalLob },
        requestActionType: modalRequestAction,
      });

      requestActions = filterCardActions?.filter(x => {
        if (x.actionType === 'wizard' || !x.actionType) {
          return (
            x?.c360Wizards?.filter(y =>
              y?.territories.some(territory => territory?.alpha2 === alpha2),
            ).length > 0 && lobCategories?.some(lob => lob?.lob === x?.lob?.lob)
          );
        }
        return true;
      });
    } else {
      let filterCardActions = filter(wizardCardActions, {
        type: modalType,
        territories: [{ alpha2 }],
        lob: { lob: modalLob },
      });

      filterCardActions = filter(filterCardActions, action => {
        return !action.requestActionType;
      });

      requestActions = filterCardActions?.filter(x => {
        if (x.actionType === 'wizard' || !x.actionType) {
          return (
            x?.c360Wizards?.filter(y =>
              y?.territories.some(territory => territory?.alpha2 === alpha2),
            ).length > 0 && lobCategories?.some(lob => lob?.lob === x?.lob?.lob)
          );
        }

        return true;
      });
    }

    requestActions = requestActions?.sort((a, b) => compareAsc(a, b, 'title'));
    // console.log('requestActions: ', requestActions);
    // console.log('displayActions: ', displayActions);
    const oneCategory = lobCategories.find(
      x => x?.lob === modalLob, // x?.requestLink && x.requestLink.length > 0 &&
    );

    if (requestActions && requestActions.length > 0) {
      modalContent = {
        title: modalTitle || `Choose a ${toTitleCase(modalLob)} Service Request`,
        displayContent,
        displayProseMirrorContent,
        content: getRequestContent(requestActions),
        lob: modalLob,
        data,
      };
    } else {
      modalContent = {
        title: `No Requests Available`,
        content: (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.paper}
          >
            <Grid item xs={12}>
              <Alert severity="info" className={classes.alert}>
                There are no requests available for this item in this territory,{' '}
                {countryName}.
              </Alert>
            </Grid>
          </Grid>
        ),
      };
    }
  }
  if (client) {
    modalContent = {
      title: '',
      content: (
        <ClientDetails
          client={client}
          onClose={() => {
            dispatch(setModalContent.setModalContentAction(null));
          }}
        />
      ),
    };
  }

  const handleBackClick = myBackSlugs => {
    const length = myBackSlugs?.length;
    if (length > 0) {
      const lastItem = myBackSlugs.pop();
      dispatch(
        setModalContent.setModalContentAction({
          modalSlug:
            myBackSlugs && myBackSlugs.length > 0
              ? myBackSlugs[myBackSlugs?.length - 1]
              : null,
          backSlugs: myBackSlugs,
          lob: modalLob,
          type: modalType,
          data,
        }),
      );
    }
  };

  return (
    <>
      {modalContent && (
        <DetailsModal
          showBackButton={backSlugs && backSlugs?.length > 0}
          onBackClick={() => handleBackClick(backSlugs)}
          title={modalContent?.title}
          onClose={() => {
            dispatch(setModalContent.setModalContentAction(null));
          }} // dispatch(setModalContent.setModalContentAction(null))
          content={modalContent?.content}
          richTextContent={modalContent?.displayContent}
          proseMirrorContent={modalContent?.proseMirrorContent}
          modalSize="md"
          // className={classes.modalBackground}
          sx={{
            backgroundColor: `${theme.palette.background.main} !important`,
          }}
        />
      )}
    </>
  );
};

export default RequestModal;
