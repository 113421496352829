import { referralImage } from '../../../utils/variables/images';

export default theme => ({
  cardHeader: {
    backgroundImage: `url(${referralImage})`,
    height: 'auto',
    width: 'auto',
    marginBottom: '15px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    // height: '400px',
    gap: '.63rem',
    padding: theme.spacing(2),
    boxShadow: theme.effects.boxShadow,
  },
  cardHeaderText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold !important',
    textAlign: 'left',
  },
  cardImageStyle: {
    borderRadius: '10px',
    width: '100%',
  },
  cardContentStyle: {
    textAlign: 'left !important',
    color: theme.palette.text.dark,
    padding: `0 !important`,
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`,
  },

  cardButtonTextStyle: {
    fontWeight: 'bold !important',
    textTransform: 'none',
  },
});
