import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { ImageList, ImageListItem } from '@mui/material';
import StatusBullet from '../statusBullet';
import { uuidv4 } from '../../../utils/functions';
import { useWindowSize } from '../../../hooks';

import styles from './style';

const useStyles = makeStyles(styles);

const Carousel = ({ cols, children, showBullet }) => {
  const [bulletIndex, setBulletIndex] = useState(0);
  const [scrollLeftPosition, setScrollLeftPosition] = useState(0);
  const [isChangeScrollFinish, setChangeScrollFinish] = useState(true);
  const divRef = useRef(null);
  const classes = useStyles();
  const size = useWindowSize();
  const windowWidth = size.width;
  // // console.log(`Window Width: ${windowWidth}`);
  const childrenLength = React.Children.count(children);
  const scrollWidth = divRef?.current?.scrollWidth;

  const smallPadding = 5;
  const itemWidth = scrollWidth
    ? parseInt((scrollWidth - smallPadding) / childrenLength, 10)
    : parseInt(windowWidth / parseFloat(cols) - smallPadding, 10);

  // // console.log(`Children Length: ${childrenLength}`);
  const scrollDiff = Math.abs(scrollLeftPosition - divRef?.current?.scrollLeft) < 5;

  const onScroll = React.useCallback(
    e => {
      if (!isChangeScrollFinish && scrollDiff) {
        // if u have finished scrolling from status bullet click,
        // but haven't updated  the finish scrolling flag, update the scroll position and the flag
        const scrollPosition = e.target.scrollLeft;
        setChangeScrollFinish(true);
        setScrollLeftPosition(scrollPosition);
      } else if (isChangeScrollFinish) {
        // if you have finished scrolling from status bullet click, then scrolling is changing
        // based on user dragging image across to scroll. Then jus update the status bullet index
        // based on the position of the scroll
        // // console.log(`Item Width: ${itemWidth}`);
        const scrollPosition = e.target.scrollLeft;
        // // console.log(`Scroll Position: ${scrollPosition}`);
        // const scrollWidth = e.target.scrollWidth;
        let currentIndex = Math.round(scrollPosition / itemWidth, 10);
        if (currentIndex >= childrenLength) currentIndex = childrenLength - 1;
        // // console.log(`Current Index: ${currentIndex}`);
        // divRef.current.scrollLeft = scrollPosition;

        setBulletIndex(currentIndex);
      }
      /*
      divRef.current.scrollTo({
        behavior: 'smooth',
        left: scrollPosition,
      });
      */
      // setScrollLeftPosition(scrollPosition);
    },
    [bulletIndex, isChangeScrollFinish],
  );

  const changeScrollPosition = index => {
    const scrollPosition = parseInt(index * itemWidth, 10);

    // divRef.current.scrollLeft = scrollPosition;
    // // console.log(`Scroll Position: ${scrollPosition}`);
    setBulletIndex(index);
    setScrollLeftPosition(scrollPosition);
    setChangeScrollFinish(false);
    divRef.current.scrollTo({
      behavior: 'smooth',
      left: scrollPosition,
    });
    //
  };

  const dataList = React.Children.map(children, child => (
    <ImageListItem cols={1} key={uuidv4()}>
      {React.cloneElement(child)}
    </ImageListItem>
  ));
  // // console.log('render');
  return (
    <div className={classes.root} onScroll={onScroll}>
      <ImageList className={classes.gridList} cols={cols} cellHeight="auto" ref={divRef}>
        {dataList}
      </ImageList>
      {showBullet && (
        <StatusBullet
          isCarousel
          currIndex={bulletIndex}
          total={childrenLength}
          onClick={changeScrollPosition}
        />
      )}
    </div>
  );
};

Carousel.propTypes = {
  cols: PropTypes.number,
  showBullet: PropTypes.bool,
};

Carousel.defaultProps = {
  cols: 1.2,
  showBullet: true,
};

export default Carousel;
