import { GET_SINGLE_ARTICLE_API_SUCCESS } from '../../../../constants/helpCenter/helpCenterActionNames';

export const initialState = {
  singleArticle: {},
};

function singleArticleReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SINGLE_ARTICLE_API_SUCCESS: {
      const filteredArticle = action?.payload?.[0] || initialState?.singleArticle;
      return {
        ...state,
        singleArticle: filteredArticle,
      };
    }
    default:
      return state;
  }
}

export default singleArticleReducer;
