import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomTooltip from '../../wizards/components/common/tooltip';
import styles from './style';
import { getRef } from '../../../utils/functions';
import { useSmall } from '../../../hooks';

const useStyles = makeStyles(styles);

const UpcomingFeature = ({ id, children }) => {
  const upcomingFeatures = useSelector(
    state =>
      state?.locations?.yourLocation?.variables?.upcomingFeatures,
  );
  const title = getRef(upcomingFeatures, id)?.title;
  const isSmall = useSmall();
  return (
    <>
      <Box
        sx={{
          display: 'block',
          position: 'absolute',
          bottom: '-70%',
          left: '60%',
          transform: 'translateX(-50%)',
          mt: 0,
          width: isSmall ? '90vw' : '20rem',
          zIndex: 1000,
          opacity: 1,
        }}
      >
        <CustomTooltip title={title} fontSize="small" showInModal={isSmall}>
          <Typography variant="subtitle2">Coming Soon...</Typography>
        </CustomTooltip>
      </Box>
      {children}
    </>
  );
};

export default UpcomingFeature;
