/* eslint-disable no-irregular-whitespace */
import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST,
  GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS,
  GET_DIGITAL_SALES_APPLICATIONS_API_ERROR,
  DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST,
  DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS,
  DELETE_DIGITAL_SALES_APPLICATION_API_ERROR,
  GET_DIGITAL_SALES_APPLICATION_API_REQUEST,
  GET_DIGITAL_SALES_APPLICATION_API_SUCCESS,
  GET_DIGITAL_SALES_APPLICATION_API_ERROR,
  GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS,
} from '../../constants/digitalSalesActionNames';
import {
  CLEAR_APPLICATIONS_CACHE,
  RESET_INFINITE_SCROLL_SUCCESS,
} from '../../constants/miscActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';

import {
  dateToString,
  isoStringToDate,
  getKeys,
  uniq,
  removeItemOnce,
  getTransformPeriod,
  addArrayOnce,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';

let searchKeys = [];

const createApplicationStage = stage => {
  if (stage === 'IDVerification') {
    const formattedStage = 'ID Verification';
    return formattedStage;
  }
  return stage;
};

const createPayload = (applications, body) => {
  if (!Array.isArray(applications)) return null;
  searchKeys = [];
  const newApplications = applications.map((object, index) => {
    // console.log("Object", object);
    const position = index;
    const application = {
      ...object,
      index: position,
      cacheId: `${object?._id}_${body?.alpha2}`,
      _id: object?._id,
      id: object?._id,
      applicationId: object?.applicationId,
      applicationStage: createApplicationStage(object?.applicationStage),
      productName: object?.productName,
      currency: object?.currency,
      paid: object?.paid,
      createdAt: dateToString(isoStringToDate(object?.createdAt)),
      quoteNumber: object?.quoteNumber,
      buttonEnabled: object?.buttonEnabled,
      status: object?.status,
      legacyResponse: object?.legacyResponse,
      body,
    };
    const newKeys = getKeys(application);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    return application;
  });
  newApplications.sort((a, b) => {
    return b.createdAt > a.createdAt ? 1 : -1;
  });
  return newApplications;
};

const createCoveragesPayload = coverageAmount => {
  if (coverageAmount === 100000) {
    return { value: 100000, coverage: '$100,000', code: 'XL1001' };
  }
  if (coverageAmount === 200000) {
    return { value: 200000, coverage: '$200,000', code: 'XL2001' };
  }
  if (coverageAmount === 300000) {
    return { value: 300000, coverage: '$300,000', code: 'XL3001' };
  }
  if (coverageAmount === 400000) {
    return { value: 400000, coverage: '$400,000', code: 'XL4001' };
  }
  return { value: 500000, coverage: '$500,000', code: 'XL5001' };
};

const createBeneficiary = beneficiaries => {
  const newBeneficiaries = beneficiaries?.map(data => {
    return {
      id: data?._id,
      beneficiaryFirstName: data?.firstName,
      beneficiaryLastName: data?.lastName,
      beneficiaryGender: data?.gender,
      beneficiaryDateOfBirth: dateToString(isoStringToDate(data?.dateOfBirth)),
      beneficiarySmokerStatus: data?.smokerStatus,
      beneficiarySplit: data?.percentageSplit,
      beneficiaryAddressStreet1: data?.address?.addressLine1,
      beneficiaryAddressStreet2: data?.address?.addressLine2,
      beneficiaryCity: data?.address?.city,
      beneficiaryRelationship: data?.relationship,
      beneficiaryRelationshipLabel: data?.relationship?.label,
      beneficiaryRelationshipValue: data?.relationship?.value,
    };
  });
  return newBeneficiaries;
};

const createModeOfPayment = modeOfPayment => {
  if (modeOfPayment === 'M') {
    return 'Monthly';
  }
  if (modeOfPayment === 'Q') {
    return 'Quarterly';
  }
  if (modeOfPayment === 'SA') {
    return 'SemiAnnual';
  }
  return 'Annual';
};

const createApplication = response => {
  const insured = response?.insured;
  const product = response?.product;
  const compliance = response?.compliance;
  const underwriting = response?.underwriting;
  const beneficiaries = response?.beneficiaries;
  const documentsUploaded = response?.documentsUploaded;

  return {
    applicationId: response?.applicationId || response?._id || '',
    stepIndex: '3',
    firstName: insured?.firstName,
    lastName: insured?.lastName,
    gender: insured?.gender?.toLowerCase(),
    dateOfBirth: dateToString(isoStringToDate(insured?.dateOfBirth)),
    countryOfBirth: insured?.countryOfBirth,
    email: insured?.emailAddress,
    contact: insured?.contact,
    isSmoker: insured?.smokerStatus?.toLowerCase(),
    addressLine1: insured?.address?.addressLine1,
    addressLine2: insured?.address?.addressLine2,
    city: insured?.address?.city,
    isPoliticallyExposed: insured?.isPoliticallyExposed?.toString(),
    pepType: {
      label: insured?.pepType,
      value: insured?.pepType,
    },
    idType: insured?.identity?.idType,
    idNumber: insured?.identity?.number?.toString(),
    idCountry: insured?.identity?.country,
    idExpDate: dateToString(isoStringToDate(insured?.identity?.expiryDate)),
    companyName: insured?.company?.name,
    companyAddressLine1: insured?.company?.addressLine1,
    companyAddressLine2: insured?.company?.addressLine2,
    companyCity: insured?.company?.city,
    companyCountry: insured?.company?.country,
    occupation: {
      label: insured?.occupation,
      value: insured?.occupation,
    },
    annualIncome: insured?.annualIncome,

    productOfInterest: product?.productOfInterest,
    coverageAmountXL: product?.coverageAmount,
    coverageAmountXLPayload: createCoveragesPayload(product?.coverageAmount),
    modeOfPayment: createModeOfPayment(product?.modeOfPayment),
    coverageType: product?.riders[0]?.name,
    coverageAmountAdditional: product?.riders[0]?.sumAssured,
    commencementDate: dateToString(isoStringToDate(product?.commencementDate)),
    preferredContact: product?.preferredContact,
    preferredAppointmentDate: dateToString(
      isoStringToDate(product?.preferredAppointmentDate),
    ),
    contractReview: product?.preferredContractMode,
    methodOfPayment: product?.methodOfPayment,
    premiumAmount: product?.premiumAmount,

    hasBeneficiaries: response?.hasBeneficiaries?.toString(),
    beneficiaryInformation: createBeneficiary(beneficiaries),
    beneficiaries: createBeneficiary(beneficiaries),

    usCitizen: compliance?.usCitizen?.toString(),
    passportNumber: compliance?.identification?.passportNumber,
    passportIssueDate: dateToString(
      isoStringToDate(compliance?.identification?.issueDate),
    ),
    passportExpiryDate: dateToString(
      isoStringToDate(compliance?.identification?.expiryDate),
    ),
    usResident: compliance?.permanentResident?.toString(),
    greenCardNumber: compliance?.citizenship?.greenCardNumber,
    greenCardIssueDate: dateToString(isoStringToDate(compliance?.citizenship?.issueDate)),
    greenCardExpiryDate: dateToString(
      isoStringToDate(compliance?.citizenship?.expiryDate),
    ),

    criticalIllness: underwriting?.criticalIllness?.toString(),
    drugAbuse: underwriting?.drugAbuseTreatment?.toString(),
    isAIDSpositive: underwriting?.isAIDSpositive?.toString(),
    wasDeclinedInsurance: underwriting?.wasDeclinedInsurance?.toString(),
    hasEasicover: underwriting?.hasEasicover?.toString(),

    uploadProofOfAddress: [
      {
        fileId: documentsUploaded?.proofOfAddress?.fileId,
        fileName: documentsUploaded?.proofOfAddress?.name,
      },
    ],
  };
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  allRows: [],
  count: 0,
  searchKeys,
  isDeleteDigitalSalesApplicationPending: false,
  isDeleteDigitalSalesApplicationError: false,
  isDeleteDigitalSalesApplicationSuccess: false,
  digitalSalesApplication: [],
  individualApplication: {},
  [DEFAULT_KEY]: null,
};

function digitalSalesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isSuccess: false,
        isError: false,
      };
    case GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS: {
      const applications = action?.payload?.payload?.applications;
      const body = action?.params;
      const newRows = createPayload(applications, body) || [];
      const oldRows = (state?.allRows && [...state?.allRows]) || [];
      const allRows = addArrayOnce(oldRows, newRows, 'cacheId');
      // const allRows = [...newRows, ...state?.allRows];
      const count = allRows.length || 0;
      const isError = action?.payload?.responseType !== statusOk;
      const cacheKey = action?.cacheKey;
      // console.log("applications", application);
      return {
        ...state,
        isPending: false,
        isSuccess: true,
        isError,
        response: action.payload,
        count,
        allRows,
        searchKeys,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }
    case GET_DIGITAL_SALES_APPLICATIONS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isSuccess: false,
        isError: true,
        // signUpBioError: action.payload,
      };
    case DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST:
      return {
        ...state,
        isDeleteDigitalSalesApplicationPending: true,
        isDeleteDigitalSalesApplicationError: false,
        isDeleteDigitalSalesApplicationSuccess: false,
      };

    case DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS: {
      const deleteDigitalSalesApplication = action?.payload?.payload?.deletedDocument;
      deleteDigitalSalesApplication.applicationId =
        deleteDigitalSalesApplication?._id ||
        deleteDigitalSalesApplication?.applicationId;
      // console.log('deleteDigitalSalesApplication: ', deleteDigitalSalesApplication);
      const existingApplications = [...state.allRows];
      // console.log('existingApplications: ', existingApplications);
      // const updatedList = existingDevices?.filter(item => item?.id !== deleteTrustedDevice?.id && item?.authId !== deleteTrustedDevice?.authId);
      const updatedList = removeItemOnce(
        existingApplications,
        deleteDigitalSalesApplication,
        'applicationId',
      );
      // console.log(updatedList);
      // console.log("Updated List", updatedList);

      return {
        ...state,
        deleteDigitalSalesApplication,
        isDeleteDigitalSalesApplicationPending: false,
        isDeleteDigitalSalesApplicationError: false,
        isDeleteDigitalSalesApplicationSuccess: true,
        allRows: updatedList,
        [DEFAULT_KEY]: null,
      };
    }

    case DELETE_DIGITAL_SALES_APPLICATION_API_ERROR: {
      const deleteDigitalSalesApplication = action?.payload;
      return {
        ...state,
        isDeleteDigitalSalesApplicationPending: false,
        isDeleteDigitalSalesApplicationError: true,
        isDeleteDigitalSalesApplicationSuccess: false,
        deleteDigitalSalesApplication,
      };
    }

    case GET_DIGITAL_SALES_APPLICATION_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isSuccess: false,
        isError: false,
      };
    case GET_DIGITAL_SALES_APPLICATION_API_SUCCESS: {
      const response = action?.payload.payload.application;
      const individualApplication = createApplication(response);

      // console.log("applications", application);
      return {
        ...state,
        isPending: false,
        isSuccess: true,
        isError: false,
        response: action.payload.payload.application,
        individualApplication,
      };
    }
    case GET_DIGITAL_SALES_APPLICATION_API_ERROR:
      return {
        ...state,
        isPending: false,
        isSuccess: false,
        isError: true,
        // signUpBioError: action.payload,
      };

    case GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    case CLEAR_APPLICATIONS_CACHE: {
      return {
        ...state,
        allRows: initialState.allRows,
        [DEFAULT_KEY]: null,
      };
    }
    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        allRows: initialState.allRows,
        [DEFAULT_KEY]: null,
      };
    }
    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    default:
      return state;
  }
}

export default digitalSalesReducer;
