// '#F8971D'; // '#FFBC63'; // '#FFE716'; // '#F5952C';
// '#D7BE82'; // '#D74E09'; // '#C60751';// '#E76B74'; // ;
// '#ECEDEC';

const lightTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#31006F', // #31006F
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#9C0059',
      contrastText: '#ffffff',
    },
    tertiary: {
      main: '#F8971D',
      contrastText: '#ffffff',
    },
    quaternary: {
      main: '#C60651',
      contrastText: '#ffffff',
    },
    quinary: {
      main: '#E86D1F',
      contrastText: '#ffffff',
    },
    senary: {
      main: '#FFD200',
      contrastText: '#000000',
    },
    septenary: {
      main: '#EA6C21',
      contrastText: '#ffffff',
    },
    octonary: {
      main: '#FFE512',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#4F5467',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff', // '#f3fcf0', // '#F0F2F5' //'#f3fcf0'
      paper: '#ffffff', // '#f3fcf0', // '#F0F2F5', // '#f5f5f5', //'#f3fcf0'
      grid: 'rgba(0, 0, 0, 0.23)', // '#f7fff7', // '#ECEDEC', // '#E4E6EB', // '#ECEDEC',
      banner: '#E5E5E5', // grey.A100, // grey[500]
      text: '#F4F4F4',
      main: '#F2F2F2', // '#F0F0F0',
      chip: '#4F5467', // 4F5467
      transparent: '#FFFFFF26',
      contentBox: '#D3D2E3',
      chatbotDefault: 'rgba(0, 0, 0, 0.38)',
    },
    text: {
      primary: '#000000',
      secondary: '#4F5467', // '#FFFFFF',
      disabled: '#95979E', // '#95979E',
      label: '#4F5467',
      main: '#31006F',
      dark: '#4F5467',
      bright: '#FFFFFF',
      chatbotPrimary: 'rgba(0, 0, 0, 0.87)',
      chatbotSecondary: 'rgba(0, 0, 0, 0.54)',
    },
    inactive: {
      veryLight: '#E9EBEF',
      light: '#CED1D6',
      main: '#AEB1BD',
      dark: '#5C656D',
      veryDark: '#4F5467',
    },
    error: {
      light: '#f44336', // '#ad0505', // '#f44336',
      main: '#AA0000',
      background: 'transparent', // '#EEAABB',
    },
    warning: {
      light: '#FFDDCC', // '#FFD200', // '#F7CD86',
      main: '#DD3311',
      background: 'transparent', //
    },
    success: {
      light: '#00C5A1', // '#332A86', // '#2E30C1', // '#35AF28', // '#B1FC9C',
      main: '#00BB55',
      background: 'transparent', // '#BBEEDD',
    },
    info: {
      light: '#0089D6',
      main: '#006CBB',
      background: 'transparent', // '#BBCCEE',
    },
    neutral: {
      light: '#E9EBEF',
      main: '#4F5467',
      background: 'transparent', // '#E9EBEF',
    },
  },
  shape: {
    borderRadius: 6,
    borderWidth: 1,
  },
  typography: {
    fontSize: 14,
  },
  effects: {
    boxShadow: '0 1px 4px #0000001f !important',
    textShadow: '0 1px 1px #0000001f',
    transition: 'all .25s cubic-bezier(0.4, 0, 0.2, 1) !important',
  },
};

export default lightTheme;
