import React from 'react';
import { Grid, FormControl, Typography, FormHelperText, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import LobIcon from '../../../componentsV2/misc/lobIcon';
import { addHelpFormDataAction } from '../../../actions/helpCenter/helpFormData';
import { CategoryCard } from '../../../componentsV2/cards';
import styles from './style';
import { sourceTextFromData } from '../../../utils/functions';
import { domain } from '../../../utils/variables';

const useStyles = makeStyles(styles);

const ChooseLob = ({ nextStep, step, content, lobCategories }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (lob, requestHelpExternalLink) => {
    dispatch(addHelpFormDataAction({ lob }));
    if (requestHelpExternalLink) {
      const state = {
        domain,
      };
      const tempLink = sourceTextFromData(state, requestHelpExternalLink);
      window.location.href = tempLink;
    } else {
      nextStep();
    }
  };
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <FormControl component="fieldset" required fullWidth>
          <Typography align="center" variant="body1" sx={{ color: 'text.label' }}>
            {content}
          </Typography>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ width: '100%' }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {lobCategories &&
            lobCategories.map(option => {
              const product = {
                title: option?.title,
                onClick: () => handleClick(option?.lob, option?.requestHelpExternalLink),
                lob: option?.lob,
                background: option?.color,
                iconBackground: option?.color,
              };

              return (
                <Grid key={option.lob} item xs={6} sm={6} md={4} align="left">
                  <CategoryCard product={product} />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChooseLob;
