import { RESET_CLAIMS_SUCCESS } from '../../../constants/claimActionNames';

export function resetClaimsAction() {
  return {
    type: RESET_CLAIMS_SUCCESS,
    payload: true,
  };
}

export default {
  resetClaimsAction,
};
