import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import routes from '../utils/variables/routes';
import { isValidHttpUrl } from '../utils/functions';
import { validateAuth } from '../hooks/useAuth';

export default function UnauthenticatedRoute({ component: C, appProps, ...rest }) {
  const { isAuthenticated } = useSelector(state => validateAuth(state));
  let redirect = useSelector(state => state?.misc?.redirectUrl);
  const userType = useSelector(state => state.auth.usertype);
  // let redirect = validateCredentialsResponse?.redirect;
  const signOutRoute = routes.account.signOut;
  if (redirect?.startsWith(routes.auth.index) || redirect === signOutRoute) {
    redirect = routes.portfolio.portfolioSummary;
  }
  if (userType === 'agent') {
    redirect = routes.home;
  }

  if (redirect && redirect.length > 0 && isValidHttpUrl(redirect)) {
    window.location.href = redirect;
    return null;
  }
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <C {...props} {...appProps} />
        ) : (
          <Redirect to={redirect || routes.portfolio.portfolioSummary} />
        )
      }
    />
  );
}
