export const serverModalErrorEvent = 'server-error';
export const loginEvent = 'login';
export const loginFailedEvent = 'login-failed';
export const loginFailedTooManyAttemptsEvent = 'login-failed-too-many-attempts';
export const signUpEvent = 'sign-up';
export const signUpPartialFailedEvent = 'sign-up-partial-failed';
export const signUpFailedEvent = 'sign-up-failed';
export const checkoutEvent = 'checkout';
export const checkoutAmountEvent = 'checkout-amount';
export const checkoutQuantityEvent = 'checkout-quantity';
export const renewalStartStep1Event = 'renewal-start-step1';
export const renewalReviewedPolicyStep2Event = 'renewal-reviewed-policy-step2';
export const renewalDeliveryOptionStep3Event = 'renewal-delivery-option-step3';
export const renewalAgentNotifyClientStep4Event = 'renewal-agent-notify-client-step4';
export const renewalAddToCartStep4Event = 'renewal-add-to-cart-step4';
export const renewalAddToCartStep4AmountEvent = 'renewal-add-to-cart-step4-amount';
export const renewalPayOfflineStep4Event = 'renewal-pay-offline-step4';
export const renewalPayOfflineStep4AmountEvent = 'renewal-pay-offline-step4-amount';
export const premiumAddToCartEvent = 'premium-add-to-cart';
export const premiumAddToCartAmountEvent = 'premium-add-to-cart-amount';
export const facPaymentStartEvent = 'fac-payment-start';
export const facPaymentStartQuantityEvent = 'fac-payment-start-quantity';
export const facPaymentStartAmountEvent = 'fac-payment-start-amount';
export const facPaymentDeclineEvent = 'fac-payment-decline';
export const facPaymentDeclineQuantityEvent = 'fac-payment-decline-quantity';
export const facPaymentDeclineAmountEvent = 'fac-payment-decline-amount';
export const facPaymentCompleteEvent = 'fac-payment-complete';
export const facPaymentCompleteQuantityEvent = 'fac-payment-complete-quantity';
export const facPaymentCompleteAmountEvent = 'fac-payment-complete-amount';
