import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
export const getSingleHelpCenterCaseUrl = helpCenterApi('getSingleHelpCenterCaseUrl');
const getSingleHelpCenterCase = (state, body) => {
  const url = getSingleHelpCenterCaseUrl;
  if (url) {
    return crmPostCall(state, url, body);
  } else {
    throw new Error('Server Error');
  }
};
export default { getSingleHelpCenterCase };
