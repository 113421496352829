import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
export const getQnRValuesUrl = helpCenterApi('getQnRValuesUrl');
const getQnRValues = (state, body) => {
  const url = getQnRValuesUrl;
  if (url) {
    return crmPostCall(state, url, body);
  } else {
    throw new Error('Server Error');
  }
};
export default { getQnRValues };
