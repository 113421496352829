export default theme => ({
  container: {
    maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiSvgIcon-fontSizeInherit': {
      fontSize: theme.spacing(6),
    },
  },
  align: {
    display: 'block !important',
  },
  icon: {
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    display: 'block',
  },
  background: {
    backgroundColor: 'red',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
  messageText: {
    overflowWrap: 'break-word',
    wordWrap: 'break-all',

    wordBreak: 'break-all',
    width: '400px',
  },
  actionButtons: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
});
