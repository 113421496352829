import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import styles from './style';
import ModalTitleWithCloseIcon from '../modalTitleWithCloseIcon';
import { updateTempState } from '../../../actions/temp';

const useStyles = makeStyles(styles);

const AlertDialog = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery('(min-width:600px)');

  // Custom Properties
  const {
    title,
    content,
    dismissiveAction,
    confirmingAction,
    acknowledgementAction,
    onClose,
    open: initialOpen = true,
    isBlocking,
    fullWidth,
    maxWidth,
    allowFullscreen,
  } = props;

  const [open, setOpen] = useState(initialOpen);
  const handleClose = () => {
    setOpen(false);
    dispatch(updateTempState.updateTempStateAction({ alertPopUp: null }));
    onClose();
  };

  return (
    <Dialog
      fullScreen={allowFullscreen ? !isDesktop : false}
      onClose={() => {
        if (!isBlocking) {
          handleClose();
        }
      }}
      open={open}
      classes={{
        paper: allowFullscreen && isDesktop && classes.dialogContainer,
      }}
    >
      <ModalTitleWithCloseIcon
        className={classes.headerTitle}
        classes={{ root: classes.dialogActions }}
        title={title}
        onClose={handleClose}
      />

      <DialogContent className={classes.dialogContent}>
        <DialogContentText classes={{ root: classes.dialogContentText }}>
          {content}
        </DialogContentText>
      </DialogContent>

      {(dismissiveAction || confirmingAction || acknowledgementAction) && (
        <DialogActions className={classes.dialogActions}>
          {dismissiveAction}
          {confirmingAction}
          {acknowledgementAction}
        </DialogActions>
      )}
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  onClose: () => {},
};

AlertDialog.propTypes = {
  // Dialog Properties
  onClose: PropTypes.func,

  // Custom Properties
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  dismissiveAction: PropTypes.element,
  confirmingAction: PropTypes.element,
  acknowledgementAction: PropTypes.element,
};

export default AlertDialog;

/*
  dialogProps: {
    open: true,
    onClose: () => {},
  },
  */
