import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { getSessionStorage, setSessionStorage } from '../../../storage/sessionStorage';
import { parseBool } from '../../../utils/functions';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        height: '100vh',
        marginTop: '-3em',
      },
      [theme.breakpoints.down('lg')]: {
        height: '100vh',
        marginTop: '-3em',
      },
      width: '100%',
    },
    icon: {
      width: '12em',
      height: '0.3em',
    },
    message: {
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.5,
      margin: '0 1em',
    },
  }),
  { name: 'RaLoading' },
);

const autoRefreshedKey = 'autoRefreshed';

const Loading = props => {
  const {
    className,
    loadingPrimary = 'Loading, Please Wait',
    loadingSecondary = '',
    children,
    isReactLoadable,
    error,
    timedOut,
  } = props;
  const classes = useStyles(props);
  const isAlreadyAutoRefreshed = parseBool(getSessionStorage());

  const newChildren = children || (
    <div className={classes.message}>
      <LinearProgress className={classes.icon} color="primary" />
      {loadingPrimary && <h3>{loadingPrimary}</h3>}
      {loadingSecondary && <div>{loadingSecondary}</div>}
    </div>
  );

  const refreshPage = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    if (error && !isAlreadyAutoRefreshed) {
      setSessionStorage(autoRefreshedKey, 'true');
      refreshPage();
    }
  }, [error]);

  if (error) {
    return (
      <div className={classnames(classes.container, className)}>
        Error!{' '}
        <Button
          color="primary"
          variant="contained"
          sx={{ width: 'auto', m: 1, px: 1 }}
          onClick={refreshPage}
        >
          Retry
        </Button>
      </div>
    );
  }
  if (timedOut) {
    return (
      <div className={classnames(classes.container, className)}>
        Taking a long time?. Click Retry.{' '}
        <Button
          color="primary"
          variant="contained"
          sx={{ width: 'auto', m: 1, px: 1 }}
          onClick={refreshPage}
        >
          Retry
        </Button>
      </div>
    );
  }
  if (props.pastDelay || !isReactLoadable) {
    return <div className={classnames(classes.container, className)}>{newChildren}</div>;
  }
  return null;
};

export default Loading;
