import {
  GET_ALL_LOCATIONS_API_REQUEST,
  GET_ALL_LOCATIONS_API_SUCCESS,
  GET_ALL_LOCATIONS_API_ERROR,
  GET_YOUR_LOCATION_API_REQUEST,
  GET_YOUR_LOCATION_API_SUCCESS,
  GET_YOUR_LOCATION_API_ERROR,
  UPDATE_YOUR_LOCATION_API_REQUEST,
  UPDATE_YOUR_LOCATION_API_SUCCESS,
  UPDATE_YOUR_LOCATION_API_ERROR,
  UPDATE_YOUR_LANGUAGE_SUCCESS,
  GET_YOUR_APP_SETTINGS_API_REQUEST,
  GET_YOUR_APP_SETTINGS_API_SUCCESS,
  GET_YOUR_APP_SETTINGS_API_ERROR,
} from '../../../constants/mainLayout/locationActionNames';
import countries from '../../../utils/variables/countries.json';
import { languageWithoutRegionCode } from '../../../translations';
// import initialLobCategories from '../../../utils/variables/lobTypes.json';

import initialLocaleData from '../../../localeData.json';
import {
  compareAsc,
  getYearsFromRange,
  parseBool,
  flatten,
  activateCookies,
  isJsonString,
  keySort,
  mergeArrayOnce,
} from '../../../utils/functions';

// import { getAlpha2 } from '../../../storage/alpha2';
// import { getLanguage, setLanguage } from '../../../storage/language';
import {
  defaultAlpha2,
  resAlpha2,
  consentKey,
  acceptAllConsentValue,
  acceptCustomConsentValue,
  customConsentKey,
  languageStorage,
  alpha2Storage,
  agentType,
  customerType,
} from '../../../utils/variables';
import Cookies from '../../../cookies/cookies';
import { getLocalStorage, setLocalStorage } from '../../../storage/localStorage';
import CookiePolicies from '../../../utils/variables/cookiePolicies';
import { mergeItemOnce } from '../../../utils/functions/arrayManipulation';

// // console.log('initialLocaleData: ', initialLocaleData);

// // console.log('languageWithoutRegionCode: ,', languageWithoutRegionCode);

export const manipulateTerritories = (
  state,
  alpha2,
  defaultCountry = null,
  userType = customerType,
  _appSettingPayload,
) => {
  const consentValue = Cookies.getCookie(consentKey);
  // // console.log('territories: ', territories);
  let allLocations = [...countries];

  // const allLocations = keySort(territories, 'positionNo');
  // console.log('allLocations: ', allLocations);
  // const yourLocation = location; // state?.yourLocation;
  // // console.log('yourLocation: ', yourLocation);

  const tempLocation = allLocations?.find(x => x?.alpha2 === alpha2);
  const countryCode = tempLocation?.alpha2 || defaultCountry;
  // // console.log('countryCode: ', countryCode);
  let currentLocation = allLocations?.find(x => x?.alpha2 === countryCode);

  let siteWideSettings = {
    showSiteUnAvailable: false,
    disableRecaptcha: false,
    disableCookieConsent: false,
  };
  let appSettingPayload = _appSettingPayload;
  if (!appSettingPayload && countryCode) {
    appSettingPayload =
      getLocalStorage(`app_setting_${countryCode}`, { isObject: true }) || null;
  }
  if (appSettingPayload && countryCode === appSettingPayload?.general?.alpha2) {
    if (appSettingPayload?.general) {
      currentLocation = { ...currentLocation, ...appSettingPayload?.general };
    }
    if (appSettingPayload?.wizardCardActions) {
      currentLocation.wizardCardActions = appSettingPayload?.wizardCardActions;
    }
    if (appSettingPayload?.emptyMessages) {
      currentLocation.emptyMessages = appSettingPayload?.emptyMessages;
    }
    if (appSettingPayload?.appBackgroundImages) {
      currentLocation.appBackgroundImages = appSettingPayload?.appBackgroundImages;
    }
    if (appSettingPayload?.featureToggles) {
      currentLocation.featureSettings = {
        features: appSettingPayload?.featureToggles,
      };
    }
    if (appSettingPayload?.siteWide) {
      siteWideSettings = appSettingPayload?.siteWide;
    }
    if (appSettingPayload?.territories) {
      allLocations = mergeArrayOnce(allLocations, appSettingPayload?.territories, 'id');
    }
    allLocations = mergeItemOnce(allLocations, currentLocation, 'id');
  }
  allLocations = allLocations?.sort((a, b) => compareAsc(a, b, 'positionNo'));
  allLocations = allLocations?.filter(
    x =>
      (x?.isAvailableForC360Agent && userType === agentType) ||
      (x?.isAvailableOnC360 && userType !== agentType),
  );

  // // console.log('currentLocation: ', currentLocation);
  // // console.log('currentLocation: ', currentLocation);
  if (currentLocation?.lightTheme?.theme) {
    currentLocation.lightTheme.theme.type = 'light';
  }
  if (currentLocation?.darkTheme?.theme) {
    currentLocation.darkTheme.theme.type = 'dark';
  }
  const languages =
    currentLocation?.languages?.sort((a, b) => compareAsc(a, b, 'code')) || [];

  // // console.log('languages: ', languages);
  const defaultLanguage =
    currentLocation?.defaultLanguage?.code || languageWithoutRegionCode || 'en';

  const lobCategories = currentLocation?.lobCategories?.filter(x => !x.hidden) || []; // || initialLobCategories;
  if (lobCategories && lobCategories.length > 0) {
    lobCategories.sort((a, b) => {
      if (a?.positionNo < b?.positionNo) {
        return -1;
      }
      if (b?.positionNo < a?.positionNo) {
        return 1;
      }
      return 0;
    });
  }

  if (currentLocation) {
    currentLocation.cookiePolicy = CookiePolicies[currentLocation?.cookiePolicyId];

    if (currentLocation.cookiePolicy) {
      // console.log('currentLocation: ', currentLocation);
      const groupCookies = currentLocation?.cookiePolicy?.cookieSetting?.cookieCategories?.map(
        category => {
          const initialCookies = category?.cookies;
          const newCookies = initialCookies?.map(cookie => ({
            categoryId: category?.id,
            cookieId: cookie?.id || cookie,
            categorySlug: category?.slug,
          }));
          return newCookies;
        },
      );
      // console.log('groupCookies: ', groupCookies);
      const cookies = groupCookies ? flatten(groupCookies) : [];
      currentLocation.cookies = cookies;
      const cookiePolicyId = currentLocation?.cookiePolicyId || null;
      const hasCookiePolicy = !!cookiePolicyId;

      const cookieFeatureDisabled = siteWideSettings?.disableCookieConsent;

      if (currentLocation.alpha2) {
        // // console.log('currentLocation?.alpha2: ', currentLocation?.alpha2);
        if (
          consentValue === acceptAllConsentValue ||
          consentValue === acceptCustomConsentValue ||
          cookieFeatureDisabled
        ) {
          // setAlpha2(currentLocation?.alpha2);
          const customConsentCookie = Cookies.getCookie(customConsentKey);
          const customKeys = isJsonString(customConsentCookie?.split('@')[0]?.toString())
            ? JSON.parse(customConsentCookie?.split('@')[0]?.toString())
            : [];
          const cookieValues = {
            alpha2: currentLocation?.alpha2,
          };

          activateCookies(
            cookies,
            consentValue,
            customKeys,
            cookieValues,
            null,
            null,
            hasCookiePolicy,
          );
        }
      }
      // console.log('cookies: ', cookies);
    }
  }
  const variables = currentLocation?.variables;

  // console.log('initialYears: ', initialYears);

  return {
    lobCategories,
    languages,
    defaultLanguage,
    currentLocation,
    allLocations,
    manufacturedYears: getYearsFromRange(variables?.yearManufactureSettings),
    iclYears: getYearsFromRange(variables?.iclYearSettings),
    siteWide: siteWideSettings,
    currentAppSetting: appSettingPayload,
  };
};

const initialAlpha2 = Cookies.getCookie(alpha2Storage);
let initialLanguage = Cookies.getCookie(languageStorage);

initialLanguage = initialLanguage?.replace(/"/g, '');
const {
  lobCategories: initialLobCategories,
  languages: initialLanguages,
  currentLocation: initialLocation,
  allLocations: initialLocations,
  manufacturedYears: initialManufacturedYears,
  iclYears: initialIclYears,
  siteWide: initialSiteWide,
  currentAppSetting: initialCurrentAppSetting,
} = manipulateTerritories(
  { allLocations: countries },
  initialAlpha2,
  resAlpha2,
  customerType,
);

export const initialState = {
  getAllLocationsResponse: null, // {payload: countries,},
  isGettingAllLocationsPending: false,
  isGettingAllLocationsError: false,
  isGettingAllLocationsSuccess: false,
  getAllLocationsError: null,
  allLocations: initialLocations,
  isGettingYourLocationPending: false,
  isGettingYourLocationError: false,
  isGettingYourLocationSuccess: false,
  getYourLocationError: null,
  getYourLocationResponse: null,
  yourLocation: initialLocation, // countries?.find(x => x?.alpha2 === 'TT')
  lightTheme: null,
  darkTheme: null,
  languages: initialLanguages,
  language: initialLanguage, // .trim(), //
  c360LayoutLink: null,
  c360LocaleData: initialLocaleData,
  defaultLanguage: initialLanguage,
  lobCategories: initialLobCategories,
  ggStaticImage: null,
  ggStaticVariable: null,
  isUpdateYourLocationPending: false,
  isUpdateYourLocationError: false,
  isUpdateYourLocationSuccess: false,
  updateYourLocationError: null,
  updateYourLocationResponse: null,
  manufacturedYears: initialManufacturedYears,
  iclYears: initialIclYears,
  isGettingYourAppSettingPending: false,
  isGettingYourAppSettingError: false,
  isGettingYourAppSettingSuccess: false,
  appSettingError: null,
  appSettingResponse: null,
  currentAppSetting: initialCurrentAppSetting,
  siteWide: initialSiteWide,
};

function locationReducer(state = initialState, action) {
  const consentValue = Cookies.getCookie(consentKey);

  switch (action.type) {
    case GET_ALL_LOCATIONS_API_REQUEST:
      return {
        ...state,
        isGettingAllLocationsPending: true,
        isGettingAllLocationsError: false,
        isGettingAllLocationsSuccess: false,
      };
    case GET_ALL_LOCATIONS_API_SUCCESS: {
      const userType = action?.userType;

      const {
        currentLocation,
        allLocations,
        lobCategories,
        defaultLanguage,
        languages,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      } = manipulateTerritories(
        action?.payload,
        state?.yourLocation?.alpha2,
        defaultAlpha2,
        userType,
      );

      let tempLanguage =
        languages?.find(x => x.code === state.language)?.code || defaultLanguage;
      tempLanguage = tempLanguage.replace(/"/g, '');

      return {
        ...state,
        isGettingAllLocationsPending: false,
        isGettingAllLocationsError: false,
        isGettingAllLocationsSuccess: true,
        // getAllLocationsResponse: action.payload,
        yourLocation: currentLocation,
        allLocations,
        lightTheme: currentLocation?.lightTheme?.theme,
        darkTheme: currentLocation?.darkTheme?.theme,
        languages, // : languages || initialLanguages,
        language: tempLanguage,
        c360LocaleData: currentLocation?.c360StaticLocale || initialLocaleData,
        c360LayoutLink: currentLocation?.c360LayoutLink,
        defaultLanguage,
        lobCategories,
        ggStaticImage: currentLocation?.ggStaticImage,
        ggStaticVariable: currentLocation?.ggStaticVariable,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      };
    }
    case GET_ALL_LOCATIONS_API_ERROR:
      return {
        ...state,
        isGettingAllLocationsPending: false,
        isGettingAllLocationsError: true,
        isGettingAllLocationsSuccess: false,
        // getAllLocationsError: action.payload,
      };

    case GET_YOUR_APP_SETTINGS_API_REQUEST:
      return {
        ...state,
        isGettingYourAppSettingPending: true,
        isGettingYourAppSettingError: false,
        isGettingYourAppSettingSuccess: false,
        appSettingResponse: null,
        appSettingError: null,
      };
    case GET_YOUR_APP_SETTINGS_API_SUCCESS: {
      const { yourLocation } = state;
      const userType = action?.userType;
      const appSettingPayload = action?.payload?.payload;

      if (appSettingPayload) {
        setLocalStorage(`app_setting_${yourLocation?.alpha2}`, appSettingPayload, {
          isObject: true,
        });
      }

      const {
        currentLocation,
        allLocations,
        lobCategories,
        defaultLanguage,
        languages,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      } = manipulateTerritories(
        state,
        yourLocation?.alpha2,
        defaultAlpha2,
        userType,
        appSettingPayload,
      );

      let tempLanguage =
        languages.find(x => x.code === state.language)?.code || defaultLanguage;
      tempLanguage = tempLanguage.replace(/"/g, '');

      // // console.log('yourLocation: ', yourLocation);

      // const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isGettingYourAppSettingPending: false,
        isGettingYourLocationError: false,
        isGettingYourLocationSuccess: true,
        getYourLocationResponse: action?.payload,
        yourLocation: currentLocation,
        allLocations,
        lightTheme: currentLocation?.lightTheme?.theme,
        darkTheme: currentLocation?.darkTheme?.theme,
        languages, // : languages || initialLanguages,
        language: tempLanguage,
        c360LocaleData: currentLocation?.c360StaticLocale || initialLocaleData,
        c360LayoutLink: currentLocation?.c360LayoutLink,
        defaultLanguage,
        lobCategories,
        ggStaticImage: currentLocation?.ggStaticImage,
        ggStaticVariable: currentLocation?.ggStaticVariable,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
        appSettingResponse: appSettingPayload,
        appSettingError: null,
      };
    }
    case GET_YOUR_APP_SETTINGS_API_ERROR: {
      return {
        ...state,
        isGettingYourAppSettingPending: false,
        isGettingYourAppSettingError: true,
        isGettingYourAppSettingSuccess: false,
        appSettingResponse: null,
        appSettingError: action?.payload,
      };
    }

    case GET_YOUR_LOCATION_API_REQUEST:
      return {
        ...state,
        isGettingYourLocationPending: true,
        isGettingYourLocationError: false,
        isGettingYourLocationSuccess: false,
        appSettingResponse: null,
        appSettingError: null,
      };
    case GET_YOUR_LOCATION_API_SUCCESS: {
      // const { allLocations } = state;
      // const defaultLocation = allLocations?.find(x => x?.alpha2 === defaultAlpha2);
      const yourLocation = action?.payload?.payload; // || defaultLocation;
      const userType = action?.userType;

      const {
        currentLocation,
        allLocations,
        lobCategories,
        defaultLanguage,
        languages,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      } = manipulateTerritories(state, yourLocation?.alpha2, defaultAlpha2, userType);

      let tempLanguage =
        languages.find(x => x.code === state.language)?.code || defaultLanguage;
      tempLanguage = tempLanguage.replace(/"/g, '');

      // // console.log('yourLocation: ', yourLocation);

      // const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isGettingYourLocationPending: false,
        isGettingYourLocationError: false,
        isGettingYourLocationSuccess: true,
        getYourLocationResponse: action?.payload,
        yourLocation: currentLocation,
        allLocations,
        lightTheme: currentLocation?.lightTheme?.theme,
        darkTheme: currentLocation?.darkTheme?.theme,
        languages, // : languages || initialLanguages,
        language: tempLanguage,
        c360LocaleData: currentLocation?.c360StaticLocale || initialLocaleData,
        c360LayoutLink: currentLocation?.c360LayoutLink,
        defaultLanguage,
        lobCategories,
        ggStaticImage: currentLocation?.ggStaticImage,
        ggStaticVariable: currentLocation?.ggStaticVariable,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      };
    }
    case GET_YOUR_LOCATION_API_ERROR: {
      const userType = action?.userType;
      const {
        currentLocation,
        allLocations,
        lobCategories,
        defaultLanguage,
        languages,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      } = manipulateTerritories(state, null, defaultAlpha2, userType);

      let tempLanguage =
        languages.find(x => x.code === state.language)?.code || defaultLanguage;
      tempLanguage = tempLanguage.replace(/"/g, '');

      return {
        ...state,
        isGettingYourLocationPending: false,
        isGettingYourLocationError: true,
        isGettingYourLocationSuccess: false,
        getYourLocationError: action?.payload,
        yourLocation: currentLocation,
        allLocations,
        lightTheme: currentLocation?.lightTheme?.theme,
        darkTheme: currentLocation?.darkTheme?.theme,
        languages, // : languages || initialLanguages,
        language: tempLanguage,
        c360LocaleData: currentLocation?.c360StaticLocale || initialLocaleData,
        c360LayoutLink: currentLocation?.c360LayoutLink,
        defaultLanguage,
        lobCategories,
        ggStaticImage: currentLocation?.ggStaticImage,
        ggStaticVariable: currentLocation?.ggStaticVariable,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      };
    }

    case UPDATE_YOUR_LOCATION_API_REQUEST:
      return {
        ...state,
        isUpdateYourLocationPending: true,
        isUpdateYourLocationError: false,
        isUpdateYourLocationSuccess: false,
      };
    case UPDATE_YOUR_LOCATION_API_SUCCESS: {
      const yourLocation = action?.payload; // action?.payload?.payload ||
      const userType = action?.userType;
      // console.log('userType22: ', userType);
      const {
        currentLocation,
        allLocations,

        lobCategories,
        defaultLanguage,
        languages,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      } = manipulateTerritories(state, yourLocation?.alpha2, defaultAlpha2, userType);

      let tempLanguage =
        languages.find(x => x.code === state.language)?.code || defaultLanguage;
      tempLanguage = tempLanguage.replace(/"/g, '');

      return {
        ...state,
        isUpdateYourLocationPending: false,
        isUpdateYourLocationError: false,
        isUpdateYourLocationSuccess: true,
        updateYourLocationResponse: action?.payload,
        yourLocation: currentLocation,
        allLocations,
        lightTheme: currentLocation?.lightTheme?.theme,
        darkTheme: currentLocation?.darkTheme?.theme,
        languages, // : languages || initialLanguages,
        language: tempLanguage,
        c360LocaleData: currentLocation?.c360StaticLocale || initialLocaleData,
        c360LayoutLink: currentLocation?.c360LayoutLink,
        defaultLanguage,
        lobCategories,
        ggStaticImage: currentLocation?.ggStaticImage,
        ggStaticVariable: currentLocation?.ggStaticVariable,
        manufacturedYears,
        iclYears,
        siteWide,
        currentAppSetting,
      };
    }
    case UPDATE_YOUR_LOCATION_API_ERROR:
      return {
        ...state,
        isUpdateYourLocationPending: false,
        isUpdateYourLocationError: true,
        isUpdateYourLocationSuccess: false,
        // updateYourLocationError: action.payload,
      };

    case UPDATE_YOUR_LANGUAGE_SUCCESS: {
      const newLanguage = action?.payload; // action?.payload?.payload ||
      const cookies = action?.cookies;
      const hasCookiePolicy = action?.hasCookiePolicy;
      const cookieFeatureDisabled = state?.siteWide?.disableCookieConsent;
      if (
        consentValue === acceptAllConsentValue ||
        consentValue === acceptCustomConsentValue ||
        cookieFeatureDisabled
      ) {
        // setLanguage(newLanguage);
        const customConsentCookie = Cookies.getCookie(customConsentKey);
        const customKeys = isJsonString(customConsentCookie?.split('@')[0]?.toString())
          ? JSON.parse(customConsentCookie?.split('@')[0]?.toString())
          : [];
        const cookieValues = {
          language: newLanguage,
        };

        activateCookies(
          cookies,
          consentValue,
          customKeys,
          cookieValues,
          null,
          null,
          hasCookiePolicy,
        );
      }
      return {
        ...state,
        language: newLanguage,
      };
    }

    default:
      return state;
  }
}
export default locationReducer;

/*
const initialData = [
  {
    id: '001',
    countryCode: 'TT',
    imageUrl: trinidadFlag,
  },
  {
    id: '002',
    countryCode: 'CA',
    imageUrl: curacaoFlag,
  },
  {
    id: '003',
    countryCode: 'BD',
    imageUrl: barbadosFlag,
  },
];

import { trinidadFlag, barbadosFlag, curacaoFlag } from '../../../utils/variables/images';
*/
