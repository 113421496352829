/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  // FormControl,
  // InputLabel,
  Typography,
  Tooltip,
  Icon,
  Hidden,
  IconButton,
  Popover,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import styles from './style';
import { addWizard } from '../../../actions/wizards';
import {
  uuidv4,
  formatDataTableValue,
  sourceTextFromData,
  getRef,
} from '../../../utils/functions';
import {
  textCustomColorTypes,
  textCustomSizeTypes,
  orientationTypes,
  textAlignTypes,
} from '../../propertyValues';
import { evaluateExpressions } from '../../../utils/functions/misc';
import { useSmall } from '../../../hooks';

const useStyles = makeStyles(styles);

const TextWithLabel = props => {
  const {
    formClassName,
    labelClassName,
    textClassName,
    label,
    text,
    tooltip,
    tooltipIcon,
    isNumeric,
    isAbbrev,
    isCurrency,
    isPercent,
    isLocalize,
    units,
    loading,
    show,
    calculateExpressions,
    name,
    showLabel,
    footNote,
    footNoteSize,
  } = props;

  let {
    textSize,
    textColor,
    textAlign,
    labelSize,
    labelColor,
    tooltipColor,
    labelAlign,
    align,
    unitsFontSize,
    decimal,
  } = props;

  textSize = textSize || 'sm';
  textColor = textColor || 'none';
  textAlign = textAlign || 'center';
  labelSize = labelSize || 'xs';
  labelColor = labelColor || 'primary';
  tooltipColor = tooltipColor || 'none';
  labelAlign = labelAlign || 'center';
  align = align || 'vertical';
  unitsFontSize = unitsFontSize || 'xxs';
  decimal = decimal || 2;

  const classes = useStyles();
  const dispatch = useDispatch();
  const id = uuidv4();
  const isSmall = useSmall();
  const storeState = useSelector(state => state);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (name && show) {
      dispatch(addWizard.addInitialWizardDataAction({ [name]: inputValue }));
    }
  }, []);

  const handleClick = event => {
    // console.log('clicked');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;

  if (!show) {
    return null;
  }

  const myTooltipIcon = loading ? (
    <Skeleton animation="wave" height={30} width={30} />
  ) : (
    <>
      <IconButton aria-label="help" size="small" onClick={handleClick}>
        <HelpOutlineIcon />
      </IconButton>

      <Popover
        id={popId}
        classes={{ paper: classes.popoverTooltip }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography>{tooltip}</Typography>
      </Popover>
    </>
  );

  const myLabel = loading ? (
    <Skeleton animation="wave" height={20} width={50} />
  ) : (
    <Tooltip
      title={tooltip}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
    >
      <Typography
        htmlFor={id}
        align={labelAlign}
        className={classnames(
          classes.labelDefaultStyle,
          {
            [classes[labelSize]]: labelSize,
            [classes[labelColor]]: labelColor,
            [classes.inlineBlock]: align === 'horizontal',
          },
          // classes.alignLeft,
          { [classes.floatLeft]: align === 'space-between' },
          labelClassName,
        )}
      >
        {sourceTextFromData(storeState, label)}
      </Typography>
    </Tooltip>
  );
  // // console.log('text: ', text);
  let inputValue = sourceTextFromData(storeState, text);
  const showDataUnits = false;
  const showOriginalUnits = true;

  if (calculateExpressions?.length > 0) {
    inputValue = evaluateExpressions(storeState, calculateExpressions);
  }

  const { dataValue, originalValue } = formatDataTableValue({
    isNumeric,
    isAbbrev,
    isCurrency,
    isPercent,
    isLocalize,
    inputValue,
    units,
    showOriginalUnits,
    showDataUnits,
    decimal,
  });

  // React.useEffect(() => {
  //   if (name && show) {
  //     dispatch(addWizard.addInitialWizardDataAction({ [name]: inputValue }));
  //   }
  // }, [inputValue]);

  const myText = loading ? (
    <Skeleton animation="wave" height={20} width={50} />
  ) : (
    <Tooltip
      title={originalValue}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
    >
      <Typography
        id={id}
        align={textAlign}
        className={classnames(
          classes.textDefaultStyle,
          {
            [classes[textSize]]: textSize,
            [classes[textColor]]: textColor,
            [classes.inlineBlock]: align === 'horizontal',
          },
          // classes.alignLeft,
          { [classes.floatRight]: align === 'space-between' },
          textClassName,
        )}
      >
        {dataValue}
        {units && (
          <span
            className={classnames(classes.inlineBlock, {
              [classes[unitsFontSize]]: unitsFontSize,
            })}
          >
            {units}
          </span>
        )}
      </Typography>
    </Tooltip>
  );

  return (
    <div
      style={{
        // display: 'flex',
        position: 'relative',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <div
        className={classnames(
          { [classes.inlineBlock]: align === 'horizontal' },
          { [classes.floatBetween]: align === 'space-between' },
          formClassName,
        )}
      >
        {(label || showLabel) && myLabel}
        {myText}
      </div>

      {tooltipIcon && tooltipIcon?.toString().length > 0 && (
        <div className={classes.tooltipIcon}>{myTooltipIcon}</div>
      )}
      {footNote && (
        <Typography
          align="left"
          className={classnames(classes.footNoteDefaultStyle, {
            [classes[footNoteSize]]: footNoteSize,
          })}
        >
          {sourceTextFromData(storeState, footNote)}
        </Typography>
      )}
    </div>
  );
};

TextWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltipIcon: PropTypes.string,
  formClassName: PropTypes.string,
  textClassName: PropTypes.string,
  units: PropTypes.string,
  unitsFontSize: PropTypes.oneOf(textCustomSizeTypes),
  textSize: PropTypes.oneOf(textCustomSizeTypes),
  textColor: PropTypes.oneOf(textCustomColorTypes),
  textAlign: PropTypes.oneOf(textAlignTypes),
  labelClassName: PropTypes.string,
  labelSize: PropTypes.oneOf(textCustomSizeTypes),
  labelColor: PropTypes.oneOf(textCustomColorTypes),
  tooltipColor: PropTypes.oneOf(textCustomColorTypes),
  labelAlign: PropTypes.oneOf(textAlignTypes),
  align: PropTypes.oneOf(orientationTypes),
  isNumeric: PropTypes.bool,
  isAbbrev: PropTypes.bool,
  isCurrency: PropTypes.bool,
  isPercent: PropTypes.bool,
  isLocalize: PropTypes.bool,
  loading: PropTypes.bool,
  show: PropTypes.bool,
  decimal: PropTypes.number,
};

TextWithLabel.defaultProps = {
  textClassName: '',
  textSize: 'sm',
  textColor: 'none',
  textAlign: 'center',
  formClassName: '',
  labelClassName: '',
  labelSize: 'xs',
  labelColor: 'primary',
  tooltipColor: 'none',
  labelAlign: 'center',
  label: '',
  text: '',
  tooltip: '',
  tooltipIcon: '',
  align: 'vertical',
  isNumeric: false,
  isAbbrev: false,
  isCurrency: false,
  isPercent: false,
  isLocalize: false,
  units: '',
  unitsFontSize: 'xxs',
  loading: false,
  show: true,
  decimal: 2,
};

export default TextWithLabel;

/*

: (
    <Hidden smDown>
      <Tooltip
        title={tooltip}
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
      >
        <Icon
          align={labelAlign}
          className={classnames(
            classes.labelDefaultStyle,
            {
              [classes[labelSize]]: labelSize,
              // [classes[tooltipColor]]: tooltipColor,
              // [classes[labelColor]]: labelColor,
              [classes.inlineBlock]: align === 'horizontal',
            },
            // classes.alignLeft,
            { [classes.floatLeft]: align === 'space-between' },
            // labelClassName,
          )}
        >
          {tooltipIcon}
        </Icon>
      </Tooltip>
    </Hidden>
  )
  */
