import React from 'react';
import { toastr } from 'react-redux-toastr';
import { Typography } from '@mui/material';

export const toastrOptions = ({
  status,
  title,
  message,
  onShow,
  onHide,
  onToastrClick,
  onCloseButtonClick,
  className,
  timeOut,
  headers,
}) => {
  // console.log('timeOut: ', timeOut);
  return {
    position: 'bottom-center',
    timeOut: timeOut || timeOut === 0 ? timeOut : 10000, // by setting to 0 it will prevent the auto close
    icon: status, // You can add any component you want but note that the width and height are 70px ;)
    status,
    onShowComplete: onShow, // show animations is done
    onHideComplete: onHide, // () => // console.log('HIDE: animation is done'),
    onCloseButtonClick, // //() => // console.log('Close button was clicked'),
    onToastrClick, // () => // console.log('Toastr was clicked'),
    progressBar: true,
    showCloseButton: true, // false by default
    closeOnToastrClick: false, // false by default, this will close the toastr when user clicks on it
    // this option will give you a func 'remove' as props
    component: () =>
      headers && headers['x-request-id'] ? (
        <Typography variant="caption" align="left" style={{ fontSize: '9px' }}>
          Id: {headers['x-request-id']}
        </Typography>
      ) : null,
    className,
  };
};

export const displayErrors = (err = {}, className, opts = {}) => {
  // console.log('className: ', className);
  if (err?.responseDesc?.message && typeof err?.responseDesc?.message === 'string') {
    err.message = err?.responseDesc?.message;
  }
  err.message = err?.message || opts?.defaultMsg;
  // console.log('err: ', err);
  const status = err?.responseDesc?.type || opts?.type || 'error';
  const errors = err?.errors;
  const headers = err?.headers;
  // console.log('headers: ', headers);
  opts.headers = opts.headers || headers;
  if (!opts.timeOut) {
    opts.timeOut = 0;
  }
  if (errors && Object.keys(errors).length > 0) {
    const result = Object.keys(errors).map(key => {
      if (key && errors[key]) {
        const keyMessage = errors[key].message;
        const errorTitle = keyMessage
          ? err?.message
          : err?.responseDesc?.title || err?.label || err?.name || opts?.defaultTitle;
        toastr[status](
          errorTitle,
          keyMessage || err?.message,
          toastrOptions({
            status,
            className,
            ...opts,
          }),
        );
      }
    });
  } else {
    toastr[status](
      err?.responseDesc?.title ||
        err?.label ||
        err?.name ||
        opts?.defaultTitle ||
        'Something went wrong!',
      err?.message || '',
      toastrOptions({
        status,
        className,
        ...opts,
      }),
    );
  }
};

export const displaySuccess = (msg = {}, className, opts = {}) => {
  if (msg?.responseDesc?.message && typeof msg?.responseDesc?.message === 'string') {
    msg.message = msg?.responseDesc?.message;
  }
  msg.message = msg?.message || opts?.defaultMsg;
  const status = msg?.responseDesc?.type || opts?.type || 'success';
  const headers = msg?.headers;
  opts.headers = opts.headers || headers;
  toastr[status](
    msg?.responseDesc?.title ||
      msg?.label ||
      msg?.name ||
      opts?.defaultTitle ||
      'Everything looks good!',
    msg?.message || '',
    toastrOptions({
      status,
      className,
      ...opts,
    }),
  );
};

export const displayInfo = (msg = {}, className, opts = {}) => {
  if (msg?.responseDesc?.message && typeof msg?.responseDesc?.message === 'string') {
    msg.message = msg?.responseDesc?.message;
  }
  msg.message = msg?.message || opts?.defaultMsg;
  const status = msg?.responseDesc?.type || opts?.type || 'info';
  const headers = msg?.headers;
  opts.headers = opts.headers || headers;
  toastr[status](
    msg?.responseDesc?.title ||
      msg?.label ||
      msg?.name ||
      opts?.defaultTitle ||
      'Something to know!',
    msg?.message || '',
    toastrOptions({
      status,
      className,
      ...opts,
    }),
  );
};

export const displayWarning = (msg = {}, className, opts = {}) => {
  if (msg.responseDesc?.message && typeof msg?.responseDesc?.message === 'string') {
    msg.message = msg.responseDesc?.message;
  }
  msg.message = msg?.message || opts?.defaultMsg;
  const status = msg?.responseDesc?.type || opts?.type || 'warning';
  const headers = msg?.headers;
  opts.headers = opts.headers || headers;
  toastr[status](
    msg?.responseDesc?.title ||
      msg?.label ||
      msg?.name ||
      opts?.defaultTitle ||
      'Something is not right!',
    msg?.message || '',
    toastrOptions({
      status,
      className,
      ...opts,
    }),
  );
};

export const displayLight = (msg = {}, className, opts = {}) => {
  if (msg.responseDesc?.message && typeof msg?.responseDesc?.message === 'string') {
    msg.message = msg.responseDesc?.message;
  }
  msg.message = msg?.message || opts?.defaultMsg;
  const status = msg?.responseDesc?.type || opts?.type || 'success';
  const headers = msg?.headers;
  opts.headers = opts.headers || headers;
  toastr.light(
    msg?.responseDesc?.title ||
      msg?.label ||
      msg?.name ||
      opts?.defaultTitle ||
      'Everything looks good!',
    msg?.message || '',
    toastrOptions({
      status,
      className,
      ...opts,
    }),
  );
};
