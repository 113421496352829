export default theme => ({
  content: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    scrollbarX: 'scroll',
    width: '100%',
    wordBreak: 'break-word',
    padding: '0px',
    textAlign: 'left',

    '& .ql-editor': {
      textAlign: 'left',
      whiteSpace: 'initial',
      lineHeight: '29px',
      padding: '0px',
    },
    '& .ql-container': {
      fontFamily: `${theme.typography.fontFamily}`,
      color: `${theme.palette.text.dark}`,
      padding: '0px',
      fontSize: `${theme.typography.body1.fontSize}`,
    },
    '& p': {
      // fontSize: '1rem !important',
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& ul li::marker': {
      fontColor: `${theme.palette.primary.main} !important`,
      fontSize: '1.2em',
    },
  },
});
