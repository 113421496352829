import { DEFAULT_KEY, generateCacheTTL } from '../../../utils/functions/cache';
import {
  GET_INSURANCES_API_REQUEST,
  GET_INSURANCES_API_SUCCESS,
  GET_INSURANCES_API_ERROR,
  GET_INSURANCE_INVESTMENTS_ERROR,
  GET_INSURANCE_INVESTMENTS_REQUEST,
  GET_INSURANCE_INVESTMENTS_SUCCESS,
  GET_NEXT_INSURANCES_SCROLL_SUCCESS,
  GET_INSURANCE_FILTER_SETTINGS_API_REQUEST,
  GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS,
  GET_INSURANCE_FILTER_SETTINGS_API_ERROR,
  GET_SINGLE_INSURANCE_API_REQUEST,
  GET_SINGLE_INSURANCE_API_SUCCESS,
  GET_SINGLE_INSURANCE_API_ERROR,
  RESET_INSURANCE,
  UPDATE_TOTALS_SUCCESS,
} from '../../../constants/portfolio/insuranceActionNames';
import {
  RENEW_POLICY_API_SUCCESS,
  AMEND_POLICY_API_SUCCESS,
} from '../../../constants/renewalActionNames';
import {
  SIGNIN_API_SUCCESS,
  SELECT_CLIENTS_SUCCESS,
} from '../../../constants/authActionNames';
import {
  ADD_RENEWAL_TO_CART_API_SUCCESS,
  PAY_NOW_API_SUCCESS,
  PAY_FIRST_PREMIUM_API_SUCCESS,
  MAKE_FAC_PAYMENT_API_SUCCESS,
} from '../../../constants/cartActionNames';
import {
  GET_MATURED_PENSION_API_REQUEST,
  GET_MATURED_PENSION_API_SUCCESS,
  GET_MATURED_PENSION_API_ERROR,
} from '../../../constants/portfolio/maturedPensionActionNames';
import {
  CLEAR_PORTFOLIO_CACHE,
  RESET_INFINITE_SCROLL_SUCCESS,
} from '../../../constants/miscActionNames';

// import initialData from './initialData';
import payloadData from './payloadData.json';
import {
  persistKey,
  searchKey,
  investmentLob,
  healthLob,
} from '../../../utils/variables';
import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  removeAllSpaces,
  generateSearchWords,
  getKeys,
  getAllKeys,
  uniq,
} from '../../../utils/functions';
import { filterInsuranceStatuses } from '../../../selectors/data';

let searchKeys = [];
export const initialState = {
  response: null, // newPortfolioData
  isPending: false,
  isError: false,
  isSuccess: false,
  isMaturedPending: false,
  isMaturedError: false,
  isMaturedSuccess: false,
  error: {},
  insurance: [],
  insurances: [],
  searchKeys,
  // insuranceCollections: [],
  [persistKey]: null,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  scrollKey: null,
  firstTime: false,
  maturedPension: null,
  total: {},
  isGetInsuranceFilterSettingsPending: false,
  isGetInsuranceFilterSettingsError: false,
  isGetInsuranceFilterSettingsSuccess: false,
  insuranceFilterSettingsResponse: null,
  insuranceFilterSettings: null,
  insuranceFilterSettingsError: null,
  isSingleInsurancePolicyPending: false,
  isSingleInsurancePolicyError: false,
  isSingleInsurancePolicySuccess: false,
  singleInsurancePolicyResponse: null,
  insurancePolicy: null,
  singleInsurancePolicyError: null,
  [DEFAULT_KEY]: null,
};

const createMaturedPension = maturedPensionPayload => {
  return {
    ...maturedPensionPayload,
    policyNumber: maturedPensionPayload?.policyNumber,
    yearToDatePension: maturedPensionPayload?.YTDPension,
    yearToDateTaxPaid: maturedPensionPayload?.YTDTax,
    lastPaidPension: dateToString(
      isoStringToDate(maturedPensionPayload?.lastPensionDate),
    ),
    monthlyPension: maturedPensionPayload?.monthlyPension,
    beneficiary: maturedPensionPayload?.beneficiaries,
  };
};

const createOtherDiscounts = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      discountName: object?.discountName,
      amount: object?.amount,
    };
  });
};

const createDiscounts = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      noClaimDiscount: object?.noClaimDiscount,
      fleetDiscount: object?.fleetDiscount,
      antiTheft: object?.antiTheft,
      otherDiscounts: createOtherDiscounts(object?.otherDiscounts),
    };
  });
};

const createCoverages = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  payloadArray.filter(x => x?.status === 'Active');
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      name: object?.name,
      status: object?.status,
      startDate: dateToString(isoStringToDate(object?.startDate)),
      premAmt: object?.premiumAmount || object?.premAmt,
      faceAmt: object?.faceAmt,
      fundValue: object?.fundValue,
      currency: object?.currency,
      paymentMode: object?.paymentMode,
      maximumBenefit: object?.maximumBenefit,
      deductiblePerPerson: object?.deductiblePerPerson,
      deductiblePerFamily: object?.deductiblePerFamily,
    };
  });
};

const createSubjectMatters = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      buildingSumInsured: object?.buidlingSumInsured,
      swimmingPool: object?.swimmingPool,
      general: object?.general,
      tenant: object?.tenant,
      electrical: object?.electrical,
      antenna: object?.antenna,
      satellite: object?.sateliite,
      perils: object?.perils,
    };
  });
};

const createInsuredItems = (payloadArray, type) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      coverageType: object?.coverageType,
      insuredItem: object?.insuredItem,
      insuredItemLocation: object?.insuredItemLocation,
      sumInsured: object?.sumInsured,
      premium: object?.premium,
      occupancy: object?.occupancy,
      addedOn: dateToString(isoStringToDate(object?.addedOn)),
      vehicleMakeandModel: object?.vehicleMakeandModel,
      vehicleYear: object?.vehicleYear,
      registrationNumber: object?.registrationNumber,
      engineNumber: object?.engineNumber,
      chassisNumber: object?.chassisNumber,
      sumInsuredLimit: object?.sumInsuredLimit,
      premiumNumber: object?.premiumNumber,
      terminatedOn: dateToString(isoStringToDate(object?.terminatedOn)),
      windscreenLimit: object?.windscreenLimit,
      personalAccidentBenefit: object?.personalAccidentBenefit,
      specialPreils: object?.specialPreils,
    };
  });
};

const createOwners = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      displaySourceId: object?.displaySourceId,
      firstName: object?.firstName,
      lastName: object?.lastName,
      primaryPhone: object?.primaryPhone,
      primaryEmail: object?.primaryEmail,
    };
  });
};

const createMembers = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      firstName: object?.firstName,
      lastName: object?.lastName,
      relationship: object?.relationship,
      dependentOf: object?.dependentOf,
    };
  });
};

const createDrivers = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      firstName: object?.firstName,
      lastName: object?.lastName,
      driverType: object?.driverType,
    };
  });
};

const createInsured = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      displaySourceId: object?.displaySourceId,
      firstName: object?.firstName,
      lastName: object?.lastName,
      primaryPhone: object?.primaryPhone,
      address: object?.address,
    };
  });
};

const createOwner = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      displaySourceId: object?.displaySourceId,
      firstName: object?.firstName,
      lastName: object?.lastName,
      primaryPhone: object?.primaryPhone,
      companyName: object?.companyName,
    };
  });
};

const createPayors = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      displaySourceId: object?.displaySourceId,
      firstName: object?.firstName,
      lastName: object?.lastName,
      primaryPhone: object?.primaryPhone,
      companyName: object?.companyName,
    };
  });
};

/*
const sampleTitleNames = [
  {
    lob: 'life',
    title: 'Express Life',
  },
  {
    lob: 'health',
    title: 'Health Provisor',
  },
  {
    lob: 'motor',
    title: 'Motor Guard',
  },
  {
    lob: 'pensions',
    title: 'Pension Quantum',
  },
  {
    lob: 'home',
    title: 'Home Quantum',
  },
  {
    lob: 'investments',
    title: 'Investment Quantum',
  },
];
*/

const updateCollection = (collections, policy) => {
  let collectionIndex = collections.findIndex(
    object => object.slimTitle === policy?.slimTitle,
  );
  if (collectionIndex < 0) {
    const collection = {
      id: uuidv4(),
      name: policy?.title,
      lob: policy?.lob,
      policies: [],
      slimTitle: policy?.slimTitle,
      filterLob: policy?.lob,
    };
    collectionIndex = collections.push(collection);
    collectionIndex -= 1;
    // // console.log('collectionIndex: ', collectionIndex);
  }
  // // console.log('collections: ', collections);
  // // console.log('collections[collectionIndex]: ', collections[collectionIndex]);
  const index = collections[collectionIndex].policies.findIndex(
    object => object.id === policy?.id,
  );
  if (index > -1) {
    collections[collectionIndex].policies.splice(index, 1, policy);
  } else {
    collections[collectionIndex].policies.push(policy);
  }

  return policy;
};

/*
const convertStatusToNumber = status => {
  if (status?.toLowerCase() === 'active') {
    return 100;
  }
  if (status?.toLowerCase() === 'renew') {
    return 70;
  }
  if (status?.toLowerCase() === 'review') {
    return 50;
  }
  if (status?.toLowerCase() === 'inactive') {
    return 20;
  }

  return 5;
};
*/

const formatMaturedDetails = details => {
  if (!details) return details;
  const newDetails = { ...details };

  if (newDetails.lastPensionDate) {
    newDetails.lastPensionDate = dateToString(
      isoStringToDate(newDetails.lastPensionDate),
    );
  }

  return newDetails;
};

export const createPolicies = (payloadArray, lobTypes) => {
  if (!Array.isArray(payloadArray)) return null;
  // console.log('payloadArray[0]: ', payloadArray[0]);

  const newPolicies = payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(
      lobObject =>
        object?.type?.toLowerCase() === lobObject?.lob ||
        object?.masterId?.toLowerCase().indexOf(lobObject?.lob) > -1,
    );
    // // console.log('lobObj: ', lobObj);
    /*
    const filteredNames = sampleTitleNames.filter(
      nameObj => nameObj?.lob === lobObj?.lob,
    );
    */
    // // console.log('filteredNames: ', filteredNames);
    // const random = Math.floor(Math.random() * filteredNames.length);
    // // console.log('random: ', random);

    const title = object?.title; // || filteredNames[random]?.title;
    // // console.log('title: ', title);
    /* const insuredItem =
      object?.insuredItemDetails && object?.insuredItemDetails.length > 0
        ? object?.insuredItemDetails[0]
        : {}; */

    // const currentSecondaryStatusObject = filterInsuranceStatuses.find(
    //  x => x?.status?.toLowerCase() === object?.secondaryStatus?.toLowerCase(),
    // );
    /*
    // console.log(
      'isoStringToDate(object?.expiryDate): ',
      isoStringToDate(object?.expiryDate),
    );
   */
    let filterDate = null;
    let filterDateType = '';
    if (object?.payToDate) {
      filterDate = dateToString(isoStringToDate(object?.payToDate));
      filterDateType = 'payTo';
    } else if (object?.dueDate) {
      filterDate = dateToString(isoStringToDate(object?.dueDate));
      filterDateType = 'due';
    } else if (object?.expiryDate) {
      filterDate = dateToString(isoStringToDate(object?.expiryDate));
      filterDateType = 'expiry';
    } else if (object?.issueDate) {
      filterDate = dateToString(isoStringToDate(object?.issueDate));
      filterDateType = 'issue';
    }

    const owner = createOwner(object?.owner);
    const inceptionDate = dateToString(isoStringToDate(object?.inceptionDate));
    const expiryDate = dateToString(isoStringToDate(object?.expiryDate));

    const policy = {
      ...object,
      index: uuidv4(), // startIndex + index,
      id: object?.id,
      idNoSpace: object?.id?.replace(/ /g, ''),
      lob: lobObj?.lob,
      color: lobObj?.color,
      icon: lobObj?.icon,
      materialIcon: lobObj?.materialIcon,
      type: object?.type?.toLowerCase(),
      planType: object?.planType?.toLowerCase(),
      cardNumber: object?.cardNumber,
      customerId: object?.customerId,
      clientKey: object?.clientKey,
      displayId: object?.displayId,
      level: object?.status, // createStatus(object?.status), // 'Active',
      mode: object?.paymentMode || object?.mode,
      paymentMethod: object?.paymentMethod,
      cashValue: object?.cashValue,
      name: object?.name,
      slimTitle: removeAllSpaces(title?.toLowerCase()),
      title, // not available
      canRenew: object?.canRenew,
      claimCount: object?.claimCount,
      lastPaidAmount: object?.lastPaidAmount,
      memberCount: object?.memberCount,
      masterId: object?.masterId,
      coverages: createCoverages(object?.coverages),
      owners: createOwners(object?.owner),
      members: createMembers(object?.members),
      subjectMatters: createSubjectMatters(object?.subjectMatters),
      insuredItems: createInsuredItems(object?.insuredItems, object?.type),
      insuredItemDetails: object?.insuredItemDetails,
      addInsured: object?.addInsured,
      // life
      // statusNumber: currentStatusObject?.statusNum || 0,
      status: object?.status,
      secondaryStatus: object?.secondaryStatus,
      buttonName: object?.buttonName,
      buttonDisabled: object?.buttonDisabled,
      canRenewStatus: !object?.buttonDisabled,
      canRenewStatusNumber: !object?.buttonDisabled ? 1 : 0,
      planId: object?.planId,
      filterStatus: object?.secondaryStatus || object?.status,
      filterLob: lobObj?.lob,
      filterDate,
      filterDateType,
      issueDate: dateToString(isoStringToDate(object?.issueDate)),
      payToDate: dateToString(isoStringToDate(object?.payToDate)),
      ceaseDate: dateToString(isoStringToDate(object?.ceaseDate)),
      renewalDate: dateToString(isoStringToDate(object?.renewalDate)),
      inceptionDate,
      originalInceptionDate: dateToString(isoStringToDate(object?.originalInceptionDate)),
      expiryDate,
      dueDate: dateToString(isoStringToDate(object?.dueDate)),
      periodOfCover: inceptionDate && expiryDate && `${inceptionDate} to ${expiryDate}`,
      fromDate: dateToString(isoStringToDate(object?.fromDate)),
      producer: object?.producer || '',
      agent: object?.agent,
      agentType: object?.agentType,
      allowRenewals: object?.allowRenewals,
      agentNumber: object?.agentNumber,
      annualPremium: object?.annualPremium,
      amount: parseFloat(object?.amount),
      premiumTax: object?.premiumTax,
      premium: object?.premiumAmount,
      premiumCharge: object?.premiumChg,
      basePremium: object?.basePremium,
      loanAmount: object?.loanAmount,
      modalPremium:
        parseFloat(object?.modalPremium) ||
        (object?.type === healthLob &&
          object?.coverages &&
          object?.coverages?.length === 1 &&
          object.coverages[0].premiumAmount),
      renewalPremium: parseFloat(object?.renewalPremium),
      premiumsDue: object?.premiumsDue,
      paidAmount: object?.paidAmount,
      suspenseAmount: object?.suspenseAmount,
      isFirstPremium: object?.isFirstPremium,
      currency: object?.currency,
      filterCurrency: object?.currency,
      insured: createInsured(object?.insured),
      insuredName: object?.insuredName,
      insuredNameNoSpace: object?.insuredName?.replace(/ /g, ''),
      policyDocuments: object?.policyDocuments,
      policyDocs: object?.policyDocs,
      owner,
      ownerFirstName: owner && owner.length > 0 && owner[0]?.firstName,
      ownerLastName: owner && owner.length > 0 && owner[0]?.lastName,
      ownerFullName:
        owner && owner.length > 0 && `${owner[0]?.firstName} ${owner[0]?.lastName}`,
      ownerPrimaryEmail: owner && owner.length > 0 && owner[0]?.primaryEmail,
      ownerPrimaryPhone: owner && owner.length > 0 && owner[0]?.primaryPhone,
      ownerFullNameNoSpace:
        owner &&
        owner.length > 0 &&
        `${owner[0]?.firstName} ${owner[0]?.lastName}`?.replace(/ /g, ''),
      payor: createPayors(object?.payor),
      discounts: createDiscounts(object?.discounts),
      drivers: createDrivers(object?.drivers),
      clientPolicyId:
        object?.owner && object?.owner[0] ? object?.owner[0]?.id?.split('-')[1] : null,
      digitalSales: object?.digitalSales,
      hasMatured: object?.hasMatured,
      maturedDetails: formatMaturedDetails(object?.maturedDetails),
      // ...searchWords,
    };

    // const searchWords = generateSearchWords(object);
    // const newKeys = getKeys(searchWords);
    // searchKeys = [...searchKeys, ...newKeys];
    // searchKeys = uniq(searchKeys);
    // // console.log('searchWords: ', searchWords);

    // const reduceObj = reduceKeys(obj);

    // // console.log('searchKeys: ', searchKeys);
    /// / console.log(policy);

    return policy;
  });

  /// / console.log(newPolicies);

  return newPolicies;
};

const createPayload = (payloadArray, lobTypes) => {
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const newPolicies = createPolicies(payloadArray, lobTypes);
  // const collections = [];
  newPolicies.map((policy, index) => {
    const newKeys = getKeys(policy);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    return policy; // updateCollection(collections, policy);
  });

  newPolicies.sort((a, b) => {
    if (a.canRenewStatusNumber === b.canRenewStatusNumber) {
      // Price is only important when cities are the same
      return b.statusNumber - a.statusNumber ? 1 : -1;
    }
    return b.canRenewStatusNumber > a.canRenewStatusNumber ? 1 : -1;
  });

  return newPolicies;
};

export const getSampleResponse = (lob, action) => {
  console.log('lob: ', lob);
  // // console.log('params: ', params);
  if (lob === investmentLob) {
    return action?.payload;
  }
  const initialPayloadData = JSON.parse(JSON.stringify(payloadData));
  console.log('initialPayloadData: ', initialPayloadData);
  if (lob && initialPayloadData.payload.result) {
    const filteredPolicies = initialPayloadData?.payload?.result?.data?.filter(
      x => x?.type === lob,
    );
    console.log('filteredPolicies: ', filteredPolicies);

    const newPayloadData = { ...initialPayloadData };
    newPayloadData.payload.result.data = filteredPolicies;
    newPayloadData.payload.result.total = filteredPolicies?.length;
    console.log('newPayloadData: ', newPayloadData);
    return newPayloadData;
  }
  return action?.payload;
};

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSURANCES_API_REQUEST:
    case GET_INSURANCE_INVESTMENTS_REQUEST: {
      const body = action?.body;
      // console.log('Response body is ', body);
      const isPending = state.isPending ? state.isPending : {};
      const isSuccess = state.isSuccess ? state.isSuccess : {};
      const isError = state.isError ? state.isError : {};

      if (body) {
        const storeKey = `${body.staticLob}_${body.alpha2}`;
        return {
          ...state,
          isPending: {
            ...isPending,
            [body.staticLob]: true,
            [storeKey]: true,
          },
          isSuccess: {
            ...isSuccess,
            [body.staticLob]: false,
            [storeKey]: false,
          },
          isError: {
            ...isError,
            [body.staticLob]: false,
            [storeKey]: false,
          },
        };
      }
      break;
    }

    case GET_INSURANCES_API_SUCCESS:
    case GET_INSURANCE_INVESTMENTS_SUCCESS: {
      // const startIndex = state?.insurances?.length;
      const lobTypes = action?.lobTypes;
      const body = action?.body;

      const response = action?.payload; // getSampleResponse(body?.staticLob, action); //

      if (response === 'forceReload') {
        return {
          ...state,
          insurances: [...state.insurances],
          nextItemCount: body?.nextItemCount,
          nextScrollCount: body?.nextScrollCount,
          nextStartIndex: body?.nextStartIndex,
        };
      }

      const cacheKey = action?.cacheKey;
      // console.log('response 2: ', response);
      let insurancePayload = [];
      let newTotal = 0;

      if (body?.staticLob === investmentLob) {
        // console.log('investment response: ', response);
        if (response && response?.data?.length > 0) {
          insurancePayload = [...response.data];
          newTotal = insurancePayload?.length;
        }
      } else {
        console.log('response: ', response);
        insurancePayload = createPayload(response?.payload?.result?.data, lobTypes);

        newTotal = response?.payload?.result?.total || 0;
      }

      const insurances = insurancePayload;

      // console.log('success body: ', body);

      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;
      const nowTime = new Date().getTime();

      const incomingInsurances =
        insurances && Array.isArray(insurances) ? insurances : [];
      const oldInsurances =
        state?.insurances && Array.isArray(state?.insurances) ? state?.insurances : [];

      const existingInsurances = [...oldInsurances];
      // console.log('oldInsurances: ', oldInsurances);
      incomingInsurances.map(incomingInsurance => {
        // console.log('incomingInsurance: ', incomingInsurance);
        incomingInsurance.body = body;
        let index = 0;
        if (incomingInsurance.body.infiniteScroll) {
          index = existingInsurances.findIndex(
            oldInsurance =>
              oldInsurance.id === incomingInsurance.id &&
              oldInsurance.body.infiniteScroll ===
                incomingInsurance.body.infiniteScroll &&
              oldInsurance.body.alpha2 === incomingInsurance.body.alpha2 &&
              oldInsurance.body.isMobile === incomingInsurance.body.isMobile,
          );
        } else {
          index = existingInsurances.findIndex(
            oldInsurance =>
              oldInsurance.id === incomingInsurance.id &&
              oldInsurance.body.infiniteScroll ===
                incomingInsurance.body.infiniteScroll &&
              oldInsurance.body.offset === incomingInsurance.body.offset &&
              oldInsurance.body.limit === incomingInsurance.body.limit &&
              oldInsurance.body.alpha2 === incomingInsurance.body.alpha2 &&
              oldInsurance.body.isMobile === incomingInsurance.body.isMobile,
          );
        }
        // Replace item at index using native splice
        if (index !== -1) {
          existingInsurances.splice(index, 1, incomingInsurance);
        } else {
          existingInsurances.push(incomingInsurance);
        }
        return null;
      });
      // console.log('existingInsurances: ', existingInsurances);

      const isPending = state.isPending ? state.isPending : {};
      const isSuccess = state.isSuccess ? state.isSuccess : {};
      const isError = state.isError ? state.isError : {};
      const tempTotal = state.total ? state.total : {};
      // console.log('total: ', tempTotal);
      // console.log('newTotal: ', newTotal);
      // console.log('body: ', body);
      // console.log('response: ', response);
      const storeKey = `${body.staticLob}_${body.alpha2}`;

      const newTotalState = {
        ...tempTotal,
        [body.staticLob]: newTotal,
        [storeKey]: newTotal,
      };

      const newError = state.error ? { ...state.error } : {};
      if (body?.staticLob === investmentLob && response?.error) {
        newError[body.staticLob] = response.error;
        newError[storeKey] = response.error;
        // console.log('response: ', response);
      }

      return {
        ...state,
        isPending: {
          ...isPending,
          [body.staticLob]: false,
          [storeKey]: false,
        },
        isError: {
          ...isError,
          [body.staticLob]: body?.staticLob === investmentLob ? response?.isError : false,
          [storeKey]: body?.staticLob === investmentLob ? response?.isError : false,
        },
        isSuccess: {
          ...isSuccess,
          [body.staticLob]:
            body?.staticLob === investmentLob ? response?.isSuccess : true,
          [storeKey]: body?.staticLob === investmentLob ? response?.isSuccess : true,
        },
        error: {
          ...newError,
        },
        total: newTotalState,
        response, // newPortfolio,
        searchKeys,
        insurances: existingInsurances,
        // insuranceCollections,
        [persistKey]: nowTime,
        firstTime: false,
        nextItemCount: body?.nextItemCount,
        nextScrollCount: body?.nextScrollCount,
        nextStartIndex: body?.nextStartIndex,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }
    case GET_INSURANCES_API_ERROR:
    case GET_INSURANCE_INVESTMENTS_ERROR: {
      const body = action?.body;

      const isPending = state.isPending ? state.isPending : {};
      const isSuccess = state.isSuccess ? state.isSuccess : {};
      const isError = state.isError ? state.isError : {};
      const error = state.error ? state.error : {};

      const storeKey = `${body.staticLob}_${body.alpha2}`;

      return {
        ...state,
        isPending: {
          ...isPending,
          [body.staticLob]: false,
          [storeKey]: false,
        },
        isError: {
          ...isError,
          [body.staticLob]: true,
          [storeKey]: true,
        },
        isSuccess: {
          ...isSuccess,
          [body.staticLob]: false,
          [storeKey]: false,
        },
        error: {
          ...error,
          [body.staticLob]: action?.payload,
          [storeKey]: action?.payload,
        },
        firstTime: false,
      };
    }
    case RENEW_POLICY_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        insurances: initialState.insurances,
      };
    // case RENEW_POLICY_HOME_API_SUCCESS:
    //   return {
    //     ...state,
    //     [persistKey]: null,
    //   };
    case ADD_RENEWAL_TO_CART_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
      };
    case AMEND_POLICY_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        insurances: initialState.insurances,
      };
    case PAY_NOW_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        insurances: initialState.insurances,
      };

    case PAY_FIRST_PREMIUM_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        insurances: initialState.insurances,
      };

    case MAKE_FAC_PAYMENT_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        insurances: initialState.insurances,
      };

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        insurances: initialState.insurances,
      };
    }
    case CLEAR_PORTFOLIO_CACHE:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        insurances: initialState.insurances,
      };

    case GET_NEXT_INSURANCES_SCROLL_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;
      const scrollKey = action?.payload?.scrollKey;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        scrollKey,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
        scrollKey: null,
      };
    }
    case SIGNIN_API_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
        insurances: [],
        firstTime: true,
      };
    }
    case GET_MATURED_PENSION_API_REQUEST:
      return {
        ...state,
        isMaturedPending: true,
        isMaturedError: false,
        isMaturedSuccess: false,
      };
    case GET_MATURED_PENSION_API_SUCCESS: {
      const response = action?.payload;
      const maturedPensionPayload = response.payload;
      const maturedPension = createMaturedPension(maturedPensionPayload);

      return {
        ...state,
        isMaturedPending: false,
        isMaturedError: false,
        isMaturedSuccess: true,
        response,
        maturedPension,
      };
    }
    case GET_MATURED_PENSION_API_ERROR:
      return {
        ...state,
        isMaturedPending: false,
        isMaturedError: true,
        isMaturedSuccess: false,
        error: action?.payload,
      };
    case RESET_INSURANCE: {
      const lob = action.payload;
      let newInsurances = [];
      let newPersistKey = null;
      if (lob) {
        newInsurances = state?.insurances?.filter(
          oldInsurance => oldInsurance.filterLob !== lob,
        );
        newPersistKey = state[persistKey];
      }
      return {
        ...state,
        [persistKey]: newPersistKey,
        [DEFAULT_KEY]: null,
        insurances: newInsurances,
      };
    }
    case GET_INSURANCE_FILTER_SETTINGS_API_REQUEST:
      return {
        ...state,
        isGetInsuranceFilterSettingsPending: true,
        isGetInsuranceFilterSettingsError: false,
        isGetInsuranceFilterSettingsSuccess: false,
      };
    case GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS: {
      const settings = action?.payload?.payload || null;

      const response = action?.payload;
      return {
        ...state,
        // searchKeys,
        isGetInsuranceFilterSettingsPending: false,
        isGetInsuranceFilterSettingsError: false,
        isGetInsuranceFilterSettingsSuccess: true,
        insuranceFilterSettingsResponse: response,
        insuranceFilterSettings: settings,
      };
    }
    case GET_INSURANCE_FILTER_SETTINGS_API_ERROR:
      return {
        ...state,
        isGetInsuranceFilterSettingsPending: false,
        isGetInsuranceFilterSettingsError: true,
        isGetInsuranceFilterSettingsSuccess: false,
        insuranceFilterSettingsError: action.payload,
      };

    case GET_SINGLE_INSURANCE_API_REQUEST:
      return {
        ...state,
        isSingleInsurancePolicyPending: true,
        isSingleInsurancePolicyError: false,
        isSingleInsurancePolicySuccess: false,
      };
    case GET_SINGLE_INSURANCE_API_SUCCESS: {
      const tempArr = [];
      const lobTypes = action?.lobTypes;
      const insurancePolicy = action?.payload?.payload;
      // console.log('insurancePolicy: ', insurancePolicy);
      // console.log('insurancePolicy: ', insurancePolicy);
      tempArr.push(insurancePolicy);
      const newPolicies = createPolicies(tempArr, lobTypes);
      // console.log('newPolicies: ', newPolicies);
      // // console.log(renewalPolicy);
      // // console.log(tempArr);

      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isSingleInsurancePolicyPending: false,
        isSingleInsurancePolicyError: false,
        isSingleInsurancePolicySuccess: true,
        singleInsurancePolicyResponse: response,
        insurancePolicy: newPolicies[0],
      };
    }
    case GET_SINGLE_INSURANCE_API_ERROR:
      return {
        ...state,
        isSingleInsurancePolicyPending: false,
        isSingleInsurancePolicyError: true,
        isSingleInsurancePolicySuccess: false,
        singleInsurancePolicyError: action?.payload,
      };

    default:
      return state;
  }
};
export default insuranceReducer;

/*


const modifiedPayload = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(
      lobObject => object?._id?.toLowerCase().indexOf(lobObject.lob) > 0,
    );
    const position = index;
    return {
      index: position,
      id: object._id ? `${object?._id}${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      name: object?.name,
      lob: lobObj?.lob,
      policies: createPolicies(object?.policies),
    };
  });

  return collections;
};

*/
