import { gql } from 'graphql-request';

const TEXT_LABEL_FIELDS = gql`
  fragment textLabel on ComponentWizardComponentsTextLabel {
    TextWithLabel_slug: slug
    TextWithLabel_text: text
    TextWithLabel_label: label
    TextWithLabel_tooltip: tooltip
    TextWithLabel_units: units
    TextWithLabel_unitsFontSize: unitsFontSize
    TextWithLabel_textSize: textSize
    TextWithLabel_labelSize: labelSize
    TextWithLabel_textColor: textColor
    TextWithLabel_labelColor: labelColor
    TextWithLabel_textAlign: textAlign
    TextWithLabel_labelAlign: labelAlign
    TextWithLabel_align: align
    TextWithLabel_isNumeric: isNumeric
    TextWithLabel_isAbbrev: isAbbrev
    TextWithLabel_isCurrency: isCurrency
    TextWithLabel_isPercent: isPercent
    TextWithLabel_isLocalize: isLocalize
    TextWithLabel_name: name
    TextWithLabel_layoutPosition: layoutPosition
    TextWithLabel_disableInitialValue: disableInitialValue
    TextWithLabel_staticValue: staticValue
    TextWithLabel_reducerKeyValue: reducerKeyValue
    TextWithLabel_positionNo: positionNo
    TextWithLabel_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    TextWithLabel_isConditionalComponent: isConditionalComponent
    TextWithLabel_conditionalValue: conditionalValue
    TextWithLabel_conditionalKey: conditionalKey
    TextWithLabel_conditionalOperator: conditionalOperator
    TextWithLabel_conditionalFieldType: conditionalFieldType
    TextWithLabel_conditionalAction: conditionalAction
    TextWithLabel_disable: disable
    TextWithLabel_show: show
    TextWithLabel_text_en: text_en
    TextWithLabel_text_nl: text_nl
    TextWithLabel_label_en: label_en
    TextWithLabel_label_nl: label_nl
    TextWithLabel_tooltip_en: tooltip_en
    TextWithLabel_tooltip_nl: tooltip_nl
    TextWithLabel_calculateExpressions: calculateExpressions {
      key
      operator
      name
      operateOn
    }
    TextWithLabel_alignSelfCenter: alignSelfCenter
    TextWithLabel_conditions: conditions {
      ...condition
    }
    TextWithLabel_footNote: footNote
    TextWithLabel_footNoteSize: footNoteSize
  }
`;

// export default TEXT_LABEL_FIELDS;
// exports.default = TEXT_LABEL_FIELDS;
export default TEXT_LABEL_FIELDS;
