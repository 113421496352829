import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import PropTypes, { object } from 'prop-types';
import { cardStatus } from '../../../../containersV2/helpCenter/checkStatus';
import { useSmall } from '../../../../hooks';
import { IssueListStyles } from '../style';
import { getAllHelpCenterCasesAction } from '../../../../actions/helpCenter/cases/getAllHelpCenterCases';
import { getSingleHelpCenterCaseAction } from '../../../../actions/helpCenter/cases/getSingleHelpCenterCase';
import { Loading } from '../../../common';
import { toastrOptions } from '../../../../utils/functions';

function IssueList({ CaseId, CaseYear }) {
  const dispatch = useDispatch();
  let allHelpCenterCases = useSelector(
    state => state.helpCenterCases.allHelpCenterCases,
  );
  const singleHelpCenterCase = useSelector(
    state => state.helpCenterCases.singleHelpCenterCase,
  );
  const error = useSelector(state => state.helpCenterCases.isError);
  const user = CaseId == '' ? useSelector(state => state.auth.user) : null;
  const userIngeniumId = user ? user.customerDetails.customer.sourceIds.ingenium : '';
  const isSmall = useSmall();
  const tableHeaderStyle = {
    color: 'text.label',
    fontWeight: 'bold',
    padding: '25px 15px',
  };
  const tableRowStyle = { color: 'text.label', borderBottom: 'none' };
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [page, setPage] = useState(1);
  const styles = IssueListStyles;
  const [isLoading, setIsLoading] = useState(true);

  const navToDetails = reference => {
    history.push(`/help/status/details/${reference}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  }; 

  function sortCasesByDate(a,b){  
    var dateA = new Date(a.createdOnSystemFormat).getTime();
    var dateB = new Date(b.createdOnSystemFormat).getTime();
    return dateA < dateB ? 1 : -1;  
}; 

  useEffect(() => {
    // console.log("Cases", allHelpCenterCases); 
    allHelpCenterCases.sort(sortCasesByDate);
  }, [allHelpCenterCases])

  useEffect(() => {
    if (CaseId) {
      const prams = { caseNumber: CaseId };
      try {
        dispatch(getSingleHelpCenterCaseAction(prams)).then(() => {
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        const title = err.message;
        const message = 'Please try after some time';
        const status = 'error';
        toastr[status](
          title,
          message,
          toastrOptions({
            status,
          }),
        );
      }
    } else if (userIngeniumId) {
      const prams = { clientId: userIngeniumId };
      try {
        dispatch(getAllHelpCenterCasesAction(prams)).then(() => {
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        const title = err.message;
        const message = 'Please try after some time';
        const status = 'error';
        toastr[status](
          title,
          message,
          toastrOptions({
            status,
          }),
        );
      }
    }
  }, []);

  const getYear = year => {
    return new Date(year).getFullYear();
  };

  const modifyDate = givenDate => {
    const date = new Date(givenDate);
    const newDateTime = date.toLocaleString();
    return newDateTime;
  };

  if (error) {
    return <Typography sx={{ color: 'text.label' }}>Sorry, No Cases</Typography>;
  }

  return (
    <>
      {!isLoading ? (
        <>
          {isSmall ? (
            <>
              {(CaseId == '' ? allHelpCenterCases : singleHelpCenterCase).map(row => (
                <Card
                  key={row.ticketNumber}
                  sx={{
                    padding: '15px 20px 40px 15px',
                    margin: '20px',
                    maxWidth: '370px',
                  }}
                >
                  <table>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '14px', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Reference#:
                        </Typography>
                      </td>
                      <td align="left">
                        <Typography
                          sx={{
                            fontSize: '14px',
                            marginLeft: '20px',
                            textDecoration: 'underline',
                            color: 'text.label',
                          }}
                          variant="subtitle1"
                          onClick={() => navToDetails(row.ticketNumber)}
                        >
                          {row.ticketNumber}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '14px', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Created Date:
                        </Typography>
                      </td>
                      <td align="left">
                        <Typography
                          sx={{
                            fontSize: '14px',
                            marginLeft: '20px',
                            color: 'text.label',
                          }}
                          variant="subtitle1"
                        >
                          {modifyDate(row.createdOnSystemFormat)}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '14px', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Subject:
                        </Typography>
                      </td>
                      <td align="left">
                        <Typography
                          sx={{
                            fontSize: '14px',
                            marginLeft: '20px',
                            color: 'text.label',
                          }}
                          variant="subtitle1"
                        >
                          {row.issueSubject ? row.issueSubject : 'Unavailable'}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '14px', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Status:
                        </Typography>
                      </td>
                      <td align="left">
                        <Stack
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: '20px',
                          }}
                        >
                          <Box
                            sx={{
                              height: '8px',
                              width: '8px',
                              marginRight: '8px',
                              borderRadius: '50%',
                              backgroundColor: cardStatus.IP.includes(row.issueStatus)
                                ? '#00bb55'
                                : cardStatus.Resolved.includes(row.issueStatus)
                                ? '#4F5467'
                                : cardStatus.IN.includes(row.issueStatus)
                                ? '#dd3311'
                                : cardStatus.Cancelled.includes(row.issueStatus)
                                ? 'red'
                                : '#006cbb',
                            }}
                          />
                          <Typography
                            sx={{ fontSize: '14px', color: 'text.label' }}
                            variant="subtitle1"
                          >
                            {row.issueStatus}
                          </Typography>
                        </Stack>
                      </td>
                    </tr>
                  </table>
                </Card>
              ))}
            </>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeaderStyle}>Subject</TableCell>
                      <TableCell sx={tableHeaderStyle}>Created Date</TableCell>
                      <TableCell sx={tableHeaderStyle}>Reference#</TableCell>
                      <TableCell sx={tableHeaderStyle}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(CaseId == ''
                      ? allHelpCenterCases.filter(
                          issue => getYear(issue.createdOnSystemFormat) == CaseYear,
                        )
                      : singleHelpCenterCase
                    )
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map(row => (
                        <TableRow key={row.ticketNumber}>
                          <TableCell sx={tableRowStyle}>
                            {row.issueSubject ? row.issueSubject : 'Unavailable'}
                          </TableCell>
                          <TableCell sx={tableRowStyle}>
                            {modifyDate(row.createdOnSystemFormat)}
                          </TableCell>
                          <TableCell
                            onClick={() => navToDetails(row.ticketNumber)}
                            sx={{
                              borderBottom: 'none',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {row.ticketNumber}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...tableRowStyle,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                height: '14px',
                                width: '14px',
                                marginRight: '8px',
                                borderRadius: '50%',
                                backgroundColor: cardStatus.IP.includes(row.issueStatus)
                                  ? '#00bb55'
                                  : cardStatus.Resolved.includes(row.issueStatus)
                                  ? '#4F5467'
                                  : cardStatus.IN.includes(row.issueStatus)
                                  ? '#dd3311'
                                  : cardStatus.Cancelled.includes(row.issueStatus)
                                  ? 'red'
                                  : '#006cbb',
                              }}
                            />
                            <span>{row.issueStatus}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {CaseId == '' ? (
                <>
                  <Stack alignItems="flex-end" sx={{ marginTop: '20px' }}>
                    <Stack direction="row" alignItems="center">
                      <Stack>
                        <Pagination
                          sx={styles.pagination}
                          page={page}
                          onChange={handlePageChange}
                          count={Math.ceil(
                            allHelpCenterCases.filter(
                              issue => getYear(issue.createdOnSystemFormat) == CaseYear,
                            ).length / rowsPerPage,
                          )}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ marginBottom: '2px' }}
                      >
                        <Typography sx={{ marginRight: '20px' }}>Show</Typography>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: 'white',
                            color: 'text.label',
                            '&:hover': {
                              backgroundColor: '#FFF',
                              color: 'text.label',
                            },
                          }}
                        >
                          {rowsPerPage} rows
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginLeft: '10px' }}
                          >
                            <ArrowDropUpIcon
                              sx={{ marginBottom: '-7px' }}
                              onClick={() => setRowsPerPage(rowsPerPage + 1)}
                            />
                            <ArrowDropDownIcon
                              sx={{ marginTop: '-7px' }}
                              onClick={() =>
                                setRowsPerPage(rowsPerPage > 1 ? rowsPerPage - 1 : 1)
                              }
                            />
                          </Stack>
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Loading />;
        </>
      )}
    </>
  );
}

IssueList.propTypes = {
  tableData: PropTypes.arrayOf(object),
};

export default IssueList;
