import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, AlertTitle, Alert } from '@mui/material';
import styles from './style';
import { checkOnlineStatus, sourceTextFromData } from '../../../utils/functions';
import Spinner from '../spinner';
// import { BackButton } from '../index';

const useStyles = makeStyles(styles);

const ErrorAlert = ({
  isError,
  infoMessage,
  errorMessage,
  successMessage,
  isSuccess,
  isPending,
  pendingMessage,
  title,
  infoTitle,
  errorTitle,
  successTitle,
  pendingTitle,
  gridSize = 8,
  variant,
  headers,
  statusCode,
}) => {
  const classes = useStyles();
  const storeState = useSelector(state => state);
  const [delayOn, setDelayOn] = useState(true);
  // const initialOnline = navigator.onLine; // await checkOnlineStatus(); //
  // // console.log('networkStatus: ', networkStatus);
  // const [online, setOnline] = useState(initialOnline);
  /*
  if (!online) {
    description = 'You are offline. Please reconnect, and try again.';
    alert = 'warning';
  } else
  */

  let alert = 'info';
  let description = infoMessage;
  let newTitle = infoTitle || title;
  if (isError) {
    description = errorMessage;
    alert = 'error';
    newTitle = errorTitle;
  } else if (isSuccess) {
    alert = successMessage ? 'success' : 'info';
    description = successMessage || infoMessage;
    newTitle = successTitle || newTitle;
  } else if (isPending) {
    alert = 'warning';
    description = pendingMessage;
    newTitle = pendingTitle;
  }

  useEffect(() => {
    /*
    const getOnlineStatus = async () => {
      const newOnline = await checkOnlineStatus();
      // console.log('newOnline: ', newOnline);
      setOnline(newOnline);
    };
    getOnlineStatus();
    */
    setTimeout(() => {
      setDelayOn(false);
    }, 1000);
  }, []);

  if (delayOn) {
    return (
      <div>
        <Spinner loading />
      </div>
    );
  }
  if (!isPending && !isError && !isSuccess) {
    return null;
  }
  const newVariant = variant || 'standard';
  const requestId = headers?.['x-request-id'];

  return (
    <div className={classes.root}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={gridSize}>
          <Alert
            severity={alert}
            color={alert}
            variant={newVariant}
            className={classes.alert}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              {newTitle && (
                <Grid item xs={12}>
                  <AlertTitle>{newTitle}</AlertTitle>
                </Grid>
              )}

              {description && (
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    align="left"
                    className={classes.secondaryButton}
                  >
                    {sourceTextFromData(storeState, description)}
                  </Typography>
                </Grid>
              )}
              {requestId && (alert === 'error' || alert === 'warning') && (
                <Grid item xs={12} align="left">
                  <Typography
                    variant="caption"
                    align="left"
                    className={classes.requestId}
                    sx={{ fontSize: '10px' }}
                  >
                    Request: {requestId || statusCode}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Alert>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorAlert;
