import React from 'react';
import { Link as RouterLink, Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Grid,
  // ButtonBase,
  Button,
  // Link,
  Card,
  Skeleton,
  CardActions,
  CardContent,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import routes from '../../../utils/variables/routes';
import { investmentLob } from '../../../utils/variables';
import { addWizard } from '../../../actions/wizards';
import { useRouter } from '../../../hooks';
import Header from './header';
import styles from './style';
// import { formatImageUrlFromStrapi } from '../../../utils/functions';
import { ImageBox } from '../../common';

const ProductCard = ({ product, loading, hideImage, buyOnlineHighPriority }) => {
  // console.log('product: ', product);
  const useStyles = makeStyles(theme => styles(theme));
  const classes = useStyles();
  const location = useLocation();
  const router = useRouter();
  const dispatch = useDispatch();
  const theme = useTheme();
  // loading = true;
  // const productImage =
  // /product?.featuredImage_pwa || product?.parentProduct?.featuredImage_pwa;

  const productImage =
    product?.featuredImage_pwa ||
    (product?.parentProducts && product?.parentProducts[0]?.featuredImage_pwa);
  // console.log('productImage: ', productImage);
  const lobCategories = product?.lobCategories;
  let isInvest = false;

  const icons = lobCategories?.map(x => {
    if (x?.lob === investmentLob) isInvest = true;
    return x?.materialIcon;
  });

  const isAllButton = product?.quoteWizard && product?.buyOnlineWizard;

  // React.useEffect(() => {
  //   console.log('Product passed is', product);
  // }, []);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container direction="column" spacing={1}>
          {!hideImage && (
            <Grid item xs={12} direction="column" className={classes.zGrid}>
              {loading ? (
                <Skeleton variant="rect" height={190} />
              ) : (
                <ImageBox
                  {...productImage}
                  isImageStyleGlobal
                  imageStyle={{ height: '190px' }}
                  fixedSettings={{
                    screenSize: { sm: 'sm', md: 'sm', lg: 'sm' },
                    fit: { sm: 'width', md: 'width', lg: 'width' },
                  }}
                  imageBorderRadius={theme.shape.borderRadius}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} className={classes.zGrid}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              direction="column"
              className={classes.gridPad}
            >
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    height={40}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <Typography
                    gutterBottom
                    textAlign="left"
                    variant="body1"
                    color="text.main"
                    component="div"
                    fontWeight="600"
                  >
                    {product?.title}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                  </>
                ) : (
                  <Typography
                    variant="subTitle2"
                    align="left"
                    gutterBottom
                    color="text.dark"
                    className={classes.description}
                  >
                    {
                      product?.description /* product?.hideDescriptionOnPWA && product?.isProductType ? null : */
                    }
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Grid container justifyContent="flex-end" spacing={1}>
          {product?.quoteWizard && (!product?.buyOnlineWizard || !buyOnlineHighPriority) && (
            <Grid item xs={6} align="center" className={classes.zGrid}>
              {loading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  height={50}
                  width="80%"
                  className={classes.button}
                />
              ) : (
                <Button
                  justifyContent="center"
                  // size="small"
                  className={classes.secondaryButton}
                  // startIcon={<AssignmentIcon />}
                  onClick={() => {
                    dispatch(addWizard.addLastEntryPointAction(router?.asPath));
                  }}
                  component={RouterLink}
                  to={`${routes.wizards.index}/${product?.quoteWizard?.slug}`}
                >
                  {isInvest ? 'Get Advice' : 'Get Quote'}
                </Button>
              )}
            </Grid>
          )}
          {product?.buyOnlineWizard && buyOnlineHighPriority && (
            <Grid item xs={6} align="center" className={classes.zGrid}>
              {loading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  height={50}
                  width="80%"
                  className={classes.button}
                />
              ) : (
                <Button
                  justifyContent="center"
                  disabled={loading}
                  className={classes.secondaryButton}
                  component={RouterLink}
                  onClick={() => {
                    dispatch(addWizard.addLastEntryPointAction(router?.asPath));
                  }}
                  to={`${routes.wizards.index}/${product?.buyOnlineWizard?.slug}`}
                  // startIcon={<AddShoppingCartIcon />}
                >
                  Purchase Now
                </Button>
              )}
            </Grid>
          )}
          <Grid item xs={6} align="center" className={classes.zGrid}>
            {loading ? (
              <Skeleton
                variant="text"
                animation="wave"
                height={50}
                width="80%"
                className={classes.button}
              />
            ) : (
              <Button
                color="primary"
                justifyContent="center"
                // size="small"
                className={classes.primaryButton}
                // startIcon={<InfoIcon />}
                component={RouterLink}
                to={`${routes.products.index}/${product?.slug}`}
              >
                Learn More
              </Button>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ProductCard;

/*
<ButtonBase className={classes.image}>
 <img className={classes.img} alt="complex" src="/images/cutegirl.jpg" /> 
 </ButtonBase>

 <Grid item xs={6} className={classes.zGrid}>
                  {loading ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      height={50}
                      width="80%"
                      className={classes.learnMore}
                    />
                  ) : (
                    <Button
                      color="primary"
                      justifyContent="center"
                      // size="small"

                      className={classes.button}
                      // startIcon={<InfoIcon />}
                      // component={RouterLink}
                      onClick={onLearnMoreClicked}
                      // to={`${routes.products.ProductCard}/${product?.slug}`}
                    >
                      Learn More
                    </Button>
                  )}
                </Grid>
                {product?.quoteWizard && (
                  <Grid item xs={6} className={classes.zGrid}>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        height={50}
                        width="80%"
                        className={classes.learnMore}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        justifyContent="center"
                        // size="small"
                        className={classes.button}
                        //   startIcon={<AssignmentIcon />}
                        //   component={RouterLink}
                        //   to={`${routes.wizards.ProductCard}/${product?.quoteWizard?.slug}`}
                      >
                        Get Quote
                      </Button>
                    )}
                     </Grid>
                )}
*/

/*
<Grid item xs={12} direction="column" className={classes.zGrid}>
            {loading ? (
              <Skeleton variant="rect" className={classes.image} />
            ) : (
              <ImageBox
                {...productImage}
                boxStyle={{ height: '140px' }}
                fixedSettings={{
                  screenSize: { sm: 'sm', md: 'sm', lg: 'sm' },
                  fit: { sm: 'height', md: 'height', lg: 'height' },
                }}
                imageBorderRadius={theme.shape.borderRadius}
              />
            )}
          </Grid>
          */
