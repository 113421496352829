import { useSelector, useDispatch } from 'react-redux';
import { getQuotes } from '../actions/quotes';
import { dateToString, toTitleCase } from '../utils/functions';
import { listSelector } from '../selectors';
import { quotesIndexName, agentType, customerType } from '../utils/variables';
// import { loadQuotesCount } from '../utils/variables';

const useQuoteData = ({ infiniteScroll = false } = {}) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const selectedClient = useSelector(state => state.auth.selectedClient);
  const usertype = useSelector(state => state.auth.usertype);
  const customerId =
    auth.usertype === agentType ? selectedClient?.clientNumber : auth?.user?.customer?.id;
  const agentId = auth?.user?.agent?.id || '';
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: quotesIndexName, infiniteScroll }),
  );
  const allRows = useSelector(state => state.quotes.allRows);

  return ({ nextStartIndex, nextItemCount, nextScrollCount, actionIndex }) => {
    const body = {
      customerId,
      agentId: agentId || undefined,
      usertype: usertype || customerType,
      limit: defaultOptions?.itemCount,
      offset: defaultOptions?.startIndex,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: toTitleCase(defaultOptions?.lob?.toString()), // defaultOptions?.lob,
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
    };

    // cellMeasurerCache.clearAll();
    // listRef.current.recomputeRowHeights();
    // listRef.current.forceUpdate();

    // if (allRows && allRows.length > 0 && startIndex > 0) {
    //   return dispatch(getQuotes.getNextQuotesAction(body));
    // }
    if (allRows && actionIndex > 0 && infiniteScroll) {
      return dispatch(getQuotes.getNextQuotesAction(body));
    }
    if (!allRows || actionIndex === 0) {
      return dispatch(getQuotes.getQuotesAction(body)).catch(err => {
        // console.log('err: ', err);
      });
    }
    return () => {};
  };
};
export default useQuoteData;
