import { useSelector, useDispatch } from 'react-redux';
import { getClaims } from '../actions/claims';
import { dateToString, toTitleCase, dateToIsoString } from '../utils/functions';
import { listSelector } from '../selectors';
import useSmall from './useSmall';
import { claimsIndexName, agentType, customerType } from '../utils/variables';
// import { loadClaimsCount } from '../utils/variables';

const useClaimData = ({ infiniteScroll = false } = {}) => {
  const dispatch = useDispatch();
  const isMobile = useSmall();
  const auth = useSelector(state => state.auth);
  const selectedClient = useSelector(state => state.auth.selectedClient);
  const customerId =
    auth.usertype === agentType ? selectedClient?.clientNumber : auth?.user?.customer?.id;
  const agentId = auth?.user?.agent?.id || '';
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: claimsIndexName, infiniteScroll }),
  );
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);

  return ({
    nextStartIndex,
    nextItemCount,
    nextScrollCount,
    actionIndex,
    infiniteScroll: primaryInfiniteScroll,
    isMobile: primaryIsMobile,
  }) => {
    // console.log('isMobile: ', primaryIsMobile);
    // console.log('primaryInfiniteScroll: ', primaryInfiniteScroll);
    // console.log('actionIndex: ', actionIndex);
    // const limit = count; // stopIndex - startIndex;
    // const offset = startIndex;
    let finalInfiniteScroll = false;
    if (primaryInfiniteScroll !== undefined) {
      finalInfiniteScroll = primaryInfiniteScroll;
    } else {
      finalInfiniteScroll = infiniteScroll;
    }

    let finalIsMobile = isMobile;
    if (primaryIsMobile !== undefined) {
      finalIsMobile = primaryIsMobile;
    } else {
      finalIsMobile = isMobile;
    }

    let newStart;
    if (nextStartIndex) {
      newStart = nextStartIndex;
    } else if (defaultOptions?.startIndex) {
      newStart = defaultOptions?.startIndex;
    } else if (defaultOptions?.startIndex === 0) {
      newStart = defaultOptions?.startIndex;
    }

    let newLimit;
    // console.log('nextItemCount: ', nextItemCount);
    // console.log('defaultOptions.itemCount: ', defaultOptions?.itemCount);
    if (nextItemCount) {
      newLimit = nextItemCount;
    } else if (defaultOptions?.itemCount) {
      newLimit = defaultOptions?.itemCount;
    } else if (defaultOptions?.itemCount === 0) {
      newLimit = defaultOptions?.itemCount;
    }
    const body = {
      id: customerId,
      agentId: agentId || undefined,
      usertype: auth.usertype || customerType,
      limit: defaultOptions?.itemCount,
      offset: defaultOptions?.startIndex,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: defaultOptions?.lob, // toTitleCase(defaultOptions?.lob),
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
      scrollKey: defaultOptions?.scrollKey,
      staticLob: defaultOptions?.staticLob,
      infiniteScroll: finalInfiniteScroll,
      finalInfiniteScroll,
      isMobile: finalIsMobile,
      alpha2,
      search: defaultOptions?.search ? defaultOptions?.search : undefined,
    };
    // console.log('body: ', body);

    const params = {
      // customer: customerId || undefined,
      // agentId: agentId || undefined,
      // usertype: usertype || undefined,
      limit: newLimit,
      start: newStart,
      q: defaultOptions?.search ? defaultOptions?.search : undefined,
      status:
        defaultOptions.status && defaultOptions.status.length > 0
          ? defaultOptions.status
          : undefined,
      startDate: defaultOptions.startDate
        ? dateToIsoString(defaultOptions.startDate)
        : undefined,
      endDate: defaultOptions.endDate
        ? dateToIsoString(defaultOptions.endDate)
        : undefined,
    };
    if (defaultOptions?.lob && defaultOptions?.lob?.length > 0) {
      params.policyType = defaultOptions?.lob;
    }

    console.log('params: ', params);

    // cellMeasurerCache.clearAll();
    // listRef.current.recomputeRowHeights();
    // listRef.current.forceUpdate();

    return dispatch(getClaims.getClaimsAction(params, body));
  };
};
export default useClaimData;
