import { gql } from 'graphql-request';

import FORM_WRAPPER_FIELDS from '../fragments/formWrapper';
import OPTION_FIELDS from '../fragments/option';
import FORM_DYNAMIC_ZONE_FIELDS from '../fragments/formDynamicZone';
import FORM_FIELDS from '../fragments/form';
import NON_RECURSIVE_OPTION_FIELDS from '../fragments/nonRecursiveOption';
import NON_RECURSIVE_FORM_DYNAMIC_ZONE_FIELDS from '../fragments/formDynamicZone/nonRecursive';
import NON_RECURSIVE_FORM_FIELDS from '../fragments/nonRecursiveForm';
import NON_RECURSIVE_FORM_WRAPPER_FIELDS from '../fragments/nonRecursiveFormWrapper';
import FEATURED_IMAGE_PWA_FIELDS from '../fragments/featuredImagePWA';
import CONDITION_FIELDS from '../fragments/condition';
import APPEND_KEY_FIELDS from '../fragments/appendKey';
import PARAM_FIELDS from '../fragments/param';
import WIZARD_ACTION_BUTTON_FIELDS from '../fragments/wizardActionButton';
import FORM_CONDITION_FIELDS from '../fragments/formCondition';
import FORM_API_CALL_FIELDS from '../fragments/formApiCall';

import DATE_INPUT_FIELDS from '../fragments/formDynamicZone/components/dateInput';
import BUTTON_GROUP_CHOICE_FIELDS from '../fragments/formDynamicZone/components/buttonGroupChoice';
import CHECK_CHOICE_FIELDS from '../fragments/formDynamicZone/components/checkChoice';
import ALERT_MESSAGE_FIELDS from '../fragments/formDynamicZone/components/alertMessage';
import HEADER_FIELDS from '../fragments/formDynamicZone/components/header';
import DROPDOWN_CHOICE_FIELDS from '../fragments/formDynamicZone/components/dropdownChoice';
import RADIO_CHOICE_FIELDS from '../fragments/formDynamicZone/components/radioChoice';
import TEXT_LABEL_FIELDS from '../fragments/formDynamicZone/components/textLabel';
import SUBMIT_BUTTON_FIELDS from '../fragments/formDynamicZone/components/submitButton';
import PHONE_INPUT_FIELDS from '../fragments/formDynamicZone/components/phoneInput';
import VALUE_INPUT_FIELDS from '../fragments/formDynamicZone/components/valueInput';
import TOGGLE_BUTTON_CHOICE_FIELDS from '../fragments/formDynamicZone/components/toggleButtonChoice';
import DOB_INPUT_FIELDS from '../fragments/formDynamicZone/components/dobInput';
import SINGLE_SEARCH_SELECT_FIELDS from '../fragments/formDynamicZone/components/singleSearchSelect';
import EXPANSION_PANEL_CHOICE_FIELDS from '../fragments/formDynamicZone/components/expansionPanelChoice';
import DISPLAY_LIST_ITEMS_FIELDS from '../fragments/formDynamicZone/components/displayListItems';
import RICH_TEXT_DISPLAY_FIELDS from '../fragments/formDynamicZone/components/richTextDisplay';
import EMPTY_BOX_FIELDS from '../fragments/formDynamicZone/components/emptyBox';
import DOWNLOAD_BUTTON_FIELDS from '../fragments/formDynamicZone/components/downloadButton';
import UPLOAD_BUTTON_FIELDS from '../fragments/formDynamicZone/components/uploadButton';
import REGION_COUNTRY_CHOICE_FIELDS from '../fragments/formDynamicZone/components/regionCountryChoice';
import UPLOAD_MODAL_BUTTON_FIELDS from '../fragments/formDynamicZone/components/uploadModalButton';
import CUSTOM_OBJECT_FIELDS from '../fragments/formDynamicZone/components/customObject';
import CARD_ITEMS_LIST_FIELDS from '../fragments/formDynamicZone/components/cardItemsList';
import NEEDS_SUMMARY_CARD_FIELDS from '../fragments/formDynamicZone/components/needsSummaryCard';
import ID_VERIFICATION_FIELDS from '../fragments/formDynamicZone/components/idVerification';
import SURVEY_FIELDS from '../fragments/formDynamicZone/components/survey';
/*
const NON_RECURSIVE_FORM_WRAPPER_FIELDS from '../fragments/nonRecursiveFormWrapper')
  
const NON_RECURSIVE_OPTION_FIELDS from '../fragments/nonRecursiveOption')
const NON_RECURSIVE_FORM_DYNAMIC_ZONE_FIELDS from '../fragments/formDynamicZone/nonRecursive')
  
const NON_RECURSIVE_FORM_FIELDS from '../fragments/nonRecursiveForm')
*/

const query = gql`
  query GetOneWizard($slug: String) {
    c360CustomWizards(where: { status: "published", hidden: false, slug: $slug }) {
      id
      title
      title_en
      title_nl
      slug
      description
      description_en
      description_nl
      wizardType
      orientation
      stepperType
      transitionStyle
      type
      status
      isAuthRequired
      defaultAuthActiveStep
      defaultNoAuthActiveStep
      hidden
      action
      operation
      mandatoryListTitle
      mandatoryListTitle_en
      mandatoryListTitle_nl
      mandatoryList {
        ...nonRecursiveOption
      }
      optionalListTitle
      optionalListTitle_en
      optionalListTitle_nl
      optionalList {
        ...nonRecursiveOption
      }
      forms {
        ...formWrapper
      }
      redirectLink
      nextBackButtonLink
      materialIcon
      featuredImagePWA {
        ...featuredImagePWA
      }
      footNote
      isSearchable
      disablePadding
      disableGridPadding
      territories {
        id
        name
        name_en
        name_nl
        alpha2
        alpha3
      }
      lob {
        title
        title_en
        title_nl
        lob
        icon
        materialIcon
      }
      globalApiCalls {
        ...formApiCall
      }
      extraSettings
    }
  }
  ${FORM_WRAPPER_FIELDS}
  ${FEATURED_IMAGE_PWA_FIELDS}
  ${OPTION_FIELDS}
  ${APPEND_KEY_FIELDS}
  ${PARAM_FIELDS}
  ${CONDITION_FIELDS}
  ${FORM_DYNAMIC_ZONE_FIELDS}
  ${WIZARD_ACTION_BUTTON_FIELDS}
  ${FORM_CONDITION_FIELDS}
  ${FORM_API_CALL_FIELDS}
  ${FORM_FIELDS}
  ${DATE_INPUT_FIELDS}
  ${BUTTON_GROUP_CHOICE_FIELDS}
  ${CHECK_CHOICE_FIELDS}
  ${ALERT_MESSAGE_FIELDS}
  ${HEADER_FIELDS}
  ${DROPDOWN_CHOICE_FIELDS}
  ${RADIO_CHOICE_FIELDS}
  ${TEXT_LABEL_FIELDS}
  ${SUBMIT_BUTTON_FIELDS}
  ${PHONE_INPUT_FIELDS}
  ${VALUE_INPUT_FIELDS}
  ${TOGGLE_BUTTON_CHOICE_FIELDS}
  ${DOB_INPUT_FIELDS}
  ${SINGLE_SEARCH_SELECT_FIELDS}
  ${EXPANSION_PANEL_CHOICE_FIELDS}
  ${DISPLAY_LIST_ITEMS_FIELDS}
  ${RICH_TEXT_DISPLAY_FIELDS}
  ${EMPTY_BOX_FIELDS}
  ${DOWNLOAD_BUTTON_FIELDS}
  ${UPLOAD_BUTTON_FIELDS}
  ${REGION_COUNTRY_CHOICE_FIELDS}
  ${UPLOAD_MODAL_BUTTON_FIELDS}
  ${CUSTOM_OBJECT_FIELDS}
  ${CARD_ITEMS_LIST_FIELDS}
  ${NEEDS_SUMMARY_CARD_FIELDS}
  ${ID_VERIFICATION_FIELDS}
  ${SURVEY_FIELDS}
  ${NON_RECURSIVE_OPTION_FIELDS}
  ${NON_RECURSIVE_FORM_DYNAMIC_ZONE_FIELDS}
  ${NON_RECURSIVE_FORM_FIELDS}
  ${NON_RECURSIVE_FORM_WRAPPER_FIELDS}
`;
/*
  ${NON_RECURSIVE_FORM_DYNAMIC_ZONE_FIELDS}
  ${NON_RECURSIVE_FORM_FIELDS}
  ${NON_RECURSIVE_FORM_WRAPPER_FIELDS}
*/
export default query;
