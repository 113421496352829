export const GET_CART_API_REQUEST = 'GET_CART_API_REQUEST';
export const GET_CART_API_SUCCESS = 'GET_CART_API_SUCCESS';
export const GET_CART_API_ERROR = 'GET_CART_API_ERROR';

export const GET_CART_PENDING_API_REQUEST = 'GET_CART_PENDING_API_REQUEST';
export const GET_CART_PENDING_API_SUCCESS = 'GET_CART_PENDING_API_SUCCESS';
export const GET_CART_PENDING_API_ERROR = 'GET_CART_PENDING_API_ERROR';

export const ADD_TO_CART_API_REQUEST = 'ADD_TO_CART_API_REQUEST';
export const ADD_TO_CART_API_SUCCESS = 'ADD_TO_CART_API_SUCCESS';
export const ADD_TO_CART_API_ERROR = 'ADD_TO_CART_API_ERROR';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';

export const ADD_PREMIUM_TO_CART_API_REQUEST = 'ADD_PREMIUM_TO_CART_API_REQUEST';
export const ADD_PREMIUM_TO_CART_API_ERROR = 'ADD_PREMIUM_TO_CART_API_ERROR';
export const ADD_PREMIUM_TO_CART_API_SUCCESS = 'ADD_PREMIUM_TO_CART_API_SUCCESS';
export const ADD_PREMIUM_TO_CART_SUCCESS = 'ADD_PREMIUM_TO_CART_SUCCESS';

export const PAY_FIRST_PREMIUM_API_REQUEST = 'PAY_FIRST_PREMIUM_API_REQUEST';
export const PAY_FIRST_PREMIUM_API_ERROR = 'PAY_FIRST_PREMIUM_API_ERROR';
export const PAY_FIRST_PREMIUM_API_SUCCESS = 'PAY_FIRST_PREMIUM_API_SUCCESS';
export const PAY_FIRST_PREMIUM_SUCCESS = 'PAY_FIRST_PREMIUM_SUCCESS';

export const ADD_RENEWAL_TO_CART_API_SUCCESS = 'ADD_RENEWAL_TO_CART_API_SUCCESS';
export const ADD_RENEWAL_TO_CART_API_ERROR = 'ADD_RENEWAL_TO_CART_API_ERROR';
export const ADD_RENEWAL_TO_CART_API_REQUEST = 'ADD_RENEWAL_TO_CART_API_REQUEST';
export const ADD_RENEWAL_TO_CART_SUCCESS = 'ADD_RENEWAL_TO_CART_SUCCESS';

export const DELETE_CART_API_REQUEST = 'DELETE_CART_API_REQUEST';
export const DELETE_CART_API_SUCCESS = 'DELETE_CART_API_SUCCESS';
export const DELETE_CART_API_ERROR = 'DELETE_CART_API_ERROR';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';

export const DELETE_CART_ITEM_API_REQUEST = 'DELETE_CART_ITEM_API_REQUEST';
export const DELETE_CART_ITEM_API_SUCCESS = 'DELETE_CART_ITEM_API_SUCCESS';
export const DELETE_CART_ITEM_API_ERROR = 'DELETE_CART_ITEM_API_ERROR';
export const DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS';

export const CHECKOUT_API_REQUEST = 'CHECKOUT_API_REQUEST';
export const CHECKOUT_API_SUCCESS = 'CHECKOUT_API_SUCCESS';
export const CHECKOUT_API_ERROR = 'CHECKOUT_API_ERROR';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';

export const GET_ORDER_SUMMARY_API_REQUEST = 'GET_ORDER_SUMMARY_API_REQUEST';
export const GET_ORDER_SUMMARY_API_SUCCESS = 'GET_ORDER_SUMMARY_API_SUCCESS';
export const GET_ORDER_SUMMARY_API_ERROR = 'GET_ORDER_SUMMARY_API_ERROR';
export const GET_ORDER_SUMMARY_SUCCESS = 'GET_ORDER_SUMMARY_SUCCESS';

export const CREATE_ORDER_API_REQUEST = 'CREATE_ORDER_API_REQUEST';
export const CREATE_ORDER_API_SUCCESS = 'CREATE_ORDER_API_SUCCESS';
export const CREATE_ORDER_API_ERROR = 'CREATE_ORDER_API_ERROR';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';

export const MAKE_FAC_PAYMENT_API_REQUEST = 'MAKE_FAC_PAYMENT_API_REQUEST';
export const MAKE_FAC_PAYMENT_API_SUCCESS = 'MAKE_FAC_PAYMENT_API_SUCCESS';
export const MAKE_FAC_PAYMENT_API_ERROR = 'MAKE_FAC_PAYMENT_API_ERROR';
export const MAKE_FAC_PAYMENT_SUCCESS = 'MAKE_FAC_PAYMENT_SUCCESS';

export const VIEW_RECEIPT_API_REQUEST = 'VIEW_RECEIPT_API_REQUEST';
export const VIEW_RECEIPT_API_SUCCESS = 'VIEW_RECEIPT_API_SUCCESS';
export const VIEW_RECEIPT_API_ERROR = 'VIEW_RECEIPT_API_ERROR';

export const CHANGE_QUANTITY_API_REQUEST = 'CHANGE_QUANTITY_API_REQUEST';
export const CHANGE_QUANTITY_API_SUCCESS = 'CHANGE_QUANTITY_API_SUCCESS';
export const CHANGE_QUANTITY_API_ERROR = 'CHANGE_QUANTITY_API_ERROR';

export const CHANGE_PARTIAL_PREMIUM_API_REQUEST = 'CHANGE_PARTIAL_PREMIUM_API_REQUEST';
export const CHANGE_PARTIAL_PREMIUM_API_SUCCESS = 'CHANGE_PARTIAL_PREMIUM_API_SUCCESS';
export const CHANGE_PARTIAL_PREMIUM_API_ERROR = 'CHANGE_PARTIAL_PREMIUM_API_ERROR';

export const PAY_NOW_API_REQUEST = 'PAY_NOW_API_REQUEST';
export const PAY_NOW_API_ERROR = 'PAY_NOW_API_ERROR';
export const PAY_NOW_API_SUCCESS = 'PAY_NOW_API_SUCCESS';
export const PAY_NOW_SUCCESS = 'PAY_NOW_SUCCESS';

export const MAKE_POWERTRANZ_PAYMENT_API_REQUEST = 'MAKE_POWERTRANZ_PAYMENT_API_REQUEST';
export const MAKE_POWERTRANZ_PAYMENT_API_SUCCESS = 'MAKE_POWERTRANZ_PAYMENT_API_SUCCESS';
export const MAKE_POWERTRANZ_PAYMENT_API_ERROR = 'MAKE_POWERTRANZ_PAYMENT_API_ERROR';
export const MAKE_POWERTRANZ_PAYMENT_SUCCESS = 'MAKE_POWERTRANZ_PAYMENT_SUCCESS';
export const RESET_CART_SUCCESS = 'RESET_CART_SUCCESS';
