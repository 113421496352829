import { getEntries } from './arrayManipulation';
import { removeEmpty } from './objectManipulation';
import { domain, introVideoUrl } from '../variables';

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const createUrl = ({
  scheme = window.location.protocol,
  server = window.location.hostname,
  port = window.location.port,
  path = window.location.pathname,
  queryObject,
}) => {
  const newObject = removeEmpty(queryObject);
  let url = `${scheme}://${server}:${port}/${path}`;
  const param = new URLSearchParams(getEntries(newObject)).toString();
  if (param) url += `?${param}`;
  return url;
};

export const createRelativeUrl = ({ path = window.location.pathname, queryObject }) => {
  const newObject = removeEmpty(queryObject);
  let url = `${path}`;
  let entries = getEntries(newObject);
  const stepIndexEntry = entries.find(entry => entry[0] === 'stepIndex');
  if (stepIndexEntry && stepIndexEntry.length > 0 && entries && entries.length > 0) {
    const filteredEntries = entries.filter(entry => entry[0] !== 'stepIndex');
    filteredEntries.unshift(stepIndexEntry);
    entries = [...filteredEntries];
  }
  // console.log('entries: ', entries);
  const param = new URLSearchParams(entries).toString();
  if (param) url += `?${param}`;
  return url;
};

export const createAPIRelativeUrl = ({
  path = window.location.pathname,
  queryObject,
}) => {
  const newObject = removeEmpty(queryObject);
  let url = `${path}`;
  // const param = new URLSearchParams(getEntries(newObject)).toString();

  let result = '';
  Object.keys(newObject).forEach(key => {
    result += `${key}=${encodeURIComponent(newObject[key])}&`;
  });
  result = result.substr(0, result.length - 1);

  if (result) url += `?${result}`;
  return url;
};

export const getFirstPath = tempPath => {
  return tempPath.split('/')[1] || tempPath;
};

export const getFirstSecondPath = tempPath => {
  // // console.log('tempPath: ', tempPath);
  const firstPath = tempPath?.split('/')[1];
  const secondPath = tempPath.split('/')[2] || '';
  if (firstPath && secondPath) return `${firstPath}/${secondPath}` || tempPath;
  if (firstPath) return firstPath || tempPath;
  return tempPath;
};

export function restructureDownloadLink(initialUrl) {
  if (isValidHttpUrl(initialUrl)) return initialUrl;
  let url = initialUrl;
  // console.log('initialUrl 1: ', initialUrl);
  // url = initialUrl.replace(/(^\w+:|^)\/\//, '');

  // console.log('url 2: ', url);

  const pattern = /^((http|https|ftp):\/\/)/;
  if (!pattern.test(url)) {
    const subPwa = domain?.pwa.replace(/(^\w+:|^)\/\//, '');
    url = `http://${subPwa}${url}`;
    // url = domain?.alternatePwa + url;
    // console.log('url3: ', url);
  }
  // console.log('url final: ', url);
  return url;
}
