import { strapiGetCall } from '../../axios';

const getYourLocation = (state, params) => {
  const url = `/findme`;
  return strapiGetCall(state, url, params);
};
export default { getYourLocation };

// https://c360-core.azure-api.net/cms/getUserLocation
// https://c360-core.azure-api.net/cms/locale
