import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';

export const reOpenOrCancelCaseUrl = helpCenterApi('reOpenOrCancelCaseUrl');
const reOpenOrCancelCase = (state, body) => {
  const url = reOpenOrCancelCaseUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  throw new Error('Server Error');
};
export default { reOpenOrCancelCase };
