import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getRef, uuidv4 } from '../../../utils/functions';
import ProductCategoryCard from '../../cards/categoryCard';
import { useSmall } from '../../../hooks';
//
function ProductsList({
  products,
  isAction,
  mobileColumnWidth = 6,
  desktopColumnWidth = 4,
  loading,
}) {
  // console.log('Products are ', products);
  const theme = useTheme();
  const isSmall = useSmall();

  return (
    <Grid
      container
      spacing={isSmall ? 1 : 2}
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      {products.map(product => {
        // console.log('product: ', product);
        const newProduct = { ...product };

        newProduct.background = getRef(theme.palette, product?.background);
        newProduct.iconBackground = getRef(theme.palette, product?.iconBackground);
        // console.log('product after: ', newProduct);

        return (
          <Grid
            key={newProduct?.lob || uuidv4()}
            item
            xs={mobileColumnWidth}
            md={mobileColumnWidth}
            lg={desktopColumnWidth}
            sx={{ height: 'auto' }}
          >
            <ProductCategoryCard
              product={newProduct}
              isAction={isAction}
              loading={loading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ProductsList;
