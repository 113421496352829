export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
  },
  textDefaultStyle: {
    cursor: 'pointer',
  },
  labelDefaultStyle: {
    cursor: 'pointer',
  },
  footNoteDefaultStyle: {
    cursor: 'text',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    fontStyle: 'italic',
  },
  block: {
    display: 'block',
  },
  floatBetween: {
    clear: 'both',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  inlineBlock: {
    display: 'inline-block',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  none: {
    fontSize: 0,
  },
  xxs: {
    fontSize: theme.typography.caption.fontSize, // theme.spacing(1.5), 12px
  },
  xs: {
    fontSize: theme.typography.body2.fontSize, // theme.spacing(1.75), // 14px
  },
  sm: {
    fontSize: theme.typography.body1.fontSize, // theme.spacing(2), // 16px
  },
  md: {
    fontSize: theme.typography.h5.fontSize, // theme.spacing(3),
  },
  lg: {
    fontSize: theme.typography.h4.fontSize, // theme.spacing(4),
  },
  xl: {
    fontSize: theme.typography.h3.fontSize, // theme.spacing(5),
  },
  neutral: {
    color: theme.palette.grey[400],
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  danger: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: theme.palette.background.banner,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    borderColor: theme.palette.text.primary,
    borderWidth: theme.shape.borderWidth,
    borderStyle: 'solid',
  },
  popoverTooltip: {
    backgroundColor: theme.palette.background.banner,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    borderColor: theme.palette.text.primary,
    borderWidth: theme.shape.borderWidth,
    borderStyle: 'solid',
    [theme.breakpoints.up('md')]: {
      minWidth: '30vw',
      maxWidth: '60vw',
    },
    minWidth: '90vw',
    maxWidth: '90vw',
    padding: theme.spacing(2),
  },
  customArrow: {
    color: theme.palette.secondary.main,
  },
  tooltipIcon: {
    position: 'absolute',
    top: '-30px',
    right: '0px',
    [theme.breakpoints.down('sm')]: {
      top: '-15px',
      right: '-10px',
    },
  },
});
