import { DriveFileMoveRounded, RepeatOneSharp } from '@mui/icons-material';
import { bool } from 'prop-types';
import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
// constants
import {
  GET_PENDING_CLAIMS_API_REQUEST,
  GET_PENDING_CLAIMS_API_SUCCESS,
  GET_PENDING_CLAIMS_API_ERROR,
  GET_NEXT_PENDING_CLAIMS_SUCCESS,
  RESET_PENDING_CLAIMS_SUCCESS,
  DELETE_PENDING_CLAIM_API_ERROR,
  DELETE_PENDING_CLAIM_API_REQUEST,
  DELETE_PENDING_CLAIM_API_SUCCESS,
  GET_PENDING_CLAIM_API_REQUEST,
  GET_PENDING_CLAIM_API_SUCCESS,
  GET_PENDING_CLAIM_API_ERROR,
  GET_NEXT_PENDING_CLAIM_SUCCESS,
} from '../../constants/pendingClaimActionNames';
import { statusOk } from '../../utils/variables';
import { removeItemOnce } from '../../utils/functions';
import payloadData from './payloadData';
// utility
import { createPartialClaim } from '../../utils/functions/backend-to-frontend-mappers/claims';

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  isDetailsPending: false,
  isDetailsError: false,
  isDetailsSuccess: false,
  response: null,
  error: null,
  allRows: [],
  details: null,
  identifier: null,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys: [],
  total: {},
  [DEFAULT_KEY]: null,
  totalRows: 0,
};

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ claims: payloadData, count: 100000 }] };
};

function pendingClaimsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_CLAIMS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_PENDING_CLAIMS_API_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const rowPayload = action?.payload?.payload[0]?.results || [];
      const newRows = rowPayload;
      const body = action?.body;
      const lob = body?.lob;
      const cacheKey = action?.cacheKey;
      // console.log('body: ', body);
      const newTotal = action?.payload?.payload[0]?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;
      const nowTime = new Date().getTime();

      const isReset = body?.reset || false;

      const incomingRows = newRows && Array.isArray(newRows) ? newRows : [];
      const oldRows =
        state?.allRows && Array.isArray(state?.allRows) ? state?.allRows : [];
      const existingRows = [...oldRows];
      // console.log('oldInsurances: ', oldInsurances);
      incomingRows.map(incomingRow => {
        // console.log('incomingRow: ', incomingRow);
        incomingRow.body = body;
        let index = 0;
        if (incomingRow.body.infiniteScroll) {
          index = existingRows.findIndex(
            oldRow =>
              oldRow.id === incomingRow.id &&
              oldRow.body.infiniteScroll === incomingRow.body.infiniteScroll &&
              oldRow.body.isMobile === incomingRow.body.isMobile &&
              oldRow.body.alpha2 === incomingRow.body.alpha2 &&
              oldRow.body?.lob?.toString() === incomingRow?.body?.lob?.toString(),
          );
        } else {
          index = existingRows.findIndex(
            oldRow =>
              oldRow.id === incomingRow.id &&
              oldRow.body.infiniteScroll === incomingRow.body.infiniteScroll &&
              oldRow.body.isMobile === incomingRow.body.isMobile &&
              oldRow.body.offset === incomingRow.body.offset &&
              oldRow.body.limit === incomingRow.body.limit &&
              oldRow.body.alpha2 === incomingRow.body.alpha2 &&
              oldRow.body?.lob?.toString() === incomingRow?.body?.lob?.toString(),
          );
        }

        // Replace item at index using native splice
        if (index !== -1) {
          existingRows.splice(index, 1, incomingRow);
        } else {
          existingRows.push(incomingRow);
        }
        return null;
      });

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[body?.alpha2] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        // rows,
        searchKeys: [],
        allRows: newRows,
        count,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        // incomingRows,
        // claims: claimsArray,
      };
    }

    case GET_PENDING_CLAIMS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };

    case GET_NEXT_PENDING_CLAIMS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    case GET_PENDING_CLAIM_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isSuccess: false,
        isError: false,
      };
    case GET_PENDING_CLAIM_API_SUCCESS: {
      const response = action?.payload?.payload;
      const individualPendingClaim = createPartialClaim(response)?.draft;
      return {
        ...state,
        isPending: false,
        isSuccess: true,
        isError: false,
        response: action.payload.payload,
        individualPendingClaim,
      };
    }
    case GET_PENDING_CLAIM_API_ERROR:
      return {
        ...state,
        isPending: false,
        isSuccess: false,
        isError: true,
        // signUpBioError: action.payload,
      };
    case GET_NEXT_PENDING_CLAIM_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;
      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    // case CLEAR__CLAIMS_CACHE: {
    //   return {
    //     ...state,
    //     [DEFAULT_KEY]: null,
    //   };
    // }

    case RESET_PENDING_CLAIMS_SUCCESS: {
      return {
        ...state,
        total: initialState.total,
        allRows: initialState.allRows,
        [DEFAULT_KEY]: null,
      };
    }

    // deleting a draft/pending-claim handlers
    case DELETE_PENDING_CLAIM_API_REQUEST: {
      return {
        ...state,
        isDeletePendingClaimPending: true,
        isDeletePendingClaimError: false,
        isDeletePendingClaimSuccess: false,
      };
    }
    case DELETE_PENDING_CLAIM_API_SUCCESS: {
      const deletedItem = action?.payload || {};
      deletedItem._id = deletedItem?._id || deletedItem?.claimId;
      // console.log('deleteDigitalSalesApplication: ', deleteDigitalSalesApplication);
      const existing = [...state.allRows];
      // console.log('existingApplications: ', existingApplications);
      // const updatedList = existingDevices?.filter(item => item?.id !== deleteTrustedDevice?.id && item?.authId !== deleteTrustedDevice?.authId);
      const updatedList = removeItemOnce(existing, deletedItem, '_id');
      // console.log(updatedList);
      // console.log("Updated List", updatedList);

      return {
        ...state,
        deletedItem,
        isDeletePendingClaimPending: false,
        isDeletePendingClaimError: false,
        isDeletePendingClaimSuccess: true,
        allRows: updatedList,
        [DEFAULT_KEY]: null,
      };
    }

    case DELETE_PENDING_CLAIM_API_ERROR: {
      const deletedItem = action?.payload;
      return {
        ...state,
        isDeletePendingClaimPending: false,
        isDeletePendingClaimError: true,
        isDeletePendingClaimSuccess: false,
        deletedPendingClaim: deletedItem,
      };
    }
    // case SELECT_CLIENTS_SUCCESS: {
    //   return {
    //     ...state,
    //     [DEFAULT_KEY]: null,
    //     total: initialState.total,
    //     allRows: initialState.allRows,
    //   };
    // }

    // case RESET_INFINITE_SCROLL_SUCCESS: {
    //   return {
    //     ...state,

    //     nextItemCount: 0,
    //     nextScrollCount: 0,
    //     nextStartIndex: 0,
    //   };
    // }

    // case GET_CLAIM_DETAILS_API_REQUEST:
    //   return {
    //     ...state,
    //     isDetailsPending: true,
    //     isDetailsError: false,
    //     isDetailsSuccess: false,
    //   };
    // case GET_CLAIM_DETAILS_API_SUCCESS: {
    //   const details = action?.payload;
    //   const newClaim = details?.payload;
    //   const { vehicleIdentifier, indexNo } = newClaim;
    //   const claimId = action?.params?.claimNo;
    //   const allRows = state?.allRows || [];
    //   const foundIndex = allRows?.findIndex(x => x?.claimNo === claimId);
    //   const claimRow = allRows[foundIndex];
    //   claimRow.vehicleIdentifier = vehicleIdentifier;
    //   allRows[foundIndex] = claimRow;
    //   // console.log('Vehicle Number', row);
    //   return {
    //     ...state,
    //     isDetailsPending: false,
    //     isDetailsError: false,
    //     isDetailsSuccess: true,
    //     details,
    //     identifier: vehicleIdentifier || indexNo,
    //     allRows,
    //   };
    // }
    // case GET_CLAIM_DETAILS_API_ERROR:
    //   return {
    //     ...state,
    //     isDetailsPending: false,
    //     isDetailsError: true,
    //     isDetailsSuccess: false,
    //   };

    default:
      return state;
  }
}

export default pendingClaimsReducer;

/*

    */
