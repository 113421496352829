import { gql } from 'graphql-request';

const REGION_COUNTRY_CHOICE_FIELDS = gql`
  fragment regionCountryChoice on ComponentWizardComponentsRegionCountryChoice {
    RegionCountryChoice_slug: slug
    RegionCountryChoice_name: name
    RegionCountryChoice_layoutPosition: layoutPosition
    RegionCountryChoice_positionNo: positionNo
    RegionCountryChoice_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    RegionCountryChoice_isConditionalComponent: isConditionalComponent
    RegionCountryChoice_conditionalValue: conditionalValue
    RegionCountryChoice_conditionalKey: conditionalKey
    RegionCountryChoice_conditionalOperator: conditionalOperator
    RegionCountryChoice_conditionalFieldType: conditionalFieldType
    RegionCountryChoice_conditionalAction: conditionalAction
    RegionCountryChoice_disable: disable
    RegionCountryChoice_show: show
    RegionCountryChoice_label: label
    RegionCountryChoice_labelAlign: labelAlign
    RegionCountryChoice_variant: variant
    RegionCountryChoice_countryStaticValue: countryStaticValue
    RegionCountryChoice_regionStaticValue: regionStaticValue
    RegionCountryChoice_countryReducerKeyValue: countryReducerKeyValue
    RegionCountryChoice_regionReducerKeyValue: regionReducerKeyValue
    RegionCountryChoice_fullWidth: fullWidth
    RegionCountryChoice_required: required
    RegionCountryChoice_tooltip: tooltip
    RegionCountryChoice_disableInitialValue: disableInitialValue
    RegionCountryChoice_regionPlaceholder: regionPlaceholder
    RegionCountryChoice_showCountryOnly: showCountryOnly
    RegionCountryChoice_label_en: label_en
    RegionCountryChoice_label_nl: label_nl
    RegionCountryChoice_tooltip_en: tooltip_en
    RegionCountryChoice_tooltip_nl: tooltip_nl
    RegionCountryChoice_regionPlaceholder_en: regionPlaceholder_en
    RegionCountryChoice_regionPlaceholder_nl: regionPlaceholder_nl
    RegionCountryChoice_conditions: conditions {
      ...condition
    }
  }
`;

// export default REGION_COUNTRY_CHOICE_FIELDS;
// exports.default = REGION_COUNTRY_CHOICE_FIELDS;
export default REGION_COUNTRY_CHOICE_FIELDS;
