import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
export const getSurveyLinkUrl = helpCenterApi('getSurveyLinkUrl');
const getSurveyLink = (state, body) => {
  const url = getSurveyLinkUrl;
  if (url) {
    return crmPostCall(state, url, body);
  } else {
    throw new Error('Server Error');
  }
};
export default { getSurveyLink };
