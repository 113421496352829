import { useSelector, useDispatch } from 'react-redux';
import useRouter from './useRouter';
import { signOut } from '../actions/auth';
import routes from '../utils/variables/routes';
import { agentType } from '../utils/variables';
import { getAccessToken } from '../storage/accessToken';
// import { getStore } from '../store';
// import { expiresAtStorage } from '../utils/variables';

export const useSignOut = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  dispatch(signOut.signOutAction()).then(() => {
    // // console.log('Your Done!');
    router.replace(routes.auth.login);
    // setTimeout(() => {
    // }, 200);
  });
};

export const validateAuth = (state, dontRevalidate) => {
  // console.log('state validate auth: ', state);
  const user = state?.auth?.user;
  // const router = useRouter();
  let isAuthenticated = state?.auth?.isAuthenticated;
  let revalidate = false;
  let isExpired = false;

  const expiresIn = user?.expiresIn;
  const clientExpiresIn = user?.clientExpiresIn;
  // // console.log('expiresIn: ', expiresIn);
  const logInTime = user?.logInTime;
  // // console.log('logInTime: ', logInTime);
  const nowTime = new Date().getTime();
  // // console.log('nowTime: ', nowTime);
  const accessToken = getAccessToken();

  if (accessToken && user && user.accessToken) {
    // user && user.accessToken
    // // console.log('user: ', user);

    if (expiresIn) {
      const expiresAt = parseInt(expiresIn, 10) * 1000 + parseInt(logInTime, 10);
      // // console.log('expiresAt: ', expiresAt);
      const isNotExpired = user && user.accessToken && nowTime < expiresAt;
      const clientExpiresAt =
        parseInt(clientExpiresIn, 10) * 1000 + parseInt(logInTime, 10);
      // // console.log('clientExpiresAt: ', clientExpiresAt);
      const isClientNotExpired = user && user.accessToken && nowTime < clientExpiresAt;
      if (isNotExpired) {
        isExpired = false;
        // // console.log('isNotExpired: ', isNotExpired);
        if (isClientNotExpired) {
          // // console.log('isClientNotExpired: ', isClientNotExpired);
          isAuthenticated = true;
          revalidate = false;
        } else if (dontRevalidate) {
          isAuthenticated = true;
          revalidate = false;
        } else {
          isAuthenticated = true;
          revalidate = true;
        }

        const clientsError = state?.clients?.error;
        if (clientsError) {
          if (clientsError?.response) {
            // console.log(clientsError?.response);
            // console.log(clientsError?.response?.status);
            if (clientsError?.response?.status === 403) {
              isExpired = true;
              isAuthenticated = false;
              revalidate = false;
            }
          }
        }
        const clientPoliciesError = state?.clientPolicies?.error;
        if (clientPoliciesError) {
          if (clientPoliciesError?.response) {
            // console.log(clientPoliciesError?.response);
            // console.log(clientPoliciesError?.response?.status);
            if (clientPoliciesError?.response?.status === 403) {
              isExpired = true;
              isAuthenticated = false;
              revalidate = false;
            }
          }
        }

        const insuranceError = state?.insurance?.error;
        if (insuranceError) {
          if (insuranceError?.response) {
            // console.log(insuranceError?.response);
            // console.log(insuranceError?.response?.status);
            if (insuranceError?.response?.status === 403) {
              isExpired = true;
              isAuthenticated = false;
              revalidate = false;
            }
          }
        }
      } else {
        // // console.log('isExpired: ', !isNotExpired);
        isExpired = true;

        if (dontRevalidate) {
          isAuthenticated = false;
          revalidate = false;
        } else {
          // isAuthenticated = false;
          revalidate = false;
          isAuthenticated = false;
        }
        // isAuthenticated = !!(user && user.accessToken);
      }
    } else {
      isAuthenticated = false; //! !(user && user.accessToken);
      revalidate = false;
      isExpired = false;
    }
  }

  // isAuthenticated = isAuthenticated && accessToken;

  return { isAuthenticated, revalidate, isExpired };
};

/*
 else {
    isAuthenticated = false;
    revalidate = false;
    isExpired = false;
  }

  */
export const useAuthValidation = () => {
  const { isAuthenticated } = useSelector(state => validateAuth(state));

  return isAuthenticated;
};

export const getUserIds = state => {
  // console.log('state: ', state);
  const { user, usertype } = state?.auth || {};
  const userIds = {
    accessToken: user?.accessToken,
    authId: user?.id,
    customerId: user?.customer?.id,
    agentId: user?.agent?.id,
    isAgent: !!(user?.agent?.id && usertype === agentType),
    usertype,
    usersubtype: user?.agentType,
  };
  return userIds;
};

export default {
  useAuthValidation,
  validateAuth,
  useSignOut,
  getUserIds,
};

/*
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expiresAt');
  setTimeout(() => {
    history.replace('/authcheck');
  }, 200);
  */
// };

// export const handleAuth = () => {
/*
  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('id_token', authResult.idToken);

  const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
  localStorage.setItem('expiresAt', expiresAt);

  this.getProfile();
  setTimeout(() => {
    history.replace('/authcheck');
  }, 600);
  */
// };
