import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  buttonColorTypes,
  buttonSizeTypes,
  buttonVariantTypes,
} from '../../propertyValues';
import {
  uuidv4,
  checkOnlineStatus,
  isSafari,
  isIOS,
  downloadFile,
  iOSversion,
  restructureDownloadLink,
  createRelativeUrl,
} from '../../../utils/functions';
import { useRouter } from '../../../hooks';

import styles from './style';

const DownloadButton = ({
  defaultDownloadText,
  download,
  spacing,
  buttonColor,
  buttonVariant,
  buttonSize,
  inverseBackgroundColor,
  fullWidth,
  icon,
  fixedText,
  className,
  sx,
  ...restProps
}) => {
  const useStyles = makeStyles(theme => styles(theme, inverseBackgroundColor));
  const classes = useStyles();
  const aRef = useRef();
  const initialOnline = navigator.onLine;
  const [online, setOnline] = useState(initialOnline);
  const router = useRouter();
  // console.log('online: ', online);

  useEffect(() => {
    const getOnlineStatus = async () => {
      const newOnline = await checkOnlineStatus();
      setOnline(newOnline);
    };
    getOnlineStatus();
  }, []);

  const iosVersion = iOSversion();

  const downloadTitle = fixedText
    ? defaultDownloadText
    : download?.title || defaultDownloadText;

  const downloadLink = download?.file?.url || download?.url || download?.value; // getRef(storeState, urlLink) || null;
  const newDownloadLink = restructureDownloadLink(downloadLink);
  // // console.log('downloadLink: ', downloadLink);
  if (!downloadLink) {
    return null;
  }

  if ((isSafari() && iosVersion > 12) || !isSafari()) {
    const filename = downloadLink
      ?.split('/')
      ?.pop()
      ?.split('?')
      ? downloadLink
          ?.split('/')
          ?.pop()
          ?.split('?')[0]
      : downloadLink?.split('/')?.pop();
    return (
      <Button
        color={buttonColor}
        variant={buttonVariant}
        size={buttonSize}
        // fullWidth={fullWidth}
        className={classnames(className)}
        startIcon={icon && <Icon>{icon}</Icon>}
        onClick={() => downloadFile(filename, newDownloadLink)}
        sx={sx}
      >
        {downloadTitle}
      </Button>
    );
  }

  if (('standalone' in window.navigator || window.navigator.standalone) && isIOS()) {
    return (
      <Button
        color={buttonColor}
        variant={buttonVariant}
        size={buttonSize}
        // fullWidth={fullWidth}
        className={classnames(className)}
        startIcon={icon && <Icon>{icon}</Icon>}
        onClick={() => window.open(newDownloadLink)}
        sx={sx}
      >
        {downloadTitle}
      </Button>
    );
  }

  return (
    <Button
      component="a"
      color={buttonColor}
      variant={buttonVariant}
      size={buttonSize}
      href={newDownloadLink}
      target="_blank"
      // rel="noopener noreferrer"
      rel="noreferrer external"
      className={classnames(classes.noDecoration, className)}
      startIcon={icon && <Icon>{icon}</Icon>}
      sx={sx}
    >
      {downloadTitle}
    </Button>
  );
};

DownloadButton.defaultProps = {
  downloadUrls: [],
  defaultDownloadText: 'Download',
  buttonColor: 'primary',
  buttonVariant: 'contained',
  buttonSize: 'medium',
  inverseBackgroundColor: false,
  spacing: 1,
  fullWidth: true,
  icon: 'cloud_download',
  fixedText: false,
};

DownloadButton.propTypes = {
  downloadUrls: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  fullWidth: PropTypes.bool,
  defaultDownloadText: PropTypes.string,
  inverseBackgroundColor: PropTypes.bool,
  buttonVariant: PropTypes.oneOf(buttonVariantTypes),
  buttonSize: PropTypes.oneOf(buttonSizeTypes),
  buttonColor: PropTypes.oneOf(buttonColorTypes),
  spacing: PropTypes.number,
  icon: PropTypes.string,
  fixedText: PropTypes.bool,
};

export default DownloadButton;

/*


  return (
          <Grid item xs={12} key={uuidv4()} className={classes.button}>
            {online && (
              <a
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                download={obj?.title}
                className={classes.noDecoration}
                title={obj?.title}
              >
                {button}
              </a>
            )}
            {!online && (
              <a
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.noDecoration}
                title={obj?.title}
              >
                {button}
              </a>
            )}
          </Grid>
        );
*/
