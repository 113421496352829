import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSmall } from '../../../../hooks';

function StatusListHeader(props) {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const history = useHistory();
  const isSmall = useSmall();
  const [caseId, setCaseId] = useState('');
  const [isCaseIdLogin, setIsCaseIdLogin] = useState(false);
  const [checkStatusButton, setCheckStatusButton] = useState(false);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  const navToLogin = () => {
    setCaseId('');
    history.push('/login');
  };

  const navToHelpRequest = () => {
    history.push('/help/request-help');
  };

  const dropdownYears = () => {
    const years = [];
    for (let i = 2020; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  };

  const handleChange = event => {
    setYear(event.target.value);
    props.caseYear(event.target.value);
  };

  const getCase = () => {
    props.issueCaseId(caseId);
    setIsCaseIdLogin(true);
  };

  useEffect(() => {
    if (caseId != '') {
      setCheckStatusButton(true);
    } else {
      setCheckStatusButton(false);
    }
  });
  const theme = useTheme();
  return (
    <>
      <Stack>
        <Stack sx={{ my: 1, overflow: 'hidden' }}>
          {!isAuthenticated && !isCaseIdLogin ? (
            <Stack
              alignItems={isSmall ? 'center' : 'flex-start'}
              sx={{
                backgroundColor: 'background.default',
                p: 2,
                borderRadius: '10px',
                mt: 2,
                width: '100%',
              }}
            >
              <Typography
                textAlign={isSmall ? 'center' : 'start'}
                color="text.label"
                sx={{ mb: 2 }}
              >
                To check your status or view support history,
                <br /> Please log in, or enter your Case ID.
              </Typography>
              <Stack
                direction={isSmall ? 'column' : 'row'}
                alignItems="center"
                sx={{ width: isSmall ? '100%' : 'initial' }}
              >
                <Stack
                  sx={{
                    width: '100%',
                    marginLeft: isSmall ? '30%' : 'initial',
                    marginBottom: isSmall ? '15px' : 'initial',
                  }}
                >
                  <Button
                    sx={{
                      width: isSmall ? '70%' : '120px',
                      height: '45px',
                      maxWidth: '300px',
                      backgroundColor: 'primary.main',
                    }}
                    variant="contained"
                    onClick={navToLogin}
                    size="small"
                  >
                    Go to Login
                  </Button>
                </Stack>
                <Stack
                  direction={isSmall ? 'column' : 'row'}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Typography
                    color="text.label"
                    sx={{ margin: isSmall ? '15px' : '0 15px' }}
                  >
                    Or
                  </Typography>
                  <TextField
                    sx={{ width: '40px', marginTop: '10px' }}
                    value={caseId}
                    onChange={event => {
                      setCaseId(event.target.value);
                    }}
                    placeholder="Enter Case ID"
                  />
                  <Typography
                    color="text.label"
                    sx={{ margin: '0 15px', visibility: isSmall ? 'hidden' : 'initial' }}
                  >
                    +
                  </Typography>
                  <TextField
                    sx={{ width: '0px', marginTop: isSmall ? '0px' : '10px' }}
                    placeholder="Name"
                  />
                  <Stack
                    sx={{
                      width: '100%',
                      marginTop: isSmall ? '15px' : 'initial',
                      marginLeft: isSmall ? '30%' : 'initial',
                    }}
                  >
                    <Button
                      sx={{
                        width: isSmall ? '70%' : '120px',
                        height: '45px',
                        maxWidth: '300px',
                        marginLeft: isSmall ? '0px' : '30px',
                        background: !checkStatusButton ? '#DADADA' : 'primary',
                        color: !checkStatusButton ? '#95979E' : 'white',
                        pointerEvents: !checkStatusButton ? 'none' : 'initial',
                      }}
                      variant="contained"
                      size="small"
                      onClick={getCase}
                    >
                      Check Status
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack sx={{ width: '100%' }}>
              <>
                <Stack
                  direction={isSmall ? 'column' : 'row'}
                  alignItems={isSmall ? 'initial' : 'center'}
                  marginLeft={isSmall ? '30px' : ''}
                >
                  <Typography
                    color="text.label"
                    textAlign={isSmall ? 'left' : null}
                    sx={{ marginRight: isSmall ? '' : '8px' }}
                  >
                    {isCaseIdLogin ? 'Logged in with Case Id:' : 'Logged in as:'}
                  </Typography>
                  {isSmall ? (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ margin: '20px 0' }}
                      >
                        <AccountCircleIcon sx={{ marginRight: '6px', color: 'black' }} />
                        <Typography sx={{ color: 'text.main' }}>
                          {isCaseIdLogin ? caseId : `${user.firstName} ${user.lastName}`}
                        </Typography>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <AccountCircleIcon sx={{ marginRight: '6px', color: 'black' }} />
                      <Typography sx={{ color: 'text.main' }}>
                        {isCaseIdLogin ? caseId : `${user.firstName} ${user.lastName}`}
                      </Typography>
                    </>
                  )}
                </Stack>
              </>

              <Stack
                direction={isSmall ? 'column-reverse' : 'row'}
                alignItems="center"
                justifyContent="space-between"
                sx={{ margin: '40px 0' }}
              >
                {isCaseIdLogin ? (
                  <></>
                ) : (
                  <>
                    <Stack
                      sx={{
                        width: isSmall ? '100%' : '130px',
                        padding: isSmall ? '20px' : '',
                        maxWidth: '400px',
                      }}
                    >
                      <Select
                        labelId="select-year"
                        id="select-year"
                        value={year}
                        label="Year"
                        onChange={handleChange}
                        sx={{
                          textAlign: isSmall ? 'left' : '',
                          color: '#95979E',
                          legend: {
                            display: 'none',
                          },
                        }}
                      >
                        {dropdownYears().map(year => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </>
                )}
                <Stack
                  sx={{
                    marginBottom: '10px',
                    width: isSmall ? '100%' : 'initial',
                    padding: isSmall ? '20px' : '',
                    maxWidth: isSmall ? '400px' : 'initial',
                  }}
                >
                  <Button
                    onClick={navToHelpRequest}
                    sx={{ height: '50px' }}
                    variant="contained"
                  >
                    Open New Help Request
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default StatusListHeader;
