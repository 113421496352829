import { gql } from 'graphql-request';

const CONDITION_FIELDS = require('../../condition').default;

const EMPTY_BOX_FIELDS = gql`
  fragment emptyBox on ComponentWizardComponentsEmptyBox {
    EmptyBox_slug: slug
    EmptyBox_positionNo: positionNo
    EmptyBox_layoutPosition: layoutPosition
    EmptyBox_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    EmptyBox_name: name
    EmptyBox_isConditionalComponent: isConditionalComponent
    EmptyBox_conditionalValue: conditionalValue
    EmptyBox_conditionalKey: conditionalKey
    EmptyBox_conditionalOperator: conditionalOperator
    EmptyBox_conditionalFieldType: conditionalFieldType
    EmptyBox_conditionalAction: conditionalAction
    EmptyBox_disable: disable
    EmptyBox_show: show
    EmptyBox_borderSize: borderSize
    EmptyBox_borderColor: borderColor
    EmptyBox_borderRadius: borderRadius
    EmptyBox_paper: paper
    EmptyBox_contentAlign: contentAlign
    EmptyBox_opacity: opacity
    EmptyBox_backgroundMedia: backgroundMedia {
      url
    }
    EmptyBox_padding: padding
    EmptyBox_margin: margin
    EmptyBox_minHeight: minHeight
    EmptyBox_overlay: overlay
    EmptyBox_backgroundColor: backgroundColor
    EmptyBox_conditions: conditions {
      ...condition
    }
  }
`;

// export default EMPTY_BOX_FIELDS;
// exports.default = EMPTY_BOX_FIELDS;
export default EMPTY_BOX_FIELDS;
