import { gql } from 'graphql-request';

const SINGLE_SEARCH_SELECT_FIELDS = gql`
  fragment singleSearchSelect on ComponentWizardComponentsSearchSingleSelectInput {
    SearchSingleSelectInput_slug: slug
    SearchSingleSelectInput_name: name
    SearchSingleSelectInput_label: label
    SearchSingleSelectInput_placeholder: placeholder
    SearchSingleSelectInput_loadingText: loadingText
    SearchSingleSelectInput_staticValue: staticValue
    SearchSingleSelectInput_reducerKeyValue: reducerKeyValue
    SearchSingleSelectInput_fullWidth: fullWidth
    SearchSingleSelectInput_optionLabelKey: optionLabelKey
    SearchSingleSelectInput_optionValueKey: optionValueKey
    SearchSingleSelectInput_layoutPosition: layoutPosition
    SearchSingleSelectInput_positionNo: positionNo
    SearchSingleSelectInput_tooltip: tooltip
    SearchSingleSelectInput_required: required
    SearchSingleSelectInput_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    SearchSingleSelectInput_labelAlign: labelAlign
    SearchSingleSelectInput_variant: variant
    SearchSingleSelectInput_items: items {
      ...nonRecursiveOption
    }
    SearchSingleSelectInput_searchCall: searchCall {
      ...formApiCall
    }
    SearchSingleSelectInput_storeKey: storeKey
    SearchSingleSelectInput_source: source
    SearchSingleSelectInput_isConditionalComponent: isConditionalComponent
    SearchSingleSelectInput_conditionalValue: conditionalValue
    SearchSingleSelectInput_conditionalKey: conditionalKey
    SearchSingleSelectInput_conditionalOperator: conditionalOperator
    SearchSingleSelectInput_conditionalFieldType: conditionalFieldType
    SearchSingleSelectInput_conditionalAction: conditionalAction
    SearchSingleSelectInput_disable: disable
    SearchSingleSelectInput_show: show
    SearchSingleSelectInput_tooltip_en: tooltip_en
    SearchSingleSelectInput_tooltip_nl: tooltip_nl
    SearchSingleSelectInput_label_en: label_en
    SearchSingleSelectInput_label_nl: label_nl
    SearchSingleSelectInput_disableInitialValue: disableInitialValue
    SearchSingleSelectInput_placeholder: placeholder
    SearchSingleSelectInput_placeholder_en: placeholder_en
    SearchSingleSelectInput_placeholder_nl: placeholder_nl
    SearchSingleSelectInput_loadingText_en: loadingText_en
    SearchSingleSelectInput_loadingText_nl: loadingText_nl
    SearchSingleSelectInput_isSelectedClient: isSelectedClient
    SearchSingleSelectInput_reloadWizard: reloadWizard
    SearchSingleSelectInput_noOptionsText: noOptionsText
    SearchSingleSelectInput_conditions: conditions {
      ...condition
    }
  }
`;

// export default SINGLE_SEARCH_SELECT_FIELDS;
// exports.default = SINGLE_SEARCH_SELECT_FIELDS;
export default SINGLE_SEARCH_SELECT_FIELDS;
