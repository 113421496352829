import { SET_AUTH_USERNAME_SUCCESS } from '../../../constants/authActionNames';

export function setAuthUsernameAction(data) {
  return {
    type: SET_AUTH_USERNAME_SUCCESS,
    payload: data,
  };
}

export default {
  setAuthUsernameAction,
};
