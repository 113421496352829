import { defineMessages } from 'react-intl';

const messages = defineMessages({
  directingYouTo: {
    id: 'app_containers_loader_directingYouTo',
    defaultMessage: 'Directing you to...',
  },
  loading: {
    id: 'app_containers_loader_loading',
    defaultMessage: 'Loading',
  },
});

export default messages;
