import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  CardMedia,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

function CallToActionCard({ title, imageSrc, footer, htmlContent, jsxContent }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      <Typography className={classes.cardHeaderText}>{title}</Typography>
      {imageSrc && (
        <CardMedia
          component="img"
          image={imageSrc}
          alt="referral-Image"
          className={classes.cardImageStyle}
        />
      )}
      <CardContent className={classes.cardContentStyle}>
        {jsxContent}
        {htmlContent && <div dangerouslySetInnerHTML={{ __html: `${htmlContent}` }} />}
      </CardContent>
      {footer}
    </Card>
  );
}

export default CallToActionCard;
