import { crmPostCall } from '../../axios';
import { helpCenterApi } from '../../../utils/functions/helpCenterApis';

export const documentsUploadUrl = helpCenterApi('documentsUploadUrl');
const documentsUpload = (state, body) => {
  const url = documentsUploadUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  throw new Error('Server Error');
};
export default { documentsUpload };
