import { useSelector } from 'react-redux';
import { createTheme, responsiveFontSizes, alpha } from '@mui/material/styles';

import light from './light';
import dark from './dark';
// import getCustomTheme from './custom';
import GetLinkTheme from './link';
import { customThemeStorage } from '../storage';
import { appThemeNameStorage } from '../utils/variables';

const formatColor = _inputColor => {
  const inputColor = _inputColor?.trim() || '';
  if (inputColor.startsWith('#', 0)) return inputColor;
  return `#${inputColor}`;
};

export const formatThemeFromForm = values => {
  if (!values) return null;
  return {
    palette: {
      name: values?.name,
      mode: values?.mode,
      primary: {
        main: formatColor(values?.primary),
      },
      secondary: {
        main: formatColor(values?.secondary),
      },
      success: {
        main: formatColor(values?.success),
      },
      warning: {
        main: formatColor(values?.warning),
      },
      error: {
        main: formatColor(values?.error),
      },
      background: {
        default: formatColor(values?.backgroundDefault),
        paper: formatColor(values?.backgroundPaper),
        grid: formatColor(values?.backgroundGrid),
        banner: formatColor(values?.backgroundBanner),
      },
    },
    shape: {
      borderRadius: parseInt(values?.borderRadius, 10),
      borderWidth: parseInt(values?.borderWidth, 10),
    },
    typography: {
      fontSize: parseInt(values?.fontSize, 10),
      htmlFontSize: parseInt(values?.htmlFontSize, 10),
      fontFamily: values?.fontFamily,
      fontWeightLight: parseInt(values?.fontWeightLight, 10),
      fontWeightRegular: parseInt(values?.fontWeightRegular, 10),
      fontWeightMedium: parseInt(values?.fontWeightMedium, 10),
      fontWeightBold: parseInt(values?.fontWeightBold, 10),
    },
    button: {
      backgroundColor: formatColor(values?.primary?.main),
    },
  };
};

export const formatThemeToForm = (mytheme, myThemeName) => {
  if (!mytheme) return null;
  return {
    name: myThemeName,
    mode: mytheme?.palette?.mode,
    primary: mytheme?.palette?.primary?.main,
    secondary: mytheme?.palette?.secondary?.main,
    error: mytheme?.palette?.error?.main,
    warning: mytheme?.palette?.warning?.main,
    success: mytheme?.palette?.success?.main,
    backgroundPaper: mytheme?.palette?.background?.paper,
    backgroundGrid: mytheme?.palette?.background?.grid,
    backgroundBanner: mytheme?.palette?.background?.banner,
    backgroundDefault: mytheme?.palette?.background?.default,
    borderRadius: parseInt(mytheme?.shape?.borderRadius, 10),
    borderWidth: parseInt(mytheme?.shape?.borderWidth, 10),
    fontSize: parseInt(mytheme?.typography?.fontSize, 10),
    htmlFontSize: parseInt(mytheme?.typography?.htmlFontSize, 10),
    fontFamily: mytheme?.typography?.fontFamily,
    fontWeightLight: mytheme?.typography?.fontWeightLight,
    fontWeightRegular: mytheme?.typography?.fontWeightRegular,
    fontWeightMedium: mytheme?.typography?.fontWeightMedium,
    fontWeightBold: mytheme?.typography?.fontWeightBold,
  };
};

const themeMap = (themeName, lightTheme, darkTheme) => {
  const themeArray = {
    light: lightTheme || light,
    dark: darkTheme || dark,
  };
  return themeArray[themeName];
};

export const themeTypes = {
  light: 'light',
  dark: 'dark',
  custom: 'custom',
};

export const useGetThemeByName = () => {
  // // console.log('themeName: ', themeName);
  const lightThemeData = useSelector(state => state?.locations?.lightTheme);
  const lightTheme = lightThemeData ? formatThemeFromForm(lightThemeData) : null;
  // // console.log('lightTheme: ', lightTheme);
  const darkThemeData = useSelector(state => state?.locations?.darkTheme);
  const darkTheme = darkThemeData ? formatThemeFromForm(darkThemeData) : null;
  // // console.log('darkTheme: ', darkTheme);
  return themeName => {
    let theme = themeMap(themeName, lightTheme, darkTheme) || light;
    // // console.log('theme: ', theme);
    const linkTheme = GetLinkTheme();
    // // console.log('linkTheme: ', linkTheme);
    const customTheme = customThemeStorage.getCustomTheme();

    theme =
      linkTheme ||
      (themeName?.startsWith(themeTypes.custom) && customTheme ? customTheme : theme);

    // console.log('theme: ', theme);
    theme = createTheme(theme);

    theme.components = {
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              color: theme.palette.text.primary,
              // top: '-8px',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          variant: 'outlined',
          root: {
            backgroundColor: theme.palette.background.text,
            color: theme.palette.text.primary,
            textAlign: 'left',
            margin: `0px 0px ${theme.spacing(1)} 0px`,
            minWidth: '100%',
            '& .MuiOutlinedInput-input': {
              padding: '12px 14px',
            },
            '& .MuiFormLabel-root': {
              color: alpha(theme.palette.text.primary, 0.6),
            },
            // marginTop: theme.spacing(1),
            // marginBottom: theme.spacing(1),
            // paddingLeft: theme.spacing(0),
            // paddingRight: theme.spacing(0),
            // paddingTop: theme.spacing(0),
            // paddingBottom: theme.spacing(0),
            // marginLeft: theme.spacing(0),
            // marginRight: theme.spacing(0),
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {},
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            // padding: theme.spacing(0),

            color: theme.palette.text.primary,
            textAlign: 'left',
            margin: theme.spacing(0),
            // margin: `0px 0px ${theme.spacing(1)} 0px !important`,
            minWidth: '100%',
            '& .MuiOutlinedInput-root': {
              paddingRight: '0px !important',
            },
            '& .MuiAutocomplete-input': {
              padding: '12px 14px !important',
              backgroundColor: theme.palette.background.text,
            },
            '& .MuiFormLabel-root': {
              color: alpha(theme.palette.text.primary, 0.6),
            },
          },
          inputRoot: {
            padding: theme.spacing(0),
          },
          hasPopupIcon: {
            // padding: theme.spacing(0),
          },
          MuiFormControl: {
            root: {
              MuiTextField: {
                root: {
                  margin: theme.spacing(0),
                },
              },
            },
          },
          MuiOutlinedInput: {
            root: {
              padding: theme.spacing(0),
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          MuiOutlinedInput: {
            root: {
              padding: theme.spacing(0),
            },
          },

          MuiFormControl: {
            root: {
              MuiSelect: {
                root: {
                  color: alpha(theme.palette.text.primary, 0.6),
                },
              },
            },
          },
          select: {
            padding: '12px 14px',
            backgroundColor: theme.palette.background.text,
            // marginLeft: theme.spacing(0),
            // paddingLeft: theme.spacing(1),
            // paddingRight: theme.spacing(1),
            // paddingTop: theme.spacing(2),
            // paddingBottom: theme.spacing(2.5),
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            // marginLeft: theme?.spacing(0),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            width: '100%',
            textTransform: 'none',
            fontSize: theme.typography.body2.fontSize,
          },
        },
      },
      TableCell: {
        styleOverrides: {
          variant: 'InnerTable',
          root: {
            color: 'white',
          },
        },
      },
    };
    // console.log('theme2: ', theme);

    theme = responsiveFontSizes(theme);

    return theme;
  };
};

export default {
  themeTypes,
  formatThemeToForm,
  formatThemeFromForm,
  useGetThemeByName,
};

/*

   


 MuiAutocomplete: {
        root: {
          padding: theme.spacing(0),
        },
        inputRoot: {
          padding: theme.spacing(0),
        },
        hasPopupIcon: {
          padding: theme.spacing(0),
        },
        MuiOutlinedInput: {
          root: {
            padding: theme.spacing(0),
          },
        },
      },
      */
