export const DEFAULT_DURATION_MS = 600000; // 10 mins
export const DEFAULT_KEY = 'cacheUntil';
export const INVALIDATE_CACHE = '@@redux-cache/INVALIDATE_CACHE';

const defaultAccessStrategy = (state, reducerKey, cacheKey) => {
  return (
    state &&
    state[reducerKey] &&
    state[reducerKey][DEFAULT_KEY] &&
    state[reducerKey][DEFAULT_KEY][cacheKey]
  );
};

export const checkCacheValid = (
  getState,
  reducerKey,
  cacheKey = DEFAULT_KEY,
  args = {},
) => {
  const { accessStrategy = defaultAccessStrategy } = args;

  const state = getState();

  const cacheUntil = accessStrategy(state, reducerKey, cacheKey);

  const currentTime = Date.now();

  return !!cacheUntil && cacheUntil > currentTime;
};

export const generateCacheTTL = (duration = DEFAULT_DURATION_MS) => {
  return Date.now() + duration;
};

export default {
  checkCacheValid,
  generateCacheTTL,
};
