export default theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'row',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  alert: {
    borderWidth: theme.shape.borderWidth,
  },
});
