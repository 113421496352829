import { gql } from 'graphql-request';

const SUBMIT_BUTTON_FIELDS = gql`
  fragment submitButton on ComponentWizardComponentsSubmitButton {
    SubmitButton_slug: slug
    SubmitButton_title: title
    SubmitButton_size: size
    SubmitButton_color: color
    SubmitButton_actionType: actionType
    SubmitButton_redirectUrl: redirectUrl
    SubmitButton_variant: variant
    SubmitButton_positionNo: positionNo
    SubmitButton_layoutPosition: layoutPosition
    SubmitButton_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    SubmitButton_fullWidth: fullWidth
    SubmitButton_apiCalls: apiCalls {
      ...formApiCall
    }
    SubmitButton_tooltip: tooltip
    SubmitButton_isConditionalComponent: isConditionalComponent
    SubmitButton_conditionalValue: conditionalValue
    SubmitButton_conditionalKey: conditionalKey
    SubmitButton_conditionalOperator: conditionalOperator
    SubmitButton_conditionalFieldType: conditionalFieldType
    SubmitButton_conditionalAction: conditionalAction
    SubmitButton_disable: disable
    SubmitButton_show: show
    SubmitButton_successTitle: successTitle
    SubmitButton_successMessage: successMessage
    SubmitButton_title_en: title_en
    SubmitButton_title_nl: title_nl
    SubmitButton_tooltip_en: tooltip_en
    SubmitButton_tooltip_nl: tooltip_nl
    SubmitButton_useStaleStore: useStaleStore
    SubmitButton_modalSize: modalSize
    SubmitButton_name: name
    SubmitButton_staticValue: staticValue
    SubmitButton_reducerKeyValue: reducerKeyValue
    SubmitButton_conditions: conditions {
      ...condition
    }
  }
`;

// export default SUBMIT_BUTTON_FIELDS;
// exports.default = SUBMIT_BUTTON_FIELDS;
export default SUBMIT_BUTTON_FIELDS;
