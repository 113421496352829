import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch, useStore } from 'react-redux';
import axios from 'axios';
import {
  TextField,
  Autocomplete,
  IconButton,
  CircularProgress,
  Container,
  Button,
  Menu,
  MenuItem,
  Icon,
  Tooltip,
  Box,
  Typography,
  useMediaQuery,
  Divider,
  Popover,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Tune as TuneIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './style';
import { resetTempState, updateTempState } from '../../../actions/temp';
import { resetInsurance } from '../../../actions/portfolio';
import { resetClaims } from '../../../actions/claims';
import {
  toTitleCase,
  createRelativeUrl,
  displayErrors,
  getRef,
  setRef,
  sourceTextFromData,
  languageTranslation,
} from '../../../utils/functions';
import routes from '../../../utils/variables/routes';
import { useRouter, useSmall } from '../../../hooks';
import FilterModal from '../../modal/filterModal';
import {
  insuranceIndexName,
  domain as domains,
  clientPoliciesIndexName,
  claimsIndexName,
} from '../../../utils/variables';

const useStyles = makeStyles(styles);

const redirectDisplayType = 'redirect';
const dropdownDisplayType = 'dropdown';

const apiCallUrlBuilder = (apiCalls, state) => {
  const urls = apiCalls?.map(apiCall => {
    const {
      params,
      domain,
      url,
      resultsHeading,
      resultsTitleKey,
      resultsDescriptionKey,
      resultsLinkKey,
      resultsLinkTarget,
    } = apiCall;

    const finalParams = {};
    const result = params?.map(param => {
      const refValue = getRef(state, param?.value);
      // console.log('param: ', param);
      // console.log('refValue: ', refValue);
      finalParams[param?.key] = refValue || param?.value;
    });
    // console.log('finalParams: ', finalParams);

    const relativeUrl = createRelativeUrl({
      path: url,
      queryObject: finalParams,
    });

    return {
      resultsHeading,
      resultsTitleKey,
      resultsDescriptionKey,
      resultsLinkKey,
      resultsLinkTarget,
      url: relativeUrl,
      domain: domains[domain],
    };
  });
  return urls;
};

const SearchBox = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { query } = router;
  // console.log('query: ', query);
  const searchRef = useRef(null);
  const divRef = useRef(null);
  const isSmall = useSmall();
  const storeState = useStore()?.getState();
  const filterOpen = useSelector(state => state.temp.filterOpen);
  const filterOptions = useSelector(state => state.temp.filterOptions);
  const searchOptions = useSelector(state => state.temp.searchOptions);
  // console.log('searchOptions: ', searchOptions);
  const defaultSearchType = useSelector(state => state.temp.defaultSearchType);
  // console.log('defaultSearchType: ', defaultSearchType);
  const [searchType, setSearchType] = useState(defaultSearchType);
  const [searchValue, setSearchValue] = useState(query?.search);
  const [modalSearchOpen, setModalSearchOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const classes = useStyles({ hasFilter: !!filterOptions });
  // console.log('searchValue: ', searchValue);
  const [loading, setLoading] = useState(false);

  const [resultOptions, setResultOptions] = useState([]);
  // console.log('resultOptions: ', resultOptions);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const language = storeState?.locations?.language || 'en';

  const currentSearchType = searchOptions?.find(option => option?.value === searchType);

  const openMenu = Boolean(menuAnchorEl);
  const handleMenuClick = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (event, index) => {
    // console.log('event: ', event);
    if (event.target.id) {
      setSearchType(event.target.id);
    }
    setMenuAnchorEl(null);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  /*
  const dispatch = useDispatch();
  useEffect(() => {
    if (products.length === 0 && !router.pathname.startsWith(routes.products.index)) {
      dispatch(getProducts.getProductsAction());
    }
  }, []);
  */

  function fetchData({
    url,
    resultsHeading,
    domain,
    resultsTitleKey,
    resultsDescriptionKey,
    resultsLinkKey,
    resultsLinkTarget,
  }) {
    return axios
      .get(url, { baseURL: domain })
      .then(async function(response) {
        const { data } = await languageTranslation({ data: response.data }, language);
        return {
          success: true,
          data,
          resultsHeading,
          resultsTitleKey,
          resultsDescriptionKey,
          resultsLinkKey,
          resultsLinkTarget,
        };
      })
      .catch(function(error) {
        return { success: false };
      });
  }

  function getAllData(myURLs) {
    return Promise.all(myURLs.map(fetchData));
  }

  useEffect(() => {
    if (!searchType) {
      setSearchType(defaultSearchType);
    }
  }, [defaultSearchType]);

  const handleChange = event => {
    // console.log('value: ', value);
    const { value } = event.target;
    divRef.current.focus();
    setSearchValue(value);
    setIsSubmitted(false);
  };

  const handleSearch = () => {
    // console.log('searchType: ', searchType);
    // console.log('currentSearchType: ', currentSearchType);
    if (
      filterOptions?.indexName === insuranceIndexName ||
      filterOptions?.indexName === clientPoliciesIndexName
    ) {
      dispatch(resetInsurance.resetInsuranceAction());
    }
    if (filterOptions?.indexName === claimsIndexName) {
      dispatch(resetClaims.resetClaimsAction());
    }
    setIsSubmitted(true);

    if (currentSearchType?.displayType === redirectDisplayType) {
      const params = {
        ...query,
        [currentSearchType?.searchKey || 'search']: searchValue,
      };
      if (!searchValue) {
        delete params[currentSearchType?.searchKey || 'search'];
      }
      // console.log('params: ', params);
      const relativeUrl = createRelativeUrl({
        path:
          currentSearchType?.redirectUrl && currentSearchType?.redirectUrl !== 'self'
            ? currentSearchType?.redirectUrl
            : router.pathname,
        queryObject: params,
      });
      // console.log('relativeUrl: ', relativeUrl);
      router.push(relativeUrl);
    } else if (currentSearchType?.displayType === dropdownDisplayType) {
      if (searchValue) {
        const apiCalls = currentSearchType?.apiCalls;
        if (apiCalls && apiCalls.length > 0) {
          setLoading(true);

          storeState.searchTextValue = searchValue;
          // console.log('storeState: ', storeState);
          const URLs = apiCallUrlBuilder(apiCalls, storeState);
          // console.log('URLs: ', URLs);
          if (URLs && URLs.length > 0) {
            getAllData(URLs)
              .then(resp => {
                // console.log('resp: ', resp);
                // console.log(resp);
                const finalOptions = [];
                resp.forEach(
                  ({
                    resultsHeading,
                    resultsTitleKey,
                    resultsDescriptionKey,
                    resultsLinkKey,
                    resultsLinkTarget,
                    data,
                    success,
                  }) => {
                    if (success) {
                      data?.map(item => {
                        finalOptions.push({
                          ...item,
                          resultsHeading,
                          title: item[resultsTitleKey],
                          description: item[resultsDescriptionKey],
                          link: sourceTextFromData(item, resultsLinkKey),
                          target: resultsLinkTarget,
                        });
                      });
                    }
                  },
                );
                setResultOptions(finalOptions);

                setLoading(false);
              })
              .catch(e => {
                // console.log(e);
                setLoading(false);
              });
          } else {
            setLoading(false);
            displayErrors(
              {
                message: 'No URLs available',
              },
              classes.toastrSM,
            );
          }
        } else {
          setLoading(false);
          displayErrors(
            {
              message: 'No apiCalls available',
            },
            classes.toastrSM,
          );
        }
      } else {
        setLoading(false);
        displayErrors(
          {
            message: 'No Search Value specified',
          },
          classes.toastrSM,
        );
      }
    } else {
      setLoading(false);
      displayErrors(
        {
          message: 'No search display available',
        },
        classes.toastrSM,
      );
    }
  };

  const openFilter = () => {
    dispatch(updateTempState.updateTempStateAction({ filterOpen: true }));
  };

  const clearFilter = () => {
    if (
      filterOptions?.indexName === insuranceIndexName ||
      filterOptions?.indexName === clientPoliciesIndexName
    ) {
      dispatch(resetInsurance.resetInsuranceAction());
    }
    if (filterOptions?.indexName === claimsIndexName) {
      dispatch(resetClaims.resetClaimsAction());
    }
    const newQuery = {
      ...query,
    };
    delete newQuery.search;

    if (filterOptions?.showFilters) {
      // console.log('filterOptions.showFilters: ', filterOptions?.showFilters);
      const filterKeys = Object.keys(filterOptions?.showFilters).map(key => {
        if (filterOptions?.showFilters[key]) {
          if (key === 'date') {
            delete newQuery.startDate;
            delete newQuery.endDate;
          }
          delete newQuery[key];
        }
      });
    }
    const relativeUrl = createRelativeUrl({
      path: router.pathname,
      queryObject: { ...newQuery },
    });
    router.push(relativeUrl);
  };

  const handleSelectedOption = selectedValue => {
    // console.log('selectedValue: ', selectedValue);
    /*
    if (selectedValue != null) {
      const type = selectedValue?.type?.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
      if (type === 'community-connections') {
        router.push(processRoute(`/news/${selectedValue?.slug}`));
      } else if (type === 'downloads' || type === 'awards') {
        window.open(
          `${selectedValue?.file?.url || selectedValue?.featuredImage?.source?.url}`,
        );
      } else if (type === 'locations') {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${selectedValue?.latitude},${selectedValue?.longitude}&z=3`,
        );
      } else if (type === 'home') {
        router.push(processRoute(`/home/`));
      } else router.push(processRoute(`/${type}/${selectedValue?.slug}`));
    }
    */
  };

  const noResults =
    searchValue && isSubmitted ? (
      <Typography sx={{ color: 'text.primary' }}>No Results Found</Typography>
    ) : searchValue && !isSubmitted ? (
      <Typography sx={{ color: 'text.primary' }}>
        Press Enter or click the Search Icon to start searching.
      </Typography>
    ) : (
      <Typography sx={{ color: 'text.primary' }}>Enter a Search Text</Typography>
    );

  const loadingText = (
    <Typography sx={{ color: 'text.primary' }}>Fetching Results...</Typography>
  );

  const renderOption = (element, option) => {
    // console.log('option: ', option);
    return (
      <Box
        display="flex"
        flexDirection="column"
        sx={{ m: 1, ml: 2, p: 1, textDecoration: 'none' }}
        component={option?.target === '_self' ? RouterLink : 'a'}
        to={option?.target === '_self' && option?.link}
        href={option?.target !== '_self' && option?.link}
        target={option?.target || '_self'}
        className={classes.resultOption}
      >
        {option?.title && (
          <Typography variant="body1" component="p" sx={{ color: 'text.primary' }}>
            {option?.title}
          </Typography>
        )}
        {option?.title && option?.description && (
          <>
            <Typography
              sx={{ color: 'text.primary' }}
              variant="subtitle2"
              component="p"
              className={classes.lineClamp}
            >
              {option?.description}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const renderGroup = group => [
    <Box
      sx={{
        color: 'text.primary',
        backgroundColor: 'background.paper',
        padding: '8px',
        fontSize: '20px',
        fontWeight: 'bold',
        paddingBottom: '0px',
      }}
    >
      {group}
      <Divider />
    </Box>,
  ];

  const renderGroups = params => [renderGroup(params.group), params.children];

  let hasSearchParamsToClear = false;
  let activeKeys = [];
  if (filterOptions?.showFilters) {
    const filterKeys = Object.keys(filterOptions?.showFilters).map(key => {
      const newQuery = {
        ...query,
        date: query?.startDate || query?.endDate,
      };
      // console.log('newQuery: ', newQuery);
      return filterOptions?.showFilters[key] && newQuery[key];
    });

    activeKeys = filterKeys.filter(x => x);
  }
  // console.log('filterKeys: ', activeKeys);
  hasSearchParamsToClear = activeKeys.length > 0 || query.search;

  const handleModalSearchOpen = event => {
    setModalSearchOpen(event.currentTarget);
  };

  const handleModalSearchClose = () => {
    setModalSearchOpen(null);
  };

  const searchContent = (
    <div ref={divRef} className={classes.root}>
      {searchOptions && (
        <Autocomplete
          ref={searchRef}
          // blurOnSelect
          disableClearable={false}
          onChange={(event, newValue) => {
            handleSelectedOption(newValue);
          }}
          openOnFocus={currentSearchType?.displayType === dropdownDisplayType}
          loading={loading}
          noOptionsText={noResults}
          loadingText={loadingText}
          ListboxProps={{ style: { maxHeight: isSmall ? '80vh' : '80vh' } }}
          renderGroup={renderGroups}
          renderOption={renderOption}
          clearOnBlur
          blurOnSelect
          inputValue={searchValue}
          // open={!!resultOptions}
          options={resultOptions}
          getOptionLabel={option => option?.title}
          className={classes.navSearchBox}
          classes={{
            clearIndicator: classes.clearIndicator,
          }}
          groupBy={option => option?.resultsHeading}
          renderInput={params => (
            <TextField
              {...params}
              // label="  Search..."
              fullWidth
              variant="outlined"
              sx={{ marginBottom: 0 }}
              onChange={(event, newInputValue) => {
                handleChange(event, newInputValue);
              }}
              value={searchValue}
              // className={classes.textField}
              placeholder={currentSearchType?.placeholder}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Box sx={{ pr: 1 }}>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <Tooltip title="Click to begin search.">
                        <div>
                          <IconButton
                            className={classes.searchIcon}
                            aria-label="Search"
                            onClick={handleSearch}
                          >
                            <SearchIcon sx={{ color: 'text.main' }} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    )}
                    {/* params.InputProps.endAdornment */}
                  </Box>
                ),
                startAdornment: (
                  <>
                    <Tooltip title="Click to change the search type.">
                      <Button
                        id="basic-button"
                        aria-controls={openMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleMenuClick}
                        sx={{ width: 'auto' }}
                        aria-label="Search Type"
                      >
                        <Icon sx={{ color: 'text.main' }}>
                          {currentSearchType?.icon || 'home'}
                        </Icon>
                      </Button>
                    </Tooltip>

                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          // renderOption={option => <>{option?.title}</>}
        />
      )}
      {filterOptions && (
        <Tooltip title="Filters">
          <div>
            <IconButton className={classes.button} aria-label="Tune" onClick={openFilter}>
              <TuneIcon
                sx={{
                  color: filterOpen ? 'primary.main' : 'text.main',
                }}
              />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {filterOptions && hasSearchParamsToClear && (
        <Tooltip title="Clear Filters">
          <div>
            <IconButton
              className={classes.button}
              aria-label="Clear"
              onClick={clearFilter}
            >
              <ClearIcon
                sx={{
                  color: filterOpen ? 'primary.main' : 'text.main',
                }}
              />
            </IconButton>
          </div>
        </Tooltip>
      )}
      <Menu
        sx={{ mt: 2 }}
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {searchOptions &&
          searchOptions?.map((option, index) => (
            <MenuItem
              key={option?.value}
              id={option?.value}
              selected={option?.value === searchType}
              onClick={event => handleMenuItemClick(event, index)}
              sx={{
                color: 'text.main',
                fontWeight: 'bold',
                my: 1,
              }}
            >
              <Icon sx={{ color: 'text.main', mr: 1 }}>{option?.icon || 'home'}</Icon>
              {option?.title}
            </MenuItem>
          ))}
      </Menu>
      {filterOptions && <FilterModal />}
    </div>
  );

  if (isSmall && searchOptions) {
    const open = Boolean(modalSearchOpen);
    const id = open ? 'simple-popover' : undefined;
    return (
      <>
        <Tooltip title="Click to open search box">
          <div>
            <IconButton
              className={classes.searchButton}
              aria-label="Search"
              onClick={handleModalSearchOpen}
              aria-describedby={id}
            >
              <SearchIcon sx={{ color: 'text.main' }} />
            </IconButton>
          </div>
        </Tooltip>
        <Popover
          sx={{ mt: 3 }}
          classes={{ paper: classes.popPaper }}
          id={id}
          open={open}
          anchorEl={modalSearchOpen}
          onClose={handleModalSearchClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 1 }}>{searchContent}</Box>
        </Popover>
      </>
    );
  }

  return <Container sx={{ px: 1 }}>{searchContent}</Container>;
};
//
export default SearchBox;
