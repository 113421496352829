import { routerGetCall } from '../../axios';

export const getInsuranceUrl = `/accounts/viewPortfolio/insurance`;

const getInsurances = (state, params) => {
  return routerGetCall(state, getInsuranceUrl, params);
};

const getSingleInsurance = (state, params) => {
  const url = `/accounts/policies/${params.trimPolicyId}`;
  return routerGetCall(state, url);
};

const getInsuranceFilterSettings = (state, params) => {
  const url = `/accounts/policies/summary`;
  return routerGetCall(state, url, params);
};

const getPoliciesSummary = (state, params) => {
  const url = `/accounts/policies/summary`;
  return routerGetCall(state, url, params);
};

export default {
  getInsurances,
  getInsuranceFilterSettings,
  getSingleInsurance,
  getPoliciesSummary,
};
