import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';

export const getAllHelpCenterCasesUrl = helpCenterApi('getAllHelpCenterCasesUrl');
const getAllHelpCenterCases = (state, body) => {
  const url = getAllHelpCenterCasesUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  throw new Error('Server Error');
};
export default { getAllHelpCenterCases };
