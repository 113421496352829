import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Grid,
  Popover,
  Paper,
  Icon,
  Typography,
  Button,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useRouter } from '../../../hooks';
import { resetInsurance } from '../../../actions/portfolio';
import {
  createRelativeUrl,
  dateToIsoString,
  dateToString,
} from '../../../utils/functions';
// import ClearFilter from '../../icons/clearFilter';
import styles from './style';
import Search from './search';
import Filter from './filter';
import {
  claimsIndexName,
  quotesIndexName,
  requestsIndexName,
  receiptsIndexName,
  insuredItemsIndexName,
  insuranceIndexName,
  clientPoliciesIndexName,
} from '../../../utils/variables';
import Spinner from '../spinner';
// import { getAppThemeName } from '../../../storage/themeName';

const useStyles = makeStyles(styles);

const SearchAndFilter = ({
  title,
  isSearch = true,
  indexName = insuranceIndexName,
  filterAction,
  filterDefaultOptions,
  hostname,
  filterActionClose,
  showFilter,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  let insuranceFilterSettings = useSelector(
    state => state?.insurance?.insuranceFilterSettings,
  );
  // console.log('filterDefaultOptions: ', filterDefaultOptions);
  const isGetInsuranceFilterSettingsPending = useSelector(
    state => state?.insurance?.isGetInsuranceFilterSettingsPending,
  );
  const query = router?.query;
  const [anchorEl, setAnchorEl] = useState(null);
  const [lob, setLob] = useState(filterDefaultOptions?.lob);
  const [currency, setCurrency] = React.useState(filterDefaultOptions?.currency);
  const [status, setStatus] = useState(filterDefaultOptions?.status);
  const [startDate, setStartDate] = useState(filterDefaultOptions?.startDate);
  const [endDate, setEndDate] = useState(filterDefaultOptions?.endDate);
  const [showClearFilter, setShowClearFilter] = useState(false);

  if (indexName !== insuranceIndexName) {
    insuranceFilterSettings = null;
  }
  if (insuranceFilterSettings && insuranceFilterSettings?.currencies.length > 0) {
    const filteredCurrencies = insuranceFilterSettings?.currencies || [];
    /*
    filterDefaultOptions?.currencies?.filter(cls =>
      insuranceFilterSettings?.currencies.includes(cls),
    );
    */
    if (filteredCurrencies && filterDefaultOptions)
      filterDefaultOptions.currencies = filteredCurrencies;
  }
  if (insuranceFilterSettings && insuranceFilterSettings?.statuses.length > 0) {
    const filteredStatuses = insuranceFilterSettings?.statuses || [];
    /*
      filterDefaultOptions?.statuses?.filter(cls =>
      insuranceFilterSettings?.statuses.includes(cls.status),
    );
    */
    if (filteredStatuses && filterDefaultOptions)
      filterDefaultOptions.statuses = filteredStatuses;
  }
  if (insuranceFilterSettings && insuranceFilterSettings?.policyTypes.length > 0) {
    const filteredPolicyTypes = filterDefaultOptions?.lobTypes?.filter(cls =>
      insuranceFilterSettings?.policyTypes.includes(cls.lob),
    );
    if (filteredPolicyTypes && filterDefaultOptions)
      filterDefaultOptions.lobTypes = filteredPolicyTypes;
  }
  let filterDateType = 'Date Range';
  if (indexName === quotesIndexName) {
    filterDateType = 'Created Date Range';
  }
  if (indexName === claimsIndexName) {
    filterDateType = 'Date Range';
  }
  if (indexName === requestsIndexName) {
    filterDateType = 'Created Date Range';
  }
  if (indexName === receiptsIndexName) {
    filterDateType = 'Paid Date Range';
  }
  if (indexName === insuredItemsIndexName) {
    filterDateType = 'Added On Date Range';
  }
  if (indexName === insuranceIndexName) {
    filterDateType = 'Date Range';
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // router.storePush(`${filterRoute}?lob=${category}&status=${status}`);
    dispatch(resetInsurance.resetInsuranceAction());
    let stringStartDate = null;
    let stringEndDate = null;
    if (indexName === insuranceIndexName || indexName === clientPoliciesIndexName) {
      stringStartDate = dateToIsoString(startDate);
      stringEndDate = dateToIsoString(endDate);
    } else {
      stringStartDate = dateToString(startDate);
      stringEndDate = dateToString(endDate);
    }
    const parsed = {
      ...query,
      search:
        filterDefaultOptions.search && filterDefaultOptions.search.length > 0
          ? filterDefaultOptions?.search
          : undefined,
      lob: lob && lob.length > 0 ? lob.toString() : undefined,
      status: status && status.length > 0 ? status.toString() : undefined,
      startDate:
        stringStartDate && stringStartDate.length > 0 ? stringStartDate : undefined,
      endDate: stringEndDate && stringEndDate.length > 0 ? stringEndDate : undefined,
      startIndex: 0,
      currency: currency && currency.length > 0 ? currency.toString() : undefined,
      reload: true,
      dontReload: false,
    };

    // console.log('parsed: ', parsed);
    // console.log('parsed close: ', parsed);
    const relativeUrl = createRelativeUrl({ path: router.pathname, queryObject: parsed });
    router.push(relativeUrl);
    setAnchorEl(null);
  };

  const handleChange = value => {
    // console.log('value: ', value);
    setLob(value.lob);
    setCurrency(value.currency);
    setStatus(value.status);
    setStartDate(value.startDate);
    setEndDate(value.endDate);
  };

  const clearFilter = () => {
    setAnchorEl(null);
    dispatch(resetInsurance.resetInsuranceAction());
    setShowClearFilter(false);
    const relativeUrl = createRelativeUrl({ path: router.pathname, queryObject: {} });
    router.push(relativeUrl);
  };

  useEffect(() => {
    const queryParams = router.query;
    if (
      (queryParams?.lob !== undefined && queryParams?.lob?.length > 0) ||
      (queryParams?.currency !== undefined && queryParams?.currency?.length) > 0 ||
      (queryParams?.status !== undefined && queryParams?.status?.length > 0) ||
      (queryParams?.startDate !== undefined && queryParams?.startDate?.length > 0) ||
      (queryParams?.endDate !== undefined && queryParams?.endDate?.length > 0) ||
      (queryParams?.assetType !== undefined && queryParams?.assetType?.length > 0)
    ) {
      setShowClearFilter(true);
    } else {
      setShowClearFilter(false);
    }
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  /*
  useEffect(() => {
    if (indexName === insuranceIndexName && open) {
      dispatch(getInsuranceFilterSettings.getInsuranceFilterSettingsAction());
    }
  }, [open]);
  */

  return (
    <>
      <Grid
        container
        justifyContent="center"
        align="center"
        alignItems="center"
        className={classes.searchContainer}
        style={{ height: showClearFilter ? '' : '100%' }}
      >
        <Grid item xs={12} sm={10}>
          <Grid item xs={12} align="left" className={classes.containerText}>
            {title}
          </Grid>
          <Grid container align="center" spacing={2}>
            {isSearch ? <Search hostname={hostname} /> : null}
            {showFilter ||
            (filterDefaultOptions?.lobTypes &&
              filterDefaultOptions?.lobTypes.length > 0) ||
            (filterDefaultOptions?.statuses &&
              filterDefaultOptions?.statuses.length > 0) ? (
              <>
                <Grid item xs={2} sm={2} align="center">
                  <IconButton className={classes.filter} onClick={handleClick}>
                    <Icon color={open ? 'inherit' : 'primary'}>filter_alt</Icon>
                  </IconButton>
                </Grid>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={filterActionClose || handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className={classes.popover}
                >
                  <Paper elevation={0} className={classes.root}>
                    {isGetInsuranceFilterSettingsPending ? (
                      <div className={classes.alignCenter}>
                        <Spinner message="Loading Filters" />
                      </div>
                    ) : (
                      <Grid container direction="row" spacing={1}>
                        <Grid item xs={10} className={classes.gridItem} align="left">
                          <IconButton
                            className={classes.popoverFilter}
                            onClick={filterActionClose || handleClose}
                          >
                            <MenuIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={2} className={classes.gridItem} align="right">
                          <IconButton
                            className={classes.popoverFilter}
                            onClick={filterActionClose || handleClose}
                          >
                            <Icon>filter_alt</Icon>
                          </IconButton>
                        </Grid>
                        {showClearFilter && (
                          <>
                            <Grid item xs={9} className={classes.gridItem} align="left">
                              {' '}
                            </Grid>
                            <Grid item xs={3} className={classes.gridItem} align="right">
                              <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={clearFilter}
                              >
                                Clear Filters
                              </Button>
                            </Grid>
                          </>
                        )}
                        {filterAction || (
                          <Filter
                            defaultOptions={filterDefaultOptions}
                            onChange={handleChange}
                            dateTitle={filterDateType}
                            indexName={indexName}
                          />
                        )}
                      </Grid>
                    )}
                  </Paper>
                </Popover>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {showClearFilter && (
        <Grid container className={classes.clearFilterContainer}>
          <Typography align="center" variant="body1">
            Displaying items based on your filter selection.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={clearFilter}
            className={classes.clearButton}
          >
            Clear All
          </Button>
        </Grid>
      )}
    </>
  );
};

export default SearchAndFilter;

/* <IconButton className={classes.iconClearFilter}>
                  <ClearFilter
                    viewBox="0 0 100.000000 100.000000"
                    className={classes.clearFilter}
                    backgroundColor={getAppThemeName() === "light" ? '#9C0059' : "#ffbc63"}
                    onClick={clearFilter}
                  />
                </IconButton> */

/*
 {indexName === insuranceIndexName ? (
     ) : (
                    filterAction
                  )}
<img src={filterIcon} height={25} />
*/
