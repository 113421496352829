import { gql } from 'graphql-request';

const ID_VERIFICATION_FIELDS = gql`
  fragment idVerification on ComponentWizardComponentsIdVerification {
    IDVerification_slug: slug
    IDVerification_positionNo: positionNo
    IDVerification_layoutPosition: layoutPosition
    IDVerification_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    IDVerification_name: name
    IDVerification_isConditionalComponent: isConditionalComponent
    IDVerification_conditionalValue: conditionalValue
    IDVerification_conditionalKey: conditionalKey
    IDVerification_conditionalOperator: conditionalOperator
    IDVerification_conditionalFieldType: conditionalFieldType
    IDVerification_conditionalAction: conditionalAction
    IDVerification_disable: disable
    IDVerification_show: show
    IDVerification_required: required
    IDVerification_disableInitialValue: disableInitialValue
    IDVerification_staticValue: staticValue
    IDVerification_reducerKeyValue: reducerKeyValue
    IDVerification_conditions: conditions {
      ...condition
    }
  }
`;

// export default ID_VERIFICATION_FIELDS;
// exports.default = ID_VERIFICATION_FIELDS;
export default ID_VERIFICATION_FIELDS;
