import {
  Card,
  CardContent,
  Grid,
  Icon,
  Link,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import React from 'react';
import styles from './style';
import { LobIcon } from '../../misc';
import { useSmall } from '../../../hooks';
import { Spinner } from '../../common';
import { getRef } from '../../../utils/functions';

const useStyles = makeStyles(styles);

function CategoryCard(props) {
  const { product, isAction, loading } = props;
  const { materialIcon, icon, customIcon, title, subtitle, onClick, href, lob } = product;
  // console.log('product: ', product);
  const isSmall = useSmall();
  const theme = useTheme();

  const lobCategories = useSelector(state => state.locations?.lobCategories);
  if (lob) {
    const lobObj = lobCategories.find(item => item.lob === lob);
    const { color } = lobObj || {};
    product.background = getRef(theme.palette, color);
    product.iconBackground = getRef(theme.palette, color);
  }

  const classes = useStyles({ ...props, isSmall });
  // console.log('Href in the product category card ', href);
  return (
    <Card
      className={classnames(
        classes.cardContainer,
        { [classes.lobHover]: !isAction },
        {
          [classes.actionHover]: isAction,
        },
      )}
      sx={{ boxShadow: 3 }}
      variant="outlined"
    >
      <Button
        component={RouterLink}
        to={href}
        className={classes.cardButton}
        onClick={onClick}
      >
        <Grid container>
          <Grid item xs={12} md={isAction ? 4 : 12} align="center">
            <LobIcon
              customIcon={customIcon}
              lob={lob}
              materialIcon={materialIcon}
              icon={icon}
              className={classes.iconContainer}
            />
          </Grid>
          <Grid xs={12} md={isAction ? 8 : 12} item className={classes.textContainer}>
            {title && <Typography className={classes.titleStyles}>{title}</Typography>}
            {subtitle && (
              <>
                {loading ? (
                  <Spinner />
                ) : (
                  <Typography className={classes.subTitleStyles}>{subtitle}</Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Button>
    </Card>
  );
}

export default CategoryCard;
