const filter = require('lodash.filter');

const getBackgroundImage = ({ yourLocation, slug = 'home' }) => {
  const alpha2 = yourLocation?.alpha2;
  const appBackgroundImages = yourLocation?.appBackgroundImages || [];

  const filterBackgroundImages = filter(appBackgroundImages, {
    territories: [{ alpha2 }],
    slug,
  });

  const backgroundImage =
    filterBackgroundImages &&
    filterBackgroundImages.length > 0 &&
    filterBackgroundImages[0];

  return backgroundImage;
};

export default getBackgroundImage;
