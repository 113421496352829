import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import initialLocaleData from '../localeData.json';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
  // require('@formatjs/intl-pluralrules/dist/locale-data/fr'); // Add locale data for de
  // require('@formatjs/intl-pluralrules/dist/locale-data/es');
  // require('@formatjs/intl-pluralrules/dist/locale-data/zh'); // Add locale data for de
  require('@formatjs/intl-pluralrules/locale-data/nl');
  // require('@formatjs/intl-pluralrules/dist/locale-data/ar');
  // require('@formatjs/intl-pluralrules/dist/locale-data/pt');
  // require('@formatjs/intl-pluralrules/dist/locale-data/hi');
}
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en');
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/zh');
  require('@formatjs/intl-relativetimeformat/locale-data/nl');
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/ar');
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/hi');
}

const LocalizationWrapper = ({ children, language, alpha2 }) => {
  const c360LocaleData =
    useSelector(state => state?.locations?.c360LocaleData) || initialLocaleData;
  const customLangAlpha2 = useSelector(
    state => state?.locations?.yourLocation?.customLangAlpha2,
  );
  if (customLangAlpha2) {
    alpha2 = customLangAlpha2;
  }
  // Try full locale, fallback to locale without region code, fallback to en
  const messages = c360LocaleData[`${language}-${alpha2}`] || c360LocaleData.en; // c360LocaleData[language] || c360LocaleData.en;
  // console.log('messages: ', messages);
  // // console.log('language: ', language);

  const languageCountry = (language && alpha2 && `${language}-${alpha2}`) || 'en';

  const myError = () => {
    // console.log('Add error to logs');
  };

  return messages ? (
    <IntlProvider
      defaultLocale="en"
      locale={languageCountry}
      messages={messages}
      onError={myError}
    >
      {children}
    </IntlProvider>
  ) : null;
};
export default LocalizationWrapper;
