import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { buyersGuideImage } from '../../../utils/variables/images';
import routes from '../../../utils/variables/routes';
import styles from './style';
import { useSmall } from '../../../hooks';

const useStyles = makeStyles(styles);

function BuyersGuide() {
  const classes = useStyles();
  const isSmall = useSmall();
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={3} align="center">
        {isSmall ? <></> : <img style={{ height: '120px' }} src={buyersGuideImage} />}
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'text.dark' }} align="justify">
              Do you need more protection? Don't worry we've got you covered.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="stretch" spacing={1}>
              {/* <Grid item xs={6} sx={{ height: 'auto' }}>
             <Button
              sx={{ textTransform: 'none', height: '100%' }}
              className={classes.primaryButton}
              onClick={() => alert('This feature is still to be built.')}
            >
              Buyer's Guide
            </Button>
           
          </Grid> */}
              <Grid item xs={12} md={6} sx={{ height: 'auto' }}>
                <Button
                  sx={{ textTransform: 'none', height: '100%' }}
                  className={classes.secondaryButton}
                  component={RouterLink}
                  to={routes.products.index}
                >
                  View Shop <Icon>trending_flat</Icon>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BuyersGuide;
