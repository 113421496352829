import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_CLIENTSPOLICIES_API_REQUEST,
  GET_CLIENTSPOLICIES_API_SUCCESS,
  GET_CLIENTSPOLICIES_API_ERROR,
  GET_NEXT_CLIENTSPOLICIES_SUCCESS,
} from '../../constants/clientsPoliciesActionNames';
import {
  RENEW_POLICY_API_SUCCESS,
  AMEND_POLICY_API_SUCCESS,
} from '../../constants/renewalActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';
import {
  ADD_RENEWAL_TO_CART_API_SUCCESS,
  PAY_NOW_API_SUCCESS,
  PAY_FIRST_PREMIUM_API_SUCCESS,
  MAKE_FAC_PAYMENT_API_SUCCESS,
} from '../../constants/cartActionNames';

import { RESET_INSURANCE } from '../../constants/portfolio/insuranceActionNames';

import { RESET_INFINITE_SCROLL_SUCCESS } from '../../constants/miscActionNames';

import payloadData from './payloadData';
import {
  uuidv4,
  dateToString,
  isoStringToDate,
  stringQuoteToDate,
  getAllKeys,
  getKeys,
  uniq,
} from '../../utils/functions';
import { persistKey, statusOk } from '../../utils/variables';

import { createPolicies } from '../portfolio/insurance';
// .slice(0, loadClientsCount)
let searchKeys = [];

const createPayload = (payloadArray, startIndex = 0, lobTypes) => {
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const newClientPolicies = createPolicies(payloadArray, lobTypes);
  newClientPolicies.map((clientPolice, index) => {
    const newKeys = getKeys(clientPolice);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    return clientPolice;
  });

  newClientPolicies.sort((a, b) => {
    if (a.canRenewStatusNumber === b.canRenewStatusNumber) {
      // Price is only important when cities are the same
      return b.statusNumber - a.statusNumber ? 1 : -1;
    }
    return b.canRenewStatusNumber > a.canRenewStatusNumber ? 1 : -1;
  });
  // newClientPolicies.sort((a, b) => {
  //     return b.slug > a.slug ? 1 : -1;
  // });

  return newClientPolicies;
};

export const initialState = {
  selectedClient: null,
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys,
  [persistKey]: null,
  total: {},
  [DEFAULT_KEY]: null,
};
// createPayload(payloadData, 0)

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ clientPolicies: payloadData, count: 100000 }] };
};

function clientPoliciesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTSPOLICIES_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_CLIENTSPOLICIES_API_SUCCESS: {
      const startIndex = state?.allRows?.length;
      const lobTypes = action?.lobTypes;
      const rowPayload = createPayload(
        action?.payload?.payload?.result?.data,
        startIndex,
        lobTypes,
      );
      const newRows = rowPayload;
      const body = action?.body;
      const cacheKey = action?.cacheKey;
      // console.log('body: ', body);
      const newTotal = action?.payload?.payload?.result?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;
      const nowTime = new Date().getTime();

      const isReset = body?.reset || false;

      const incomingRows = newRows && Array.isArray(newRows) ? newRows : [];
      const oldRows =
        state?.allRows && Array.isArray(state?.allRows) ? state?.allRows : [];
      // if (lob && lob.length > 0) {
      //  oldClaims = oldClaims.filter(x => lob.some(y => x.lob === y));
      // }
      const existingRows = [...oldRows];
      // console.log('oldInsurances: ', oldInsurances);
      incomingRows.map(incomingRow => {
        // console.log('incomingRow: ', incomingRow);
        incomingRow.body = body;
        let index = 0;
        if (incomingRow.body.infiniteScroll) {
          index = existingRows.findIndex(
            oldRow =>
              oldRow.id === incomingRow.id &&
              oldRow.body.infiniteScroll === incomingRow.body.infiniteScroll &&
              oldRow.body.isMobile === incomingRow.body.isMobile &&
              oldRow.body.alpha2 === incomingRow.body.alpha2 &&
              oldRow.body?.lob?.toString() === incomingRow?.body?.lob?.toString(),
          );
        } else {
          index = existingRows.findIndex(
            oldRow =>
              oldRow.id === incomingRow.id &&
              oldRow.body.infiniteScroll === incomingRow.body.infiniteScroll &&
              oldRow.body.isMobile === incomingRow.body.isMobile &&
              oldRow.body.offset === incomingRow.body.offset &&
              oldRow.body.limit === incomingRow.body.limit &&
              oldRow.body.alpha2 === incomingRow.body.alpha2 &&
              oldRow.body?.lob?.toString() === incomingRow?.body?.lob?.toString(),
          );
        }

        // Replace item at index using native splice
        if (index !== -1) {
          existingRows.splice(index, 1, incomingRow);
        } else {
          existingRows.push(incomingRow);
        }
        return null;
      });
      // console.log('existingRows: ', existingRows);
      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[body?.alpha2] = newTotal;

      return {
        ...state,
        isPending: false,
        isSuccess: true,
        isError,
        response, // newPortfolio,
        searchKeys,
        allRows: existingRows,
        count,
        // insuranceCollections,
        [persistKey]: nowTime,
        firstTime: false,
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }

    case GET_CLIENTSPOLICIES_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action.payload,
      };

    case GET_NEXT_CLIENTSPOLICIES_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    case RENEW_POLICY_API_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: null,
        [persistKey]: null,
      };

    case ADD_RENEWAL_TO_CART_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
      };
    case AMEND_POLICY_API_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: null,
        [persistKey]: null,
      };
    case PAY_NOW_API_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: null,
        [persistKey]: null,
      };

    case PAY_FIRST_PREMIUM_API_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: null,
        [persistKey]: null,
      };

    case MAKE_FAC_PAYMENT_API_SUCCESS:
      return {
        ...state,
        [DEFAULT_KEY]: null,
        [persistKey]: null,
      };

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        total: 0,
        allRows: initialState.allRows,
        [persistKey]: null,
      };
    }

    case RESET_INSURANCE: {
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_KEY]: null,
        allRows: [],
      };
    }

    default:
      return state;
  }
}

export default clientPoliciesReducer;
