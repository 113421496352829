import { gql } from 'graphql-request';

const NON_RECURSIVE_OPTION_FIELDS = gql`
  fragment nonRecursiveOption on ComponentMiscComponentsOptions {
    title
    title_en
    title_nl
    secondaryTitle
    secondaryTitle_en
    secondaryTitle_nl
    value
    startIcon
    position
    sampleData
    isConditional
    conditionalKey
    conditionalValue
    conditionalOperator
    conditionalFieldType
    secondaryTitleIsNumeric
    secondaryTitleIsCurrency
    secondaryTitleIsAbbrev
    secondaryTitleIsLocalize
    secondaryTitleIsPercent
    titleIsNumeric
    titleIsCurrency
    titleIsAbbrev
    titleIsLocalize
    titleIsPercent
    secondaryUnits
    primaryUnits
    key
    conditionalAction
    conditionalArrayKey
    hidden
    content
    lob
    secondaryTitleItemFromArrayKey
    secondaryTitleItemFromArrayValue
    secondaryTitleReferenceKey
    externalLink
    extraSettings
  }
`;
/*
    
    */
// export default OPTION_FIELDS;
// exports.default = NON_RECURSIVE_OPTION_FIELDS;
export default NON_RECURSIVE_OPTION_FIELDS;
