export const GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST =
  'GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST';
export const GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS =
  'GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS';
export const GET_DIGITAL_SALES_APPLICATIONS_API_ERROR =
  'GET_DIGITAL_SALES_APPLICATIONS_API_ERROR';

export const GET_DIGITAL_SALES_APPLICATION_API_REQUEST =
  'GET_DIGITAL_SALES_APPLICATION_API_REQUEST';
export const GET_DIGITAL_SALES_APPLICATION_API_SUCCESS =
  'GET_DIGITAL_SALES_APPLICATION_API_SUCCESS';
export const GET_DIGITAL_SALES_APPLICATION_API_ERROR =
  'GET_DIGITAL_SALES_APPLICATION_API_ERROR';

export const DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST =
  'DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST';
export const DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS =
  'DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS';
export const DELETE_DIGITAL_SALES_APPLICATION_API_ERROR =
  'DELETE_DIGITAL_SALES_APPLICATION_API_ERROR';

export const GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS =
  'GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS';
