import { gql } from 'graphql-request';

const CARD_ITEMS_LIST_FIELDS = gql`
  fragment cardItemsList on ComponentWizardComponentsCardItemsList {
    CardItemsList_slug: slug
    CardItemsList_layoutPosition: layoutPosition
    CardItemsList_positionNo: positionNo
    CardItemsList_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    CardItemsList_isConditionalComponent: isConditionalComponent
    CardItemsList_conditionalValue: conditionalValue
    CardItemsList_conditionalKey: conditionalKey
    CardItemsList_conditionalOperator: conditionalOperator
    CardItemsList_conditionalFieldType: conditionalFieldType
    CardItemsList_conditionalAction: conditionalAction
    CardItemsList_cardTemplate: cardTemplate {
      title
      summaryDetails {
        reducerKeyValue
        staticValue
        valueColor
        valueVariant
        reducerKeyLabel
        staticLabel
        labelColor
        labelVariant
        isCurrency
      }
      listConfig {
        xs
        sm
        md
        align
      }
      cardDetailsConfig {
        xs
        sm
        md
        align
      }
      cardItemConfig {
        xs
        sm
        md
        align
      }
      buttonsConfig {
        xs
        sm
        md
        align
      }
      completeDetails {
        reducerKeyValue
        staticValue
        valueColor
        valueVariant
        reducerKeyLabel
        staticLabel
        labelColor
        labelVariant
        isCurrency
      }
      headingLabelColor
      headingLabelVariant
      reducerKeyHeadingLabel
      staticHeadingLabel
      borderSize
      borderColor
      borderSizeSelect
      borderColorSelect
      borderRadius
      addButton {
        label
        unLabel
        variant
        color
        config {
          xs
          sm
          md
          align
        }
        icon
        hidden
        size
      }
      editButton {
        label
        unLabel
        variant
        color
        config {
          xs
          sm
          md
          align
        }
        icon
        hidden
        size
      }
      selectButton {
        label
        unLabel
        variant
        color
        config {
          xs
          sm
          md
          align
        }
        icon
        hidden
        size
      }
      viewButton {
        label
        unLabel
        variant
        color
        config {
          xs
          sm
          md
          align
        }
        icon
        hidden
        size
      }
      deleteButton {
        label
        unLabel
        variant
        color
        config {
          xs
          sm
          md
          align
        }
        icon
        hidden
        size
      }
    }
    CardItemsList_storeKey: storeKey
    CardItemsList_name: name
    CardItemsList_savedKeyName: savedKeyName
    CardItemsList_multiple: multiple
    CardItemsList_addWizardSlug: addWizardSlug
    CardItemsList_editWizardSlug: editWizardSlug
    CardItemsList_useStaleStore: useStaleStore
    CardItemsList_modalSize: modalSize
    CardItemsList_maxItemsLimit: maxItemsLimit
    CardItemsList_maxItemsLimitExceedAlert: maxItemsLimitExceedAlert
    CardItemsList_required: required
    CardItemsList_labelAlign: labelAlign
    CardItemsList_justify: justify
    CardItemsList_alignItems: alignItems
    CardItemsList_triggerHandleNext: triggerHandleNext
    CardItemsList_reloadWizard: reloadWizard
    CardItemsList_savedStringKeyName: savedStringKeyName
    CardItemsList_optionValueKey: optionValueKey
    CardItemsList_optionLabelKey: optionLabelKey
    CardItemsList_options: options {
      ...nonRecursiveOption
    }
    CardItemsList_appendKeys: appendKeys {
      ...appendKey
    }
    CardItemsList_conditions: conditions {
      ...condition
    }
  }
`;

// export default CARD_ITEMS_LIST_FIELDS;
// exports.default = CARD_ITEMS_LIST_FIELDS;
export default CARD_ITEMS_LIST_FIELDS;
