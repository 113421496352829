import { Button, CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { recommendationEngine } from '../../../utils/variables/images';
import routes from '../../../utils/variables/routes';
import CallToActionCard from '../../cards/callToActionCard';
import styles from './style';
//
function RecommendationsEngine() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const footer = (
    <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        variant="text"
        sx={{ textTransform: 'none', fontWeight: 'bold !important', m: 0.5 }}
        className={classes.secondaryButton}
        to={routes.recommendations.index}
        component={RouterLink}
      >
        Learn More
      </Button>
      <Button
        variant="outlined"
        sx={{ textTransform: 'none', fontWeight: 'bold !important', m: 0.5 }}
        className={classes.primaryButton}
        to={routes.recommendations.configure}
        component={RouterLink}
      >
        Configure Now
      </Button>
    </CardActions>
  );
  return (
    <CallToActionCard
      title="Recommendations Engine"
      content="Get notified of products and services that cater to your needs today."
      imageSrc={recommendationEngine}
      footer={footer}
    />
  );
}

export default RecommendationsEngine;
