import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {
  // Container,
  Card,
  Grid,
  Typography,
  // Link,
  Button,
  Icon,
  Container,
  Skeleton,
  // CardActions,
} from '@mui/material';
import { setModalContent } from '../../../actions/misc';
import styles from './style';
import { addWizard } from '../../../actions/wizards';
import { useRouter } from '../../../hooks';

import routes from '../../../utils/variables/routes';
import conditionalActionCheck from '../../../utils/functions/actions';
import { checkComponent } from '../../wizards/functions';
import { getRef, isValidHttpUrl } from '../../../utils/functions';
import { getAccessToken } from '../../../storage/accessToken';
import { ContentProseDisplay } from '../../common';
import { DetailsModal } from '../../modal';
import RequestCard from '../requestCard';
import PurchaseNowCard from '../purchaseNowCard';
import CalculatorCard from '../calculatorCard';

const Index = props => {
  const {
    title,
    description,
    loading,
    wizardType,
    data = {},
    lob: initialLob,
    color: initialColor,
    icon: initialIcon,
    materialIcon: initialMaterialIcon,
    isMobileFull,
    cardType = 'Request',
    actionType = 'wizard',
    desktopCardWidth = 12,
    mobileCardWidth = 12,
    hideIcon = false,
    customClick,
  } = props;
  // // console.log('data: ', data);
  // console.log('actionType: ', actionType);
  // console.log('wizardType: ', wizardType);
  // console.log('cardType: ', cardType);

  const theme = useTheme();
  const router = useRouter();
  const dispatch = useDispatch();
  const storeState = useSelector(state => state);
  const storeModal = useSelector(state => state.misc.modalContent);
  const oldBackSlugs = storeModal?.backSlugs || [];
  const accessToken = getAccessToken();
  const isAuthenticated =
    useSelector(state => state?.auth?.isAuthenticated) && accessToken;
  const isCustomer =
    useSelector(state => state?.auth?.user?.customer?.customerVerified) && accessToken;
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  const { newAction } = conditionalActionCheck(storeState, props);
  // console.log('newAction: ', newAction);
  if (newAction) {
    newAction.isConditionalComponent = newAction?.isConditional;
  }
  const { show } = checkComponent(
    newAction || {},
    storeState,
    isAuthenticated,
    isCustomer,
  );
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const handleMoreInfoClose = () => {
    setOpenMoreInfo(false);
  };
  const handleMoreInfoOpen = () => {
    setOpenMoreInfo(true);
  };

  // console.log('Show, New Action', show, newAction);
  if (!show || !newAction) return null;

  const filterC360Wizards = newAction?.c360Wizards?.filter(y =>
    y?.territories.some(territory => territory?.alpha2 === alpha2),
  );

  const isMultipleWizards = filterC360Wizards?.length > 1;
  // console.log('isMultipleWizards: ', isMultipleWizards);
  const isRequest = actionType === 'request';
  // console.log('isRequest: ', isRequest);
  const isWizard = actionType === 'wizard';
  const isModalWizard = actionType === 'modalWizard';
  // console.log('isModalWizard: ', isModalWizard);
  // console.log('isWizard: ', isWizard);
  const isAction = actionType === 'action';
  // console.log('isAction: ', isAction);
  // console.log('actionType: ', actionType);
  const lob = initialLob || newAction?.lob?.lob;
  const color = initialColor || newAction?.lob?.color;
  const delay = 200;

  const handleRequest = () => {
    const newActionType = newAction?.actionType;
    const target = newAction?.target;
    const isModalActionType = newActionType === 'modal';
    const isLinkActionType = newActionType === 'link';
    if (isLinkActionType) {
      const actionUrl = newAction?.actionUrl;
      const newData = { ...data, focusKey: '' };
      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
      dispatch(addWizard.addInitialWizardDataAction(newData));
      dispatch(setModalContent.setModalContentAction(null));

      if (isValidHttpUrl(actionUrl)) {
        // window.location.href = actionUrl;
        window.open(actionUrl, target);
      } else {
        setTimeout(() => {
          router.push(actionUrl);
        }, delay);
      }
    } else if (isModalActionType) {
      const modalSlug = newAction?.modalSlug;
      const newData = { ...data, focusKey: '' };
      // // console.log('newData: ', newData);
      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
      dispatch(addWizard.addInitialWizardDataAction(newData));
      setTimeout(() => {
        dispatch(
          setModalContent.setModalContentAction({
            actionType: newActionType,
            modalSlug,
            lob,
            type: wizardType,
            data: newData,
            backSlugs: [...oldBackSlugs, modalSlug],
          }),
        );
      }, delay);
    } else if (isRequest) {
      // // console.log('isRequest: ', isRequest);
      const newData = { ...data, focusKey: '' };
      // // console.log('newData: ', newData);
      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
      dispatch(addWizard.addInitialWizardDataAction(newData));
      setTimeout(() => {
        dispatch(
          setModalContent.setModalContentAction({
            lob,
            data: newData,
            type: wizardType,
            actionType: newActionType,
          }),
        );
      }, delay);
    } else if (isAction) {
      const newData = { ...data, focusKey: '' };
      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
      dispatch(addWizard.addInitialWizardDataAction(newData));
      dispatch(setModalContent.setModalContentAction(null));
      const wizardLink = `${routes.wizards.index}/${newAction?.slug ||
        filterC360Wizards?.[0].slug}`;

      setTimeout(() => {
        router.push(wizardLink);
      }, delay);
    } else if (isModalWizard && !isMultipleWizards) {
      const newData = { ...data, focusKey: '' };
      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
      dispatch(addWizard.addInitialWizardDataAction(newData));
      dispatch(setModalContent.setModalContentAction(null));
      const wizardLink = `${routes.wizards.index}/${newAction?.slug ||
        filterC360Wizards?.[0].slug}`;
      setTimeout(() => {
        router.push(wizardLink);
      }, delay);
    } else if (isMultipleWizards) {
      // console.log('isMultipleWizards: ', isMultipleWizards);
      // console.log('wizardType: ', wizardType);
      // // console.log('isRequest: ', isRequest);
      const newData = { ...data, focusKey: '' };
      // // console.log('newData: ', newData);
      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
      dispatch(addWizard.addInitialWizardDataAction(newData));
      dispatch(
        setModalContent.setModalContentAction({ lob, data: newData, type: wizardType }),
      );
    } else {
      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
      const wizardLink = `${routes.wizards.index}/${newAction?.slug ||
        filterC360Wizards?.[0].slug}`;

      setTimeout(() => {
        router.push(wizardLink);
      }, delay);
    }
  };

  const tempIcon = newAction?.startIcon || newAction?.lob?.icon || initialIcon;
  const finalMaterialIcon = hideIcon
    ? ''
    : newAction?.materialIcon || initialMaterialIcon;
  const finalIcon = hideIcon || tempIcon === 'none' ? '' : tempIcon;
  const validColor = color || 'background.chip';
  const finalColor = getRef(theme.palette, validColor);
  const finalTitle = newAction?.title || title;
  const finalDescription = newAction?.description || description;

  const contrastArray = color?.split('.');
  let contrastTextRef = 'text.bright';
  if (contrastArray) {
    contrastArray.pop();
    contrastArray.push('contrastText');
    contrastTextRef = contrastArray.join('.');
  }
  const textColor = getRef(theme.palette, contrastTextRef);
  const mainColor = finalColor;
  const useStyles = makeStyles(mytheme => styles(mytheme, textColor, mainColor));
  const classes = useStyles({ ...props });

  const moreInfoContent = (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{finalTitle}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ContentProseDisplay content={newAction?.moreInfo} />
        </Grid>
      </Grid>
    </Container>
  );

  const detailsModal = openMoreInfo && (
    <DetailsModal fullWidth onClose={handleMoreInfoClose} content={moreInfoContent} />
  );

  if (cardType === 'PurchaseNow') {
    return (
      <Grid
        item
        xs={mobileCardWidth}
        md={desktopCardWidth}
        sx={{ height: 'auto' }}
        className={classes.cardPad}
      >
        <PurchaseNowCard
          {...props}
          classes={classes}
          icon={finalIcon}
          materialIcon={finalMaterialIcon}
          color={finalColor}
          loading={loading}
          finalTitle={finalTitle}
          isRequest={isRequest}
          isModalWizard={isModalWizard}
          finalDescription={finalDescription}
          handleMoreInfoOpen={handleMoreInfoOpen}
          newAction={newAction}
          handleRequest={customClick || handleRequest}
          mainColor={mainColor}
          textColor={textColor}
        />
        {detailsModal}
      </Grid>
    );
  }
  if (cardType === 'Calculator') {
    return (
      <Grid
        item
        xs={mobileCardWidth}
        md={desktopCardWidth}
        sx={{ height: 'auto' }}
        className={classes.cardPad}
      >
        <CalculatorCard
          {...props}
          classes={classes}
          icon={finalIcon}
          materialIcon={finalMaterialIcon}
          color={finalColor}
          loading={loading}
          finalTitle={finalTitle}
          isRequest={isRequest}
          isModalWizard={isModalWizard}
          finalDescription={finalDescription}
          handleMoreInfoOpen={handleMoreInfoOpen}
          newAction={newAction}
          handleRequest={customClick || handleRequest}
          mainColor={mainColor}
          textColor={textColor}
        />
        {detailsModal}
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={mobileCardWidth}
      md={desktopCardWidth}
      sx={{ height: 'auto' }}
      className={classes.cardPad}
    >
      <RequestCard
        {...props}
        classes={classes}
        icon={finalIcon}
        materialIcon={finalMaterialIcon}
        color={finalColor}
        loading={loading}
        finalTitle={finalTitle}
        isRequest={isRequest}
        isAction={isAction}
        isModalWizard={isModalWizard}
        finalDescription={finalDescription}
        handleMoreInfoOpen={handleMoreInfoOpen}
        newAction={newAction}
        handleRequest={customClick || handleRequest}
        mainColor={mainColor}
        textColor={textColor}
      />
      {detailsModal}
    </Grid>
  );
};

export default Index;

Index.propTypes = {
  materialIcon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
};

Index.defaultProps = {
  materialIcon: '',
  title: '',
  description: '',
  loading: false,
};

/*
  {isAction ? newAction?.title || title : title || newAction?.title}{' '}
              
  const handleCloseModal = () => {
    setModalContent(null);
  };

  const handleOpenModal = () => {
    setModalContent({ title, content: requestContent });
  };
  */
