import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
export const getTicketHistoryUrl = helpCenterApi('getTicketHistoryUrl');
const getTicketHistory = (state, body) => {
  const url = getTicketHistoryUrl;
  if (url) {
    return crmPostCall(state, url, body);
  } else {
    throw new Error('Server Error');
  }
};
export default { getTicketHistory };
