import { parseBool } from './boolManipulation';
import {
  userThemeSelection,
  userLanguageSelection,
  userCountrySelection,
  googleAnalyticsPageViews,
  googleAds,
  downloadAppModalLastSeen,
} from '../variables/cookies';
import {
  loadGoogleAnalytics,
  getGATrackingID,
  storePageView,
  updateDataLayer,
  storeModalPageView,
  storeEvent,
} from './googleAnalytics';
import {
  acceptAllConsentValue,
  acceptCustomConsentValue,
  appThemeNameStorage,
  languageStorage,
  alpha2Storage,
  iosInstallPromptedAtStorage,
  webInstallPromptedAtStorage,
  consentAcceptExpireTime,
  consentRejectExpireTime,
  consentKey,
  customConsentKey,
  userSettingsExpireTime,
} from '../variables';
import Cookies from '../../cookies/cookies';

const activateCookies = (
  cookies = [],
  consentValue,
  _acceptCookieCategories,
  cookieValues,
  additionalSettings = '',
  dimensions,
  hasCookiePolicy = false,
) => {
  // console.log('consentValue: ', consentValue);
  const result = {};
  const categorySlugs =
    consentValue === acceptCustomConsentValue ||
    cookieValues?.consent === acceptCustomConsentValue
      ? _acceptCookieCategories
      : null;
  const activeCookies = cookies?.filter(cookie =>
    categorySlugs?.includes(cookie?.categorySlug),
  );
  // console.log('_acceptCookieCategories: ', _acceptCookieCategories);
  // console.log('categorySlugs: ', categorySlugs);
  // /console.log('cookies: ', cookies);
  // console.log('activeCookies: ', activeCookies);
  // console.log('categorySlugs: ', categorySlugs);
  // console.log('activeCookies: ', activeCookies);
  // console.log('consentValue: ', consentValue);
  // console.log('cookieValues: ', cookieValues);
  // console.log('cookieFeatureDisabled: ', cookieFeatureDisabled);
  // console.log('hasCookiePolicy: ', hasCookiePolicy);
  // console.log('acceptAllConsentValue: ', acceptAllConsentValue);
  const allCookiesAreActive =
    consentValue === acceptAllConsentValue ||
    cookieValues?.consent === acceptAllConsentValue;

  // console.log('consentValue: ', consentValue);
  if (!cookies || cookies.length === 0) {
    result.cookies = [];
    // return result;
  }
  // console.log('allCookiesAreActive: ', allCookiesAreActive);

  if (cookieValues?.theme) {
    // console.log('cookieValues: ', cookieValues);
    // console.log('thisCookie: ', thisCookie);
    // console.log('allCookiesAreActive: ', allCookiesAreActive);
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === userThemeSelection)
    ) {
      // themeNameStorage.setAppThemeName(initialThemeName);
      // console.log('im changing theme', cookieValues?.theme);
      Cookies.setCookie(
        appThemeNameStorage,
        cookieValues?.theme,
        userSettingsExpireTime,
        additionalSettings,
      );
      result.theme = true;
      // console.log('set appThemeNameStorage: ', appThemeNameStorage);
      localStorage.removeItem(appThemeNameStorage);
    } else {
      Cookies.deleteCookie(appThemeNameStorage);
      // console.log('delete appThemeNameStorage: ', appThemeNameStorage);
    }
  }
  if (cookieValues?.language) {
    // console.log('cookieValues: ', cookieValues);
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === userLanguageSelection)
    ) {
      // console.log('activeCookies: ', activeCookies);
      // languageStorage.setLanguage(language);
      result.language = true;
      Cookies.setCookie(
        languageStorage,
        cookieValues?.language,
        userSettingsExpireTime,
        additionalSettings,
      );
      localStorage.removeItem(languageStorage);
    } else {
      Cookies.deleteCookie(languageStorage);
      // console.log('deleteLanguageCookie');
    }
  }
  if (cookieValues?.alpha2) {
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === userCountrySelection)
    ) {
      // alpha2Storage.setAlpha2(alpha2);
      result.alpha2 = true;
      Cookies.setCookie(
        alpha2Storage,
        cookieValues?.alpha2,
        userSettingsExpireTime,
        additionalSettings,
      );
      // console.log('set alpha2Storage: ', alpha2Storage);
      localStorage.removeItem(alpha2Storage);
    } else {
      Cookies.deleteCookie(alpha2Storage);
      // console.log('delete alpha2Storage: ', alpha2Storage);
    }
  }
  if (cookieValues?.googleAnalytics) {
    const hasAllowedGoogleAnalytics =
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === googleAnalyticsPageViews);
    const hasAllowedGoogleAds =
      allCookiesAreActive || activeCookies.find(cookie => cookie?.cookieId === googleAds);
    // console.log('hasAllowedGoogleAnalytics: ', hasAllowedGoogleAnalytics);
    if (hasAllowedGoogleAnalytics || hasAllowedGoogleAds) {
      result.googleAnalytics = true;
      loadGoogleAnalytics(hasAllowedGoogleAnalytics, hasAllowedGoogleAds, dimensions);
    } else {
      // if (typeof window.gtag === 'function') {
      //   window.gtag('config', 'GA_MEASUREMENT_ID', { send_page_view: false });
      // }
      // console.log('im deleting google analytics');
      Cookies.deleteCookie('_ga');
      Cookies.deleteCookie('_gat');
      Cookies.deleteCookie('_gid');
      window[`ga-disable-${getGATrackingID()}`] = true;
    }
  }

  if (cookieValues?.iosInstallPromptedAt) {
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === downloadAppModalLastSeen)
    ) {
      // themeNameStorage.setAppThemeName(initialThemeName);
      // Cookies.setCookie(iosInstallPromptedAtStorage, cookieValues?.iosInstallPromptedAt);
      result.iosInstallPromptedAt = true;
      localStorage.setItem(
        iosInstallPromptedAtStorage,
        cookieValues?.iosInstallPromptedAt,
      );
    } else {
      // Cookies.deleteCookie(iosInstallPromptedAtStorage);
      localStorage.removeItem(iosInstallPromptedAtStorage);
    }
  }

  if (cookieValues?.webInstallPromptedAt) {
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === downloadAppModalLastSeen)
    ) {
      // themeNameStorage.setAppThemeName(initialThemeName);
      // Cookies.setCookie(webInstallPromptedAtStorage, cookieValues?.webInstallPromptedAt);
      result.webInstallPromptedAt = true;
      localStorage.setItem(
        webInstallPromptedAtStorage,
        cookieValues?.webInstallPromptedAt,
      );
    } else {
      // Cookies.deleteCookie(webInstallPromptedAtStorage);
      localStorage.removeItem(webInstallPromptedAtStorage);
    }
  }
  if (cookieValues?.pageLocation) {
    const hasAllowedGoogleAnalytics =
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === googleAnalyticsPageViews);

    if (hasAllowedGoogleAnalytics) {
      result.pageLocation = true;
      storePageView(cookieValues?.pageLocation, dimensions);
    }
  }

  if (cookieValues?.updateDataLayer) {
    const hasAllowedGoogleAnalytics =
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === googleAnalyticsPageViews);
    if (hasAllowedGoogleAnalytics) {
      result.updateDataLayer = true;
      updateDataLayer(dimensions);
    }
  }

  if (cookieValues?.modalUrl) {
    const hasAllowedGoogleAnalytics =
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === googleAnalyticsPageViews);

    if (hasAllowedGoogleAnalytics) {
      result.modalUrl = true;
      storeModalPageView(cookieValues?.modalUrl, dimensions);
    }
  }

  if (cookieValues?.event) {
    // console.log('cookieValues?.event: ', cookieValues?.event);
    const hasAllowedGoogleAnalytics =
      allCookiesAreActive ||
      activeCookies?.find(cookie => cookie?.cookieId === googleAnalyticsPageViews);
    if (hasAllowedGoogleAnalytics) {
      result.event = true;
      storeEvent(cookieValues?.event, dimensions);
    }
  }

  result.cookies = activeCookies;
  return result;
};

export default activateCookies;

/*
 if (cookieValues?.consent !== undefined) {
    Cookies.setCookie(consentKey, cookieValues?.consent);
  }
  if (cookieValues?.customConsent !== undefined) {
    Cookies.setCookie(customConsentKey, cookieValues?.customConsent);
  }
  */
