import {
  routerPostCall,
  routerGetCall,
  strapiGetCall,
  strapiPostCall,
  routerPatchCall,
} from '../../axios';

const apiCallWizard = (state, url, params, httpMethod) => {
  if (httpMethod === 'routerGet') {
    // let newParams = params;
    // if (params && Object.keys(params).length > 0) {
    //  newParams = {};
    //  Object.keys(params).forEach(x => {
    //    const newX = encodeURIComponent(x);
    //    newParams[newX] = encodeURIComponent(params[x]);
    //  });
    // }
    return routerGetCall(state, url, params); // newParams
  }
  if (httpMethod === 'routerPost') {
    return routerPostCall(state, url, params);
  }
  if (httpMethod === 'strapiGet') {
    return strapiGetCall(state, url, params);
  }
  if (httpMethod === 'strapiPost') {
    return strapiPostCall(state, url, params);
  }
  if (httpMethod === 'routerPatch') {
    return routerPatchCall(state, url, params);
  }
  return Promise.resolve(null);
};
export default { apiCallWizard };
