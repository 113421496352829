import { useSelector, useDispatch } from 'react-redux';
import { getIndividualReceipt } from '../actions/receipts';

/**
 * Downloads Receipts
 * @param {int} orderNo
 * @param {Array} receiptNumbers
 */
const useDownloadReceipt = () => {
  const masterId = useSelector(state => state.auth.user.customer.id);
  const dispatch = useDispatch();

  // downloading
  // failed
  // success
  return (orderNo, receiptNumbers) => {
    // console.log('Order No and ReceiptNumbers', orderNo, receiptNumbers, masterId);
    if (receiptNumbers?.length > 0) {
      return dispatch(
        getIndividualReceipt({
          orderNo,
          receiptNos: receiptNumbers,
          masterId,
        }),
      );
    }
    return Promise.reject();
  };
};
export default useDownloadReceipt;
