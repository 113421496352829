import {
  getRef,
  transformConditionalStatus,
  transformConditionalValue,
  sourceTextFromData,
} from '../../../utils/functions';
// import { customerType } from '../../../utils/variables';

export const checkComponent = (
  props,
  storeState,
  isAuthenticated,
  customerVerified,
  defaultHide = false,
) => {
  const { isConditionalComponent, disable, show, conditions } = props || {};
  // console.log('props.name: ', props.name);
  let finalReqMessage = null;
  let {
    finalDisable,
    finalRequired,
    finalShow,
    finalRegExpEnabled,
    activeCondition: finalActiveCondition,
  } = conditionalProcessBlock(
    props,
    storeState,
    isAuthenticated,
    customerVerified,
    false,
  );

  if (conditions && conditions.length > 0) {
    // console.log('conditions: ', conditions);
    if (defaultHide) finalShow = false;
    conditions.map(item => {
      // console.log('item: ', item);
      const {
        finalDisable: tempDisable,
        finalRequired: tempRequired,
        finalShow: tempShow,
        finalNotRequired: tempNotRequired,
        finalRegExpEnabled: tempRegExpEnabled,
        finalRegExpDisabled: tempRegExpDisabled,
        hasChanged,
      } = conditionalProcessBlock(
        item,
        storeState,
        isAuthenticated,
        customerVerified,
        defaultHide,
      );
      // console.log('tempShow: ', tempShow);
      // console.log('hasChanged: ', hasChanged);
      // console.log('tempRequired: ', tempRequired, item);

      if (
        item.conditionalChain &&
        (item.conditionalChain === 'or_and' ||
          item.conditionalChain === 'or_or' ||
          item.conditionalChain === 'and_and' ||
          item.conditionalChain === 'and_or')
      ) {
        // console.log(item);
        let subConditions = item?.subConditions ? [...item?.subConditions] : [];
        subConditions.push({
          isConditionalComponent: item?.isConditionalComponent,
          conditionalValue: item?.conditionalValue,
          conditionalKey: item?.conditionalKey,
          conditionalOperator: item?.conditionalOperator,
          conditionalFieldType: item?.conditionalFieldType,
        });
        subConditions = subConditions.filter(x => x?.isConditionalComponent);
        // console.log('subConditions: ', subConditions);
        // console.log('subConditions: ', subConditions);
        const activeConditions = subConditions.map(subitem => {
          const { activeCondition } = conditionalProcessBlock(
            subitem,
            storeState,
            isAuthenticated,
            customerVerified,
            defaultHide,
          );

          return activeCondition;
        });
        // console.log('activeConditions: ', activeConditions);

        // const allConditionsChecked = activeConditions.every(v => v === true);

        let allConditionsChecked = false;

        if (item.conditionalChain === 'or_and') {
          allConditionsChecked =
            activeConditions.length > 0 && activeConditions.every(v => v === true);
        } else if (item.conditionalChain === 'or_or') {
          allConditionsChecked =
            activeConditions.length > 0 && activeConditions.some(v => v === true);
        } else if (item.conditionalChain === 'and_and') {
          allConditionsChecked =
            activeConditions.length > 0 && activeConditions.every(v => v === true);
        } else if (item.conditionalChain === 'and_or') {
          allConditionsChecked =
            activeConditions.length > 0 && activeConditions.some(v => v === true);
        }

        const isDataInvalid =
          !getRef(storeState, item?.conditionalKey) ||
          !parseInt(getRef(storeState, item?.conditionalKey), 10);
        if (item?.trueIfEmpty && isDataInvalid) {
          allConditionsChecked = true;
        } else if (item?.notTrueIfEmpty && isDataInvalid) {
          allConditionsChecked = false;
          // console.log('allConditionsChecked: ', allConditionsChecked);
        }
        // console.log('activeConditions: ', activeConditions);
        //

        let hasChangedValues = conditions.map(subitem => {
          const subProps = conditionalProcessBlock(
            subitem,
            storeState,
            isAuthenticated,
            customerVerified,
            defaultHide,
          );

          const {
            finalDisable: subDisable,
            finalRequired: subRequired,
            finalShow: subShow,
            finalNotRequired: subNotRequired,
            finalRegExpEnabled: subRegExpEnabled,
            finalRegExpDisabled: subRegExpDisabled,
            hasChanged: subHasChanged,
          } = subProps;

          let subItemSubConditions = [...subitem?.subConditions] || [];
          subItemSubConditions.push({
            isConditionalComponent: subitem?.isConditionalComponent,
            conditionalValue: subitem?.conditionalValue,
            conditionalKey: subitem?.conditionalKey,
            conditionalOperator: subitem?.conditionalOperator,
            conditionalFieldType: subitem?.conditionalFieldType,
          });
          subItemSubConditions = subItemSubConditions.filter(
            x => x?.isConditionalComponent,
          );
          // console.log('subItemSubConditions: ', subItemSubConditions);

          const activeSubItemConditions = subItemSubConditions.map(subsubitem => {
            const { activeCondition: activeSubCondition } = conditionalProcessBlock(
              subsubitem,

              storeState,
              isAuthenticated,
              customerVerified,
              defaultHide,
            );
            // console.log('subsubitem: ', subsubitem);
            // console.log('activeSubCondition: ', activeSubCondition);
            return activeSubCondition;
          });
          // console.log('activeSubItemConditions: ', activeSubItemConditions);
          let allSubConditionsChecked = false;

          if (subitem.conditionalChain === 'or_and') {
            allSubConditionsChecked =
              activeSubItemConditions.length > 0 &&
              activeSubItemConditions.every(v => v === true);
          } else if (subitem.conditionalChain === 'or_or') {
            allSubConditionsChecked =
              activeSubItemConditions.length > 0 &&
              activeSubItemConditions.some(v => v === true);
          } else if (subitem.conditionalChain === 'and_and') {
            allSubConditionsChecked =
              activeSubItemConditions.length > 0 &&
              activeSubItemConditions.every(v => v === true);
          } else if (subitem.conditionalChain === 'and_or') {
            allSubConditionsChecked =
              activeSubItemConditions.length > 0 &&
              activeSubItemConditions.some(v => v === true);
          }

          const isSubDataInvalid =
            !getRef(storeState, subitem?.conditionalKey) ||
            !parseInt(getRef(storeState, subitem?.conditionalKey), 10);

          if (subitem?.trueIfEmpty && isSubDataInvalid) {
            allSubConditionsChecked = true;
            // console.log('allSubConditionsChecked: ', allSubConditionsChecked);
          } else if (subitem?.notTrueIfEmpty && isSubDataInvalid) {
            allSubConditionsChecked = false;
            // console.log('allSubConditionsChecked: ', allSubConditionsChecked);
          }
          //
          // console.log('hasChanged', hasChanged);
          // console.log('finalShow', finalShow);
          // console.log('subHasChanged', subHasChanged);
          // console.log('allSubConditionsChecked: ', allSubConditionsChecked);
          // console.log('hasChanged: ', hasChanged);
          // console.log('subHasChanged: ', subHasChanged);
          if (hasChanged === subHasChanged && allSubConditionsChecked) {
            return true;
          }
          if (hasChanged === subHasChanged && !allSubConditionsChecked) {
            return false;
          }
          return null;
        });

        hasChangedValues = hasChangedValues.filter(el => {
          return el != null;
        });

        // console.log('props name: ', props?.title || props?.name);
        // console.log('conditions: ', conditions);
        // console.log('item title: ', item?.title);
        // console.log('hasChangedValues', hasChangedValues);
        let hasChangeChecker = false;
        if (item.conditionalChain === 'or_and') {
          hasChangeChecker =
            hasChangedValues.length > 0 && hasChangedValues.some(v => v === true);
        } else if (item.conditionalChain === 'or_or') {
          hasChangeChecker =
            hasChangedValues.length > 0 && hasChangedValues.some(v => v === true);
        } else if (item.conditionalChain === 'and_and') {
          hasChangeChecker =
            hasChangedValues.length > 0 && hasChangedValues.every(v => v === true);
        } else if (item.conditionalChain === 'and_or') {
          hasChangeChecker =
            hasChangedValues.length > 0 && hasChangedValues.every(v => v === true);
        }

        let hasChangeCheckerfalse = false;
        if (item.conditionalChain === 'or_and') {
          hasChangeCheckerfalse =
            hasChangedValues.length > 0 && hasChangedValues.some(v => v === false);
        } else if (item.conditionalChain === 'or_or') {
          hasChangeCheckerfalse =
            hasChangedValues.length > 0 && hasChangedValues.some(v => v === false);
        } else if (item.conditionalChain === 'and_and') {
          hasChangeCheckerfalse =
            hasChangedValues.length > 0 && hasChangedValues.every(v => v === false);
        } else if (item.conditionalChain === 'and_or') {
          hasChangeCheckerfalse =
            hasChangedValues.length > 0 && hasChangedValues.every(v => v === false);
        }

        // console.log('hasChangeCheckerfalse', hasChangeCheckerfalse);
        // console.log('hasChangeChecker', hasChangeChecker);
        // console.log('allConditionsChecked: ', allConditionsChecked);
        // console.log('.....');
        if (
          tempDisable &&
          hasChangedValues.length > 0 &&
          hasChangeChecker &&
          allConditionsChecked
        ) {
          finalDisable = true;
        }
        // console.log('allConditionsChecked: ', allConditionsChecked);
        // console.log('hasChangeChecker: ', hasChangeChecker);
        // console.log('tempRequired: ', tempRequired);
        if (tempRequired && hasChangeChecker && allConditionsChecked) {
          // console.log('tempRequired2: ', tempRequired, item);
          finalRequired = true;
          if (item?.validationReqMessage) {
            finalReqMessage = item?.validationReqMessage;
          }
        }
        if (tempNotRequired && hasChangeChecker && allConditionsChecked) {
          finalRequired = false;
        }
        if (tempRegExpEnabled && hasChangeChecker && allConditionsChecked) {
          finalRegExpEnabled = true;
        }
        if (tempRegExpDisabled && hasChangeChecker && allConditionsChecked) {
          finalRegExpEnabled = false;
        }

        // console.log('allConditionsChecked: ', allConditionsChecked);
        // console.log('hasChangeCheckerfalse: ', hasChangeCheckerfalse);
        // console.log('tempShow: ', tempShow);
        if (
          !tempShow &&
          (hasChangeCheckerfalse || hasChangeChecker) &&
          allConditionsChecked
        ) {
          finalShow = tempShow;
        }
        // console.log('tempShow', tempShow);
        // console.log('defaultHide: ', defaultHide);
        if (defaultHide && hasChangeChecker && allConditionsChecked) {
          finalShow = true;
        }
        // debugger;

        // console.log("finalShow", finalShow);
      } else {
        let subItemSubConditions = [...item?.subConditions] || [];
        subItemSubConditions.push({
          isConditionalComponent: item?.isConditionalComponent,
          conditionalValue: item?.conditionalValue,
          conditionalKey: item?.conditionalKey,
          conditionalOperator: item?.conditionalOperator,
          conditionalFieldType: item?.conditionalFieldType,
        });
        subItemSubConditions = subItemSubConditions.filter(
          x => x?.isConditionalComponent,
        );
        // console.log('subItemSubConditions: ', subItemSubConditions);

        const activeConditions = subItemSubConditions.map(subitem => {
          const { activeCondition: activeSubCondition } = conditionalProcessBlock(
            subitem,
            storeState,
            isAuthenticated,
            customerVerified,
            defaultHide,
          );
          // console.log('subsubitem: ', subsubitem);
          // console.log('activeSubCondition: ', activeSubCondition);
          return activeSubCondition;
        });
        // console.log('item', item);
        // console.log('storeState: ', storeState);
        // console.log('activeConditions: ', activeConditions);

        const allConditionsChecked =
          activeConditions.length > 0 && activeConditions.every(v => v === true);
        // console.log('allConditionsChecked: ', allConditionsChecked);
        // console.log('tempShow', tempShow);
        // console.log('hasChanged: ', hasChanged);
        if (tempDisable && allConditionsChecked) {
          finalDisable = tempDisable;
        }
        // console.log('Item', item);
        if (!tempDisable && hasChanged === 'finalDisable' && allConditionsChecked) {
          finalDisable = false;
          // console.log('Item', item);
        }

        if (tempRequired && allConditionsChecked) {
          // console.log('tempRequired2', tempRequired, item);
          finalRequired = tempRequired;
          if (item?.validationReqMessage) {
            finalReqMessage = item?.validationReqMessage;
          }
        }
        if (tempNotRequired && allConditionsChecked) {
          finalRequired = false;
        }
        if (tempRegExpEnabled && allConditionsChecked) {
          finalRegExpEnabled = tempRegExpEnabled;
        }
        if (tempRegExpDisabled && allConditionsChecked) {
          finalRegExpEnabled = false;
        }
        if (!tempShow && allConditionsChecked) {
          // console.log('item 2', item);
          finalShow = tempShow;
        }
        if (defaultHide && tempShow && allConditionsChecked) {
          finalShow = tempShow;
        }
        if (hasChanged === 'finalShow' && tempShow && allConditionsChecked) {
          finalShow = true;
          // console.log('Item', item);
        }
      }
    });
  }

  if (!isConditionalComponent && disable === 'no') {
    finalDisable = false;
    // // console.log('finalDisable: ', finalDisable);
  } else if (!isConditionalComponent && disable === 'yes') {
    finalDisable = true;
    // // console.log('finalDisable: ', finalDisable);
  }

  if (!isConditionalComponent && show === 'no') {
    finalShow = false;
    // // console.log('finalShow: ', finalShow);
  } else if (!isConditionalComponent && show === 'yes') {
    finalShow = true;
    // // console.log('finalShow: ', finalShow);
  }
  // console.log('finalShow 22: ', finalShow);
  // console.log('finalDisable: ', finalDisable);

  return {
    show: finalShow,
    disable: finalDisable,
    required: finalRequired,
    reqMessage: finalReqMessage,
    regExpEnabled: finalRegExpEnabled,
    activeCondition: finalActiveCondition,
  };
};

export const conditionalProcessBlock = (
  props,
  storeState,
  isAuthenticated,
  customerVerified,
  defaultHide,
) => {
  const {
    slug,
    name,
    isConditionalComponent,
    conditionalValue,
    conditionalKey,
    conditionalOperator,
    conditionalFieldType,
    conditionalAction = 'show',
    disable,
    show,
    conditionalArrayKey,
    notTrueIfEmpty,
    trueIfEmpty,
    validationTitle,
  } = props || {};

  const formatConditionalValue = sourceTextFromData(storeState, conditionalValue);
  // console.log('slug: ', slug);
  // console.log('name: ', name);
  // console.log('formatConditionalValue: ', formatConditionalValue);

  const newConditionalValue = transformConditionalValue(
    formatConditionalValue,
    conditionalFieldType,
  );
  // console.log('newConditionalValue: ', newConditionalValue);
  const formatConditionalKey = sourceTextFromData(storeState, conditionalKey);
  // console.log('conditionalKey: ', conditionalKey);
  // console.log('formatConditionalKey', formatConditionalKey);

  const refValue = getRef(storeState, formatConditionalKey);
  // console.log('refValue: ', refValue);
  const newConditionalStatus = transformConditionalStatus(
    newConditionalValue,
    conditionalOperator,
    refValue,
    conditionalFieldType,
    conditionalArrayKey,
  );
  // console.log("newConditionalValue", newConditionalValue);

  // console.log('isConditionalComponent: ', isConditionalComponent);
  let activeCondition = isConditionalComponent && newConditionalStatus;
  const inActiveCondition = isConditionalComponent && !newConditionalStatus;
  if (trueIfEmpty && !refValue && isConditionalComponent) {
    activeCondition = true;
  }
  if (notTrueIfEmpty && !refValue && isConditionalComponent) {
    activeCondition = false;
  }
  // console.log('inActiveCondition: ', inActiveCondition);
  // console.log('conditionalValue: ', conditionalValue);
  // console.log('newConditionalStatus: ', newConditionalStatus);

  // conditionalValue === getRef(store.getState(), conditionalKey).toString());
  const tempDisable = false;
  const tempShow = !defaultHide;
  let finalRequired = false;
  let finalNotRequired = false;
  let finalRegExpEnabled = true;
  let finalRegExpDisabled = false;
  let hasChanged = 'finalShow';
  // console.log('conditionalKey: ', conditionalKey);
  // console.log('conditionalAction: ', conditionalAction);

  let finalShow =
    (!(isConditionalComponent && conditionalAction === 'show') &&
      isAuthenticated &&
      show === 'auth') ||
    (!(isConditionalComponent && conditionalAction === 'show') &&
      !isAuthenticated &&
      show === 'notAuth') ||
    (!(isConditionalComponent && conditionalAction === 'show') &&
      !customerVerified &&
      show === 'notCustomer') ||
    (!(isConditionalComponent && conditionalAction === 'show') &&
      isAuthenticated &&
      customerVerified &&
      show === 'customer') ||
    tempShow;

  let finalDisable =
    (!(isConditionalComponent && conditionalAction === 'disable') &&
      isAuthenticated &&
      disable === 'auth') ||
    (!(isConditionalComponent && conditionalAction === 'disable') &&
      !isAuthenticated &&
      disable === 'notAuth') ||
    (!(isConditionalComponent && conditionalAction === 'disable') &&
      !customerVerified &&
      disable === 'notCustomer') ||
    (!(isConditionalComponent && conditionalAction === 'disable') &&
      isAuthenticated &&
      customerVerified &&
      disable === 'customer') ||
    tempDisable;

  if (inActiveCondition && conditionalAction === 'show') {
    finalShow = false;
    hasChanged = 'finalShow';
    // // console.log('finalShow: ', finalShow);
  } else if (activeCondition && conditionalAction === 'show') {
    finalShow = true;
    hasChanged = 'finalShow';
    // console.log('finalShow: ', finalShow);
  } else if (activeCondition && conditionalAction === 'disable') {
    finalDisable = true;
    hasChanged = 'finalDisable';
    // //console.log('finalDisable: ', finalDisable);
  } else if (activeCondition && conditionalAction === 'enable') {
    finalDisable = false;
    hasChanged = 'finalDisable';
    // // console.log('finalDisable: ', finalDisable);
  } else if (activeCondition && conditionalAction === 'hide') {
    finalShow = false;
    hasChanged = 'finalShow';
    // console.log('finalShow hide: ', finalShow);
  }
  // console.log('activeCondition: ', activeCondition);
  // console.log('conditionalAction: ', conditionalAction);
  if (activeCondition && conditionalAction === 'required') {
    finalRequired = true;
    hasChanged = 'finalRequired';
    // // console.log('finalShow: ', finalShow);
  }

  if (activeCondition && conditionalAction === 'notRequired') {
    finalNotRequired = true;
    hasChanged = 'finalNotRequired';
    // // console.log('finalShow: ', finalShow);
  }

  if (activeCondition && conditionalAction === 'regexEnabled') {
    finalRegExpEnabled = true;
    hasChanged = 'finalRegExpEnabled';
    // // console.log('finalShow: ', finalShow);
  }
  if (activeCondition && conditionalAction === 'regexDisabled') {
    finalRegExpDisabled = true;
    hasChanged = 'finalRegExpDisabled';
    // // console.log('finalShow: ', finalShow);
  }

  return {
    finalDisable,
    finalRequired,
    finalShow,
    finalRegExpEnabled,
    finalNotRequired,
    finalRegExpDisabled,
    hasChanged,
    activeCondition,
    inActiveCondition,
  };
};

export const collapseView = collapse => {
  let mobile = true;
  let desktop = false;
  if (collapse === 'mobileOnly') {
    mobile = true;
    desktop = false;
  } else if (collapse === 'desktopOnly') {
    mobile = false;
    desktop = true;
  } else if (collapse === 'all') {
    mobile = true;
    desktop = true;
  } else if (collapse === 'none') {
    mobile = false;
    desktop = false;
  }
  return { mobile, desktop };
};

export const transformExpansionItems = ({
  source,
  searchResults,
  primaryTextSourceKey,
  secondaryTextSourceKey,
  valueSourceKey,
  iconSourceKey,
  sampleDataKey,
  items,
}) => {
  const tempItems =
    source && source === 'store'
      ? searchResults.map(x => ({
          ...x,
          primaryText: x[primaryTextSourceKey],
          secondaryText: x[secondaryTextSourceKey],
          value: x[valueSourceKey],
          icon: x[iconSourceKey],
          sampleData: x[sampleDataKey],
        }))
      : items.map(x => ({
          ...x,
          primaryText: x?.title,
          secondaryText: x?.secondaryTitle,
          value: x?.value,
          icon: x?.startIcon,
          sampleData: x?.sampleData,
          secondaryTitleIsNumeric: x?.secondaryTitleIsNumeric,
          secondaryTitleIsCurrency: x?.secondaryTitleIsCurrency,
          secondaryTitleIsAbbrev: x?.secondaryTitleIsAbbrev,
          secondaryTitleIsLocalize: x?.secondaryTitleIsLocalize,
          secondaryTitleIsPercent: x?.secondaryTitleIsPercent,
          primaryTitleIsNumeric: x?.titleIsNumeric,
          primaryTitleIsCurrency: x?.titleIsCurrency,
          primaryTitleIsAbbrev: x?.titleIsAbbrev,
          primaryTitleIsLocalize: x?.titleIsLocalize,
          primaryTitleIsPercent: x?.titleIsPercent,
          primaryUnits: x?.primaryUnits,
          secondaryUnits: x?.secondaryUnits,
        }));

  return tempItems;
};

export const transformReducerValue = (
  storeState,
  reducerKeyValue,
  isDefaultUndefined,
) => {
  const tempReducerValueArray = reducerKeyValue?.split(',') || [];
  // console.log('tempReducerValueArray: ', tempReducerValueArray);
  // // console.log('tempReducerValueArray: ', tempReducerValueArray);

  let finalStoreValue = isDefaultUndefined ? undefined : null;
  if (Array.isArray(tempReducerValueArray) && tempReducerValueArray.length > 0) {
    tempReducerValueArray.some(x => {
      const storeValue = getRef(storeState, x);
      if (storeValue) {
        finalStoreValue = storeValue;
        // console.log('finalStoreValue: ', finalStoreValue);
        return true;
      }
    });
    // console.log('finalStoreValue1', finalStoreValue);
  } else {
    finalStoreValue = getRef(storeState, reducerKeyValue);
    // console.log('finalStoreValue2', finalStoreValue);
  }

  return finalStoreValue;
};

export const validateAccess = (inputValue, isAuthenticated, customerVerified) => {
  const valid =
    (isAuthenticated && inputValue === 'auth') ||
    (!isAuthenticated && inputValue === 'notAuth') ||
    (!customerVerified && inputValue === 'notCustomer') ||
    (isAuthenticated && customerVerified && inputValue === 'customer') ||
    inputValue === 'yes' ||
    false;

  return valid;
};

export const getLabelText = (value, required) => {
  if (required && value && !value.includes('*')) {
    return `${value} (*)`;
  }
  return value;
};

export const getStoreState = (store, params, extraSettings) => {
  const initialStoreState = store?.getState() || {};
  const storeState = { ...initialStoreState };
  if (params) {
    storeState.wizards.allInitialWizardData.params = params;
    storeState.wizards.allInitialWizardData = {
      ...storeState.wizards.allInitialWizardData,
      ...params,
    };
  }
  if (extraSettings) {
    storeState.wizards.allInitialWizardData.extraSettings = extraSettings;
    storeState.wizards.allInitialWizardData = {
      ...storeState.wizards.allInitialWizardData,
      ...extraSettings,
    };
  }

  return storeState;
};
