import { portfolioButtonStyles } from '../../../styles';

export default theme => ({
  ...portfolioButtonStyles(theme),
  noTitlePadding: {
    paddingTop: theme.spacing(3),
  },
  dialogContainer: {
    maxWidth: '70%!important',
    boxShadow: `0 2px 10px 1px ${theme.palette.background.grid} !important`,
  },
  dialogActions: {
    backgroundColor: theme.palette.background.main,
  },
  headerTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dialogContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.main,
  },
  dialogContentText: {
    color: `${theme.palette.text.primary} !important`,
  },
});
