import {
  ADD_INITIAL_WIZARD_DATA_SUCCESS,
  LOADING_WIZARD_SUCCESS,
  ADD_LOCAL_WIZARD_SUCCESS,
  ADD_LOCAL_MODAL_WIZARD_SUCCESS,
  ADD_LAST_ENTRY_POINT_SUCCESS,
  ADD_MODAL_WIZARD_SLUG_SUCCESS,
  EMPTY_INITIAL_WIZARD_DATA_SUCCESS,
} from '../../../constants/wizardActionNames';

function addInitialWizardDataSuccess(prod, dataSettings) {
  return {
    type: ADD_INITIAL_WIZARD_DATA_SUCCESS,
    payload: prod,
    dataSettings,
  };
}

export function addInitialWizardDataAction(_data, dataSettings) {
  return (dispatch, getState) => {
    const state = getState();
    const customerId = state?.auth?.user?.id || 'none';
    const data = { ..._data };
    data.customerId = customerId;
    return Promise.resolve(dispatch(addInitialWizardDataSuccess(data, dataSettings)));
  };
}

function emptyInitialWizardDataSuccess() {
  return {
    type: EMPTY_INITIAL_WIZARD_DATA_SUCCESS,
    payload: {},
  };
}

export function emptyInitialWizardDataAction() {
  return emptyInitialWizardDataSuccess();
}

function loadingWizardSuccess(prod) {
  return {
    type: LOADING_WIZARD_SUCCESS,
    payload: prod,
  };
}

export function loadingWizardAction(data) {
  return loadingWizardSuccess(data);
}

function addLocalWizardSuccess(prod) {
  return {
    type: ADD_LOCAL_WIZARD_SUCCESS,
    payload: prod,
  };
}

export function addLocalWizardAction(data) {
  return addLocalWizardSuccess(data);
}

function loadingModalWizardSuccess(prod) {
  return {
    type: LOADING_WIZARD_SUCCESS,
    payload: prod,
  };
}

export function loadingModalWizardAction(data) {
  return loadingModalWizardSuccess(data);
}

function addLocalModalWizardSuccess(prod) {
  return {
    type: ADD_LOCAL_MODAL_WIZARD_SUCCESS,
    payload: prod,
  };
}

export function addLocalModalWizardAction(data) {
  return addLocalModalWizardSuccess(data);
}

export function addLastEntryPointAction(data) {
  return {
    type: ADD_LAST_ENTRY_POINT_SUCCESS,
    payload: data,
  };
}

export function addModalWizardSlug(slug) {
  return {
    type: ADD_MODAL_WIZARD_SLUG_SUCCESS,
    payload: slug,
  };
}
export default {
  addInitialWizardDataAction,
  emptyInitialWizardDataAction,
  loadingWizardAction,
  addLocalWizardAction,
  addLastEntryPointAction,
  addLocalModalWizardAction,
  addModalWizardSlug,
};

/*
export function addAction() {
  // const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  //    return signOutApi
  // .signOut(credentials)
  return dispatch => {
    dispatch(signOutRequest());
    return Promise.resolve()
      .then(promiseTimeout(200))
      .then(data => {
        // console.log('here sign out action');
        dispatch(signOutSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(signOutError(error));
        throw error;
      });
  };
}
*/
