import { gql } from 'graphql-request';

const CUSTOM_OBJECT_FIELDS = gql`
  fragment customObject on ComponentWizardComponentsCustomObject {
    CustomObject_slug: slug
    CustomObject_customSchema: customSchema
    CustomObject_layoutPosition: layoutPosition
    CustomObject_positionNo: positionNo
    CustomObject_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    CustomObject_name: name
    CustomObject_isConditionalComponent: isConditionalComponent
    CustomObject_conditionalValue: conditionalValue
    CustomObject_conditionalKey: conditionalKey
    CustomObject_conditionalOperator: conditionalOperator
    CustomObject_conditionalFieldType: conditionalFieldType
    CustomObject_conditionalAction: conditionalAction
    CustomObject_label: label
    CustomObject_useStaleStore: useStaleStore
    CustomObject_tooltip: tooltip
    CustomObject_conditions: conditions {
      ...condition
    }
    CustomObject_customForm: customForm {
      ...nonRecursiveForm
    }
  }
`;

/*
    customForm {
      ...nonRecursiveForm
    }
    */

// export default CUSTOM_OBJECT_FIELDS;
// exports.default = CUSTOM_OBJECT_FIELDS;
export default CUSTOM_OBJECT_FIELDS;
