import { gql } from 'graphql-request';

const PHONE_INPUT_FIELDS = gql`
  fragment phoneInput on ComponentWizardComponentsPhoneInput {
    PhoneInput_slug: slug
    PhoneInput_name: name
    PhoneInput_label: label
    PhoneInput_labelAlign: labelAlign
    PhoneInput_defaultCountry: defaultCountry
    PhoneInput_variant: variant
    PhoneInput_layoutPosition: layoutPosition
    PhoneInput_staticValue: staticValue
    PhoneInput_reducerKeyValue: reducerKeyValue
    PhoneInput_positionNo: positionNo
    PhoneInput_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    PhoneInput_fullWidth: fullWidth
    PhoneInput_required: required
    PhoneInput_tooltip: tooltip
    PhoneInput_isConditionalComponent: isConditionalComponent
    PhoneInput_conditionalValue: conditionalValue
    PhoneInput_conditionalKey: conditionalKey
    PhoneInput_conditionalOperator: conditionalOperator
    PhoneInput_conditionalFieldType: conditionalFieldType
    PhoneInput_conditionalAction: conditionalAction
    PhoneInput_disable: disable
    PhoneInput_show: show
    PhoneInput_showEditPhone: showEditPhone
    PhoneInput_disableInitialValue: disableInitialValue
    PhoneInput_phoneVerificationRequired: phoneVerificationRequired
    PhoneInput_regExp: regExp
    PhoneInput_validationExpMessage: validationExpMessage
    PhoneInput_disablePlaceholder: disablePlaceholder
    PhoneInput_placeholder: placeholder
    PhoneInput_tooltip_en: tooltip_en
    PhoneInput_tooltip_nl: tooltip_nl
    PhoneInput_label_en: label_en
    PhoneInput_label_nl: label_nl
    PhoneInput_validationExpMessage_en: validationExpMessage_en
    PhoneInput_validationExpMessage_nl: validationExpMessage_nl
    PhoneInput_disablePlaceholder_en: disablePlaceholder_en
    PhoneInput_disablePlaceholder_nl: disablePlaceholder_nl
    PhoneInput_placeholder_en: placeholder_en
    PhoneInput_placeholder_nl: placeholder_nl
    PhoneInput_conditions: conditions {
      ...condition
    }
  }
`;

// export default PHONE_INPUT_FIELDS;
// exports.default = PHONE_INPUT_FIELDS;
export default PHONE_INPUT_FIELDS;
