/* eslint-disable global-require */
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
// react-dom connects the react application to the index.html page
import isbot from 'isbot';

import Loadable from 'react-loadable';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  getYourLocation,
  // getAllLocations,
  updateYourLocation,
  getYourAppSettings,
} from './actions/mainLayout';
import { resetExpired, storeCampaign } from './actions/misc';
import { updateTempState, appReadyState } from './actions/temp';
import { updateYourStorageVersion } from './actions/disk';
// import { getAlpha2, setAlpha2 } from './storage/alpha2';
import RequestModal from './componentsV2/modal/requestModal';
import SettingsModal from './componentsV2/modal/settingModal';
import AlertPopUpModal from './componentsV2/modal/alertPopUpModal';
import SplashScreen from './componentsV2/common/splashScreen';
import CountryLocator from './containersV2/countryLocator';
import { ThemeContext, ConsentContext } from './context';
import CustomIntlProvider from './providers/intl';
import Cookies from './cookies/cookies';
// import Cookies from './cookies/cookies';
import history from './history';
import {
  useRouter,
  useProcessCookies,
  useFeatureToggle,
  useSmall,
  useGTMDataLayer,
} from './hooks';
import { manipulateTerritories } from './reducers/mainLayout/location';
// import queryString from 'query-string';
import Routes from './routes';
// import { getGATrackingID } from './utils/functions/googleAnalytics';
import { getPersistor } from './store';
import { languageWithoutRegionCode } from './translations';
import {
  activateCookies,
  parseBool,
  secondsToHms,
  displayWarning,
} from './utils/functions';
import {
  alpha2Storage,
  defaultAlpha2,
  newStorageVersion,
  resAlpha2,
  campaignExpiryTimeSeconds,
} from './utils/variables';
import { addTrustedDeviceAction } from './actions/disk/addTrustedDevice';
// import { getProducts } from './actions/products';
import routes from './utils/variables/routes';
import excludedUrls from './utils/variables/seoUrls';
import { toastrStyles } from './styles';

const EmbeddedSection = Loadable({
  loader: () => import('./routes/embed'),
  loading: () => null,
});
const CookieConsent = Loadable({
  loader: () => import('./componentsV2/common/cookieConsent'),
  loading: () => null,
});
const CookieSnackbarContent = Loadable({
  loader: () => import('./componentsV2/common/cookieConsent/cookieSnackbarContent'),
  loading: () => null,
});
const SyncWithPublicSite = Loadable({
  loader: () => import('./componentsV2/common/syncData/syncWithPublicSite'),
  loading: () => null,
});

const NewChatBot = Loadable({
  loader: () => import('./componentsV2/fancyChatLauncher/chatBot/newChatBot'),
  loading: () => null,
});

const OldChatBot = Loadable({
  loader: () => import('./componentsV2/oldChat/chatBot/newChatBot'),
  loading: () => null,
});

// import { localeData } from './translations';

const useStyles = makeStyles(toastrStyles);
let counter = 0;
let lastFetchedCountry = null;
let lastUserType = null;

const Main = () => {
  // // console.log('restProps: ',restProps);
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const store = useStore();
  const processCookies = useProcessCookies();
  const doFeatureToggle = useFeatureToggle();
  const storeGtmDataLayer = useGTMDataLayer();
  const isMobile = useSmall();
  // console.log('my consent: ', consent);
  const { query, pathname } = router;
  const queryCountryCode = query?.c?.toUpperCase();
  const { consent, setConsent } = useContext(ConsentContext);
  const { themeName: initialThemeName, setThemeName } = useContext(ThemeContext);
  const language =
    useSelector(state => state?.locations?.language) || languageWithoutRegionCode || 'en';
  const { bypass: initialBypass = '', rs } = query;
  const [bypass, setBypass] = useState(initialBypass);

  const isGettingYourLocationPending = useSelector(
    state => state?.locations?.isGettingYourLocationPending,
  );
  const resetStorageCheck = !!rs;

  const userType = useSelector(state => state?.auth?.usertype);
  const yourLocation = useSelector(state => state?.locations?.yourLocation);
  const allLocations = useSelector(state => state?.locations?.allLocations);
  const siteWide = useSelector(state => state?.locations?.siteWide);
  const currentAppSetting = useSelector(state => state?.locations?.currentAppSetting);
  const appSettingError = useSelector(state => state?.locations?.appSettingError);

  const cookieFeatureDisabled = siteWide?.disableCookieConsent;
  const { currentLocation: queryLocation } = manipulateTerritories(
    allLocations,
    queryCountryCode,
    null,
  );

  const storageAlpha2 = Cookies.getCookie(alpha2Storage);
  const { currentLocation: storageLocation } = manipulateTerritories(
    allLocations,
    storageAlpha2,
    null,
  );
  // const products = useSelector(state => state?.products?.products);
  const isRehydrated = useSelector(state => state.misc.isRehydrated);
  const appReady = useSelector(state => state.temp.appReady);
  const isExpired = useSelector(state => state.auth.isExpired);
  const expiresIn = useSelector(state => state?.auth?.expiresIn);
  const campaignStored = useSelector(state => state?.misc?.campaign);

  const storageVersion =
    useSelector(state => state?.disk?.storageVersion || state?.misc?.storageVersion) || 0;
  const myCountryCode = yourLocation?.alpha2; // || defaultAlpha2;
  const cookiePolicyId = yourLocation?.cookiePolicyId || null;
  const hasCookiePolicy = !!cookiePolicyId;
  // console.log('hasCookiePolicy: ', hasCookiePolicy);
  const customerId = useSelector(state => state?.auth?.user?.customer?.id);
  const storePathname = useSelector(state => state?.router?.location?.pathname);
  const alpha2 = myCountryCode;

  const isRobot = isbot(navigator.userAgent);
  let skipLoad = false;
  if (typeof window !== 'undefined') {
    const { location } = window;
    // // console.log('location: ', location);
    // parsed = queryString.parse(location.search);
    skipLoad = location?.pathname === routes.embed.syncData;
  }

  const purgeStorage = () => {
    const storeState = store?.getState();
    let tempState = {};
    if (storeState) {
      tempState = { ...storeState };
    }
    // console.log('storeState: ', storeState);
    return getPersistor()
      .purge()
      .then(() => {
        const trustedDevices = tempState?.disk?.devices || tempState?.misc?.devices || [];
        // console.log('trustedDevices: ', trustedDevices);
        const trustedDevicePromises = trustedDevices.map(device => {
          return dispatch(addTrustedDeviceAction(device));
        });
        // console.log('trustedDevicePromises: ', trustedDevicePromises);
        return Promise.resolve(trustedDevicePromises);
      })
      .then(r => {
        // console.log('r: ', r);
        for (let i = 0; i < r.length; i++) {
          if (r[i].name !== 'localforage') {
            try {
              window.indexedDB.deleteDatabase(r[i].name);
              // console.log('r[i].name: ', r[i].name);
            } catch (err) {
              console.error(err);
            }
          }
        }

        return Promise.resolve(null);
      })
      .catch(err => {
        console.log('purge err: ', err);
      });
    // .then(() => window.location.reload()); // alert('Purge Complete')
    // .then(() => {
    //   window.alert('finish purge');
    // })
  };

  const boolVersion = newStorageVersion === storageVersion;
  console.log('stored-db-version: ', storageVersion);
  console.log('current-db-version: ', newStorageVersion);

  const initialResetStorage = !boolVersion || resetStorageCheck;

  const [resetStorage, setResetStorage] = useState(initialResetStorage);

  useEffect(() => {
    const isLocalStorageEnabled = () => {
      try {
        const key = `__storage__test`;
        window.localStorage.setItem(key, null);
        window.localStorage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    };

    async function renewStorage() {
      return Promise.resolve(null)
        .then(() => {
          // // console.log('here first');
          if (resetStorage && isLocalStorageEnabled()) {
            // return purgeStorage();
            // window.alert = () => {
            console.log('deleting all databases');
            return purgeStorage();
            // return Promise.resolve(null);
            // };
          }

          return Promise.resolve(null);
        })
        .then(() => {
          // // console.log('here first');
          if (resetStorage && isLocalStorageEnabled()) {
            console.log('deleting all local storage');
            localStorage.clear();
          }

          return Promise.resolve(null);
        })
        .then(() => {
          // // console.log('here first');
          if (resetStorage && isLocalStorageEnabled() && parseInt(rs, 10) > 2) {
            console.log('deleting all cookies');
            Cookies.deleteAllCookies();
          }

          return Promise.resolve(null);
        })
        .then(() => {
          console.log('here to update storage version');
          return dispatch(
            updateYourStorageVersion.updateYourStorageVersionAction(newStorageVersion),
          );
        })
        .then(() => {
          if (!boolVersion && isLocalStorageEnabled()) {
            setTimeout(() => {
              console.log('reloading');
              window.location.reload();
            }, 1000);
          }
          setResetStorage(false);
        })
        .catch(error => {
          console.log(`Error in promises ${error}`);
          setResetStorage(false);
        });
    }
    if (resetStorage) {
      renewStorage();
    }
  }, [resetStorage]);

  const [loadedCountry, setLoadedCountry] = useState(myCountryCode);

  useEffect(() => {
    async function fetchCountry() {
      return Promise.resolve(null)
        .then(result => {
          console.log('fetching country');
          if (
            (yourLocation && yourLocation?.alpha2 && yourLocation.alpha2 !== resAlpha2) ||
            (queryLocation && queryLocation?.alpha2) ||
            (storageLocation && storageLocation?.alpha2)
          ) {
            // console.log('yourLocation?.alpha2 present: ', yourLocation?.alpha2);

            let newLocation = yourLocation;
            // console.log('counter: ', counter);
            if (counter === 0) {
              newLocation = queryLocation || yourLocation || storageLocation;
              counter = 1;
            } else {
              newLocation = yourLocation;
              counter = 1;
            }
            return Promise.all([
              // Promise.resolve({ payload: yourLocation }),
              // dispatch(getAllLocations.getAllLocationsAction()),
              dispatch(updateYourLocation.updateYourLocationAction(newLocation)),
            ]);
          }
          // console.log('get New Location: ');
          return Promise.all([
            dispatch(getYourLocation.getYourLocationAction()),
            // dispatch(getAllLocations.getAllLocationsAction()),
          ]);
        })
        .then(result => {
          // console.log('countryCode: ', countryCode);
          // setLoadedCountry(result && result[0]);
          // console.log('result1: ', result);
          if (!result || result.length < 1)
            return Promise.reject(new Error('Error getting your countryCode.'));
          const countryCode = (result && result[0]?.payload?.alpha2) || defaultAlpha2;
          setLoadedCountry(countryCode);
          lastFetchedCountry = countryCode;
          lastUserType = userType;
          return Promise.resolve(null);
        })
        .catch(error => {
          // console.log(`Error in promises ${error}`);
          setLoadedCountry(defaultAlpha2);
          lastFetchedCountry = defaultAlpha2;
          lastUserType = userType;
        });
    }
    if (!resetStorage && (lastFetchedCountry !== myCountryCode || !loadedCountry)) {
      setLoadedCountry(null);
      fetchCountry();
    }
  }, [myCountryCode, resetStorage]);

  useEffect(() => {
    async function updateCountry() {
      return Promise.resolve(null)
        .then(result => {
          console.log('updating country when changing usertype');
          if (yourLocation && yourLocation?.alpha2 && yourLocation.alpha2 !== resAlpha2) {
            return Promise.all([
              dispatch(updateYourLocation.updateYourLocationAction(yourLocation)),
            ]);
          }
          return Promise.all([dispatch(getYourLocation.getYourLocationAction())]);
        })
        .then(result => {
          if (!result || result.length < 1)
            return Promise.reject(new Error('Error getting your countryCode.'));
          const countryCode = (result && result[0]?.payload?.alpha2) || defaultAlpha2;
          setLoadedCountry(countryCode);
          lastFetchedCountry = countryCode;
          lastUserType = userType;
          return Promise.resolve(null);
        })
        .catch(error => {
          // console.log(`Error in promises ${error}`);
          setLoadedCountry(defaultAlpha2);
          lastFetchedCountry = defaultAlpha2;
          lastUserType = userType;
        });
    }
    console.log('userType: ', userType);
    console.log('lastUserType: ', lastUserType);
    if (lastUserType && userType !== lastUserType) {
      updateCountry();
    }
  }, [userType]);

  useEffect(() => {
    async function fetchAppSetting() {
      console.log('fetching app settings');
      return Promise.resolve(null)
        .then(() => {
          const countryCode = loadedCountry;
          return Promise.all([
            Promise.resolve(countryCode),
            dispatch(getYourAppSettings.getYourAppSettingAction()),
          ]);
        })
        .then(result => {
          // console.log('app setting results: ', result);

          // console.log('countryCode: ', countryCode);
          // setLoadedCountry(result && result[0]);
          return Promise.resolve(null);
        })
        .catch(error => {
          console.log('fetch app setting error: ', error);
        });
    }
    if (loadedCountry && (!resetStorage || loadedCountry !== myCountryCode)) {
      fetchAppSetting();
    }
  }, [loadedCountry, resetStorage, myCountryCode]);

  const expiresTime = secondsToHms(expiresIn);

  useEffect(() => {
    // console.log('show error expired message: ', isExpired);
    if (isExpired && expiresTime && !resetStorage) {
      const msg = {
        label: 'Your session has expired.',
        message: 'Your session has expired due to inactivity',
      };
      router.push(`${routes.auth.signIn}?redirect=${router?.pathname}`);
      dispatch(resetExpired.resetExpiredAction());
      displayWarning(msg, classes.toastrSM);
    }
  }, [isExpired, resetStorage]);

  useEffect(() => {
    if (!isRobot && !resetStorage) {
      // console.log('hasCookiePolicy 22: ', hasCookiePolicy);
      // window[`ga-disable-${getGATrackingID()}`] = false;
      // const customConsentCookie = Cookies.getCookie(customConsentKey);
      // const customKeys = customConsentCookie?.split(',');

      const cookieValues = {
        alpha2,
        language,
        theme: initialThemeName,
        googleAnalytics: !pathname?.startsWith('/embed'),
      };

      processCookies({ cookieValues });
    }
    // else {
    // deleteCookiesAndStorageExceptConsent();
    // }
  }, [
    JSON.stringify(consent),
    hasCookiePolicy,
    alpha2,
    initialThemeName,
    language,
    resetStorage,
  ]);

  useEffect(() => {
    if (!appReady && !resetStorage) {
      setTimeout(() => {
        dispatch(appReadyState.appReadyStateAction());
      }, 2000);
    }
  }, [appReady, resetStorage]);

  useEffect(() => {
    if (!resetStorage) {
      storeGtmDataLayer({ event: 'updateDataLayer' });
    }
  }, [storePathname, resetStorage]);

  history.listen(location => {
    // const customConsentCookie = Cookies.getCookie(customConsentKey);
    // const customKeys = customConsentCookie?.split(',');

    if (!resetStorage) {
      const cookieValues = {
        pageLocation: location,
      };
      processCookies({ cookieValues });
    }
  });

  const storeNewCampaign = query => {
    const {
      utm_campaign,
      utm_source,
      utm_medium,
      utm_term,
      utm_content,
      utm_expire,
    } = query;
    const nowTime = new Date().getTime();
    const additionalTime = (utm_expire || campaignExpiryTimeSeconds) * 1000;
    const campaign = {
      campaign: utm_campaign || undefined,
      source: utm_source || undefined,
      medium: utm_medium || undefined,
      term: utm_term || undefined,
      content: utm_content || undefined,
      expiryTime: additionalTime ? nowTime + additionalTime : undefined,
      additionalTime: additionalTime || undefined,
    };
    Object.keys(campaign).forEach(key => {
      if (campaign[key] === undefined) {
        delete campaign[key];
      }
    });
    dispatch(storeCampaign.storeCampaignAction(campaign));
  };

  useEffect(() => {
    if (!resetStorage) {
      const {
        utm_campaign,
        utm_source,
        utm_medium,
        utm_term,
        utm_content,
        utm_expire,
      } = query;
      if (
        utm_campaign &&
        (!campaignStored?.campaign || utm_campaign !== campaignStored?.campaign)
      ) {
        storeNewCampaign(query);
      } else if (campaignStored?.campaign) {
        const expiryTime = campaignStored?.expiryTime;
        const nowTime = new Date().getTime();
        if (nowTime > expiryTime) {
          dispatch(storeCampaign.storeCampaignAction(null));
        }
        if (utm_campaign) {
          storeNewCampaign(query);
        }
      }
    }
  }, [resetStorage]);

  useEffect(() => {
    if (!resetStorage) {
      dispatch(updateTempState.updateTempStateAction({ isMobile }));
    }
  }, [isMobile, resetStorage]);

  // const yourLocation = useSelector(state => state.locations.yourLocation);
  // const countryCode = yourLocation?.countryCode || 'TT';

  // const locale = {};

  const loaded =
    (!resetStorage && loadedCountry && isRehydrated && appReady && currentAppSetting) ||
    appSettingError; // && loadedData
  // // console.log('loadedData: ', loadedData);
  // // console.log('loadedCountry: ', loadedCountry);
  // // console.log('language: ', language);
  // // console.log('myCountryCode: ', myCountryCode);
  // // console.log('boolVersion: ', boolVersion);
  const siteUnavailable = siteWide?.showSiteUnAvailable;
  const siteOffline = parseBool(siteUnavailable) && bypass !== 'c360admin';
  const offlineRoutes = siteWide?.serviceOfflineRoutes || [
    {
      exact: false,
      url: '/test',
    },
    {
      exact: false,
      url: '/help',
      inActive: doFeatureToggle('helpCenter'),
    },
  ];
  const serviceOfflineRoutes =
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'development' ? [] : offlineRoutes;

  const isSyncingPath = pathname?.startsWith('/embed/update-data');

  if (isSyncingPath) {
    return (
      <Switch>
        <Route
          path={routes.embed.index}
          component={props => <EmbeddedSection {...props} />}
        />
      </Switch>
    );
  }
  // console.log("Language & Country", languageCountry, messages);
  const remoteExcludedSeoUrls = siteWide?.excludedSeoUrls || [];
  const finalExcludedSeoUrls = excludedUrls || [];
  if (remoteExcludedSeoUrls) {
    finalExcludedSeoUrls.push(...remoteExcludedSeoUrls);
  }
  const dontSeoIndexPage = finalExcludedSeoUrls?.findIndex(
    x =>
      pathname?.includes(x?.url) > -1 ||
      pathname?.includes('test') ||
      pathname?.includes('embed') ||
      pathname?.includes('agent') ||
      pathname?.includes('insurancerep'),
  );

  return (
    <CustomIntlProvider language={language} alpha2={alpha2}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          {(process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' ||
            dontSeoIndexPage) && <meta name="robots" content="noindex" />}
          <title>myGGonline</title>
        </Helmet>
        {!loaded ? (
          <>
            <SplashScreen />
            {/* {<CountryLocator
            isFetchingLocation={isGettingYourLocationPending}
            countryCode={myCountryCode || loadedCountry}
            loading={!loaded}
            divHeight="100vh"
          />} */}
          </>
        ) : (
          <>
            <div
              style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
            >
              <Switch>
                <Route
                  path={routes.embed.index}
                  component={props => <EmbeddedSection {...props} />}
                />
                <Route
                  path={routes.index}
                  render={props => {
                    return (
                      <>
                        <SyncWithPublicSite />
                        <Routes
                          {...props}
                          siteOffline={siteOffline}
                          isRobot={isRobot}
                          serviceOfflineRoutes={serviceOfflineRoutes}
                          alpha2={alpha2}
                        />
                      </>
                    );
                  }}
                />
              </Switch>
            </div>
            {!cookieFeatureDisabled && cookiePolicyId && !isRobot && !siteOffline && (
              <CookieConsent
                message="This site uses cookies in order to analyze our traffic, and offer you the most relevant information (content and ads). Please accept cookies for optimal performance."
                // debug
                acceptButtonLabel="Accept"
                rejectButtonLabel="Reject"
                // onAccept={setCookieValues}
                // onReject={deleteCookiesAndStorageExceptConsent}
                // componentType="Dialog"
                activateCookies={activateCookies}
              >
                <CookieSnackbarContent />
              </CookieConsent>
            )}
            {doFeatureToggle('liveChat') && doFeatureToggle('oldChat') ? (
              <OldChatBot />
            ) : doFeatureToggle('liveChat') ? (
              <NewChatBot />
            ) : (
              <></>
            )}
            <RequestModal />
            <SettingsModal />
            <AlertPopUpModal />
          </>
        )}
      </div>
    </CustomIntlProvider>
  );
};

export default Main;
