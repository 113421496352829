import { GET_ALL_ARTICLES_API_SUCCESS } from '../../../../constants/helpCenter/helpCenterActionNames';

export const initialState = {
  allHelpArticles: [],
};

function allHelpArticlesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ARTICLES_API_SUCCESS:
      return {
        ...state,
        allHelpArticles: action.payload,
      };
    default:
      return state;
  }
}

export default allHelpArticlesReducer;
