import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Grid, Container, Typography, Tooltip } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import DetailsModal from '../detailsModal';
import { updateTempState } from '../../../actions/temp';
import { LocationSwitcher, ThemeSwitcher, LanguageSwitcher } from '../../common';

export default function Settings(props) {
  const dispatch = useDispatch();
  const open = useSelector(state => state.temp.settingsOpen);

  const handleClose = () => {
    dispatch(updateTempState.updateTempStateAction({ settingsOpen: false }));
  };

  const content = (
    <Container id={props.id}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
        <Grid item xs={12} sm={5} align="center">
          <Typography variant="body1">Choose your Appearance:</Typography>
        </Grid>
        <Grid item xs={12} sm={7} align="center">
          <ThemeSwitcher id="ModalThemeSwitcher" />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <Typography variant="body1">Choose your Location:</Typography>
        </Grid>
        <Grid item xs={12} sm={7} align="center">
          <LocationSwitcher view="fullText" id="ModalLocationSwitcher" />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <Typography variant="body1">Choose your Language:</Typography>
        </Grid>
        <Grid item xs={12} sm={7} align="center">
          <LanguageSwitcher type="select" id="ModalLanguageSwitcher" />
        </Grid>
        {/* <Grid item xs={12} sm={5} align="center">
          <Typography variant="body1">Date format:</Typography>
        </Grid>
        <Grid item xs={12} sm={7} align="center">
          <LanguageSwitcher type="select" />
        </Grid>
        */}
      </Grid>
    </Container>
  );

  return (
    <div>
      {open && (
        <DetailsModal
          title="My App Settings"
          onClose={handleClose} // dispatch(setModalContent.setModalContentAction(null))
          content={content}
        />
      )}
    </div>
  );
}
