import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// we import two react components from react-router-dom, one is Route which
// let's us to route to an individual page and the other is Switch
// which lets us break our routes into sections
// import { useSelector } from 'react-redux';
import Loadable from 'react-loadable';
import NotFound from '../componentsV2/common/notFound';
import Loading from '../componentsV2/common/loading';
// import InsuranceSummaryPage from '../containersV2/portfolio/portfolioSummary';
// import SignOutPage from '../containers/account/signOut';
// in the app we have two layouts which gives a template structure for our pages.
// One layout is applied to all our authentication pages, and is called AuthLayout
// the other layout is applied to the rest of our app, and is called MainLayout
// import Auth from './auth';
// import Main from './main';
// Auth and Main are sub-route directories placed in react components
// to section of the the routes with different layouts
import routes from '../utils/variables/routes';
import UnAuthenticatedRoute from './unAuthenticatedRoute';
// import NormalRoute from './normalRoute';

const AuthSection = Loadable({
  loader: () => import('./auth'),
  loading: () => <Loading addBottomNav={false} addFooter={false} addHeader={false} />,
});

const MainSection = Loadable({
  loader: () => import('./main'),
  loading: () => <Loading addBottomNav={false} addFooter={false} addHeader={false} />,
});

const SiteOffline = Loadable({
  loader: () => import('../containersV2/offline'),
  loading: () => <Loading addBottomNav={false} addFooter={false} addHeader={false} />,
});

const Routes = ({
  siteOffline = false,
  isRobot = false,
  serviceOfflineRoutes = [],
  alpha2,
}) => {
  return (
    <Switch>
      {siteOffline && !isRobot && <Route component={SiteOffline} />}
      {serviceOfflineRoutes?.map(newRoute => {
        if (
          newRoute?.inActive ||
          (alpha2 &&
            newRoute?.territory &&
            newRoute?.territory?.toLowerCase() !== alpha2?.toLowerCase())
        ) {
          return null;
        }
        return (
          <Route
            exact={newRoute?.exact}
            path={newRoute?.url}
            render={() => <Redirect to={routes.serviceOffline.index} />}
          />
        );
      })}
      {/*
      <Route
        exact
        path="/wizards/motor-quote"
        render={() => <Redirect to={routes.serviceOffline.index} />}
      />
      <Route
        exact
        path="/wizards/home-quote"
        render={() => <Redirect to={routes.serviceOffline.index} />}
      />
      */}

      <Route exact path="/login" render={() => <Redirect to={routes.auth.signIn} />} />
      <Route
        exact
        path={routes.portfolio.oldInsuranceSummary}
        render={() => <Redirect to={routes.portfolio.portfolioSummary} />}
      />
      <Route
        exact
        path={routes.portfolio.insuranceSummary}
        render={() => <Redirect to={routes.portfolio.portfolioSummary} />}
      />
      <Route
        exact
        path={routes.portfolio.investmentSummary}
        render={() => <Redirect to={routes.portfolio.newInvestmentSummary} />}
      />
      <Route
        exact
        path={routes.portfolio.oldInvestmentSummary}
        render={() => <Redirect to={routes.portfolio.newInvestmentSummary} />}
      />
      <Route
        exact
        path={routes.wizards.digitalSales}
        render={() => <Redirect to={routes.wizards.application} />}
      />
      <Route
        exact
        path="/agent"
        render={() => <Redirect to={routes.auth.insuranceRep} />}
      />
      <Route
        exact
        path="/corporate"
        render={() => <Redirect to={routes.auth.corporate} />}
      />
      <Route
        exact
        path="/insurancerep"
        render={() => <Redirect to={routes.auth.insuranceRep} />}
      />
      <Route
        exact
        path="/auth/signin"
        render={() => <Redirect to={routes.auth.signIn} />}
      />

      <UnAuthenticatedRoute
        path={routes.auth.index}
        component={props => <AuthSection {...props} />}
      />

      <Route path={routes.index} component={props => <MainSection {...props} />} />

      {/*
      <NormalRoute
        exact
        path={routes.portfolio.portfolioSummary}
        component={InsuranceSummaryPage}
      />
      <Route
        exact
        path={routes.portfolio.index}
        // component={InsurancePortfolioSummary}
        component={InsuranceSummaryPage}
      />

      <Route exact path={routes.account.signOut} component={SignOutPage} />
      */}

      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;

/*

      <Route exact path={routes.index} component={Loader} />

      */
