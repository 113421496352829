import React from 'react';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  lightModeLogo,
  darkModeLogo,
  ggIconOnly,
  oldLightModeLogo,
} from '../../../utils/variables/images';
import routes from '../../../utils/variables/routes';
import { brandLogoHeight } from '../../../utils/variables';
import styles from './style';
import { themeTypes } from '../../../themes';

const useStyles = makeStyles(styles);

const Brand = ({
  showWhite,
  showBlue,
  height = brandLogoHeight,
  disableRouting = false,
  iconOnly = false,
  padding = 0,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let newComponent = (
    <div className={classes.root}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {iconOnly ? (
        <img alt="Logo" src={ggIconOnly} height={height} className={classes.image} />
      ) : (theme.palette.mode === themeTypes.dark || showWhite) && !showBlue ? (
        <img alt="Logo" src={darkModeLogo} height={height} className={classes.image} />
      ) : (
        <img alt="Logo" src={lightModeLogo} height={height} className={classes.image} />
      )}
      {/* <img alt="Logo" src={lightModeLogo} height="70px" /> */}
    </div>
  );
  if (!disableRouting) {
    newComponent = (
      <Link to={routes.index} component={RouterLink}>
        {newComponent}
      </Link>
    );
  }
  return <div style={{ padding }}>{newComponent}</div>;
};

export default Brand;

/*

 <Grid container justifyContent="center" alignItems="center" align="center">
            <Grid item xs={12}>
              <Typography className={classes.font} variant="h5">
                Guardian Group
              </Typography>
            </Grid>
            <Grid item xs={12} />
          </Grid>

    <div className="flex flex-middle flex-space-between brand-area">

      <div className="flex flex-middle brand">
        <img src="/assets/images/logo.png" alt="company-logo" />
        <span className="brand__text">Matx</span>
      </div>

    </div>
    */
