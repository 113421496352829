export default theme => ({
  loadingMessage: {
    position: 'relative',
    width: '100%',

    display: ' flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    display: 'block',

    textAlign: 'center',
  },
  text: {
    marginTop: theme.spacing(1),
  },
});
