import React, { useState, useContext } from 'react';
import { ConsentContext } from '../context';
import Cookies from '../cookies/cookies';
import {
  consentKey,
  customConsentKey,
  consentAcceptExpireTime,
} from '../utils/variables';

const Consent = ({ children }) => {
  const { consent: initialConsent } = useContext(ConsentContext);

  const [consent, setConsent] = useState(initialConsent);
  // // console.log('consent11: ', consent);

  return (
    <ConsentContext.Provider
      value={{
        consent,
        setConsent: value => {
          const keys = value?.customConsent || [];
          const cookies = value?.cookies || [];
          const consentValue = value?.consent;
          const cookiePolicyVersion = value?.version;

          // const keyChain = `${keys?.map(key => key).join(',')}@${cookies
          //  ?.map(cookie => cookie?.cookieId)
          //  .join(',')}@${cookiePolicyVersion}`;
          // handleClose();
          const cookieString = JSON.stringify(cookies);
          const delimiter = '@';
          const keyString = JSON.stringify(keys);
          const versionString = cookiePolicyVersion;
          // const keyChain = `${keys?.map((key) => key).join(',')}@${cookies
          //  ?.map((cookie) => cookie?.cookieId)
          //  .join(',')}@${cookiePolicyVersion}`;

          if (consentValue) {
            const keyChain = `${keyString}${delimiter}${cookieString}${delimiter}${versionString}`;
            // console.log('keyChain: ', keyChain);

            Cookies.setCookie(consentKey, consentValue, consentAcceptExpireTime);
            Cookies.setCookie(customConsentKey, keyChain, consentAcceptExpireTime);

            setConsent(value);
          }
        },
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

export default Consent;
