import { crmPostCall } from '../../axios';
import { helpCenterApi } from '../../../utils/functions/helpCenterApis';

export const submitHelpRequestUrl = helpCenterApi('submitHelpRequestUrl');
const submitHelpRequest = (state, body) => {
  const url = submitHelpRequestUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  throw new Error('Server Error');
};
export default { submitHelpRequest };
