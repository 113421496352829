import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_RECEIPTS_API_REQUEST,
  GET_RECEIPTS_API_SUCCESS,
  GET_RECEIPTS_API_ERROR,
  GET_NEXT_RECEIPTS_SUCCESS,
  GET_INDIVIDUAL_RECEIPT_API_REQUEST,
  GET_INDIVIDUAL_RECEIPT_API_SUCCESS,
  GET_INDIVIDUAL_RECEIPT_API_ERROR,
  GET_INDIVIDUAL_RECEIPT_CANCEL,
} from '../../constants/receiptActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';

import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  dateTimeToString,
  getAllKeys,
  uniq,
  getKeys,
  addArrayOnce,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';
import { CLEAR_RECEIPTS_CACHE } from '../../constants/miscActionNames';

const createReceipt = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    // console.log("Create Receipt", object);
    return {
      id: index,
      receiptNo: object?.receiptNo,
      policies: object?.policies,
      totalReceiptAmount: object?.totalReceiptAmount,
    };
  });
};

let searchKeys = [];

const createPayload = (payloadArray, body) => {
  // console.log("PAyload Array", payloadArray);
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const newReceipt = payloadArray.map((object, index) => {
    const position = index;
    const receipt = {
      ...object,
      index: position,
      cacheId: `${object?.orderNo}_${body?.alpha2}`,
      id: object?.orderNo,
      slug: object?.orderNo,
      date: dateToString(isoStringToDate(object?.paymentDate)),
      filterDate: dateToString(isoStringToDate(object?.paymentDate)),
      filterCurrency: object?.currency,
      totalAmount: object?.totalOrderAmount,
      currency: object?.currency,
      orderNo: object?.orderNo,
      receipt: createReceipt(object?.receipts),
      body,

      // receiptNo: object?.receiptNo,
    };
    const newKeys = getKeys(receipt);

    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    // console.log("receipt", receipt);
    return receipt;
  });
  newReceipt.sort((a, b) => {
    return b.filterDate < a.filterDate ? -1 : 1;
  });

  return newReceipt;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  isIndividualReceiptPending: false,
  isIndividualReceiptSuccess: false,
  isIndividualReceiptError: false,
  individualReceiptResponse: null,
  allRows: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys,
  [DEFAULT_KEY]: null,
  // pageItemCount: 5,
};
// createPayload(payloadData, 0)

function receiptsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECEIPTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_RECEIPTS_API_SUCCESS: {
      const response = action?.payload;
      const rowInfo = response?.payload;
      const cacheKey = action?.cacheKey;
      const body = action?.params;

      const initialRows = rowInfo?.receipts;
      // console.log("Response", initialRows);
      const newRows = createPayload(initialRows, body) || [];
      const oldRows = (state?.allRows && [...state?.allRows]) || [];
      const allRows = addArrayOnce(oldRows, newRows, 'cacheId');
      // const allRows = [...newRows, ...state?.allRows];
      const isError = response?.responseType !== statusOk;
      const count = allRows?.length || 0;

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        // rows,
        count,
        searchKeys,
        allRows,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }

    case GET_RECEIPTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action.payload,
      };

    case GET_NEXT_RECEIPTS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case GET_INDIVIDUAL_RECEIPT_API_REQUEST: {
      return {
        ...state,
        isIndividualReceiptPending: true,
        isIndividualReceiptSuccess: false,
        isIndividualReceiptError: false,
      };
    }
    case GET_INDIVIDUAL_RECEIPT_API_SUCCESS: {
      const response = action?.payload;
      /// console.log('Reducer', response);
      return {
        ...state,
        isIndividualReceiptPending: false,
        isIndividualReceiptSuccess: true,
        isIndividualReceiptError: false,
        individualReceiptResponse: response,
      };
    }
    case GET_INDIVIDUAL_RECEIPT_API_ERROR: {
      return {
        ...state,
        isIndividualReceiptPending: false,
        isIndividualReceiptSuccess: false,
        isIndividualReceiptError: true,
      };
    }
    case GET_INDIVIDUAL_RECEIPT_CANCEL: {
      return {
        ...state,
        isIndividualReceiptPending: false,
        isIndividualReceiptSuccess: false,
        isIndividualReceiptError: false,
      };
    }

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        allRows: initialState.allRows,
        [DEFAULT_KEY]: null,
      };
    }

    case CLEAR_RECEIPTS_CACHE: {
      return {
        ...state,
        allRows: initialState.allRows,
        [DEFAULT_KEY]: null,
      };
    }

    default:
      return state;
  }
}

export default receiptsReducer;
