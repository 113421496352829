import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { Grid, Typography, Icon, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';
import { textAlignTypes, textVariantTypes, textColorTypes } from '../../propertyValues';
import styles from './style';
import { useSmall } from '../../../hooks';
import { sourceTextFromData } from '../../../utils/functions';
import ImageBox from '../imageBox/index';

const useStyles = makeStyles(styles);

const Header = ({
  title,
  seoTitle,
  subTitle,
  seoSubTitle,
  align,
  titleColor,
  subTitleColor,
  titleVariant,
  subTitleVariant,
  oneLiner,
  cover,
  actions,
  contentLayout,
  actionsLayout,
  noPadding,
  disableSeo,
}) => {
  const classes = useStyles();
  const isSmall = useSmall();
  const storeState = useStore()?.getState();

  const titleGridSize = contentLayout?.lg || (actions && actions.length > 0 ? 8 : 12);
  const actionsGridSize = actionsLayout?.lg || (actions && actions.length > 0 ? 4 : 12);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
      sx={{ px: isSmall && !noPadding ? 1 : 0, py: isSmall && !noPadding ? 1 : 0 }}
    >
      {!disableSeo && (
        <Helmet>
          <meta charSet="utf-8" />
          {process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' && (
            <meta name="robots" content="noindex" />
          )}
          <title>{seoTitle || title}</title>
          <meta name="description" content={seoSubTitle || subTitle} />
        </Helmet>
      )}
      <Grid item sm={contentLayout?.sm || 12} lg={titleGridSize}>
        {cover && (
          <ImageBox
            source={{
              sm: { url: cover.url },
              md: { url: cover.url },
              lg: { url: cover.url },
            }}
          />
        )}
        {title && (
          <Typography
            variant={titleVariant}
            align={align}
            className={classes.header}
            color={titleColor}
          >
            {sourceTextFromData(storeState, title)}
          </Typography>
        )}
        {subTitle && (
          <Typography
            variant={subTitleVariant}
            align={align}
            color={subTitleColor}
            className={classnames(
              { [classes.oneLineSubHeader]: oneLiner },
              { [classes.subHeader]: !oneLiner },
            )}
          >
            {sourceTextFromData(storeState, subTitle)}
          </Typography>
        )}
      </Grid>
      {actions && actions.length > 0 && (
        <Grid
          item
          xs={12}
          sm={actionsLayout?.sm || 12}
          lg={actionsGridSize}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {actions.map(
            action =>
              !action?.hide && (
                <Button
                  className={classes[action?.variant || 'primaryButton']}
                  size={action?.size || 'small'}
                  fullWidth={action?.fullWidth || false}
                  onClick={action?.onClick}
                  component={RouterLink}
                  to={action?.link}
                  startIcon={
                    action?.icon && (
                      <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                        {action?.icon}
                      </Icon>
                    )
                  }
                  sx={{
                    width: 'auto',
                    p: '.5rem 1.25rem',
                    mx: 0.5,
                    textAlign: action?.textAlign || 'left',
                  }}
                >
                  {action?.title}
                </Button>
              ),
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Header;

Header.propTypes = {
  align: PropTypes.oneOf(textAlignTypes),
  title: PropTypes.string,
  titleColor: PropTypes.oneOf(textColorTypes),
  titleVariant: PropTypes.oneOf(textVariantTypes),
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.oneOf(textColorTypes),
  subTitleVariant: PropTypes.oneOf(textVariantTypes),
  oneLiner: PropTypes.bool,
};

Header.defaultProps = {
  align: 'left',
  subTitle: '',
  title: '',
  titleColor: 'text.main',
  subTitleColor: 'text.disabled',
  titleVariant: 'h6',
  subTitleVariant: 'subtitle1',
  oneLiner: false,
};

/*
className={classnames(
              { [classes.oneLineSubHeader]: oneLiner },
              { [classes.subHeader]: !oneLiner },
            )}
            */
