import { gql } from 'graphql-request';

const BUTTON_GROUP_CHOICE_FIELDS = gql`
  fragment buttonGroupChoice on ComponentWizardComponentsButtonGroupChoice {
    ButtonGroupChoice_slug: slug
    ButtonGroupChoice_name: name
    ButtonGroupChoice_label: label
    ButtonGroupChoice_variant: variant
    ButtonGroupChoice_labelAlign: labelAlign
    ButtonGroupChoice_orientation: orientation
    ButtonGroupChoice_size: size
    ButtonGroupChoice_color: color
    ButtonGroupChoice_staticValue: staticValue
    ButtonGroupChoice_reducerKeyValue: reducerKeyValue
    ButtonGroupChoice_positionNo: positionNo
    ButtonGroupChoice_layoutPosition: layoutPosition
    ButtonGroupChoice_fullWidth: fullWidth
    ButtonGroupChoice_required: required
    ButtonGroupChoice_isConditionalComponent: isConditionalComponent
    ButtonGroupChoice_conditionalValue: conditionalValue
    ButtonGroupChoice_conditionalKey: conditionalKey
    ButtonGroupChoice_conditionalOperator: conditionalOperator
    ButtonGroupChoice_conditionalFieldType: conditionalFieldType
    ButtonGroupChoice_conditionalAction: conditionalAction
    ButtonGroupChoice_disable: disable
    ButtonGroupChoice_show: show
    ButtonGroupChoice_tooltip: tooltip
    ButtonGroupChoice_tooltip_en: tooltip_en
    ButtonGroupChoice_tooltip_nl: tooltip_nl
    ButtonGroupChoice_label_en: label_en
    ButtonGroupChoice_label_nl: label_nl
    ButtonGroupChoice_disableInitialValue: disableInitialValue
    ButtonGroupChoice_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    ButtonGroupChoice_options: options {
      ...nonRecursiveOption
    }
    ButtonGroupChoice_conditions: conditions {
      ...condition
    }
  }
`;

// export default BUTTON_GROUP_CHOICE_FIELDS;
// exports.default = BUTTON_GROUP_CHOICE_FIELDS;
export default BUTTON_GROUP_CHOICE_FIELDS;
