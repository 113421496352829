// // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
import ReactGa from 'react-ga';
import TagManager from 'react-gtm-module';
import { getAppMode } from './misc';
import { validateGTMObj } from './objectManipulation';

export const GA_TRACKING_ID_PROD = 'UA-45667516-5'; // G-QT39CX574R
export const GA_TRACKING_ID_DEV = 'UA-186558011-3'; // G-G77RC68R0P
export const GA_TRACKING_ID_TEST = 'UA-186558011-2'; // G-SDQNYSET
const GTM_TRACKING_ID = 'GTM-WVN7797';

let clientId = '';

export const getGATrackingID = () => {
  switch (process.env.REACT_APP_CUSTOM_NODE_ENV) {
    case 'production':
      return GA_TRACKING_ID_PROD;
    case 'development':
      return GA_TRACKING_ID_DEV;
    case 'staging':
      return GA_TRACKING_ID_TEST;
    default:
      return GA_TRACKING_ID_DEV;
  }
};

export const pageview = url => {
  // window.gtag('config', getGATrackingID(), {
  //  page_path: url,
  // });
};

const injectGA = ({ trackingID, hasAllowedGoogleAnalytics, hasAllowedGoogleAds }) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', trackingID);
  gtag('config', 'GA_MEASUREMENT_ID', {
    send_page_view: hasAllowedGoogleAnalytics,
  });
  gtag('set', 'allow_google_signals', hasAllowedGoogleAds);
  gtag('set', 'allow_ad_personalization_signals', hasAllowedGoogleAds);
};

export const loadGoogleAnalytics = (
  hasAllowedGoogleAnalyticsPageView,
  hasAllowedGoogleAds,
  dimensions,
) => {
  // console.log('getGATrackingID: ', getGATrackingID());
  // injectGA({
  //  trackingID: getGATrackingID(),
  //  hasAllowedGoogleAnalytics,
  //  hasAllowedGoogleAds,
  // });

  ReactGa.initialize(getGATrackingID());
  const ga = ReactGa.ga();
  ga('set', 'allowAdFeatures', hasAllowedGoogleAds);
  ga('set', 'anonymizeIp', !hasAllowedGoogleAnalyticsPageView);
  ga('set', 'allowAdPersonalizationSignals', hasAllowedGoogleAds);
  window[`ga-disable-${getGATrackingID()}`] = false;

  const obj = {};
  obj.GA_TRACKING_ID = getGATrackingID();

  ga(tracker => {
    // console.log(tracker.get('clientId'));
    clientId = tracker.get('clientId');

    if (clientId) {
      obj.clientId = clientId;
    }
    const appMode = getAppMode();
    if (appMode) {
      obj.appMode = appMode;
    }
    if (dimensions?.customerId) {
      obj.customerId = dimensions?.customerId;
    }
    if (dimensions?.usertype) {
      obj.usertype = dimensions?.usertype;
    }

    if (hasAllowedGoogleAnalyticsPageView) {
      if (dimensions?.alpha2) {
        // ga('set', 'dimension1', dimensions.alpha2);
        ReactGa.set({ dimension1: dimensions?.alpha2 });
      }
      if (dimensions?.theme) {
        // ga('set', 'dimension2', dimensions.theme);
        ReactGa.set({ dimension2: dimensions?.theme });
      }
      if (clientId) {
        // ga('set', 'dimension3', clientId);
        ReactGa.set({ dimension3: clientId });
      }
      if (dimensions?.customerId) {
        // ga('set', 'dimension4', dimensions.customerId);
        ReactGa.set({ dimension4: dimensions.customerId });
      }
      if (dimensions?.usertype) {
        ReactGa.set({ dimension5: dimensions.usertype });
      }
      const appMode = getAppMode();
      if (appMode) {
        ReactGa.set({ dimension6: appMode });
      }

      // ga('send', 'pageview', window.location.pathname + window.location.search);
      const url = window.location.pathname + window.location.search;
      ReactGa.pageview(url);
      // console.log('initial page sent');
    }

    // console.log('adad', obj);

    const tagManagerArgs = {
      gtmId: GTM_TRACKING_ID,
      dataLayer: { ...validateGTMObj(obj) },
      dataLayerName: 'PageDataLayer',
    };

    TagManager.initialize(tagManagerArgs);
  });
};

export const updateDataLayer = dimensions => {
  if (clientId) {
    dimensions.clientId = clientId;
  }
  const appMode = getAppMode();
  if (appMode) {
    dimensions.appMode = appMode;
  }
  //console.log('dimensions: ', dimensions);
  const tagManagerArgs = {
    dataLayer: {
      ...validateGTMObj(dimensions),
    },
    dataLayerName: 'PageDataLayer',
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const storePageView = (location, dimensions) => {
  ReactGa.set({ page: location.pathname + location.search });

  if (dimensions?.alpha2) {
    ReactGa.set({ dimension1: dimensions?.alpha2 });
  }
  if (dimensions?.theme) {
    ReactGa.set({ dimension2: dimensions?.theme });
  }
  if (clientId) {
    ReactGa.set({ dimension3: clientId });
  }
  if (dimensions?.customerId) {
    ReactGa.set({ dimension4: dimensions.customerId });
  }
  if (dimensions?.usertype) {
    ReactGa.set({ dimension5: dimensions.usertype });
  }
  const appMode = getAppMode();
  if (appMode) {
    ReactGa.set({ dimension6: appMode });
  }
  ReactGa.pageview(location.pathname + location.search);
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const storeEvent = (event = {}, dimensions) => {
  const { action, category, label, value, nonInteraction = false } = event;

  // console.log('action: ', action);
  if (action) {
    // console.log('action: ', action);
    // console.log('label: ', label);
    // console.log('value: ', value);
    if (dimensions?.alpha2) {
      ReactGa.set({ dimension1: dimensions?.alpha2 });
    }
    if (dimensions?.theme) {
      ReactGa.set({ dimension2: dimensions?.theme });
    }
    if (clientId) {
      ReactGa.set({ dimension3: clientId });
    }
    if (dimensions?.customerId) {
      ReactGa.set({ dimension4: dimensions.customerId });
    }
    if (dimensions?.usertype) {
      ReactGa.set({ dimension5: dimensions.usertype });
    }
    const appMode = getAppMode();
    if (appMode) {
      ReactGa.set({ dimension6: appMode });
    }
    ReactGa.event({
      category: category || 'Non-Campaign Tracking',
      action,
      label,
      value: value || value === 0 ? Math.round(value) : undefined,
      nonInteraction,
    });
  }
};

export const storeModalPageView = (url, dimensions) => {
  ReactGa.set({ page: url });
  if (dimensions?.alpha2) {
    ReactGa.set({ dimension1: dimensions?.alpha2 });
  }
  if (dimensions?.theme) {
    ReactGa.set({ dimension2: dimensions?.theme });
  }
  if (clientId) {
    ReactGa.set({ dimension3: clientId });
  }
  if (dimensions?.customerId) {
    ReactGa.set({ dimension4: dimensions.customerId });
  }
  if (dimensions?.usertype) {
    ReactGa.set({ dimension5: dimensions.usertype });
  }
  const appMode = getAppMode();
  if (appMode) {
    ReactGa.set({ dimension6: appMode });
  }
  ReactGa.modalview(url);
};
