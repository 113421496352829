import {
  GET_REQUESTS_API_REQUEST,
  GET_REQUESTS_API_SUCCESS,
  GET_REQUESTS_API_ERROR,
  GET_NEXT_REQUESTS_SUCCESS,
  GET_REQUEST_HISTORY_API_REQUEST,
  GET_REQUEST_HISTORY_API_SUCCESS,
  GET_REQUEST_HISTORY_API_ERROR,
  SUBMIT_REQUEST_API_REQUEST,
  SUBMIT_REQUEST_API_ERROR,
  SUBMIT_REQUEST_API_SUCCESS,
} from '../../constants/requestActionNames';
import { RESET_INFINITE_SCROLL_SUCCESS } from '../../constants/miscActionNames';

import payloadData from './payloadData';
import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  dateTimeToString,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';

// parseInt(startIndex + index, 10)
//
const createPayload = (payloadArray, lobTypes) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex =
        object?.lob?.toLowerCase().indexOf(lobObject.lob) > -1 ||
        object?.coverage?.toLowerCase().indexOf(lobObject.lob);
      // // console.log('index: ', index2, lobObject.lob);

      return lobIndex > -1;
    });
    const position = index;
    return {
      index: position,
      id: object?._id, // ? `${object._id}_${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      slug: object?._id?.split('_')[1],
      filterDate: dateToString(isoStringToDate(object?.reportDate)),
      lob: lobObj?.lob,
      icon: lobObj?.icon,
      filterId: object?.requestNumber,
    };
  });
};

const createTypeFromOperation = operation => {
  if (
    operation === 'submit-request-investment-deposit-pw' ||
    operation === 'submit-request-investment-deposit-mf'
  ) {
    return 'Deposit';
  }
  if (
    operation === 'submit-request-investment-transfer-pw' ||
    operation === 'submit-request-investment-transfer-mf'
  ) {
    return 'Transfer';
  }
  if (
    operation === 'submit-request-investment-withdrawal-pw' ||
    operation === 'submit-request-investment-withdrawal-mf'
  ) {
    return 'Withdrawal';
  }
  if (
    operation === 'submit-request-investment-switch-pw' ||
    operation === 'submit-request-investment-switch-mf'
  ) {
    return 'Switch';
  }
};

const createNewRequestPayload = (payloadArray, startIndex = 0) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    const position = startIndex + index;
    return {
      ...object,
      index: position,
      additionalDetails: object?.additionalDetails,
      amount: object?.amount,
      operation: object?.operation,
      // clientEmail: object?.clientEmail,
      // dateOfBirth: object?.dateOfBirth,
      // email: object?.email,
      // firstName: object?.firstName,
      // lastName: object?.lastName,
      // mobile: object?.mobile,
      // territory:object?.territory,
      currency: object?.currency,
      paymentType: object?.paymentType,
      sourceAccount: object?.sourceAccount,
      sourceFund: object?.sourceFund,
      depositType: object?.depositType,
      depositTypeDetails: object?.depositTypeDetails,
      destinationAccount: object?.destinationAccount,
      destinationFund: object?.destinationFund,
      fullTransfer: object?.fullTransfer,
      fullSwitch: object?.fullSwitch,
      type: createTypeFromOperation(object?.operation),
      date: dateToString(isoStringToDate(object?.createdOn)),
      createdOn: object?.createdOn,
    };
  });
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  // pageItemCount: 5,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  // startIndex: 0,
  isRequestHistoryPending: false,
  isRequestHistoryError: false,
  isRequestHistorySuccess: false,
  requestHistoryResponse: null,
  newRequestHistoryResponse: null,
  isSubmitRequestPending: false,
  isSubmitRequestError: false,
  isSubmitRequestSuccess: false,
  submitRequestResponse: null,
  submitRequestError: null,
};
// createPayload(payloadData, 0)

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ requests: payloadData, count: 100000 }] };
};

function requestsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REQUESTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_REQUESTS_API_SUCCESS: {
      const response = action?.payload;
      const lobTypes = action?.lobTypes;
      const rowInfo =
        response?.payload && response?.payload.length > 0 ? response?.payload[0] : null;
      const initialRows = rowInfo?.requests;
      // // console.log('initialRequests: ', initialRequests);
      // const startIndex = action?.params?.offset;
      const allRows = createPayload(initialRows, lobTypes) || initialState?.allRows;
      const isError = response?.responseType !== statusOk;
      const count = allRows?.length || 0;

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        // response,
        // rows,
        count,
        // startIndex,
        allRows,
      };
    }

    case GET_REQUESTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };

    case GET_NEXT_REQUESTS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }
    case GET_REQUEST_HISTORY_API_REQUEST:
      return {
        ...state,
        isRequestHistoryPending: true,
        isRequestHistoryError: false,
        isRequestHistorySuccess: false,
        // error: action.payload,
      };
    case GET_REQUEST_HISTORY_API_SUCCESS: {
      const newPayload = createNewRequestPayload(action?.payload?.payload);
      return {
        ...state,
        isRequestHistoryPending: false,
        isRequestHistoryError: false,
        isRequestHistorySuccess: true,
        requestHistoryResponse: action.payload,
        newRequestHistoryResponse: newPayload,
      };
    }
    case GET_REQUEST_HISTORY_API_ERROR:
      return {
        ...state,
        isRequestHistoryPending: false,
        isRequestHistoryError: true,
        isRequestHistorySuccess: false,
        // error: action.payload,
      };

    case SUBMIT_REQUEST_API_REQUEST: {
      return {
        ...state,
        isSubmitRequestPending: true,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: false,
      };
    }
    case SUBMIT_REQUEST_API_SUCCESS: {
      const response = action?.payload;

      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: true,
        submitRequestResponse: response,
      };
    }
    case SUBMIT_REQUEST_API_ERROR: {
      const error = action?.payload;

      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: true,
        isSubmitRequestSuccess: false,
        submitRequestError: error,
      };
    }

    default:
      return state;
  }
}

export default requestsReducer;
