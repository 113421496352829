import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';

export const getDropdownValuesUrl = helpCenterApi('getDropdownValuesUrl');
const getDropdownValues = (state, body) => {
  const url = getDropdownValuesUrl;
  if (url) {
    return crmPostCall(state, url, body);
  } else {
    throw new Error('Server Error');
  }
};
export default { getDropdownValues };
