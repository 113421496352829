import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_CLIENTS_API_REQUEST,
  GET_CLIENTS_API_SUCCESS,
  GET_CLIENTS_API_ERROR,
  GET_NEXT_CLIENTS_SUCCESS,
} from '../../constants/clientActionNames';
import { RESET_INFINITE_SCROLL_SUCCESS } from '../../constants/miscActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';
import payloadData from './payloadData';
import {
  uuidv4,
  dateToString,
  stringQuoteToDate,
  isoStringToDate,
  getAllKeys,
  getKeys,
  uniq,
} from '../../utils/functions';
import { createPayload as createProfilePayload } from '../profile';
import { persistKey, statusOk } from '../../utils/variables';

// .slice(0, loadClientsCount)
let searchKeys = [];

export const createPayload = (payloadArray, lobTypes) => {
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const newClients = payloadArray?.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex = object?.lob?.toLowerCase() === lobObject.lob;
      // // console.log('index: ', index2, lobObject.lob);

      return lobIndex;
    });
    const position = index;
    const convertedDate = stringQuoteToDate(object?.createTime);
    // // console.log('convertedDate: ', convertedDate);

    // console.log("icon: " + JSON.stringify(lobObj));
    const client = {
      ...createProfilePayload(object),
      index: position,
      id: object?.masterId, // object?._id, // ? `${object._id}_${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      clientNumber: object?.masterId, // object?._id,
      slug: object?.masterId, // object?._id,
      icon: lobObj?.materialIcon,
      // dob: object?.dob,
      dob: dateToString(isoStringToDate(object?.dob)),
      createTime: object?.createTime, // dateToString(isoStringToDate(object?.quoteData?.createTime)),
      expiredTime: object?.expiredTime, // dateToString(isoStringToDate(object?.quoteData?.expiredTime)),
      firstName: object?.firstName,
      lastName: object?.lastName,
      clientFullName: `${object?.firstName} ${object?.lastName}`,
      clientFullNameNoSpace: `${object?.firstName} ${object?.lastName}`?.replace(
        / /g,
        '',
      ),
      email: object?.primaryEmail,
      address: `${object?.addresses?.find(x => x.type == 'RES')?.addressLine1} ${
        object?.addresses?.find(x => x.type == 'RES')?.addressLine2 != undefined
          ? object?.addresses?.find(x => x.type == 'RES')?.addressLine2
          : ''
      } ${object?.addresses?.find(x => x.type == 'RES')?.city}`,
      homeNumber:
        object?.phones?.find(x => x.type == 'RES')?.value == undefined
          ? ''
          : object?.phones?.find(x => x.type == 'RES')?.value,
      mobileNumber:
        object?.phones?.find(x => x.type == 'MOB')?.value == undefined
          ? ''
          : object?.phones?.find(x => x.type == 'MOB')?.value,
      maritalStatus: object?.maritalStatus == undefined ? 'N/A' : object?.maritalStatus,
      // gender:
      // object?.gender == 'M' ? 'Male' : object?.gender == 'F' ? 'Female' : 'Others',
      // ...searchWords,
    };

    // const searchWords = generateSearchWords(object);
    // const newKeys = getKeys(searchWords);
    // // console.log('newKeys: ', newKeys);
    // searchKeys = [...searchKeys, ...newKeys];
    // searchKeys = uniq(searchKeys);
    // // console.log('searchWords: ', searchWords);

    // const reduceObj = reduceKeys(obj);
    const newKeys = getKeys(client);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    // // console.log('searchKeys: ', searchKeys);

    return client;
  });

  newClients.sort((a, b) => {
    return b.slug > a.slug ? 1 : -1;
  });

  return newClients;
};

export const initialState = {
  selectedClient: null,
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys,
  [persistKey]: null,
  [DEFAULT_KEY]: null,
};
// createPayload(payloadData, 0)

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ clients: payloadData, count: 100000 }] };
};

function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_CLIENTS_API_SUCCESS: {
      const response = action?.payload;
      const rows = response?.payload;
      const lobTypes = action?.lobTypes;
      const isError = response?.responseType !== statusOk;
      // // console.log('initialCLIENTS: ', initialClients);
      // const startIndex = action?.params?.offset;
      const allRows = createPayload(rows, lobTypes) || initialState?.allRows;
      // console.log('ALL ROWS', allRows);
      const cacheKey = action?.cacheKey;

      const count = allRows?.length || 0;
      response.payload = null;
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        // rows,
        count,
        searchKeys,
        allRows,
        [persistKey]: nowTime,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }

    case GET_CLIENTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action.payload,
      };

    case GET_NEXT_CLIENTS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
      };
    }
    default:
      return state;
  }
}

export default clientsReducer;
