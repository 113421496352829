import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext, ConsentContext } from '../context';
import { activateCookies, parseBool } from '../utils/functions';
import { acceptAllConsentValue, acceptCustomConsentValue } from '../utils/variables';

const useGTMDataLayer = () => {
  const cookieFeatureDisabled = !parseBool(process.env.REACT_APP_COOKIE_CONSENT);
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  const alpha3 = useSelector(state => state?.locations?.yourLocation?.alpha3);
  const customerId = useSelector(state => state?.auth?.user?.customer?.id);
  const usertype = useSelector(state => state?.auth?.usertype);
  const { consent, setConsent } = useContext(ConsentContext);
  const { themeName: theme } = useContext(ThemeContext);

  return dataObj => {
    const cookieValues = {
      updateDataLayer: true,
    };
    const dimensions = {
      alpha2,
      alpha3,
      theme,
      customerId,
      usertype,
      ...dataObj,
    };

    const consentValue = consent?.consent; // Cookies.getCookie(consentKey);
    const keys = consent?.customConsent || [];
    const cookies = consent?.cookies || [];
    const cookiePolicyVersion = consent?.version;
    const hasCookiePolicy = !!consentValue;

    if (
      consentValue === acceptAllConsentValue ||
      consentValue === acceptCustomConsentValue ||
      cookieFeatureDisabled
    ) {
      const result = activateCookies(
        cookies,
        consentValue,
        keys,
        cookieValues,
        null,
        dimensions,
        hasCookiePolicy,
      );
    }
  };
};

export default useGTMDataLayer;
