import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useRouter } from '../../../hooks';
// import routes from '../../../utils/variables/routes';
import { signOut } from '../../../actions/auth';
// import { accessTokenStorage } from '../../../utils/variables';
// import { getAccessToken } from '../../../storage/accessToken';
import {
  getSessionStorageKey,
  removeSessionStorageKey,
  sessionStorageKey,
} from '../../../utils/variables';

const TokenListener = () => {
  // const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    // transfers sessionStorage from one tab to another
    const sessionStorageTransfer = event => {
      if (!event) {
        event = window.event;
      } // ie suq

      if (!event.newValue) return;

      if (event.key === removeSessionStorageKey) {
        dispatch(signOut.signOutAction({ signOutType: 'auto' })).then(() => {});
      }

      if (event.key === getSessionStorageKey) {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem(sessionStorageKey, JSON.stringify(window.sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem(sessionStorageKey); // <- could do short timeout as well.
      } else if (event.key === sessionStorageKey && !window.sessionStorage.length) {
        // another tab sent data <- get it
        const data = JSON.parse(event.newValue);
        for (const key in data) {
          window.sessionStorage.setItem(key, data[key]);
        }
      }
    };

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener('storage', sessionStorageTransfer, false);
    } else {
      window.attachEvent('onstorage', sessionStorageTransfer);
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!window.sessionStorage.length) {
      localStorage.setItem(getSessionStorageKey, 'foobar');
      localStorage.removeItem(getSessionStorageKey, 'foobar');
    }

    return () => {
      window.removeEventListener('keydown', sessionStorageTransfer, false);
      window.removeEvent('onstorage', sessionStorageTransfer);
    };
  }, [window.sessionStorage]);

  return <div />;
};

export default TokenListener;

/*

  const router = useRouter();
  // const token = getAccessToken();
  useEffect(() => {
    const handleInvalidToken = e => {
      if (e.key === accessTokenStorage) {
        // && e.oldValue && !e.newValue
        // console.log('e.newValue: ', e.newValue);
        // Your logout logic here

        // console.log(e);
        if (e.newValue) {
          window.location.reload();
        } else {
          router.push(routes.account.signOut);
        }
      }
    };
    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, []);
  */
