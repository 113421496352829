import queryString from 'query-string';
import Fuse from 'fuse.js';
// import initialLobTypes from '../utils/variables/lobTypes.json';
import { updateQueryHistory, fuseOptions, trimOneChar } from './functions';
import { addItemOnce, getKeys, reduceKeys, keySort } from '../utils/functions';

/*
const lobTypes = addItemOnce(
  initialLobTypes,
  {
    id: '001',
    slug: 'all',
    lob: 'all',
    title: 'All',
  },
  'slug',
);
*/
// const lobTypes = [...initialLobTypes];
// lobTypes.unshift({
// slug: 'all',
// lob: 'all',
// title: 'All',
// });

const historyList = [];

export const productOptions = state => {
  const { location } = state.router;
  const { lobCategories } = state.locations;
  const newLobTypes = addItemOnce(
    lobCategories,
    {
      id: 'all',
      slug: 'all',
      lob: 'all',
      title: 'All',
    },
    'slug',
  );
  const products = state?.products?.products || [];
  const parsed = queryString.parse(location.search);
  // const { parsed, history } = updateQueryHistory(historyList, location);
  // historyList = [...history];
  // eslint-disable-next-line no-nested-ternary
  const lobArray = Array.isArray(parsed?.lob)
    ? parsed?.lob
    : typeof parsed?.lob === 'string' && parsed?.lob.length > 0
    ? parsed?.lob.split(',')
    : null;

  const options = {
    lob: lobArray || [],
    slug: parsed?.slug || 'all',
    lobTypes: newLobTypes || [],
    search: parsed?.search || '',
  };
  // // console.log('options: ', options);
  const newItems = products.filter(
    e =>
      !e?.filterLob ||
      options.lob.some(
        option =>
          e.filterLob?.toLowerCase() === option?.toLowerCase() ||
          option?.toLowerCase() === 'all',
      ),
  );
  newItems.push({
    id: 'all',
    slug: 'all',
    lob: 'all',
    title: 'All',
  });

  /*
  const item = newItems.find(info => info.slug === options.slug);
  if (!item || (options?.lob !== 'all' && item?.filterLob !== options.lob)) {
    options.slug = 'all';
  }
  */

  options.products = newItems;
  // // console.log('options: ', options);

  return options;
};

export const productFind = (state, match) => {
  // const { location } = state.router;
  const { products } = state?.products;

  const slug = match?.params?.slug;

  const product = products?.find(info => info.slug === slug);
  return product;
};

export const productFilter = state => {
  // const { location } = state.router;
  const { products, searchKeys } = state?.products;
  // // console.log('products: ', products);

  // const parsed = queryString.parse(location.search);
  const options = productOptions(state);
  // gives {lob:'Health', product:'id'}
  let filteredRows = [...products];

  if (options.lob && options.lob.length > 0) {
    filteredRows = filteredRows.filter(
      e =>
        !e?.filterLob ||
        options.lob.some(
          option =>
            e.filterLob?.toLowerCase()?.includes(option?.toLowerCase()) ||
            option?.toLowerCase() === 'all',
        ),
    );
  }

  filteredRows = filteredRows.filter(el => {
    return el != null;
  });

  if (options.search && options.search.length > 0 && filteredRows.length > 0) {
    // // console.log('search: ', options.search);
    // const obj = filteredRows && filteredRows.length > 0 ? filteredRows[0] : {};
    // // console.log('obj: ', fuseOptions(obj));
    // // console.log('filteredRows: ', filteredRows);
    // const reduceObj = reduceKeys(obj);
    // // console.log('reduceObj: ', reduceObj);

    const fuse = new Fuse(filteredRows, fuseOptions(searchKeys));
    let searchResults = fuse.search(options.search);

    if (!searchResults || searchResults.length === 0) {
      options.search = `*${options.search}*`;
      searchResults = fuse.search(options.search); // .replace(/ /g, '|')
      options.search = trimOneChar(options.search, '*');
    }
    // console.log('searchResults: ', searchResults);
    filteredRows = searchResults.map(result => result.item);
  }

  const filterCount = filteredRows.length;

  filteredRows = filteredRows.filter(el => {
    return el != null;
  });

  filteredRows = keySort(filteredRows, 'positionNo');

  return { rows: filteredRows, options, filterCount };
};

export default {
  productFilter,
  productOptions,
  productFind,
};

/*
  if (options.lob && options.slug && options.lob !== 'all') {
    if (options.slug !== 'all') {
      filteredRows = filteredRows.filter(
        e => e?.filterLob === options.lob && e.slug === options.slug,
      );
    } else {
      filteredRows = filteredRows.filter(e => e?.filterLob === options.lob);
    }
  } else if (options.lob && options.lob !== 'all') {
    filteredRows = filteredRows.filter(e => e?.filterLob === options.lob);
  } else if (options.slug && options.slug !== 'all') {
    filteredRows = filteredRows.filter(e => e.slug === options.slug);
  }

  */
