import { appThemeNameStorage, userSettingsExpireTime } from '../utils/variables';
import { themeTypes } from '../themes';
import Cookies from '../cookies/cookies';
// import Cookies from './cookies';
// Cookies.setCookie(appThemeNameStorage, themeName);

export const getAppThemeName = () => {
  const themeName = Cookies.getCookie(appThemeNameStorage) || themeTypes.light; // localStorage.getItem(appThemeNameStorage)
  // // console.log('themeName: ', themeName);
  return themeName;
};

export const removeAppThemeName = () => {
  // if (localStorage.getItem(appThemeNameStorage)) {
  // localStorage.removeItem(appThemeNameStorage);
  // }
  if (Cookies.getCookie(appThemeNameStorage)) {
    Cookies.deleteCookie(appThemeNameStorage);
  }
  return null;
};

export const setAppThemeName = themeName => {
  if (themeName) {
    // localStorage.setItem(appThemeNameStorage, themeName);
    Cookies.setCookie(appThemeNameStorage, themeName, userSettingsExpireTime);
  }
  return null;
};

export default {
  setAppThemeName,
  removeAppThemeName,
  getAppThemeName,
};
