import {
  GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST,
  GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS,
  GET_DIGITAL_SALES_APPLICATIONS_API_ERROR,
  GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS,
} from '../../../constants/digitalSalesActionNames';
import { createRelativeUrl } from '../../../utils/functions';
import { checkCacheValid } from '../../../utils/functions/cache';
import { getApplicationsUrl } from '../../../apis/digitalSales/getDigitalSalesApplicationsApi';
import { getDigitalSalesApplicationsApi } from '../../../apis/digitalSales';

function getDigitalSalesApplicationsRequest() {
  return {
    type: GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST,
    payload: true,
  };
}

function getDigitalSalesApplicationsSuccess(applications, params, cacheKey) {
  return {
    type: GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS,
    payload: applications,
    params,
    cacheKey,
  };
}

function getDigitalSalesApplicationsError(err) {
  return {
    type: GET_DIGITAL_SALES_APPLICATIONS_API_ERROR,
    payload: err,
  };
}

export function getDigitalSalesApplicationsAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    params.alpha2 = state?.locations?.yourLocation?.alpha2;
    const cacheKey = createRelativeUrl({
      path: getApplicationsUrl,
      queryObject: params,
    });

    const isCacheValid = checkCacheValid(getState, 'digitalSales', cacheKey);
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      return Promise.resolve(null);
    }

    dispatch(getDigitalSalesApplicationsRequest());
    return getDigitalSalesApplicationsApi
      .getDigitalSalesApplications(state, params)
      .then(data => {
        dispatch(getDigitalSalesApplicationsSuccess(data, params, cacheKey));
        return data;
      })
      .catch(error => {
        dispatch(getDigitalSalesApplicationsError(error));
        throw error;
      });
  };
}

function getNextDigitalSalesApplicationsSuccess(prod) {
  return {
    type: GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS,
    payload: prod,
  };
}

export function getNextDigitalSalesApplicationsAction(data) {
  return getNextDigitalSalesApplicationsSuccess(data);
}

export default {
  getDigitalSalesApplicationsAction,
  getNextDigitalSalesApplicationsAction,
};
