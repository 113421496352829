import { GET_HELP_ARTICLE_TITLES_API_SUCCESS } from '../../../../constants/helpCenter/helpCenterActionNames';

export const initialState = {
  helpArticleTitles: [],
};

function helpArticleTitlesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HELP_ARTICLE_TITLES_API_SUCCESS:
      return {
        ...state,
        helpArticleTitles: action.payload,
      };
    default:
      return state;
  }
}

export default helpArticleTitlesReducer;
