import { useSelector, useDispatch } from 'react-redux';
import { getClients } from '../actions/clients';
import { dateToString, toTitleCase } from '../utils/functions';
import { listSelector } from '../selectors';
import { clientsIndexName, customerType } from '../utils/variables';

const useClientData = ({ infiniteScroll = false } = {}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const usertype = useSelector(state => state.auth.usertype);
  const customerId = user?.customer?.id || '';
  const agentId = user?.agent?.id || '';
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: clientsIndexName, infiniteScroll }),
  );
  const allRows = useSelector(state => state.clients.allRows);

  return ({ nextStartIndex, nextItemCount, nextScrollCount, actionIndex }) => {
    const body = {
      customerId,
      agentId: agentId || undefined,
      usertype: usertype || customerType,
      limit: defaultOptions?.itemCount,
      offset: defaultOptions?.startIndex,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: toTitleCase(defaultOptions?.lob?.toString()), // defaultOptions?.lob,
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
    };

    if (allRows && actionIndex > 0 && infiniteScroll) {
      return dispatch(getClients.getNextClientsAction(body));
    }
    if (!allRows || actionIndex === 0) {
      return dispatch(getClients.getClientsAction(body)).catch(err => {
        // console.log('err: ', err);
      });
    }
    return () => {};
  };
};
export default useClientData;
