export default theme => ({
  content: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    scrollbarX: 'scroll',
    width: '100%',
    wordBreak: 'break-word',
    padding: '0px',
    // textAlign: 'center',
    '& .ProseMirror': {
      fontFamily: `${theme.typography.fontFamily}`,
      color: `${theme.palette.text.dark}`,
      fontSize: `${theme.typography.body1.fontSize}`,
      textAlign: 'left',
      whiteSpace: 'initial',
      lineHeight: '29px',
      height: '100%',
      overflow: 'visible',
      padding: '0px',
      background: 'transparent',
      '& p': {
        // fontSize: '1rem !important',
        marginTop: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      '& p.empty-node::before': {
        content: "'' !important",
      },
      '& a': {
        color: theme.palette.primary.main,
      },
      '& ul li::marker': {
        fontColor: `${theme.palette.primary.main} !important`,
        fontSize: '1.2em',
      },
    },
  },
});
