import { useSelector, useDispatch } from 'react-redux';
import { getReceipts } from '../actions/receipts';
import { dateToString, toTitleCase } from '../utils/functions';
import { listSelector } from '../selectors';
import { receiptsIndexName } from '../utils/variables';

const useReceiptData = ({ infiniteScroll = false } = {}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const customerId = user?.customer?.id || '';
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: receiptsIndexName, infiniteScroll }),
  );
  const allRows = useSelector(state => state.receipts.allRows);

  return ({ nextStartIndex, nextItemCount, nextScrollCount }) => {
    const body = {
      id: customerId,
      masterId: customerId,
      limit: defaultOptions?.itemCount,
      offset: defaultOptions?.startIndex,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: toTitleCase(defaultOptions?.lob.toString()), // defaultOptions?.lob,
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
    };

    // cellMeasurerCache.clearAll();
    // listRef.current.recomputeRowHeights();
    // listRef.current.forceUpdate();

    // if (allRows && allRows.length > 0 && startIndex > 0) {
    //   return dispatch(getReceipts.getNextReceiptsAction(body));
    // }
    if (allRows && allRows.length > 0 && infiniteScroll) {
      return dispatch(getReceipts.getNextReceiptsAction(body));
    }
    if (!allRows || allRows.length === 0) {
      return dispatch(getReceipts.getReceiptsAction(body)).catch(err => {
        // console.log('err: ', err);
      });
    }
    return () => {};
  };
};
export default useReceiptData;
