import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button,
  Dialog,
  Hidden,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ImageBox from '../imageBox';
import ContentDisplay from '../contentDisplay';
import Brand from '../brand';
import Header from '../header';

const useStyles = makeStyles(theme => ({
  appBar: {
    // position: 'relative',
    backgroundColor: `inherit !important`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cookieButtonTitle: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
  },
  dialogContent: {
    position: 'relative',
  },
  button: {
    padding: 0,
    width: 'auto !important',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookiePolicyModal = ({
  cookiePolicyButtonTitle,
  cookiePolicyTitle,
  cookiePolicyContent,
  cookiePolicyFeaturedImagePWA,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: 'inline' }}>
      <Button
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        className={classes.button}
      >
        <Typography variant="subtitle1" className={classes.cookieButtonTitle}>
          {cookiePolicyButtonTitle}
        </Typography>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ root: classes.appBar }}
      >
        <Grid container>
          <Grid item lg={4}>
            <Hidden mdDown>
              <div style={{ position: 'relative' }}>
                <ImageBox {...cookiePolicyFeaturedImagePWA} imageBorderRadius={0} />
              </div>
            </Hidden>
          </Grid>
          <Grid item lg={8}>
            <AppBar
              color="default"
              className={classes.appBar}
              position="relative"
              elevation={0}
            >
              <Toolbar classes={{ root: classes.toolbar }}>
                <span />
                <Brand />
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Header title={cookiePolicyTitle} titleVariant="h4" align="center" />
            <Container>
              <ContentDisplay content={cookiePolicyContent} />
            </Container>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default CookiePolicyModal;
