import {
  GET_ALL_HELP_CENTER_CASES_API_ERROR,
  GET_ALL_HELP_CENTER_CASES_API_REQUEST,
  GET_ALL_HELP_CENTER_CASES_API_SUCCESS,
  GET_SINGLE_HELP_CENTER_CASE_API_ERROR,
  GET_SINGLE_HELP_CENTER_CASE_API_REQUEST,
  GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS,
  GET_SURVEY_LINK_ERROR,
  GET_SURVEY_LINK_REQUEST,
  GET_SURVEY_LINK_SUCCESS,
  GET_TICKET_HISTORY_API_ERROR,
  GET_TICKET_HISTORY_API_REQUEST,
  GET_TICKET_HISTORY_API_SUCCESS,
  RE_OPEN_OR_CANCEL_CASE_API_ERROR,
  RE_OPEN_OR_CANCEL_CASE_API_REQUEST,
  RE_OPEN_OR_CANCEL_CASE_API_SUCCESS,
  GET_REQUIRED_DOCUMENTS_LIST_ERROR,
  GET_REQUIRED_DOCUMENTS_LIST_REQUEST,
  GET_REQUIRED_DOCUMENTS_LIST_SUCCESS,
} from '../../../constants/helpCenter/getAllHelpCenterCases';
import { getOnlyStringKeys, reduceKeys } from '../../../utils/functions';
import { persistKey, domain, investmentLob } from '../../../utils/variables';
import { cardStatus } from '../../../containersV2/helpCenter/checkStatus';

export const initialState = {
  response: null, // { payload: summaryData },
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
  allHelpCenterCases: [],
  singleHelpCenterCase: [],
  ticketHistory: null,
  reOpenOrCancelCase: null,
  surveyLink: null,
  requiredDocumentsList: [],
  [persistKey]: null,
};

const createAllCases = payloadArray => {
  if (!Array.isArray(payloadArray)) {
    const cases = [
      {
        index: 0,
        ticketNumber: payloadArray.case[0].ticketnumber,
        issueStatus: cardStatus.Resolved.includes(
          payloadArray.case[0]['statuscode@OData.Community.Display.V1.FormattedValue'],
        )
          ? 'Resolved'
          : cardStatus.IN.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'Info Needed'
          : cardStatus.IP.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'In Progress'
          : cardStatus.Delayed.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'Delayed'
          : cardStatus.Cancelled.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'Cancelled'
          : payloadArray.case[0]['statuscode@OData.Community.Display.V1.FormattedValue'],
        createdOn:
          payloadArray.case[0]['createdon@OData.Community.Display.V1.FormattedValue'],
        createdOnSystemFormat: payloadArray.case[0]?.createdon,
        issueSubject: payloadArray.case[0]['aa.gg_issuetypeparent'],
        failureTime:
          payloadArray.case[0][
            'slakpiinstance1.failuretime@OData.Community.Display.V1.FormattedValue'
          ],
        ticketCreatedBy:
          payloadArray.case[0][
            '_customerid_value@OData.Community.Display.V1.FormattedValue'
          ] == 'GLOC Guest'
            ? payloadArray.case[0][
                '_gg_guestuser_value@OData.Community.Display.V1.FormattedValue'
              ]
            : payloadArray.case[0][
                '_customerid_value@OData.Community.Display.V1.FormattedValue'
              ],
        email: payloadArray.email,
        phone: payloadArray.phone,
      },
    ];
    return cases;
  }
  const allCases = payloadArray.map((object, index) => {
    const position = index;

    return {
      index: position,
      ticketNumber: object?.ticketnumber,
      issueStatus: cardStatus.Resolved.includes(
        object['statuscode@OData.Community.Display.V1.FormattedValue'],
      )
        ? 'Resolved'
        : cardStatus.IN.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'Info Needed'
        : cardStatus.IP.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'In Progress'
        : cardStatus.Delayed.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'Delayed'
        : cardStatus.Cancelled.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'Cancelled'
        : object['statuscode@OData.Community.Display.V1.FormattedValue'],
      createdOn: object['createdon@OData.Community.Display.V1.FormattedValue'],
      createdOnSystemFormat: object?.createdon,
      ticketCreatedBy:
        object['_customerid_value@OData.Community.Display.V1.FormattedValue'] ==
        'GLOC Guest'
          ? object['_gg_guestuser_value@OData.Community.Display.V1.FormattedValue']
          : object['_customerid_value@OData.Community.Display.V1.FormattedValue'],
      issueSubject: object['aa.gg_issuetypeparent'],
      failureTime:
        object['slakpiinstance1.failuretime@OData.Community.Display.V1.FormattedValue'],
    };
  });

  return allCases;
};

const createTicketHistory = payloadData => {
  if (!payloadData) return null;

  const ticketHistoryContent = [];

  if (payloadData.notes.length > 0) {
    payloadData.notes.forEach((element, index) => {
      if (element.subject.includes('Case is')) {
        const newElement = {
          subject: element.subject,
          date: element.createdon,
        };
        ticketHistoryContent.push(newElement);
      }
    });
    if (payloadData.appointments.length > 0) {
      payloadData.appointments.forEach((element, index) => {
        if (element.subject) {
          const newElement = {
            subject: element.subject.toUpperCase(),
            date: element.createdon,
          };
          ticketHistoryContent.push(newElement);
        }
      });
    }
  }

  const ticketHistory = { ...payloadData, ticketHistoryContent };

  return ticketHistory;
};

function helpCenterCasesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HELP_CENTER_CASES_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_ALL_HELP_CENTER_CASES_API_SUCCESS: {
      // const response = action.payload;
      const allCases = createAllCases(action?.payload) || [];

      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        // response, // newProducts,
        allHelpCenterCases: allCases,
        [persistKey]: nowTime,
      };
    }
    case GET_ALL_HELP_CENTER_CASES_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    case GET_SINGLE_HELP_CENTER_CASE_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS: {
      const caseDetails = createAllCases(action?.payload) || [];
      // const response = action.payload;

      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        // response, // newProducts,
        singleHelpCenterCase: caseDetails,
        [persistKey]: nowTime,
      };
    }
    case GET_SINGLE_HELP_CENTER_CASE_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    case GET_TICKET_HISTORY_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_TICKET_HISTORY_API_SUCCESS: {
      const updatedTicketHistory = createTicketHistory(action?.payload) || null;
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        // response, // newProducts,
        ticketHistory: updatedTicketHistory,
        [persistKey]: nowTime,
      };
    }
    case GET_TICKET_HISTORY_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    case RE_OPEN_OR_CANCEL_CASE_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case RE_OPEN_OR_CANCEL_CASE_API_SUCCESS: {
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        // response, // newProducts,
        reOpenOrCancelCase: action.payload,
        [persistKey]: nowTime,
      };
    }
    case RE_OPEN_OR_CANCEL_CASE_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    case GET_SURVEY_LINK_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_SURVEY_LINK_SUCCESS: {
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        surveyLink: action.payload,
        [persistKey]: nowTime,
      };
    }
    case GET_SURVEY_LINK_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    case GET_REQUIRED_DOCUMENTS_LIST_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_REQUIRED_DOCUMENTS_LIST_SUCCESS: {
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        requiredDocumentsList: action.payload,
        [persistKey]: nowTime,
      };
    }
    case GET_REQUIRED_DOCUMENTS_LIST_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    default:
      return state;
  }
}
export default helpCenterCasesReducer;
