import React from 'react';
import { Stack, Chip, Icon, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StatusStack = ({ statuses, loading }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      {loading ? (
        <Skeleton height={20} width="90%" animation="wave" />
      ) : (
        <>
          {statuses &&
            statuses.map(x => (
              <Chip
                size="small"
                label={x?.status}
                sx={{
                  color: theme?.palette[x?.color]?.main,
                  backgroundColor: theme?.palette[x?.color]?.background,
                  borderColor: theme?.palette[x?.color]?.main,
                  borderStyle: 'solid',
                  borderWidth: '1px',
                }}
                icon={
                  <Icon
                    sx={{
                      color: `${theme?.palette[x?.color]?.main} !important`,
                    }}
                  >
                    {x?.icon}
                  </Icon>
                }
              />
            ))}
        </>
      )}
    </Stack>
  );
};

export default StatusStack;
