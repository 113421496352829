import { LOCATION_CHANGE } from 'connected-react-router';
import {
  UPDATE_TEMP_STATE,
  RESET_TEMP_STATE,
  APP_READY_STATE,
  HIDE_BACK_BUTTON_STATE,
} from '../../constants/tempActionNames';

const initialState = {
  dontReload: false,
  scrollKey: '',
  filterOptions: null,
  defaultSearchType: null,
  searchOptions: null,
  filterOpen: false,
  filterTitle: null,
  tempInvestmentsCount: null,
  settingsOpen: false,
  isMobile: false,
  appReady: false,
  hideBackButton: false,
  wizardLoaded: false,
};

function tempReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TEMP_STATE: {
      return {
        ...state,
        ...action?.payload,
      };
    }
    case RESET_TEMP_STATE: {
      return { ...initialState };
    }

    case LOCATION_CHANGE: {
      return {
        ...initialState,
        reloadWizard: state.reloadWizard,
        wizardLoaded: state.wizardLoaded,
        dontReload: state.dontReload,
        appReady: state.appReady,
        settingsOpen: state.settingsOpen,
        filterOpen: state.filterOpen,
        tempInvestmentsCount: state.tempInvestmentsCount,
      };
    }

    case APP_READY_STATE: {
      return {
        ...state,
        appReady: true,
      };
    }

    case HIDE_BACK_BUTTON_STATE: {
      return {
        ...state,
        hideBackButton: action?.payload,
      };
    }
    default:
      return state;
  }
}

export default tempReducer;
