import { gql } from 'graphql-request';

const HEADER_FIELDS = gql`
  fragment header on ComponentWizardComponentsHeader {
    Header_slug: slug
    Header_title: title
    Header_subTitle: subTitle
    Header_align: align
    Header_titleColor: titleColor
    Header_subTitleColor: subTitleColor
    Header_titleVariant: titleVariant
    Header_subTitleVariant: subTitleVariant
    Header_layoutPosition: layoutPosition
    Header_name: name
    Header_positionNo: positionNo
    Header_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    Header_isConditionalComponent: isConditionalComponent
    Header_conditionalValue: conditionalValue
    Header_conditionalKey: conditionalKey
    Header_conditionalOperator: conditionalOperator
    Header_conditionalFieldType: conditionalFieldType
    Header_conditionalAction: conditionalAction
    Header_disable: disable
    Header_show: show
    Header_title_en: title_en
    Header_title_nl: title_nl
    Header_subTitle_en: subTitle_en
    Header_subTitle_nl: subTitle_nl
    Header_titleAlign: titleAlign
    Header_subTitleAlign: subTitleAlign
    Header_conditions: conditions {
      ...condition
    }
  }
`;

// export default HEADER_FIELDS;
// exports.default = HEADER_FIELDS;
export default HEADER_FIELDS;
