import { gql } from 'graphql-request';

const DOWNLOAD_BUTTON_FIELDS = gql`
  fragment downloadButton on ComponentWizardComponentsDownloadButton {
    DownloadButton_slug: slug
    DownloadButton_layoutPosition: layoutPosition
    DownloadButton_positionNo: positionNo
    DownloadButton_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    DownloadButton_name: name
    DownloadButton_label: label
    DownloadButton_isConditionalComponent: isConditionalComponent
    DownloadButton_conditionalValue: conditionalValue
    DownloadButton_conditionalKey: conditionalKey
    DownloadButton_conditionalOperator: conditionalOperator
    DownloadButton_conditionalFieldType: conditionalFieldType
    DownloadButton_conditionalAction: conditionalAction
    DownloadButton_disable: disable
    DownloadButton_show: show
    DownloadButton_downloadUrls: downloadUrls {
      ...nonRecursiveOption
    }
    DownloadButton_defaultDownloadText: defaultDownloadText
    DownloadButton_inverseBackgroundColor: inverseBackgroundColor
    DownloadButton_buttonVariant: buttonVariant
    DownloadButton_buttonSize: buttonSize
    DownloadButton_buttonColor: buttonColor
    DownloadButton_downloadConfig: downloadConfig {
      xs
      sm
      md
      align
    }
    DownloadButton_spacing: spacing
    DownloadButton_fullWidth: fullWidth
    DownloadButton_storeKeyValue: storeKeyValue
    DownloadButton_icon: icon
    DownloadButton_labelAlign: labelAlign
    DownloadButton_tooltip: tooltip
    DownloadButton_label_en: label_en
    DownloadButton_label_nl: label_nl
    DownloadButton_tooltip_en: tooltip_en
    DownloadButton_tooltip_nl: tooltip_nl
    DownloadButton_conditions: conditions {
      ...condition
    }
  }
`;

// export default DOWNLOAD_BUTTON_FIELDS;
// exports.default = DOWNLOAD_BUTTON_FIELDS;
export default DOWNLOAD_BUTTON_FIELDS;
