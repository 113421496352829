import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  useMediaQuery,
  Typography,
  Popover,
  Popper,
  Paper,
  Box,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from 'react-router-dom';
import { useSmall } from '../../../../../hooks';
import { updateTempState } from '../../../../../actions/temp';
import { ContentDisplay } from '../../../../common';

const useStyles = makeStyles(theme => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: theme.palette.background.banner,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    borderColor: theme.palette.text.primary,
    borderWidth: theme.shape.borderWidth,
    borderStyle: 'solid',
  },
  popoverTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: theme.palette.background.banner,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    borderColor: theme.palette.text.primary,
    borderWidth: theme.shape.borderWidth,
    borderStyle: 'solid',
    [theme.breakpoints.up('md')]: {
      minWidth: '30vw',
      maxWidth: '60vw',
    },
    minWidth: '90vw',
    maxWidth: '60vw',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      top: '0px !important',
      left: '0px !important',
    },
  },
  customArrow: {
    color: theme.palette.secondary.main,
  },
}));

const CustomTooltip = ({
  title,
  url = '',
  style = {},
  icon,
  children,
  fontSize = 'medium',
  showInModal,
  content,
}) => {
  const classes = useStyles();
  const isSmall = useSmall();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  console.log('content: ', content);
  const handleClick = event => {
    if (showInModal) {
      const tooltipObj = {
        content: title,
      };
      dispatch(updateTempState.updateTempStateAction({ alertPopUp: tooltipObj }));
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (isSmall) {
    return (
      <span style={style}>
        {children && (
          <Box sx={{ display: 'inline-block', mr: 1 }} onClick={handleClick}>
            {children}
          </Box>
        )}

        <IconButton
          aria-label="help"
          size="small"
          sx={{ display: 'inline-block' }}
          onClick={handleClick}
        >
          {icon || <HelpOutlineIcon fontSize={fontSize} />}
        </IconButton>
        {!showInModal && (
          <Popover
            id={id}
            classes={{ paper: classes.popoverTooltip }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ zIndex: 1000, minHeight: '20rem', position: 'absolute' }}
            disablePortal
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {title && <Typography>{title}</Typography>}
            {url && (
              <Link color="primary" to={url}>
                View more
              </Link>
            )}
            {content && content?.length > 11 && <ContentDisplay content={content} />}
          </Popover>
        )}
      </span>
    );
  }
  return (
    <span onMouseEnter={handleClick} onMouseLeave={handleClose} style={style}>
      {children && <Box sx={{ display: 'inline-block', mr: 1 }}>{children}</Box>}
      <Box sx={{ display: 'inline-block' }}>
        <IconButton aria-label="help" size="small">
          {icon || <HelpOutlineIcon fontSize={fontSize} />}
        </IconButton>
      </Box>
      {!showInModal && (
        <Popper
          style={{ zIndex: 1000 }}
          disablePortal
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <Paper className={classes.popoverTooltip}>
            {title && <Typography>{title}</Typography>}
            {url && (
              <Link color="primary" to={url}>
                View more
              </Link>
            )}
            {content && content?.length > 11 && <ContentDisplay content={content} />}
          </Paper>
        </Popper>
      )}
    </span>
  );
};

export default CustomTooltip;
