import {
  UPDATE_LIVE_CHAT_BRIGHT_PATTERN,
  UPDATE_LIVE_CHAT_CRM_D365,
  UPDATE_END_LIVE_CHAT,
  SET_BRIGHT_PATTERN_CONTEXT,
  SET_CRM_CONTEXT,
} from '../../constants/liveChatActionNames';

export const initialState = {
  context: null,
  chatId: null,
  history: [],
};

const liveChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LIVE_CHAT_BRIGHT_PATTERN: {
      const payload = action?.payload;
      return {
        ...state,
        chatId: payload.chatId,
      };
    }
    case UPDATE_LIVE_CHAT_CRM_D365: {
      const payload = action?.payload;
      return {
        ...state,
        chatId: payload.chatId,
      };
    }
    case SET_BRIGHT_PATTERN_CONTEXT: {
      return {
        ...state,
        context: action.payload,
      };
    }
    case SET_CRM_CONTEXT: {
      return {
        ...state,
        context: action.payload,
      };
    }
    case UPDATE_END_LIVE_CHAT: {
      return {
        ...state,
        context: null,
        chatId: null,
      };
    }
    default:
      return state;
  }
};

export default liveChatReducer;
