import {
  GET_POLICIES_API_REQUEST,
  GET_POLICIES_API_SUCCESS,
  GET_POLICIES_API_ERROR,
} from '../../constants/helpCenter/getPolicies';
import {
  SUBMIT_HELP_REQUEST_API_REQUEST,
  SUBMIT_HELP_REQUEST_API_SUCCESS,
  SUBMIT_HELP_REQUEST_API_ERROR,
} from '../../constants/helpCenter/submitHelpRequest';

import {
  DOCUMENTS_UPLOAD_API_REQUEST,
  DOCUMENTS_UPLOAD_API_SUCCESS,
  DOCUMENTS_UPLOAD_API_ERROR,
} from '../../constants/helpCenter/documentsUpload';

import {
  NOTIFY_ME_ON_UPDATE_API_REQUEST,
  NOTIFY_ME_ON_UPDATE_API_ERROR,
  NOTIFY_ME_ON_UPDATE_API_SUCCESS,
} from '../../constants/helpCenter/notifyMeOnUpdate';

import {
  GET_REQUEST_HELP_DROPDOWN_VALUES_API_REQUEST,
  GET_REQUEST_HELP_DROPDOWN_VALUES_API_ERROR,
  GET_REQUEST_HELP_DROPDOWN_VALUES_API_SUCCESS,
  GET_QNR_VALUES_API_REQUEST,
  GET_QNR_VALUES_API_ERROR,
  GET_QNR_VALUES_API_SUCCESS,
} from '../../constants/helpCenter/dropdown';

import {
  ADD_HELP_FORM_DATA_SUCCESS,
  EMPTY_HELP_FORM_DATA_SUCCESS,
} from '../../constants/helpCenter/helpCenterActionNames';

import { persistKey } from '../../utils/variables';

export const initialState = {
  response: null, // { payload: summaryData },
  isPending: false,
  isError: false,
  isSuccess: false,
  isSubmitRequestSuccess: false,
  isSubmitRequestPending: false,
  isSubmitRequestError: false,
  isUpdateContactPending: false,
  isUpdateContactError: false,
  isUpdateContactSuccess: false,
  isDocumentsUploadPending: false,
  isDocumentsUploadError: false,
  isDocumentsUploadSuccess: false,
  isDropdownValuesPending: false,
  isDropdownValuesError: false,
  isDropdownValuesSuccess: false,

  isQnrValuesPending: false,
  isQnrValuesError: false,
  isQnrValuesSuccess: false,

  userDetails: null,
  error: null,
  submitRequestErrorerror: null,
  policies: [],
  caseNumber: '',
  accountId: '',
  [persistKey]: null,
  helpFormData: {},
};

const createPolicies = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  const policies = payloadArray.map((object, index) => {
    const position = index;

    return {
      ...object,
      index: position,
      id: object?.policyId,
      name: object?.policyName,
      type: object?.policyType,
    };
  });

  return policies;
};

function helpCenterReducer(state = initialState, action) {
  switch (action.type) {
    case GET_POLICIES_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_POLICIES_API_SUCCESS: {
      // const response = action.payload;

      const policies = createPolicies(action?.payload) || [];

      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        // response, // newProducts,
        policies,
        [persistKey]: nowTime,
      };
    }
    case GET_POLICIES_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
      };

    case SUBMIT_HELP_REQUEST_API_REQUEST:
      return {
        ...state,
        isSubmitRequestPending: true,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: false,
      };
    case SUBMIT_HELP_REQUEST_API_SUCCESS: {
      // const response = action.payload;

      const caseNumber = action?.payload?.caseNumber;
      const accountId = action?.payload?.AccountId;

      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: true,
        // response, // newProducts,
        caseNumber,
        accountId,
      };
    }
    case SUBMIT_HELP_REQUEST_API_ERROR:
      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: true,
        isSubmitRequestSuccess: false,
        error: action.payload,
      };

    case NOTIFY_ME_ON_UPDATE_API_REQUEST:
      return {
        ...state,
        isUpdateContactPending: true,
        isSUpdateContactError: false,
        isUpdateContactSuccess: false,
      };
    case NOTIFY_ME_ON_UPDATE_API_SUCCESS: {
      // const response = action.payload;

      const updateContactMessage = action?.payload?.message;
      const accountId = action?.payload?.accountId;
      const updateContactStatus = action?.payload?.message;
      return {
        ...state,
        isUpdateContactPending: false,
        isUpdateContactError: false,
        isUpdateContactSuccess: true,
        // response, // newProducts,
        updateContactMessage,
        updateContactStatus,
        accountId,
      };
    }
    case NOTIFY_ME_ON_UPDATE_API_ERROR:
      return {
        ...state,
        isUpdateContactPending: false,
        isUpdateContactError: true,
        isUpdateContactSuccess: false,
        // error: action.payload,
      };

    case DOCUMENTS_UPLOAD_API_REQUEST:
      return {
        ...state,
        isDocumentsUploadPending: true,
        isDocumentsUploadError: false,
        isDocumentsUploadSuccess: false,
      };
    case DOCUMENTS_UPLOAD_API_SUCCESS: {
      // const response = action.payload;
      return {
        ...state,
        isDocumentsUploadPending: false,
        isDocumentsUploadError: false,
        isDocumentsUploadSuccess: true,
      };
    }
    case DOCUMENTS_UPLOAD_API_ERROR:
      return {
        ...state,
        isDocumentsUploadPending: false,
        isDocumentsUploadError: true,
        isDocumentsUploadSuccess: false,
      };

    case GET_REQUEST_HELP_DROPDOWN_VALUES_API_REQUEST:
      return {
        ...state,
        isDropdownValuesPending: true,
        isDropdownValuesError: false,
        isDropdownValuesSuccess: false,
      };
    case GET_REQUEST_HELP_DROPDOWN_VALUES_API_SUCCESS: {
      // const response = action.payload;

      return {
        ...state,
        isDropdownValuesPending: false,
        isDropdownValuesError: false,
        isDropdownValuesSuccess: true,
      };
    }
    case GET_REQUEST_HELP_DROPDOWN_VALUES_API_ERROR:
      return {
        ...state,
        isDropdownValuesPending: false,
        isDropdownValuesError: true,
        isDropdownValuesSuccess: false,
      };

    case GET_QNR_VALUES_API_REQUEST:
      return {
        ...state,
        isQnrValuesPending: true,
        isQnrValuesError: false,
        isQnrValuesSuccess: false,
      };
    case GET_QNR_VALUES_API_SUCCESS: {
      // const response = action.payload;

      return {
        ...state,
        isQnrValuesPending: false,
        isQnrValuesError: false,
        isQnrValuesSuccess: true,
      };
    }
    case GET_QNR_VALUES_API_ERROR: {
      return {
        ...state,
        isQnrValuesPending: false,
        isQnrValuesError: true,
        isQnrValuesSuccess: false,
      };
    }

    case ADD_HELP_FORM_DATA_SUCCESS: {
      const response = action.payload || {};

      const helpFormData = {
        ...state.helpFormData,
        ...response,
      };

      return {
        ...state,
        helpFormData,
      };
    }

    case EMPTY_HELP_FORM_DATA_SUCCESS: {
      const helpFormData = {};

      return {
        ...state,
        helpFormData,
      };
    }

    default:
      return state;
  }
}
export default helpCenterReducer;
