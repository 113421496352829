import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select,
  FormControl,
  MenuItem,
  Input,
  // IconButton,
  Tooltip,
  useMediaQuery,
  Avatar,
  Grid,
  Container,
  Typography,
  InputLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { updateYourLocation } from '../../../actions/mainLayout';
import { postIframe, formatImageUrlFromStrapi, keySort } from '../../../utils/functions';
import { defaultIntlId, defaultAlpha2 } from '../../../utils/variables';
import { scrollStyles } from '../../../styles';
import messages from './message';
import styles from './style';
import CountryChecker from './countryChecker';
import { countryVerificationStorage } from '../../../storage';
import { useSmall, useRouter } from '../../../hooks';
import routes from '../../../utils/variables/routes';

const displayFlag = 'flag';
const displayFlagButton = 'flagButton';
const displayFullText = 'fullText';

const useStyles = makeStyles(styles);
const useScrollStyles = makeStyles(scrollStyles);

function Location({ view, reverseColor, ...props }) {
  const classes = useStyles();
  const scrollClasses = useScrollStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const intl = useIntl();
  const countrySelectorRef = useRef();
  let allLocations = useSelector(state => state.locations.allLocations);

  allLocations = keySort(allLocations, 'positionNo');
  // // console.log('allLocations: ', allLocations);

  let yourLocation = useSelector(state => state.locations.yourLocation);
  // // console.log('yourLocation: ', yourLocation);
  const isSmall = useSmall();

  const tempLocation = allLocations?.find(x => x?.alpha2 === yourLocation?.alpha2);
  const countryCode = tempLocation?.alpha2 || defaultAlpha2;
  // // console.log('countryCode: ', countryCode);
  yourLocation = allLocations?.find(x => x?.alpha2 === countryCode);

  const sendDataToIframe = location => {
    postIframe({
      yourLocation: location,
    });
  };

  const handleChange = newAlpha2 => {
    // // console.log('event: ', event);
    const newCountryValue = newAlpha2;
    // // console.log('newCountryValue: ', newCountryValue);
    // dispatch(newCountryValue);
    const newLocation = allLocations?.find(x => x.alpha2 === newCountryValue);
    // console.log('newLocation: ', newLocation);
    dispatch(updateYourLocation.updateYourLocationAction(newLocation));
    sendDataToIframe(newLocation);

    const currentRoute = router?.pathname;
    if (
      !currentRoute.includes(routes.portfolio.index) &&
      currentRoute !== routes.wizards.quote &&
      currentRoute !== routes.products.index &&
      currentRoute !== routes.cart.index
    ) {
      setTimeout(function() {
        router.push(routes.home);
      }, 50);
    }
  };
  const yourCurrentLocationText = intl.formatMessage(messages.yourCurrentLocation);

  if (view === displayFullText) {
    return (
      <Container>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-label">Location</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id={props.id}
            name="countryCode"
            value={countryCode}
            fullWidth
            onChange={event => {
              countryVerificationStorage.removeCountryVerification();
              handleChange(event.target.value);
            }}
            label="Location"
            ref={countrySelectorRef}
          >
            {allLocations &&
              allLocations.map(option => {
                return (
                  <MenuItem
                    key={option?.alpha2}
                    value={option?.alpha2}
                    selected={countryCode === option?.alpha2}
                  >
                    {`${option?.name} - ${option?.alpha2}`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <CountryChecker ref={countrySelectorRef} countryChange={handleChange} />
      </Container>
    );
  }
  return (
    <span>
      <FormControl className={classnames(classes.root)}>
        <Tooltip title={`${yourCurrentLocationText} ${yourLocation?.name}`}>
          <Select
            ref={countrySelectorRef}
            labelId="select-country-label"
            id="standard-select-country"
            size="small"
            value={countryCode}
            onChange={event => {
              countryVerificationStorage.removeCountryVerification();
              handleChange(event.target.value);
            }}
            IconComponent={() => null}
            className={classnames(classes.countryDropdown)}
            classes={{
              icon: classes.hideIcon,
              select: classes.hideIconPadding,
            }}
            input={<Input disableUnderline />}
            inputProps={{
              classes: {
                root: classes.inputRoot,
              },
            }}
            MenuProps={{
              classes: {
                paper: classnames({
                  [classes.dropdownStyle]: true,
                  [scrollClasses.scrollbar]: !isSmall,
                }),
              },
            }}
            renderValue={alpha2 => {
              // // console.log('alpha2: ', alpha2);
              // const option = locations.payload.find(x => x.countryCode === value);
              const option = allLocations?.find(x => x.alpha2 === alpha2);
              if (view === displayFlag) {
                // // console.log('option: ', option);
                return (
                  <img
                    src={
                      option.staticFlagLink
                        ? option?.staticFlagLink
                        : formatImageUrlFromStrapi(option?.flag)?.url
                    }
                    className={classes.flag}
                    alt="Logo"
                  />
                );
              }

              if (view === displayFlagButton) {
                // // console.log('option: ', option);
                return (
                  <Avatar
                    alt={option.alpha2}
                    src={
                      option.staticFlagLink
                        ? option?.staticFlagLink
                        : formatImageUrlFromStrapi(option?.flag)?.url
                    }
                    classes={{
                      root: classnames(classes.avatar),
                      img: classnames(classes.flagButton),
                    }}
                  />
                );
              }

              return (
                <span
                  className={classnames(classes.inlineBlock, {
                    [classes.reverseColor]: reverseColor,
                  })}
                >
                  {option?.name}
                </span>
              );
            }}
          >
            {allLocations.map(option => {
              return (
                <MenuItem
                  key={option?.alpha2}
                  value={option?.alpha2}
                  className={classes.menuItem}
                >
                  <>
                    {view === displayFlag && (
                      <>
                        <img
                          style={{ marginRight: 10 }}
                          src={
                            option.staticFlagLink
                              ? option?.staticFlagLink
                              : formatImageUrlFromStrapi(option?.flag)?.url
                          }
                          className={classes.flag}
                          alt="Logo"
                        />

                        <Tooltip title={option?.name}>
                          <span className={classes.countryName}>{option?.name}</span>
                        </Tooltip>
                      </>
                    )}
                    {view === displayFullText && (
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Tooltip title={option?.name}>
                            <span className={classes.countryName}>{option?.name}</span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                    {view === displayFlagButton && (
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <Avatar
                            alt={option.alpha2}
                            src={
                              option.staticFlagLink
                                ? option?.staticFlagLink
                                : formatImageUrlFromStrapi(option?.flag)?.url
                            }
                            classes={{
                              root: classnames(classes.avatar, classes.avatarMenuItem),
                              img: classnames(classes.flagButton),
                            }}
                          />
                        </Grid>
                        <Grid item xs={9} align="left">
                          <Tooltip title={option?.name}>
                            <Typography className={classes.countryName}>
                              {option?.name}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                  </>
                </MenuItem>
              );
            })}
          </Select>
        </Tooltip>
      </FormControl>
      <CountryChecker myRef={countrySelectorRef} countryChange={handleChange} />
    </span>
  );
}

Location.propTypes = {
  view: PropTypes.oneOf([displayFlag, displayFullText, displayFlagButton]),
  reverseColor: PropTypes.bool,
};

Location.defaultProps = {
  view: displayFlag,
  reverseColor: false,
};

export default Location;

/*
           IconComponent={() => (
              <ArrowDropDownIcon
                onChange={event => {
                  countryVerificationStorage.removeCountryVerification();
                  handleChange(event.target.value);
                }}
                className={classnames(
                  { [classes.none]: view === displayFlag || view === displayFlagButton },
                  {
                    [classes.inlineBlock]:
                      view !== displayFlag && view !== displayFlagButton,
                  },
                  {
                    [classes.reverseColor]: reverseColor,
                  },
                )}
              />
            )}
            */

/*
  // console.log(locations);
  // console.log(locations.success);

  if (locations.length === 0) {
    return (
      <FormControl className={classes.formControl}>
        <Select
          labelId="select-country-label"
          id="standard-select-country"
          IconComponent={() => <ArrowDropDownIcon className={classes.none} />}
          size="small"
          value={country}
          onChange={handleChange}
          className={classes.countryDropdown}
          input={<Input disableUnderline />}
          inputProps={{
            classes: {
              root: classes.inputRoot,
            },
          }}
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
          renderValue={value => {
            const option = countries.find(x => x.value === value);
            return option.label;
          }}
        >
          {countries.map(option => (

          ))}
        </Select>
      </FormControl>
    );
  }
  */
