import {
  CREATE_RENEWAL_ORDER_API_REQUEST,
  CREATE_RENEWAL_ORDER_API_SUCCESS,
  CREATE_RENEWAL_ORDER_API_ERROR,
  RENEW_POLICY_API_REQUEST,
  RENEW_POLICY_API_SUCCESS,
  RENEW_POLICY_API_ERROR,
  UPDATE_ORDER_API_REQUEST,
  UPDATE_ORDER_API_SUCCESS,
  UPDATE_ORDER_API_ERROR,
  UPDATE_ORDER_ITEM_API_REQUEST,
  UPDATE_ORDER_ITEM_API_SUCCESS,
  UPDATE_ORDER_ITEM_API_ERROR,
  GET_RENEWAL_DATA_API_REQUEST,
  GET_RENEWAL_DATA_API_SUCCESS,
  GET_RENEWAL_DATA_API_ERROR,
  AMEND_POLICY_API_REQUEST,
  AMEND_POLICY_API_SUCCESS,
  AMEND_POLICY_API_ERROR,
  LOCATION_API_REQUEST,
  LOCATION_API_SUCCESS,
  LOCATION_API_ERROR,
  DELIVERY_OPTIONS_API_REQUEST,
  DELIVERY_OPTIONS_API_SUCCESS,
  DELIVERY_OPTIONS_API_ERROR,
  ADD_CHANGE_REQUEST_API_REQUEST,
  ADD_CHANGE_REQUEST_API_SUCCESS,
  ADD_CHANGE_REQUEST_API_ERROR,
  SEND_INVOICE_API_REQUEST,
  SEND_INVOICE_API_SUCCESS,
  SEND_INVOICE_API_ERROR,
  CAN_POLICY_RENEW_API_REQUEST,
  CAN_POLICY_RENEW_API_SUCCESS,
  CAN_POLICY_RENEW_API_ERROR,
  PAYMENT_OPTIONS_API_REQUEST,
  PAYMENT_OPTIONS_API_SUCCESS,
  PAYMENT_OPTIONS_API_ERROR,
  GET_RENEWAL_POLICY_API_REQUEST,
  GET_RENEWAL_POLICY_API_SUCCESS,
  GET_RENEWAL_POLICY_API_ERROR,
  RESET_RENEWAL_SUCCESS,
  NOTIFY_PAYMENT_API_REQUEST,
  NOTIFY_PAYMENT_API_SUCCESS,
  NOTIFY_PAYMENT_API_ERROR,
  RESET_NOTIFY_PAYMENT_SUCCESS,
  UPDATE_AGREED_CLAUSE_API_ERROR,
  UPDATE_AGREED_CLAUSE_API_SUCCESS,
  UPDATE_AGREED_CLAUSE_API_REQUEST,
} from '../../constants/renewalActionNames';
import { RESET_RENEWAL_ORDER_SUCCESS } from '../../constants/miscActionNames';
import { createPolicies } from '../portfolio/insurance';
//   import payloadData from './payloadData';

export const initialState = {
  // response: { payload: payloadData },
  response: null,
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
  createRenewalOrderResponse: null,
  isCreateRenewalOrderPending: false,
  isCreateRenewalOrderError: false,
  isCreateRenewalOrderSuccess: false,
  createRenewalOrderError: null,
  renewalOrderData: null,
  originalSumInsured: null,
  renewPolicyResponse: null,
  isRenewPolicyPending: false,
  isRenewPolicyError: false,
  isRenewPolicySuccess: false,
  renewPolicyError: null,
  updateOrderResponse: null,
  updateOrderError: null,
  isUpdateOrderPending: false,
  isUpdateOrderSuccess: false,
  isUpdateOrderError: false,
  updateOrderItemResponse: null,
  updateOrderItemError: null,
  isUpdateOrderItemPending: false,
  isUpdateOrderItemSuccess: false,
  isUpdateOrderItemError: false,
  renewPolicyHomeResponse: null,
  isRenewPolicyHomePending: false,
  isRenewPolicyHomeError: false,
  isRenewPolicyHomeSuccess: false,
  renewPolicyHomeError: null,
  isAmendPolicyPending: false,
  isAmendPolicySuccess: false,
  isAmendPolicyError: false,
  amendPolicyError: null,
  amendPolicyResponse: null,
  isLocationPending: false,
  isLocationSuccess: false,
  isLocationError: false,
  locationError: null,
  locationResponse: null,
  isDeliveryOptionsPending: false,
  isDeliveryOptionsSuccess: false,
  isDeliveryOptionsError: false,
  deliveryOptionsError: null,
  deliveryOptionsResponse: null,
  deliveryOptions: [],
  isAddChangeRequestPending: false,
  isAddChangeRequestSuccess: false,
  isAddChangeRequestError: false,
  addChangeRequestError: null,
  addChangeRequestResponse: null,
  addChangeRequest: [],
  isSendInvoicePending: false,
  isSendInvoiceSuccess: false,
  isSendInvoiceError: false,
  sendInvoiceError: null,
  sendInvoiceResponse: null,
  sendInvoice: [],
  collectionDate: [],
  isCanPolicyRenewPending: false,
  isCanPolicyRenewSuccess: false,
  isCanPolicyRenewError: false,
  canPolicyRenewError: null,
  canPolicyRenewResponse: null,
  isPaymentOptionsPending: false,
  isPaymentOptionsSuccess: false,
  isPaymentOptionsError: false,
  paymentOptionsError: null,
  paymentOptionsResponse: null,
  isGetSumInsuredPending: false,
  isGetSumInsuredSuccess: false,
  isGetSumInsuredError: false,
  getSumInsuredError: null,
  getSumInsuredResponse: null,
  isSetSumInsuredPending: false,
  isSetSumInsuredSuccess: false,
  isSetSumInsuredError: false,
  setSumInsuredError: null,
  setSumInsuredResponse: null,
  isResetSumInsuredPending: false,
  isResetSumInsuredSuccess: false,
  isResetSumInsuredError: false,
  resetSumInsuredError: null,
  resetSumInsuredResponse: null,
  isRenewalPolicyPending: false,
  isRenewalPolicySuccess: false,
  isRenewalPolicyError: false,
  renewalPolicyError: null,
  renewalPolicyResponse: null,
  notifyPaymentResponse: null,
  notifyPaymentError: null,
  isNotifyPaymentError: false,
  isNotifyPaymentPending: false,
  isNotifyPaymentSuccess: false,
  renewalPolicy: null,
  isUpdateAgreedClauseError: false,
  isUpdateAgreedClausePending: false,
  isUpdateAgreedClauseSuccess: false,
  updateAgreedClauseResponse: null,
  updateAgreedClauseError: null,
};

function renewalReducer(_state = initialState, action) {
  const state = {
    ..._state,
    isSuccess: false,
    isAmendPolicySuccess: false,
    isLocationSuccess: false,
    isDeliveryOptionsSuccess: false,
    // createRenewalOrderResponse: null,
  };
  switch (action.type) {
    case CREATE_RENEWAL_ORDER_API_REQUEST:
      return {
        ...state,
        isCreateRenewalOrderPending: true,
        isCreateRenewalOrderError: false,
        isCreateRenewalOrderSuccess: false,
      };
    case CREATE_RENEWAL_ORDER_API_SUCCESS: {
      const renewalOrderData = action?.payload?.payload?.renewalOrder;
      renewalOrderData.policyId = action?.params?.policyId;
      const originalSumInsured = renewalOrderData?.insuredItems;

      return {
        ...state,
        isCreateRenewalOrderPending: false,
        isCreateRenewalOrderError: false,
        isCreateRenewalOrderSuccess: true,
        createRenewalOrderResponse: action?.payload,
        renewalOrderData,
        originalSumInsured,
      };
    }
    case CREATE_RENEWAL_ORDER_API_ERROR:
      return {
        ...state,
        isCreateRenewalOrderPending: false,
        isCreateRenewalOrderError: true,
        isCreateRenewalOrderSuccess: false,
        createRenewalOrderError: action?.payload,
      };
    case RESET_RENEWAL_ORDER_SUCCESS:
      return {
        ...state,
        isCreateRenewalOrderPending: false,
        isCreateRenewalOrderError: false,
        isCreateRenewalOrderSuccess: false,
        createRenewalOrderResponse: null,
        renewalOrderData: null,
      };
    case RESET_RENEWAL_SUCCESS:
      return {
        ...state,
        isCreateRenewalOrderPending: false,
        isCreateRenewalOrderError: false,
        isCreateRenewalOrderSuccess: false,
        createRenewalOrderResponse: null,
        renewalOrderData: null,
        isUpdateOrderPending: false,
        isUpdateOrderError: false,
        isUpdateOrderSuccess: false,
        updateOrderResponse: null,
        isRenewPolicyPending: false,
        isRenewPolicyError: false,
        isRenewPolicySuccess: false,
        renewPolicyResponse: null,
      };
    case RENEW_POLICY_API_REQUEST:
      return {
        ...state,
        isRenewPolicyPending: true,
        isRenewPolicyError: false,
        isRenewPolicySuccess: false,
      };
    case RENEW_POLICY_API_SUCCESS: {
      return {
        ...state,
        isRenewPolicyPending: false,
        isRenewPolicyError: false,
        isRenewPolicySuccess: true,
        renewPolicyResponse: action.payload,
      };
    }
    case RENEW_POLICY_API_ERROR:
      return {
        ...state,
        isRenewPolicyPending: false,
        isRenewPolicyError: true,
        isRenewPolicySuccess: false,
        // renewPolicyError: action.payload,
      };
    case UPDATE_ORDER_API_REQUEST:
      return {
        ...state,
        isUpdateOrderPending: true,
        isUpdateOrderError: false,
        isUpdateOrderSuccess: false,
      };
    case UPDATE_ORDER_API_SUCCESS: {
      const renewalOrderData = action?.payload?.payload?.renewalOrder;
      renewalOrderData.policyId = action?.params?.policyId;
      return {
        ...state,
        isUpdateOrderPending: false,
        isUpdateOrderError: false,
        isUpdateOrderSuccess: true,
        updateOrderResponse: action.payload,
        renewalOrderData,
      };
    }
    case UPDATE_ORDER_API_ERROR:
      return {
        ...state,
        isUpdateOrderPending: false,
        isUpdateOrderError: true,
        isUpdateOrderSuccess: false,
        updateOrderError: action.payload,
      };
    case UPDATE_ORDER_ITEM_API_REQUEST:
      return {
        ...state,
        isUpdateOrderItemPending: true,
        isUpdateOrderItemError: false,
        isUpdateOrderItemSuccess: false,
      };
    case UPDATE_ORDER_ITEM_API_SUCCESS: {
      const renewalOrderData = action?.payload?.payload?.renewalOrder;
      renewalOrderData.policyId = action?.params?.policyId;
      return {
        ...state,
        isUpdateOrderItemPending: false,
        isUpdateOrderItemError: false,
        isUpdateOrderItemSuccess: true,
        updateOrderItemResponse: action.payload,
        renewalOrderData,
      };
    }
    case UPDATE_ORDER_ITEM_API_ERROR:
      return {
        ...state,
        isUpdateOrderItemPending: false,
        isUpdateOrderItemError: true,
        isUpdateOrderItemSuccess: false,
        updateOrderItemError: action.payload,
      };
    case GET_RENEWAL_DATA_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_RENEWAL_DATA_API_SUCCESS: {
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        response: action.payload,
      };
    }
    case GET_RENEWAL_DATA_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    case AMEND_POLICY_API_REQUEST:
      return {
        ...state,
        isAmendPolicyPending: true,
        isAmendPolicyError: false,
        isAmendPolicySuccess: false,
      };
    case AMEND_POLICY_API_SUCCESS: {
      return {
        ...state,
        isAmendPolicyPending: false,
        isAmendPolicyError: false,
        isAmendPolicySuccess: true,
        amendPolicyResponse: action.payload,
      };
    }
    case AMEND_POLICY_API_ERROR:
      return {
        ...state,
        isAmendPolicyPending: false,
        isAmendPolicyError: true,
        isAmendPolicySuccess: false,
        // amendPolicyError: action.payload,
      };
    case LOCATION_API_REQUEST:
      return {
        ...state,
        isLocationPending: true,
        isLocationError: false,
        isLocationSuccess: false,
      };
    case LOCATION_API_SUCCESS: {
      return {
        ...state,
        isLocationPending: false,
        isLocationError: false,
        isLocationSuccess: true,
        locationResponse: action.payload,
      };
    }
    case LOCATION_API_ERROR:
      return {
        ...state,
        isLocationPending: false,
        isLocationError: true,
        isLocationSuccess: false,
        // locationError: action.payload,
      };
    case DELIVERY_OPTIONS_API_REQUEST:
      return {
        ...state,
        isDeliveryOptionsPending: true,
        isDeliveryOptionsError: false,
        isDeliveryOptionsSuccess: false,
      };
    case DELIVERY_OPTIONS_API_SUCCESS: {
      const deliveryOptions = action?.payload?.payload?.deliveryOptions || [];
      const response = action?.payload;
      response.payload = null;
      return {
        ...state,
        isDeliveryOptionsPending: false,
        isDeliveryOptionsError: false,
        isDeliveryOptionsSuccess: true,
        deliveryOptionsResponse: response,
        deliveryOptions,
      };
    }
    case DELIVERY_OPTIONS_API_ERROR:
      return {
        ...state,
        isDeliveryOptionsPending: false,
        isDeliveryOptionsError: true,
        isDeliveryOptionsSuccess: false,
        // deliveryOptionsError: action.payload,
      };
    case ADD_CHANGE_REQUEST_API_REQUEST:
      return {
        ...state,
        isAddChangeRequestPending: true,
        isAddChangeRequestError: false,
        isAddChangeRequestSuccess: false,
      };
    case ADD_CHANGE_REQUEST_API_SUCCESS: {
      // const addChangeRequest = action?.payload?.payload?.deliveryOptions || [];
      return {
        ...state,
        isAddChangeRequestPending: false,
        isAddChangeRequestError: false,
        isAddChangeRequestSuccess: true,
        addChangeRequestResponse: action.payload,
        // addChangeRequest,
      };
    }
    case ADD_CHANGE_REQUEST_API_ERROR:
      return {
        ...state,
        isAddChangeRequestPending: false,
        isAddChangeRequestError: true,
        isAddChangeRequestSuccess: false,
        // addChangeRequestError: action.payload,
      };
    case SEND_INVOICE_API_REQUEST:
      return {
        ...state,
        isSendInvoicePending: true,
        isSendInvoiceError: false,
        isSendInvoiceSuccess: false,
      };
    case SEND_INVOICE_API_SUCCESS: {
      // const addChangeRequest = action?.payload?.payload?.deliveryOptions || [];
      return {
        ...state,
        isSendInvoicePending: false,
        isSendInvoiceError: false,
        isSendInvoiceSuccess: true,
        sendInvoiceResponse: action.payload,
        collectionDate: action?.collectionDate,
        // addChangeRequest,
      };
    }
    case SEND_INVOICE_API_ERROR:
      return {
        ...state,
        isSendInvoicePending: false,
        isSendInvoiceError: true,
        isSendInvoiceSuccess: false,
        // sendInvoiceError: action.payload,
      };
    case CAN_POLICY_RENEW_API_REQUEST:
      return {
        ...state,
        isCanPolicyRenewPending: true,
        isCanPolicyRenewError: false,
        isCanPolicyRenewSuccess: false,
      };
    case CAN_POLICY_RENEW_API_SUCCESS: {
      return {
        ...state,
        isCanPolicyRenewPending: false,
        isCanPolicyRenewError: false,
        isCanPolicyRenewSuccess: true,
        canPolicyRenewResponse: action.payload,
      };
    }
    case CAN_POLICY_RENEW_API_ERROR:
      return {
        ...state,
        isCanPolicyRenewPending: false,
        isCanPolicyRenewError: true,
        isCanPolicyRenewSuccess: false,
        // canPolicyRenewError: action.payload,
      };
    case PAYMENT_OPTIONS_API_REQUEST:
      return {
        ...state,
        isPaymentOptionsPending: true,
        isPaymentOptionsError: false,
        isPaymentOptionsSuccess: false,
      };
    case PAYMENT_OPTIONS_API_SUCCESS: {
      return {
        ...state,
        isPaymentOptionsPending: false,
        isPaymentOptionsError: false,
        isPaymentOptionsSuccess: true,
        paymentOptionsResponse: action.payload,
      };
    }
    case PAYMENT_OPTIONS_API_ERROR:
      return {
        ...state,
        isPaymentOptionsPending: false,
        isPaymentOptionsError: true,
        isPaymentOptionsSuccess: false,
        // paymentOptionsError: action.payload,
      };
    // case GET_SUM_INSURED_API_REQUEST:
    //   return {
    //     ...state,
    //     isGetSumInsurePending: true,
    //     isGetSumInsureError: false,
    //     isGetSumInsureSuccess: false,
    //   };
    // case GET_SUM_INSURED_API_SUCCESS: {
    //   return {
    //     ...state,
    //     isGetSumInsuredPending: false,
    //     isGetSumInsuredError: false,
    //     isGetSumInsuredSuccess: true,
    //     getSumInsuredResponse: action.payload,
    //   };
    // }
    // case GET_SUM_INSURED_API_ERROR:
    //   return {
    //     ...state,
    //     isGetSumInsuredPending: false,
    //     isGetSumInsuredError: true,
    //     isGetSumInsuredSuccess: false,
    //     getSumInsuredError: action.payload,
    //   };
    // case SET_SUM_INSURED_API_REQUEST:
    //   return {
    //     ...state,
    //     isSetSumInsuredPending: true,
    //     isSetSumInsuredError: false,
    //     isSetSumInsuredSuccess: false,
    //   };
    // case SET_SUM_INSURED_API_SUCCESS: {
    //   return {
    //     ...state,
    //     isSetSumInsuredPending: false,
    //     isSetSumInsuredError: false,
    //     isSetSumInsuredSuccess: true,
    //     setSumInsuredResponse: action.payload,
    //   };
    // }
    // case SET_SUM_INSURED_API_ERROR:
    //   return {
    //     ...state,
    //     isSetSumInsuredPending: false,
    //     isSetSumInsuredError: true,
    //     isSetSumInsuredSuccess: false,
    //     setSumInsuredError: action.payload,
    //   };
    // case RESET_SUM_INSURED_API_REQUEST:
    //   return {
    //     ...state,
    //     isResetSumInsuredPending: true,
    //     isResetSumInsuredError: false,
    //     isResetSumInsuredSuccess: false,
    //   };
    // case RESET_SUM_INSURED_API_SUCCESS: {
    //   return {
    //     ...state,
    //     isResetSumInsuredPending: false,
    //     isResetSumInsuredError: false,
    //     isResetSumInsuredSuccess: true,
    //     resetSumInsuredResponse: action.payload,
    //   };
    // }
    // case RESET_SUM_INSURED_API_ERROR:
    //   return {
    //     ...state,
    //     isResetSumInsuredPending: false,
    //     isResetSumInsuredError: true,
    //     isResetSumInsuredSuccess: false,
    //     resetSumInsuredError: action.payload,
    //   };
    case GET_RENEWAL_POLICY_API_REQUEST:
      return {
        ...state,
        isRenewalPolicyPending: true,
        isRenewalPolicyError: false,
        isRenewalPolicySuccess: false,
      };
    case GET_RENEWAL_POLICY_API_SUCCESS: {
      const tempArr = [];
      const renewalPolicy = action?.payload?.payload;
      tempArr.push(renewalPolicy);
      const lobTypes = action?.lobTypes;
      const newPolicies = createPolicies(tempArr, lobTypes);
      // // console.log(renewalPolicy);
      // // console.log(tempArr);
      // // console.log(newPolicies);
      const response = action?.payload;
      response.payload = null;
      return {
        ...state,
        isRenewalPolicyPending: false,
        isRenewalPolicyError: false,
        isRenewalPolicySuccess: true,
        renewalPolicyResponse: response,
        renewalPolicy: newPolicies[0],
      };
    }
    case GET_RENEWAL_POLICY_API_ERROR:
      return {
        ...state,
        isRenewalPolicyPending: false,
        isRenewalPolicyError: true,
        isRenewalPolicySuccess: false,
        // renewalPolicyError: action?.payload,
      };
    case NOTIFY_PAYMENT_API_REQUEST:
      return {
        ...state,
        isNotifyPaymentPending: true,
        isNotifyPaymentSuccess: false,
        isNotifyPaymentError: false,
      };
    case NOTIFY_PAYMENT_API_SUCCESS: {
      return {
        ...state,
        notifyPaymentResponse: action.payload,
        isNotifyPaymentPending: false,
        isNotifyPaymentSuccess: true,
        isNotifyPaymentError: false,
      };
    }
    case RESET_NOTIFY_PAYMENT_SUCCESS: {
      return {
        ...state,
        isNotifyPaymentPending: false,
        isNotifyPaymentSuccess: false,
        isNotifyPaymentError: false,
        notifyPaymentError: null,
        notifyPaymentResponse: null,
      };
    }

    case NOTIFY_PAYMENT_API_ERROR:
      return {
        ...state,
        isNotifyPaymentPending: false,
        isNotifyPaymentSuccess: false,
        isNotifyPaymentError: true,
        notifyPaymentError: action.payload,
      };
    case UPDATE_AGREED_CLAUSE_API_REQUEST:
      return {
        ...state,
        isUpdateAgreedClausePending: true,
        isUpdateAgreedClauseSuccess: false,
        isUpdateAgreedClauseError: false,
      };
    case UPDATE_AGREED_CLAUSE_API_SUCCESS: {
      return {
        ...state,
        isUpdateAgreedClausePending: false,
        isUpdateAgreedClauseError: false,
        isUpdateAgreedClauseSuccess: true,
        updateAgreedClauseResponse: action?.payload,
      };
    }
    case UPDATE_AGREED_CLAUSE_API_ERROR:
      return {
        ...state,
        isUpdateAgreedClausePending: false,
        isUpdateAgreedClauseError: true,
        isUpdateAgreedClauseSuccess: false,
        updateAgreedClauseError: action?.payload,
      };

    default:
      return state;
  }
}
export default renewalReducer;
