import { useState, useEffect } from 'react';
import useShouldShowPrompt from './useShouldShowPrompt';
import { webInstallPromptedAtStorage } from '../utils/variables';

const useWebInstallPrompt = () => {
  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const [
    userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ] = useShouldShowPrompt(webInstallPromptedAtStorage);
  // // console.log('userShouldBePromptedToInstall web: ', userShouldBePromptedToInstall);
  useEffect(() => {
    const beforeInstallPromptHandler = event => {
      event.preventDefault();
      // console.log('beforeInstallPromptHandler fired');
      // check if user has already been asked
      if (userShouldBePromptedToInstall) {
        // store the event for later use
        setInstallPromptEvent(event);
      }
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () =>
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, [userShouldBePromptedToInstall]);

  const handleInstallDeclined = () => {
    handleUserSeeingInstallPrompt();
    setInstallPromptEvent(null);
  };

  const handleInstallAccepted = async () => {
    // show native prompt
    installPromptEvent.prompt();

    // decide what to do after the user chooses
    // installPromptEvent.userChoice.then(choice => {
    // if the user declined, we don't want to show the prompt again
    // if (choice.outcome !== 'accepted') {
    // handleUserSeeingInstallPrompt();
    // }
    // setInstallPromptEvent(null);
    // });

    const outcome = await installPromptEvent.userChoice;
    if (outcome.outcome === 'accepted') {
      // console.log('App Installed');
      handleUserSeeingInstallPrompt();
    } else {
      // console.log('App not installed');
    }
    setInstallPromptEvent(null);
  };

  return [
    installPromptEvent,
    handleInstallDeclined,
    handleInstallAccepted,
    userShouldBePromptedToInstall,
  ];
};
export default useWebInstallPrompt;
