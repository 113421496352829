import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography, LinearProgress, Box, Backdrop, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';
import messages from './message';

const useStyles = makeStyles(styles);

const Spinner = props => {
  const {
    loading,
    message,
    style,
    width,
    sx,
    noPleaseWait,
    hasBackDrop,
    backDropStyle,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const pleaseWait = intl.formatMessage(messages.pleaseWait);

  let spinnerContent = (
    <div className={classes.loadingMessage}>
      <div style={loading ? { display: 'block' } : { display: 'none' }}>
        <div className={classes.center}>
          <Box sx={{ width: width || '100%', minWidth: '100px' }}>
            <LinearProgress color="primary" />
          </Box>

          {message && message.length > 0 && (
            <div className={classes.secondaryButton}>
              <Typography style={style} sx={sx} variant="body1">
                {noPleaseWait ? message : `${message}, ${pleaseWait}`}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (hasBackDrop) {
    spinnerContent = (
      <Backdrop style={backDropStyle} open={hasBackDrop}>
        {spinnerContent}
      </Backdrop>
    );
  }

  const refreshPage = () => {
    window.location.reload();
  };

  if (props.error) {
    return (
      <div>
        Error!{' '}
        <Button
          color="primary"
          variant="contained"
          sx={{ width: 'auto', m: 1, px: 1 }}
          onClick={refreshPage}
        >
          Retry
        </Button>
      </div>
    );
  }
  if (props.timedOut) {
    return (
      <div>
        Taking a long time?. Click Retry.{' '}
        <Button
          color="primary"
          variant="contained"
          sx={{ width: 'auto', m: 1, px: 1 }}
          onClick={refreshPage}
        >
          Retry
        </Button>
      </div>
    );
  }

  return <>{spinnerContent}</>;
};

Spinner.defaultProps = {
  loading: true,
  message: '',
};

Spinner.propTypes = {
  loading: PropTypes.bool, // .isRequired,
  message: PropTypes.string,
};

export default Spinner;
