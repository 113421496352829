import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { Typography, Button, Grid, Card, CardContent, Icon } from '@mui/material';
import {
  // alertSeverityTypes,
  // alertVariantTypes,
  textVariantTypes,
  // customColorTypes,
  buttonVariantTypes,
  buttonColorTypes,
  iconSizeTypes,
  // textAlignTypes,
} from '../../propertyValues';
import { useRouter } from '../../../hooks';
import styles from './style';
// import store from '../../../store';

const useStyles = makeStyles(styles);

const NotifyMessage = ({
  icon,
  iconSize,
  iconClass,
  title,
  titleVariant,
  subtitle,
  subtitleVariant,
  description,
  descriptionVariant,
  button1,
  button1Text,
  button1RedirectUrl,
  button1RedirectAction,
  button1RedirectHandle,
  button1Class,
  button2,
  button2Text,
  button2RedirectUrl,
  button2RedirectAction,
  button2RedirectHandle,
  button2Class,
  link,
  linkText,
  linkRedirectUrl,
  linkRedirectAction,
  linkRedirectHandle,
  linkVariant,
  linkClass,
  linkColor,
  showCardStyle,
  error,
  removeShadow,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const storeState = useSelector(state => state);
  // console.log(button2RedirectHandle);

  // console.log(error);
  // // console.log('actionColor: ', actionColor);

  return (
    <div className={classes.root}>
      <Card
        className={classnames(
          { [classes.cardStyleSuccess]: showCardStyle && !error },
          { [classes.cardStyleError]: error },
          classes.card,
        )}
        style={{
          boxShadow: removeShadow ? 'none' : '',
        }}
      >
        {icon && (
          <Icon
            fontSize="80px"
            className={classnames(
              { [classes.iconStyleError]: iconClass === 'error' },
              { [classes.iconStyleInfo]: iconClass === 'info' },
              { [classes.iconStyle]: iconClass === 'success' },
            )}
          >
            {icon}
          </Icon>
        )}
        <CardContent>
          <Grid container spacing={2}>
            {title && (
              <Grid item xs={12}>
                <Typography align="center" variant={titleVariant}>
                  <b>{title}</b>
                </Typography>
              </Grid>
            )}

            {subtitle && (
              <Grid item xs={12}>
                <Typography variant={subtitleVariant}>
                  <b>{subtitle}</b>
                </Typography>
              </Grid>
            )}

            {description && (
              <Grid item xs={12}>
                <Typography variant={descriptionVariant}>{description}</Typography>
              </Grid>
            )}

            {button1 && (
              <Grid item xs={12}>
                <Button
                  onClick={button1RedirectHandle || button1RedirectUrl}
                  // className={button1Class}
                  classes={{
                    root: classnames(
                      { [classes[button1Class]]: button1Class },
                      classes.margin,
                      classes.primaryButton,
                    ),
                  }}
                  //   className={classes.button}
                  fullWidth
                >
                  {button1Text}
                </Button>
              </Grid>
            )}

            {button2 && (
              <Grid item xs={12}>
                <Button
                  onClick={button2RedirectHandle || button2RedirectUrl}
                  classes={{
                    root: classnames(
                      { [classes[button2Class]]: button2Class },
                      classes.margin,
                      classes.secondaryButton,
                    ),
                  }}
                  size="large"
                  //   className={classes.button}
                  fullWidth
                >
                  {button2Text}
                </Button>
              </Grid>
            )}

            {link && (
              <Grid item xs={12}>
                <Button
                  variant={linkVariant}
                  color={linkColor}
                  onClick={linkRedirectHandle || linkRedirectUrl}
                  classes={{
                    root: classnames({ [classes[linkClass]]: linkClass }, classes.margin),
                  }}
                  //   className={classes.button}
                  fullWidth
                  size="large"
                >
                  {linkText}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {button1RedirectAction && button1RedirectAction()}
      {button2RedirectAction && button2RedirectAction()}
      {linkRedirectAction && linkRedirectAction()}
    </div>
  );
};

NotifyMessage.defaultProps = {
  icon: '',
  iconSize: 'large',
  iconClass: 'success',
  title: '',
  subtitle: '',
  description: '',
  titleVariant: 'h6',
  subtitleVariant: 'body1',
  descriptionVariant: 'body1',
  button1: false,
  button1Text: '',
  button1RedirectUrl: '',
  button1Variant: 'contained',
  button1Class: '',
  button1Color: 'inherit',
  button2: false,
  button2Text: '',
  button2RedirectUrl: '',
  button2Variant: 'outlined',
  button2Class: '',
  button2Color: 'inherit',
  link: false,
  linkText: '',
  linkRedirectUrl: '',
  linkVariant: 'text',
  linkClass: '',
  linkColor: 'inherit',
  showCardStyle: false,
  error: false,
  removeShadow: false,
};

NotifyMessage.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.oneOf(iconSizeTypes),
  iconClass: PropTypes.string,
  title: PropTypes.string,
  titleVariant: PropTypes.oneOf(textVariantTypes),
  subtitle: PropTypes.string,
  subtitleVariant: PropTypes.oneOf(textVariantTypes),
  description: PropTypes.string,
  descriptionVariant: PropTypes.oneOf(textVariantTypes),
  button1: PropTypes.bool,
  button1Text: PropTypes.string,
  button1RedirectUrl: PropTypes.string,
  button1Variant: PropTypes.oneOf(buttonVariantTypes),
  button1Color: PropTypes.oneOf(buttonColorTypes),
  button1Class: PropTypes.string,
  button2: PropTypes.bool,
  button2Text: PropTypes.string,
  button2RedirectUrl: PropTypes.string,
  button2Variant: PropTypes.oneOf(buttonVariantTypes),
  button2Color: PropTypes.oneOf(buttonColorTypes),
  button2Class: PropTypes.string,
  link: PropTypes.bool,
  linkText: PropTypes.string,
  linkRedirectUrl: PropTypes.string,
  linkVariant: PropTypes.oneOf(buttonVariantTypes),
  linkColor: PropTypes.oneOf(buttonColorTypes),
  linkClass: PropTypes.string,
  showCardStyle: PropTypes.bool,
  error: PropTypes.bool,
  removeShadow: PropTypes.bool,
};

export default NotifyMessage;
