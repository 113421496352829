// import fileDownload from 'js-file-download';
import FileSaver from 'file-saver';

const createFile = async (filename, url) => {
  const response = await fetch(url, { mode: 'cors' });
  const blob = await response.blob();
  // console.log('blob: ', blob);
  const metadata = {
    type: 'application/pdf',
  };
  const file = new File([blob], filename, metadata);
  return { blob, file };
};

// Convert file to base64 string
const fileToBase64 = async (filename, filepath) => {
  // console.log('filepath: ', filepath);
  // console.log('filename: ', filename);
  const { blob, file } = await createFile(filename, filepath); // new File([filename], filepath);

  // fileDownload(blob, filename);
  FileSaver.saveAs(blob, filename);
  return Promise.resolve(null);

  /*
  const fileURL = URL.createObjectURL(file);
  const win = window.open();
  win.document.open();
  win.document.write(
    `<iframe src="${fileURL}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`,
  );
  win.document.close();

  return new Promise(resolve => {
    // console.log('file: ', file);
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function(event) {
      resolve(event.target.result);
    };
    // Convert data to base64
    reader.readAsDataURL(file);
  });
  */
};

function downloadPDF(pdfSource, filename) {
  const linkSource = pdfSource; // `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

export const downloadFile = (filename, filepath) => {
  fileToBase64(filename, filepath).then(result => {
    // console.log('result: ', result);
    // window.location.href = result;
    // downloadPDF(result, filename);
  });
};
