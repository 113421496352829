// export const domain = 'https://c360-api-test.azurewebsites.net';
// export const nodeDomain = 'https://c360-api-test-node.azurewebsites.net';
export const domain = {
  // auth: 'https://c360-api-test-node.azurewebsites.net',
  router: process.env.REACT_APP_ROUTER_DOMAIN, // 'http://localhost:8086', // 'https://c360-router.azurewebsites.net',
  strapi: process.env.REACT_APP_STRAPI_DOMAIN, // 'https://c360-strapi.azurewebsites.net', //
  // strapi: 'http://localhost:1337', // // 'https://c360-strapi.azurewebsites.net', //
  pwa: process.env.REACT_APP_PWA_DOMAIN,
  publicSite: process.env.REACT_APP_PUBLIC_SITE_DOMAIN, //
  // publicSite: 'http://localhost:3000',
  pagename: process.env.REACT_APP_FAC_HPP_PAGENAME,
  pagesetname: process.env.REACT_APP_FAC_HPP_PAGESETNAME,
  subdomains: {
    auth: process.env.REACT_APP_ROUTER_DOMAIN,
    accounts: process.env.REACT_APP_ROUTER_DOMAIN, // 'https://a029-190-213-94-12.ngrok.io', // 'http://localhost:8081', // "https://api.test.c360.myggonline.app/test",
    customers: process.env.REACT_APP_ROUTER_DOMAIN, // 'http://localhost:8084',
    'account-services': process.env.REACT_APP_ROUTER_DOMAIN, // 'http://localhost:8080'
    cms: process.env.REACT_APP_ROUTER_DOMAIN,
    webstar: process.env.REACT_APP_ROUTER_DOMAIN,
  },
  support: process.env.REACT_APP_SUPPORT_DOMAIN,
};

export const newStorageVersion = 45;

export const facebookAuthPath = `${domain.router}/passport/facebook`;
// export const facebookAuthPath = 'https://c360-auth.azurewebsites.net/facebook';
export const googleAuthPath = `${domain.router}/auth/google`;
export const apiCredentials = {
  subscriptionKey: '3d0934c2c0b741dc91b70118a3cd4705',
};

export const disableRecaptchaEnv = false;
export const introVideoUrl = '/videos/sample_video.mp4';
export const statusOk = 'ok';
export const statusError = 'error';
export const statusSuccess = 'success';

export const customerTimeOut = 60000 * 3; // 20000;
export const agentTimeOut = 20000 * 3 * 5;

export const drawerWidth = 250;
export const maxAppWidth = 1920;

export const persistKey = '_persisted_at';
export const initialEncryptKey = 'guardian_group_c360_app';

export const signInOperation = 'signIn';
export const signUpOperation = 'signUp';
export const resetOperation = 'reset';
export const facebookSignInOperation = 'fbSignIn';
export const facebookSignUpOperation = 'fbSignUp';
export const googleSignInOperation = 'googleSignIn';
export const googleSignUpOperation = 'googleSignUp';
export const allSignUpOperation = 'allSignUp';

export const authFooterHeight = '5px';
export const authHeaderHeight = '10vh';

export const mainFooterHeight = '0px'; // '167px';
export const mainHeaderHeight = '65px';
// export const mainMobileHeaderHeight = '100px';
export const mainMobileHeaderHeight = '60px';
export const mainMobileNotAuthHeaderHeight = '60px';
export const brandLogoHeight = '60px';
export const bottomNavHeight = '60px';

export const getSessionStorageKey = 'getSessionStorage';
export const removeSessionStorageKey = 'removeSessionStorage';
export const sessionStorageKey = 'sessionStorage';

export const appThemeNameStorage = 'appThemeName';
export const accessTokenStorage = 'accessToken';
export const customThemeStorage = 'customTheme';
export const networkStatusStorage = 'networkStatus';
export const alpha2Storage = 'alpha2';
export const languageStorage = 'language';
export const countryVerificationStorage = 'countryVerification';
export const countryVerificationStorageType = 'local';
export const countryVerificationExpireTimeInDays = 1;

export const renewalListing = 'Renewal Listing';
export const transactionBordereau = 'Transaction Bordereau';
export const investmentType = 'investment';
export const insuranceType = 'insurance';
export const insuranceDetailsType = 'insuranceDetails';
export const investmentDetailsType = 'investmentDetails';
export const insuredItemType = 'insuredItemType';

export const motorLob = 'motor';
export const healthLob = 'health';
export const lifeLob = 'life';
export const investmentLob = 'investments';
export const investmentTypeMf = 'mf';
export const investmentTypePw = 'pw';
export const pensionLob = 'pension';
export const homeLob = 'home';
export const otherLob = 'other';
export const allLob = 'all';

export const ggil = 'ggil';

export const netBillingType = 'net';
export const grossBillingType = 'gross';

export const loadTransactionCount = 30;
export const loadClaimsCount = 6;
export const loadRequestsCount = 6;
export const loadQuotesCount = 6;
export const loadInvestmentCount = 3;
export const loadReceiptsCount = 6;
export const loadInsuredItemsCount = 6;

export const claimsIndexName = 'claims';
export const partialClaimsIndexName = 'partiallyFilledClaims';
export const requestsIndexName = 'requests';
export const quotesIndexName = 'quotes';
export const clientsIndexName = 'clients';
export const reportsIndexName = 'reports';
export const clientPoliciesIndexName = 'clientPolicies';
export const receiptsIndexName = 'receipts';
export const insuredItemsIndexName = 'insuredItems';
export const insuranceIndexName = 'insurances';
export const cardItemsListSelectedIndexName = 'cardItemsListSelectedIndices';
export const digitalSalesName = 'digitalSales';
export const portfolioIndexName = 'portfolio';

export const linkType = 'link';
export const dropdownLinkType = 'non_link';
export const dividerType = 'divider';
export const modalLinkType = 'modal';

export const applyLinkTheme = 'applyLinkTheme';

export const defaultIntlId = 'test';

export const migrationSourceGGIL = 'GGIL';
export const migrationSourceGLOC = 'GLOC';

export const searchKey = 'search';

export const agentType = 'agent';
export const agentBRK = 'BRK';
export const agentSAL = 'SAL';
export const agentBIN = 'BIN';
export const customerType = 'customer';
export const groupPlanType = 'group';
// export const storageRoot = 'persist:root';

export const facDomainUrl = process.env.REACT_APP_FAC_HPP_DOMAIN;
export const defaultAlpha2 = 'TT';
export const resAlpha2 = 'RES';

export const consentKey = 'consent';
export const customConsentKey = 'customConsent';
export const acceptAllConsentValue = 'acceptAll';
export const acceptCustomConsentValue = 'acceptCustom';
export const rejectConcentValue = 'reject';

export const iosInstallPromptedAtStorage = 'iosInstallPromptedAt';
export const webInstallPromptedAtStorage = 'webInstallPromptedAt';

export const consentAcceptExpireTime = 365;
export const consentRejectExpireTime = 365;
export const userSettingsExpireTime = 365;

export const campaignExpiryTimeSeconds = 60 * 60 * 3;

export const cookiePolicyVersion = 3;
export const cookiePolicyVersionKey = 'cookiePolicyVersion';

export const calcOperators = {
  Add: '+',
  Subtract: '-',
  Multiple: '*',
  Divide: '/',
  Mod: '%',
};
export const titleDropdownOptions = ['Mr.', 'Mrs.', 'Ms.', 'Dr.'];

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_%*.#()?&-])[A-Za-z\d@$!_%*.#()?&-]{10,20}$/;
export const PASSWORD_MESSAGE =
  'Password should be between 10 and 20 characters long, have at least one upper case letter, one lower case letter, one number and at least one of the following special characters   @$!_%*.#()?&-';

export const searchOptionTypes = {
  resources: 'resources',
  holdings: 'holdings',
  onlineForms: 'online-forms',
  pdfForms: 'pdf-forms',
  products: 'products',
  portfolio: 'portfolio',
  notifications: 'notifications',
  claims: 'claims',
  quotes: 'quotes',
  applications: 'applications',
  receipts: 'receipts',
  clientPolicies: 'client-policies',
  clients: 'clients',
};

export const statusMatured = 'Matured';

export const motorAccidentType = 'Motor Accident';
export const thirdPartyType = 'Third Party';
export const windscreenType = 'Windscreen';
export const keyReplacementType = 'Key Replacement';
/*
export const api360Credentials = {
  // app: 'portal',
  apiKey: 'SUPER_SECRET_API_KEY_AUTH',
  apiSecret: 'SUPER_SECRET_API_SECRET_AUTH',
};
*/
